@use '@restream/styles/scss/tokens';

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;

  color: #172b4d;

  align-self: center;
  margin: 0 4px;
}

.desc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  color: #091e42;

  align-self: center;
  margin: 0 4px;
}

.progress {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  width: 100%;

  margin-bottom: 20px;
}

.step {
  flex: 1;
  max-width: 60px;
  height: 4px;
  background-color: #ebecf0;
  border-radius: tokens.$border-radius-200;

  margin: 0 2px;
}

.active {
  background-color: #2684ff;
}
