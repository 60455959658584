@use 'sass:math';
@use '@restream/styles/scss/variables';

$color-success: #00aa77;
$color-error: #de350b;
$stroke-width: 3px;
$size: 60px;

.checkmarkCircle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: $stroke-width;
  stroke-miterlimit: 10;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

  &.success {
    stroke: $color-success;
  }

  &.error,
  &.warning {
    stroke: $color-error;
  }
}

.checkmark {
  width: $size;
  height: $size;
  border-radius: 50%;
  display: block;
  stroke-width: $stroke-width;
  stroke: white;
  stroke-miterlimit: 10;
  margin: 0 auto;

  &.success {
    box-shadow: inset 0 0 0 $color-success;
    animation: fillsuccess 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  &.error,
  &.warning {
    box-shadow: inset 0 0 0 $color-error;
    animation: fillerror 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }
}

.checkmarkIconError,
.checkmarkIconWarning,
.checkmarkIconSuccess {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards;
}

.checkmarkIconWarning {
  stroke-dashoffset: 6;
  stroke-dasharray: 6;
  animation: warningStroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards;
}

.progress {
  width: $size;
  height: $size;
  border-radius: 50%;
  display: block;
  stroke-width: $stroke-width;
  stroke: $color-success;
  stroke-miterlimit: 10;
  margin: 0 auto;
  transform: rotate(-90deg);

  path {
    display: none;
  }

  circle {
    stroke-dasharray: 130;
    stroke-dashoffset: 130;
    animation: dash 1.5s infinite;
  }
}

@keyframes dash {
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -130;
  }
}

@keyframes warningStroke {
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fillsuccess {
  100% {
    box-shadow: inset 0 0 0 math.div($size, 2) $color-success;
  }
}

@keyframes fillerror {
  100% {
    box-shadow: inset 0 0 0 math.div($size, 2) $color-error;
  }
}
