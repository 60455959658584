@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.card {
  position: relative;
  width: 136px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: #ffffff;
  box-shadow: 0 2px 4px rgb(9 30 66 / 20%);
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  transition: box-shadow 200ms ease;

  &:hover {
    box-shadow: 0 0 0 2px #276fff;
  }
}

.wrapper {
  position: relative;
  top: 4px;
  width: 48px;
  height: 48px;
  margin: auto;
}

.logoWrapper {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.logo {
  position: absolute;
  right: -4px;
  bottom: -4px;
}

.logoType {
  width: 100%;
  height: 100%;
  border-radius: 48px;
}

.cardTitle {
  @include mixins.truncate-text;
  max-width: 100%;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #172b4d;
  margin: 0 0 8px;
}
