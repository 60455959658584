@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.eventCard {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: tokens.$color-gray-700;
  border-radius: tokens.$border-radius-400;
  padding: 8px;

  opacity: 1;
  transition: all 0.2s;

  &.highlight {
    animation: highlight 1.2s ease-out;
  }

  @include media.xs {
    border-radius: tokens.$border-radius-500;
  }
}

@keyframes highlight {
  50% {
    background-color: tokens.$color-gray-600;
  }
}
