.root {
  width: 100%;
  max-width: 100%;

  border-collapse: collapse;
  border-spacing: 0;
}

.th {
  line-height: 1.4;
  padding-bottom: 12px;
  vertical-align: top;
  color: #aeb4bf;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;

  &:first-child {
    width: 165px;
  }
}

.td {
  height: 60px;
  vertical-align: middle;
  color: #ebedf2;
  padding: 16px 8px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  line-height: 1.42857143;

  &:first-child {
    width: 165px;
  }
}
