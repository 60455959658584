@use '@restream/styles/scss/tokens';

.item {
  overflow: hidden;

  &:focus-within {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0 2px var(--mauve-12);
  }
}

.header {
  display: flex;
}

.trigger {
  flex: 1;
  display: flex;
  align-items: center;

  font-family: inherit;
  min-height: 45px;
  text-align: left;
  font-size: 14px;
  line-height: 1;

  padding: 0 20px;
  margin: 0;
  border: none;
  outline: none;
  color: white;
  background-color: transparent;
  transition: all 0.25s ease-out;
  cursor: pointer;

  &:hover {
    background-color: tokens.$color-gray-700;

    &[data-state='closed'] {
      opacity: 0.8;
    }
  }

  &[data-state='open'] {
    background-color: tokens.$color-gray-700;
  }

  &[data-state='open'] > .chevron {
    transform: rotate(90deg);
  }
}

.contentWrapper {
  overflow: hidden;
  font-size: 14px;
  color: white;
  line-height: 20px;
  background-color: tokens.$color-gray-700;

  &[data-state='open'] {
    animation: slideDown 250ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: slideUp 250ms cubic-bezier(0.87, 0, 0.13, 1);
  }
}

.content {
  padding: 0 20px;
}

.chevron {
  width: 14px;
  stroke-width: 2;
  margin-right: 16px;
  color: white;
  transition: transform 250ms cubic-bezier(0.87, 0, 0.13, 1);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
