@use '@restream/styles/scss/tokens';

.title {
  margin-bottom: 8px;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.text {
  font-size: 16px;
  margin-bottom: 16px;
}
