@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.root {
  border-radius: tokens.$border-radius-200;
  overflow: hidden;
}

.mobileModalRoot {
  @include media.xs {
    border-radius: tokens.$border-radius-300;
  }
}
