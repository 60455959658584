@use '@restream/styles/scss/tokens';

.root {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: tokens.$color-gray-900;
  cursor: pointer;

  display: flex;
  align-items: center;
  padding: 6px 0;
}

.alignTop {
  align-items: flex-start;
}

.switch {
  padding: 0;
  margin: 0 6px 0 0;
}

.wrap {
  display: flex;
  flex-direction: column;
  padding-left: 2px;
}

.subtitle {
  color: tokens.$color-gray-500;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
