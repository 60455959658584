@use '@restream/styles/scss/tokens';
@use '../../scss/colors' as colors;

.select {
  .react-select {
    &__control {
      border: 0;
      border-radius: tokens.$border-radius-200;
      background: #091e42;
    }

    &__input {
      color: colors.$fg-color;
    }

    &__placeholder {
      color: colors.$ui-placeholder-color;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      border-radius: tokens.$border-radius-200;
      position: absolute;
      background: colors.$bg-color--lighten-1;
      width: 100%;
      z-index: 100;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    &__value-container {
      padding: 5px 15px;
    }

    &--is-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &__single-value {
      color: colors.$fg-color;
    }

    &__option {
      color: colors.$fg-color;

      &--is-focused {
        background: colors.$bg-color--lighten-1;
        color: colors.$fg-color;
      }

      &--is-selected {
        background: colors.$bg-color--lighten-1;
        color: colors.$fg-color;
      }

      &:hover {
        background: colors.$ui-focus-color;
        color: colors.$fg-color;
      }
    }
  }

  .react-select-light {
    padding: 1px;

    &__control {
      border: 0;
      border-radius: tokens.$border-radius-200;
      background: whitesmoke;
    }
  }
}
