@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 16px;
  color: tokens.$color-gray-700;
  font-size: 14px;
}

.link {
  color: tokens.$color-blue-500;
  margin-left: 4px;
  text-decoration: underline;

  &:hover {
    color: tokens.$color-blue-600;
  }
}
