.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15vh;
}

.imageWrapper {
  margin-bottom: 16px;
}

.description {
  margin-bottom: 24px;
}
