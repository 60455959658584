@use '@restream/styles/scss/tokens';

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  height: 22px;
  border-radius: 8px;
  line-height: 1.4;
  background-color: #2970ff;
}

.deleteItem {
  color: tokens.$color-red-600;
}

.dropdownButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #fff;
  border: 0;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:active,
  &:focus,
  &:hover,
  &:focus-within {
    outline: none;
    background-color: #172b4d;
  }

  & > svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  & .srOnly {
    display: none;
  }

  .btnContainer[data-state='open'] & {
    background-color: #172b4d;
  }
}

.dropdownMenuItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1;
  color: #2b3d5c;
  cursor: pointer;

  &:focus-visible {
    border-radius: 0;
    outline: 0 none;
    background-color: #f5f6f8;
  }

  &,
  &:hover {
    transition: background-color 0.2s linear;
  }

  &:hover {
    background-color: #f5f6f8;
    outline: none;
  }

  & svg {
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  & svg ~ span,
  & span ~ div,
  & span ~ span {
    margin-left: 12px;
  }

  &.danger {
    color: tokens.$color-red-600;
  }

  &.danger:hover {
    background-color: tokens.$color-red-50;
  }

  &.disabled {
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.disabled::selection {
    background-color: none;
  }
}
