@use '@restream/styles/scss/tokens';

.root {
  background-color: tokens.$color-red-600;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
}

.iconWrapper,
.textWrapper {
  display: flex;
}

.title {
  color: tokens.$color-red-25;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.description {
  color: tokens.$color-red-100;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  a,
  button {
    text-decoration: underline;
    margin: 0;
    padding: 0;
    background: unset;
    border: none;
    color: inherit;

    font-size: inherit;
    font-family: inherit;
    font-weight: 500;

    cursor: pointer;
  }
}
