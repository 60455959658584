@use '@restream/styles/scss/media';

.rootModal {
  width: fit-content;
  min-width: 500px;

  @include media.xs {
    width: unset;
    min-width: unset;
  }
}
