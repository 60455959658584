@import '../../../scss/typography';

$font-size: 14px;

.root {
  @include font-14;
  font-size: $font-size;
  color: #091e42;

  h6 {
    font-size: $font-size;
    font-weight: normal;
  }
}

.image {
  width: 100%;
}
