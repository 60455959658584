@use '@restream/styles/scss/tokens';

.loader {
  animation: loader-spin 1.2s linear infinite;
}

.loaderContent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0 24px;
}

.loaderText {
  width: 100%;
  margin-top: 10px;
  // TODO(color): use token
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  text-align: center;
  min-height: 24px;

  &.light {
    // TODO(color): use token
    color: rgba(255, 255, 255, 0.8);
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
