@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.root {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: tokens.$border-radius-200;
  background-color: tokens.$color-blue-25;

  border: 1px solid transparent; // made to prevent jumping on hover
  transition: all 0.15s ease;

  &.clickable {
    cursor: pointer;

    &:hover {
      border: 1px solid tokens.$color-blue-100;
      background-color: tokens.$color-blue-50;

      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
  }
}

.header {
  display: flex;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin: 0 8px 0 12px;

  @include media.xs {
    margin-left: 12px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: 20px;
  color: tokens.$color-gray-900;
  margin: 0;

  @include media.xs {
    margin-bottom: 2px;
  }
}

.badge {
  font-weight: 500;
  font-size: tokens.$font-size-body-xs;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;

  background-color: tokens.$color-blue-600;
  border-radius: 8px;
  margin-left: 4px;
  padding: 0 8px;
}

.text {
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: 20px;
  color: tokens.$color-gray-500;
  text-align: left;

  @include media.xs {
    font-size: tokens.$font-size-body-xs;
    line-height: 18px;
  }
}

.actionContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.upgradeText {
  color: tokens.$color-blue-700;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.429;
}

.upgradeTextIcon {
  display: flex;
  margin-right: 8px;
}
