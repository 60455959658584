@use '@restream/styles/scss/tokens';

.item {
  display: flex;
  background: #fff;
  width: 100%;
  text-align: left;
  font-family: inherit;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border-radius: tokens.$border-radius-200;
  border: 1px solid tokens.$color-gray-200;
  padding: 16px;
  cursor: pointer;
  transition: all 300ms;

  &.highlightType {
    border-radius: 8px;
    border: 1px solid tokens.$color-blue-200;
    box-shadow: 0 2px 4px -2px rgba(41, 112, 255, 0.12),
      0px 4px 8px -2px rgba(41, 112, 255, 0.2);
  }

  &:hover {
    border-color: transparent;
    box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 0px 0px 2px tokens.$color-blue-500;

    & .arrowIcon path {
      stroke: tokens.$color-blue-500;
    }
  }

  &:focus {
    outline: none;
  }

  @keyframes gradient {
    0% {
      background-position: 100% 100%;
    }

    100% {
      background-position: 91% 100%;
    }
  }
}

.content {
  display: flex;
  align-items: center;
}

.description {
  margin-left: 24px;
  flex: 1;
}

.title {
  color: #091e42;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 4px;
  position: relative;
}

.badge {
  display: inline-block;
  border-radius: 6px;
  padding: 2px 6px;
  margin-left: 6px;

  background: tokens.$color-blue-500;
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: 18px;
  color: tokens.$color-blue-25;
}

.subtitle {
  color: #8993a4;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
