.root {
  display: flex;
  min-width: 0;
  min-height: 0;
}

.root[data-orientation='horizontal'] {
  flex-direction: row;
}

.root[data-orientation='vertical'] {
  flex-direction: column-reverse;
}
