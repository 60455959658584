@use '@restream/styles/scss/modals' as modals;

.root {
  @include modals.modal-width;

  padding: 0 24px 24px;
}

.header {
  padding-top: 16px;
  padding-bottom: 16px;

  position: relative;

  margin-bottom: 12px;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: -12px;
}

.backButton {
  position: absolute;
  top: 12px;
  left: -12px;
}

.title {
  color: #091e42;
  text-align: center;
  font-family: Graphik, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.subtitle {
  color: var(--Gray-600, #42526e);
  text-align: center;

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-9, 20px); /* 142.857% */
}

.inputGroup {
  padding-bottom: 0;
  margin-bottom: 24px;
}

.inputGroupError {
  bottom: -20px;
}

.input {
  font-family: Graphik, sans-serif;
  padding: 0;

  border-radius: unset;
}

:global(.dark-theme) {
  .input {
    color: rgba(255, 255, 255, 0.9);
  }
}

.inputWrapper {
  padding: 8px 12px;
}

:global(.dark-theme) {
  .inputWrapper {
    box-shadow: inset 0 0 0 1px #a5adba69;
  }
}

:global(.dark-theme) {
  .textArea {
    box-shadow: inset 0 0 0 1px #a5adba69;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  color: var(--Gray-700, #2b3d5c);
  // box-shadow: inset 0 0 0 1px #ffffff;
  margin-right: 12px;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f5f6f8 !important;
  }
}
