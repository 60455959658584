@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.root {
  display: inline-flex;
  height: 36px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  border-radius: 100px;
  border: none;
  background: rgba(255, 42, 246, 0.1);

  box-shadow: 0 0 10px rgba(255, 42, 246, 0.4);

  cursor: pointer;
  transition: all 0.2s ease-out;

  position: relative;
  z-index: 1;

  &:hover {
    background: rgba(255, 42, 246, 0.25);
    box-shadow: 0 0 15px rgba(255, 42, 246, 0.5);
  }

  &::before {
    content: '';
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    padding: 1px;
    border-radius: inherit;

    background: linear-gradient(90deg, #6e71ff 0%, #ff2af6 100%),
      linear-gradient(
        90deg,
        rgba(110, 113, 255, 0.15) 0%,
        rgba(255, 42, 246, 0.15) 100%
      );

    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    transition: --recordingClip 4s linear;
  }
}

.separator {
  width: 1px;
  height: 12px;
  background: rgba(66, 82, 110, 0.5);
}

.upgrade {
  font-weight: 500;
}
