@use '../../../scss/modals' as modals;

.root {
  @include modals.modal-width;

  color: #091e42;
}

.selectItem {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectItemTitleWrapper {
  display: flex;
  flex-direction: column;
}

.selectItemRoleWrapper {
  display: flex;
}

.label {
  background: #8993a4;
}

.role {
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  margin-right: 6px;
}

.roleDescription {
  font-size: 12px;
  line-height: 16px;
  color: #8993a4;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.highlighted:not(.disabled) {
  background: #f5f5f5;
}

.calloutText {
  color: #172b4d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.link {
  color: #4c9aff;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.link:hover {
  box-shadow: inset 0 -1px 0 0 #4c9aff;
}
