@use '@restream/styles/scss/tokens';

.actionButtonRightIconsWrapper {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.actionButtonIcon,
.actionButtonIconCheck {
  width: 16px;
}

.actionButtonIcon {
  color: inherit;
}

.actionButtonIconCheck {
  width: 16px;
  stroke-width: 2;
  color: tokens.$color-white;
}

.pendingLabel {
  display: flex;
  align-items: center;
}

// todo think about unifying styles with ui-kit
.productMenuSideDivider {
  height: 1px;
  overflow: hidden;
  background-color: tokens.$color-gray-800;
  width: calc(100% + 20px);
  margin: 5.5px -10px;
}

.sectionTitle {
  color: #8993a4;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px 0 8px 16px;
}
