@use '@restream/styles/scss/tokens';

.root {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #344563;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 14px;
  color: #ebecf0;
  overflow: hidden;
  box-sizing: border-box;

  &.group {
    background: #6554c0;
  }

  &.organization {
    background: tokens.$color-green-600;
  }

  &.workspace {
    background: #aab9fa; // todo add tokens or change depending on the product decision
    border-radius: 8px;

    &.extraSmallMode {
      border-radius: 4px;
    }
  }

  &.largeMode {
    width: 64px;
    height: 64px;

    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  &.medium {
    width: 48px;
    height: 48px;
    font-size: 18px;
  }

  &.regularMode {
    width: 36px;
    height: 36px;
    font-size: 14px;
  }

  &.compactMode {
    width: 28px;
    height: 28px;
  }

  &.smallMode {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  &.extraSmallMode {
    width: 16px;
    height: 16px;
    font-size: 10px;
  }

  &.nestedMode {
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 50%;
    height: 50%;
    font-size: 12px;
    border: 1px solid tokens.$color-gray-50;
  }

  &.light {
    &.personal {
      background: tokens.$color-gray-100;
      color: tokens.$color-gray-600;
    }
  }

  &.squareSmall {
    width: 20px;
    height: 20px;
    font-size: 10px;
    border-radius: 4px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
