@use '@restream/styles/scss/tokens';

.root {
  height: 100%;
  position: relative;
  background-color: tokens.$color-gray-700;
  border-top-left-radius: tokens.$border-radius-200;
  border-bottom-left-radius: tokens.$border-radius-200;
  overflow: hidden;
}

.withGrayScale {
  filter: grayscale(20%);
  opacity: 0.56;
}

.draft {
  opacity: 0.5;
  filter: grayscale(70%);
}
