.root {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;

  //min-height: 200px;
}

.callout {
  width: 100%;
}
