@use '@restream/styles/scss/tokens';

.root {
  width: 100%;
  max-width: 380px;

  background: #ffffff;

  padding: 16px 24px;
  border-radius: 12px;
  box-sizing: border-box;
}

.title {
  font-size: 22px;
  color: black;
  text-align: center;
  line-height: 28px;
  margin-bottom: 16px;
}

.input {
  line-height: 16px;
  font-size: 14px;
  height: 16px;
}

.inputWrapper {
  margin-bottom: 16px;
  padding: 12px;

  &.noMarginBottom {
    margin-bottom: 0;
  }
}

.submitButton {
  height: 40px;
  font-size: 14px;
  margin-top: 24px;

  font-family: Graphik-Bold-Web, Helvetica, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;

  &.outline {
    color: tokens.$color-gray-700;
    box-shadow: inset 0 0 0 1px tokens.$color-gray-300;

    &:disabled {
      opacity: 0.6;
    }

    &:hover:not(&:disabled) {
      background: tokens.$color-gray-50;
    }
  }
}

.submitButtonContent {
  font-size: 14px;
  justify-content: center;
}

.googleButtonWrapper,
.ssoButtonWrapper {
  display: flex;
  flex-direction: column;

  height: 40px;
  font-size: 16px;

  font-family: Graphik-Bold-Web, Helvetica, Arial, sans-serif;
  font-weight: 400;

  margin-bottom: 16px;
}

.orText {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;

  font-size: 16px;
  line-height: 16px;
}

.orText:before,
.orText:after {
  position: absolute;
  content: '';
  width: 40%;
  height: 1px;
  background: #ebecf0;
}

.orText:before {
  left: 0;
}

.orText:after {
  right: 0;
}

.otpModeToggle {
  display: flex;
  justify-content: space-between;

  height: 16px;

  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: 16px;
    color: #091e42;
  }

  button {
    font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;
    color: #286fff;

    background: none;
    padding: 0;
    border: none;
    margin: 0;
  }
}

.errorCallout {
  line-height: 16px;
  padding: 11px;
  font-size: 14px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font-size: 16px;

  & .text {
    font-size: inherit;
    line-height: 1.25;
    color: black;
    text-align: center;
    margin: 0;
  }

  & .button,
  & .link {
    color: #286fff;
    text-decoration: underline;

    &.autoLeft {
      margin-left: auto;
    }

    &:hover {
      text-decoration: initial;
    }
  }

  & .button {
    cursor: pointer;
    outline: none;
    border: none;
    font-size: inherit;
    font-weight: 400;
    line-height: normal;

    padding: 0;
    margin: 0;
    background: transparent;
    transition: all 0.2s ease-out;

    &:hover:not(&.disabled) {
      background: transparent;
      color: tokens.$color-blue-500;
    }
  }
}
