@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';
@use '@restream/styles/scss/mixins';

.videoCard {
  display: flex;
  flex-direction: column;
  border-radius: tokens.$border-radius-200;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgb(9 30 66 / 8%);
  transition: box-shadow 200ms ease-in-out;

  &.disabled {
    .preview,
    .thumbnail {
      opacity: 0.24;
      filter: grayscale(1);
    }
  }

  &.videoCardSelected {
    box-shadow: 0 0 0 2px #286fff;

    .videoSelectedOverlay {
      opacity: 1;
    }
  }

  &.videoCardSelectable {
    &:hover:not(:has(div[data-radix-popper-content-wrapper]:hover)):not(
        .videoCardSelected
      ):not(.disabled):not(.forbidden):not(.invalid) {
      box-shadow: 0 0 0 2px tokens.$color-blue-600;
    }
  }

  &.videoCardUnselectable,
  &.videoCardSelectable:not(.videoCardSelected) {
    &:not(.invalid):not(.disabled) {
      cursor: pointer;
    }
  }

  &:not(.disabled):not(.forbidden) {
    .main {
      background: #2a3c5b;
    }
  }

  @include media.xs() {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .main {
    position: relative;
  }

  &:not(&.videoCardSelected) {
    .content {
      &.notReady {
        opacity: 0.5;
      }
    }
  }
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  background: #253858;
  padding: 16px;
  height: 100%;
  overflow: hidden;

  &.infoWrapperLight {
    padding: 12px 8px 12px 16px;
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #6b778c;
  }
}

.textContainer {
  flex: 1;
  min-width: 0;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.errorIcon {
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.name {
  @include mixins.truncate-text;

  color: white;
  min-width: 0;
  cursor: pointer;

  &.nameLight {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #091e42;
  }
}

.description {
  @include mixins.truncate-text;
  display: inline-block;
  width: 100%;

  color: tokens.$color-gray-500;
  font-weight: 400;
  line-height: 18px;
}

.invalidVideoCaption {
  color: #ff8f73;
}

.closeIcon {
  position: absolute;
  right: 16px;
  top: 16px;

  &:hover {
    background: rgba(9, 30, 66, 0.48);
  }
}
