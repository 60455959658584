.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 16px;
}

.imageWrapper {
  margin-bottom: 16px;
}

.titleWrapper {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10%;
}

.title {
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
  text-align: center;
  color: #fff;
  margin-bottom: 16px;
}

.message {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 32px;
}

.squid {
  height: 90px;
}

.button,
.anchorButton {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
  text-decoration: underline;
}

.button {
  padding: 0;
  background: transparent;
  text-decoration: underline;

  &:hover:not(&.disabled) {
    background: transparent;
    color: inherit;
  }
}
