@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.root {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 500px;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

.backButtonWrapper {
  position: absolute;
  left: 20px;
  top: 0;
}

.checkoutFormWrapper {
  border-radius: tokens.$border-radius-200;
  width: 500px;
  background: #ffff;

  @include media.xs {
    width: 100%;
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
