@use '@restream/styles/scss/tokens';

.button {
  color: tokens.$color-gray-700;
  border: 1px solid tokens.$color-gray-300;

  &:hover:not(&:disabled) {
    background: tokens.$color-gray-50;
    border: 1px solid tokens.$color-gray-300;
  }
}
