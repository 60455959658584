@use '~@restream/styles/scss/media' as media;

.root {
  margin-top: 24px;
  display: flex;
}

.toggle {
  width: fit-content;
  margin-right: 10px;
}

.content {
  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #f5f6f8;

    @include media.sm {
      font-size: 16px;
    }
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #97a0af;

    @include media.sm {
      font-size: 16px;
    }
  }
}
