@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  border: 2px solid #5135ff;
  background: rgba(81, 53, 255, 0.8);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  transition: background-color 0.2s ease-out;
  overflow: hidden;
  position: relative;

  margin-top: 20px;

  &:hover {
    .iframeOverlay {
      background: transparent;
    }
  }
}

.main {
  padding: 10px 8px;
  margin-top: auto;
  margin-bottom: auto;

  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 3;

  background: rgba(81, 53, 255, 1);
}

.text {
  color: tokens.$color-gray-200;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.linkText {
  display: inline-flex;
  align-items: center;

  color: white;
  font-size: 13px;

  text-decoration: underline;
}

.linkTextIcon {
  width: 14px;
  height: 14px;
  margin-left: 2px;

  vertical-align: middle;

  & path {
    stroke-width: 2px;
  }
}

.iframeWrapper {
  display: flex;
  position: relative;
}

.iframeOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.25s all ease-out;
}

.playButton {
  border-radius: 100px;
  border: 0px solid var(--Gray-500, #6b778c);
  background-color: rgba(0, 0, 0, 0.5);

  backdrop-filter: blur(4px);
  transition: 0.2s all ease-out;

  margin-bottom: 28px;

  &:hover:not(&:disabled) {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.playButtonIcon {
  fill: white;
  stroke: none;
  opacity: 1;
}

.iframe {
  width: 100%;
  aspect-ratio: 9/16;

  overflow: hidden;
}
