@use '@restream/styles/scss/tokens';
@import '../../../home/constants';
@import '../../../../scss/colors';

.root {
  display: grid;
  grid-template-columns: auto max-content;
  width: fit-content;

  margin: 50px auto 0;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;

  &.withOverlay {
    margin-top: 100px;
  }
}

.eventFlow {
  padding: 0;

  &.emptyVideosStep {
    //padding: 40px;
  }
}

.stepperWrapper {
  max-width: 380px;
  padding: 42px 32px;
  background-color: tokens.$color-gray-50;

  @media (max-width: $mobileMaxWidth) {
    display: none;
  }
}
