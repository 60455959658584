@use '@restream/styles/scss/tokens';
@import '../../../components/scss/modals';

.root {
  @include modal-width;

  color: tokens.$color-gray-900;
}

.body {
  display: flex;
}

.iconWrapper {
  margin-right: 16px;
  display: flex;
}

.text {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  color: tokens.$color-gray-900;
}
