@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';
@use '@restream/styles/scss/media-mf';
@use '@restream/styles/scss/scrollbar';

.root {
  width: 800px;

  @include media.md {
    width: 720px;
  }

  @include media.md {
    width: 540px;

    max-height: 90%;
    height: 90%;
  }

  @include media.xs {
    width: 100%;

    max-height: 90%;
    height: 90%;
  }

  &.fullHeight {
    @include media.xs {
      display: grid;
      grid-template-rows: min-content auto min-content;
      height: inherit;
    }
  }

  &.mobileOnboardingModalRoot {
    @include media.xs {
      max-height: none;
      height: inherit;
      grid-template-rows: auto min-content;
    }
  }

  &.extendedOnboardingInterface {
    width: 500px;
  }
}

.modalHeader {
  &.mobileModalHeader {
    @include media.xs {
      position: sticky;
      top: 0;
      z-index: 5;

      background: white;
      padding-bottom: 24px;
      border-radius: tokens.$border-radius-300 tokens.$border-radius-300 0 0;

      box-shadow: 0 20px 24px -4px rgb(16 24 40 / 8%),
        0 8px 8px -4px rgb(16 24 40 / 3%);
    }
  }

  &.mobileOnboardingModalHeader {
    @include media.xs {
      position: initial;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
}

.modalHeaderAndBodyWrapper {
  @include media.xs {
    position: initial;
    overflow: auto;
    height: inherit;
    display: grid;
    grid-template-rows: max-content auto;
    width: 100%;
    grid-template-columns: 100%;
  }
}

.header {
  font-size: 14px;
  color: #8993a4;

  position: relative;

  padding: 4px 28px;

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }
}

.topContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 14px;
  flex-direction: column;

  @include media-mf.tablet {
    flex-direction: row;
  }

  @include media.xs {
    margin-bottom: 10px;
    flex-direction: column;
  }

  &.extendedOnboardingInterface {
    padding: 0 40px;
  }
}

.tabsContainer {
  display: flex;
}

.tabs {
  margin-bottom: 20px;

  @include media-mf.tablet {
    margin-bottom: 0;
  }

  .tabUploadsNumber {
    margin-left: 4px;

    &.exceededMaxSlots {
      color: tokens.$color-red-400;
    }
  }

  .tab {
    padding: 8px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-transform: none;
    color: #091e42;

    &.activeTab {
      box-shadow: inset 0px -2px 0px #286fff !important;
    }

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.calloutContainer {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
}

.submitButton {
  &:disabled {
    opacity: 0.6;
  }

  &:hover:not(:disabled) {
    background: #4280ff;
  }

  &.fullWidthButton {
    @include media.xs {
      width: 100%;
      height: 36px;
      background: #2970ff;
      border-radius: tokens.$border-radius-200;
    }
  }

  &.extendedOnboardingInterface {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    min-width: fit-content;
    background-color: #155eef;
    border-radius: tokens.$border-radius-200;

    cursor: pointer;
    box-shadow: 0 1px 2px rgb(16 24 40 / 5%);

    & .submitButtonIcon {
      display: block;

      & path {
        stroke: white;
      }
    }
  }
}

.videoUploadButtonWrapperTabs {
  @include media.xs {
    width: 100%;
  }

  .videoUploadButtonTabs {
    color: #004eeb;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    background: #eff4ff;
    border: 1px solid #b2ccff;
    border-radius: tokens.$border-radius-200;

    @include media.xs {
      width: 100%;
    }

    svg {
      height: 20px;
      width: 20px;

      path {
        stroke: #004eeb;
      }
    }

    &:disabled {
      color: #84adff;
      background: #f5f8ff;
      opacity: 1;

      svg {
        path {
          stroke: #84adff;
        }
      }
    }

    &:not(&:disabled) {
      &:hover {
        background: #d1e0ff;
        color: #004eeb;
      }
    }
  }
}

.videoUploadButtonLoaderTabs {
  & circle {
    stroke: tokens.$color-blue-700;
  }
}

.videoUploadButtonWrapperList {
  .videoUploadButtonList {
    width: 220px;
    height: 40px;

    @include media.xs {
      width: auto;
    }
  }
}

.warningMessageList {
  list-style: none;
  font-size: 14px;
  line-height: 22px;

  li {
    display: flex;
    gap: 4px;

    &::before {
      margin-left: 4px;
      content: '-';
    }
  }
}

.uploadAndImportButtonsWrapper {
  display: flex;
}

.groupFirstButton {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.groupLastButton {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
