@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/outline';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //flex-grow: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px;
}

.button {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;

  padding: 0;
  cursor: pointer;
  background: none;
  border: none;

  color: #ebecf0;
  transition: color 200ms ease;

  &:hover {
    color: #fff;
  }

  @include outline.focus-visible-outline;
}

.categoryLabel {
  // Hide it on mobile
  display: none;

  padding: 3px 12px;
  border: 1px solid #6b778c;
  border-radius: 999px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #bac1cc;

  .button:hover & {
    background: rgba(215, 220, 227, 0.1);
  }

  @media (min-width: 576px) {
    display: initial;
  }
}

.text {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.8;
  transition: opacity 200ms ease;

  .button:hover & {
    opacity: 1;
  }
}

.iconContainer {
  padding: 4px;
  border-radius: tokens.$border-radius-150;
  opacity: 0.8;
  transition: opacity 200ms ease, background-color 200ms ease;

  .button:hover & {
    opacity: 1;
  }
}

.iconEdit {
  position: relative;
  top: 1px;
}

.iconButton {
  margin-left: 2px;
  padding: 7px;
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  transition: background-color 200ms ease-out;
  border: 0;
  background-color: transparent;

  .icon {
    color: #a5adba;
    vertical-align: top;
  }

  &:hover {
    background-color: #374561;
    .icon {
      color: #fff;
    }
  }

  @include outline.focus-visible-outline;
}

.streamTitleEdit {
  position: relative;
}
