@use '@restream/styles/scss/tokens';

.promoBlock {
  display: flex;
  align-items: center;
  background: #eff4ff;
  padding: 12px 16px;
  border-radius: tokens.$border-radius-200;
  margin-bottom: 24px;
  position: relative;
}

.promoHeader {
  font-size: 16px;
  line-height: 24px;
  color: #172b4d;
  letter-spacing: 0.01em;
  font-weight: 600;
}

.promoText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #344563;
}

.pairsToggle {
  margin-left: 15px;
}
