@use '../../scss/colors' as colors;

$paddingX: 32px;

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 12px;
}

.content {
  overflow-y: auto;
  padding: 20px $paddingX 32px;
}

.noPermissionsRoot {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.noPermissionsWrapper {
  min-height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.settings_header {
  margin-bottom: 30px;
}

.header {
  font-size: 24px;
  color: colors.$text-color;
  font-weight: 500;
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  margin-left: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 960px;
  padding: 0 20px;
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.boxWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 400px;
}

.box {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 32px;
  background: colors.$bg-color--lighten-1;
  margin: 15px;
  position: relative;
  min-width: 400px;
}

.boxAvatar {
  position: absolute;
  width: 100%;
  top: -30px;
  text-align: center;
  left: 0;
}

.avatar {
  height: 60px;
  border-radius: 50%;
  border: 2px solid colors.$fg-color;
  width: 60px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: colors.$fg-color;
  font-size: 12px;
}

.description {
  color: colors.$fg-color;
  font-size: 14px;
  padding-bottom: 10px;
}

.radioList {
  flex-direction: column;
  margin-bottom: 14px;
}

.label {
  color: colors.$fg-color;
}

.formGroup {
  margin-bottom: 0;
  padding-bottom: 0;
}

.error {
  color: colors.$fg-color;
  background-color: rgba(222, 53, 11, 0.16);
  border: none;
}

.button {
  margin-top: 24px;
}

.footer {
  margin: 15px;
}
