@use '@restream/styles/scss/tokens';

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.root {
  .leftPartWrapper {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .listLoaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;

    &.collapsed {
      height: 15px;
      transition: height 0.1s ease-in;
    }
  }

  .crawledProductsHeader {
    padding: 0 24px 2px;

    @include mobileMedia() {
      padding-top: 16px;
    }

    .crawledProductsHeaderFirstLine {
      padding-bottom: 16px;
      display: grid;
      grid-gap: 6px;
      grid-template-columns: 1fr auto;
      align-items: center;
      justify-content: space-between;

      .storeTitle {
        font-weight: 600;
        // TODO(font): use token
        font-size: 18px;
        line-height: 24px;
        color: tokens.$color-gray-900;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (min-width: 576px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  .crawledProducts {
    position: relative;
    .crawledProductsOverlay {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 8px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: tokens.$border-radius-200;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.4);
    }

    height: 100%;
    overflow: auto;
    padding-bottom: 20px;
  }

  .initialLoaderWrapper {
    max-height: 558px;
    min-height: 558px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include mobileMedia() {
      min-height: calc(100% - 58px);
      max-height: calc(100% - 58px);
    }
  }

  .topGradient {
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    width: 100%;
    height: 28px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.0816599) 20%,
      rgba(255, 255, 255, 0.231775) 33.33%,
      rgba(255, 255, 255, 0.96449) 86.67%,
      #ffffff 100%
    );
    z-index: 1;
  }

  .bottomGradient {
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    width: 100%;
    height: 22px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.0816599) 20%,
      rgba(255, 255, 255, 0.231775) 33.33%,
      rgba(255, 255, 255, 0.96449) 86.67%,
      #ffffff 100%
    );
    z-index: 1;
  }
}

.customLoader {
  & circle {
    stroke: rgba(0, 0, 0, 0.5);
  }
}

.waitAMinute {
  margin-top: 15px;
  width: 100%;
  text-align: center;
}

.tooltipTrigger {
  width: 100%;
  flex-shrink: 1;
}

.tooltipDisabled {
  display: none;
}

.inputContainer {
  position: relative;

  .closeImg {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 13px;
  }
}

.noResultsWrapper {
  box-sizing: border-box;
  padding: 25px 30px;

  .noResults {
    display: grid;
    grid-template-columns: 32px 1fr 77px;
    grid-column-gap: 8px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px dashed #d7dce3;
    border-radius: tokens.$border-radius-200;
    height: 52px;
  }

  .noResultsPart {
    width: 100%;
    height: 100%;
    background: #d7dce3;
    border-radius: tokens.$border-radius-50;
  }

  .noResultsText {
    margin-top: 26px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #42526e;

    & > span.bold {
      font-weight: 600;
    }

    & > span.link {
      margin-left: 10px;
      color: #4c9aff;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.listWrapper {
  position: relative;
}

.errorContainer {
  padding: 0 24px;
}

.errorWrapper {
  width: 100%;
  margin-top: 15px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  box-sizing: border-box;
  padding: 15px;

  & p {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #253858;
  }

  .errorLink {
    margin-left: 5px;
    color: tokens.$color-gray-700;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid tokens.$color-gray-700;
    transition: border-bottom-color 250ms ease, opacity 250ms ease;

    &:hover {
      opacity: 0.9;
      border-bottom-color: #fff2f0;
    }
  }
}

.inputWrapper {
  height: 42px;
}

.searchbarWrapper {
  display: flex;
  gap: 6px;
}

.addMoreBtn {
  appearance: none;
  transition: background-color 0.2s ease-in-out;
  -webkit-appearance: none;
  background: tokens.$color-white;
  border: solid 1px rgba(186, 193, 204, 1);
  color: tokens.$color-gray-700;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 12px;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  cursor: pointer;
  border-radius: tokens.$border-radius-200;
  .addMoreBtnPlus {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }

  .loader {
    width: 26px !important;
    height: 26px !important;
    position: absolute;
    top: calc(50% - 13px);
    left: calc(50% - 13px);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    circle {
      transition: stroke 0.1s ease-in-out;
      color: tokens.$color-gray-400;
    }
  }

  &:disabled {
    cursor: default;
  }

  span {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  &.loading {
    span {
      opacity: 0;
    }
    .loader {
      opacity: 1;
    }
  }

  &:not(:disabled):hover {
    // TODO(color): use token
    background: tokens.$color-gray-50;
  }
}
