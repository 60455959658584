@use '~@restream/styles/scss/colors';
@use '~@restream/styles/scss/outline';
@use '@restream/styles/scss/tokens';

.tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px;
  justify-content: center;
  align-content: center;
  // TODO(color): use token
  background-color: #ebecf0;
  border: 1px solid #ebecf0;
  box-shadow: 0 0 0 1px #ebecf0;
  border-radius: tokens.$border-radius-200;

  margin: auto;
}

.content {
  position: relative;
  width: 560px;
  height: 315px;
  margin: 24px auto;
  border-radius: tokens.$border-radius-300;
  overflow: hidden;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.isActive {
    animation: fadeIn 300ms ease-in 1;
  }
}

.help {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.helpText {
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  text-align: center;
  color: tokens.$color-gray-500;
}

.helpAction {
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  text-align: center;
  color: tokens.$color-blue-500;
  text-decoration: underline;
  transition: opacity 300ms ease;

  cursor: pointer;
  background: none;
  border: none;

  &:hover {
    opacity: 0.9;
  }

  @include outline.focus-visible-outline;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
