@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/modals' as modals;

.root {
  @include modals.modal-width;

  text-align: center;
}

.body {
  margin-bottom: 20px;
}

.bodyTitle {
  margin: 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #172b4d;
}

.bodyDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #8993a4 !important;
  margin-top: 8px;
}

.link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #286fff;
  text-decoration: none;
  display: block;

  &:hover {
    text-decoration: underline;
  }
}

.bottomBackButton {
  margin-top: 32px;
  width: 100%;
  color: #286fff;
  background-color: #fff;
  border: 2px solid #286fff;
  box-sizing: border-box;
  border-radius: tokens.$border-radius-200;
  transition: background-color 200ms ease;

  &:hover {
    color: #fff !important;
    background-color: #286fff !important;
  }
}
