@use '@restream/styles/scss/tokens';

.content {
  z-index: 1051;
  // overflow: hidden;
  border-radius: 6px;
  background-color: black;
  padding: 6px;
  color: #ffffff;
  animation:
    fadeIn 50ms cubic-bezier(0.16, 1, 0.3, 1),
    zoomIn 50ms cubic-bezier(0.16, 1, 0.3, 1);

  text-align: center;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  font-weight: normal;
  font-family: inherit;
}

.content[data-state='closed'] {
  animation:
    fadeOut 50ms cubic-bezier(0.16, 1, 0.3, 1),
    zoomOut 50ms cubic-bezier(0.16, 1, 0.3, 1);
}

// .content[data-side='bottom'] {
//   animation: slideFromTop 50ms cubic-bezier(0.16, 1, 0.3, 1);
// }

// .content[data-side='left'] {
//   animation: slideFromRight 50ms cubic-bezier(0.16, 1, 0.3, 1);
// }

// .content[data-side='right'] {
//   animation: slideFromLeft 50ms cubic-bezier(0.16, 1, 0.3, 1);
// }

// .content[data-side='top'] {
//   animation: slideFromBottom 50ms cubic-bezier(0.16, 1, 0.3, 1);
// }

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.95);
  }
}

@keyframes slideFromTop {
  /* from { transform: translateY(-0.5rem); }
  to { transform: translateY(0); } */
}

@keyframes slideFromRight {
  /* from { transform: translateX(0.5rem); }
  to { transform: translateX(0); } */
}

@keyframes slideFromLeft {
  /* from { transform: translateX(-0.5rem); }
  to { transform: translateX(0); } */
}

@keyframes slideFromBottom {
  /* from { transform: translatey(0.5rem); }
  to { transform: translatey(0); } */
}
