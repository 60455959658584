@use '@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

.accountSelectTriggerButton {
  width: unset;
  padding: 8px;

  max-width: 100%;

  @include media.md {
    max-width: calc(100% - 40px);
  }
}

.accordionItem {
  overflow: hidden;
}

// margin top was applied to child and not to wrapper itself because in some cases wrapper can be empty
.organizationButtonsWrapper {
  & > *:first-child {
    margin-top: 8px;
  }
}

.personalButtonsWrapper,
.workspacesTitle {
  margin-top: 16px;
}

.workspacesTitle {
  display: flex;
  align-items: center;

  color: tokens.$color-gray-500;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  padding-left: 8px;
  margin-bottom: 8px;

  & .button {
    color: inherit;
    font-size: 11px;

    padding: 0;
    background: transparent;

    margin-left: auto;

    &:hover:not(&.disabled) {
      color: tokens.$color-gray-400;
      background: transparent;
    }
  }

  & .buttonLeft {
    margin-right: 4px;
  }
}

.workspacesTitleWrapper {
  display: flex;
  flex: 1;
  gap: 6px;
}

.workspaceName {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.workspacesChevronWrapper {
  display: flex;
  align-items: center;
}

.accordionContent {
  padding-left: 24px;
}
