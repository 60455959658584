@use '@restream/styles/scss/tokens';
@use '../../../../scss/mixins' as mixins;
@import '../../constants';
@import '../constants';

.root {
  @include mixins.display-flex-center;
  justify-content: space-between;
  gap: 32px;
  box-sizing: border-box;
  border: 1px solid tokens.$color-gray-700;
  border-radius: tokens.$border-radius-400;
  padding: 20px;
  width: 100%;
  transition: 0.3s all ease;

  &.hasError {
    border-color: tokens.$color-red-500;
  }
}

.title {
  color: tokens.$color-gray-100;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.subtitle {
  color: tokens.$color-gray-500;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.column {
  @include mixins.column;
}

.row {
  @include mixins.display-flex-center;
  gap: 8px;
  margin-top: 4px;
}

.actionButton {
  @include mixins.linkStyleButton;
  padding: 10px 16px;

  @media (max-width: $smallMobileMaxWidth) {
    background-color: tokens.$color-gray-600;
    color: tokens.$color-gray-50;
    width: 100%;

    &:hover:not(:disabled) {
      background-color: tokens.$color-gray-500;
      color: tokens.$color-gray-50;
    }
  }

  &.error {
    padding: 10px;
  }
}

.errorAlertIcon {
  color: #f04438;
  margin-left: 8px;
}

.popover,
.popoverRoot {
  @include mixins.display-flex-center;
  width: 100%;
}

.popoverRoot {
  &.zIndex {
    z-index: 9;
  }
}

.subtitleSkeleton {
  background: $skeletonBackground;
  border-radius: tokens.$border-radius-200;
  width: 80px;
  height: 20px;
}

.buttonSkeleton {
  background: $skeletonBackground;
  border-radius: tokens.$border-radius-200;
  width: 60px;
  height: 40px;

  @media (max-width: $smallMobileMaxWidth) {
    width: 100%;
  }
}
