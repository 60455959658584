@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/variables' as variables;
@use '../../../../components/scss/modals' as modals;

$padding: variables.$grid-base-value;
$min-width-desktop: 500px;

.root {
  @include modals.modal-width;
}

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;

  @include modals.xs {
    min-width: 300px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.error {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.status {
  margin-top: 4px;
}

.alreadyAddedContainer {
  background: rgba(0, 170, 119, 0.12);
  border-radius: tokens.$border-radius-200;
  padding: 16px;
  animation: longFadeIn 300ms;
}

.alreadyAddedContent {
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  animation: slideIn 500ms;
}

.alreadyAddedText {
  margin: 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #091e42 !important;
}

.targets {
  display: grid;
  grid-gap: 8px;
  margin: 16px 0;
  min-height: 76px;
  max-height: 412px;
  overflow-y: auto;
}

.target {
  margin: 6px 3px; // because of overflow

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(9, 30, 66, 0.2);
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 200ms ease;

  &:focus-visible {
    outline: #015ecc auto 1px;
  }

  .isInteractable &:hover {
    background-color: rgb(9 30 66 / 5%);
  }

  &:disabled {
    cursor: default;
    background-color: #ebecf0 !important;
    box-shadow: none;
  }
}

.info {
  display: grid;
  justify-content: flex-start;
  grid-auto-flow: column;
  grid-gap: 16px;
}

.image {
  width: 48px;
  height: 48px;
  border-radius: 48px;
}

.customImage {
  background-color: #8993a4;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  display: grid;
  grid-gap: 2px;
  margin: auto;
}

.name {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #091e42;

  width: 100%;

  //-webkit-box-orient: vertical;
  //-webkit-line-clamp: 2;
  //display: -webkit-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.targetTypeName {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #505f79;
  text-transform: capitalize;
  width: fit-content;
}

.sectionTitle {
  margin-top: 8px;
  margin-bottom: -8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.01em;
  color: #8993a4 !important;
}

.saveTargetButton {
  margin-top: 18px;
  width: 100%;
  background-color: #2970ff;

  &:not(:disabled):hover {
    background-color: #155eef;
  }

  &:not(:disabled):active {
    background-color: #2970ff;
  }
}

.unavailableSection {
  cursor: default;
  padding: 4px 0;

  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: flex-start;
  align-items: center;
}

.unavailableCounter {
  color: #172b4d;
}

.loaderContainer {
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  margin: auto;
}

.subtitle {
  margin: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8993a4;
}

.subtitleIcon {
  transform: scale(0.75);
}

.tooltipContent {
  word-break: break-word;
  max-width: 350px;
}

.tooltipItem {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 2px 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.link {
  display: block;
  margin-top: 40px;
  text-align: center;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #538cff;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms ease;

  &.visible {
    pointer-events: auto;
    opacity: 1;
  }

  &:hover {
    text-decoration: none;
  }
}

@keyframes longFadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(10px);
    opacity: 0;
  }

  50% {
    transform: translateX(10px);
    opacity: 0.3;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.common {
  line-height: 24px;
  font-size: 16px;
  color: #0a1e42;
}
