@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/scrollbar';

$accountSelectTriggerWidth: 250px;
$accountSelectTriggerHeight: 50px;

.triggerButton {
  width: $accountSelectTriggerWidth;
  padding: 6px 8px;
  border-radius: 8px;
  overflow: hidden;
  outline: none;

  &[data-state='open'] {
    background: #172b4d;
  }
}

.triggerButtonIconWrapper,
.actionButtonRightIconWrapper {
  display: flex;
}

.triggerButtonIconWrapper {
  color: white;
}

.triggerButtonIcon {
  color: tokens.$color-gray-400;
  stroke-width: 2;

  transition: all 200ms;

  &.open {
    color: tokens.$color-gray-100;
    transform: rotate(180deg);
  }
}

.actionButtonIconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButtonIcon,
.actionButtonIconXs {
  flex-shrink: 0;
}

.actionButtonIconCheck {
  stroke-width: 2;
  color: tokens.$color-gray-700;
}

.actionButtonIcon {
  width: 20px;
}

.actionButtonIconXs {
  width: 16px;
}

.actionButtonIconButton {
  background: transparent;
  color: tokens.$color-gray-700;
  padding: 8px;

  &:hover:not(&:disabled) {
    background: tokens.$color-gray-150;
    color: initial;
  }
}

.actionButtonHoverOnlyIcon {
  opacity: 0;
  visibility: hidden;
  transition: all 0.05s linear;
}

.pendingLabel {
  display: flex;
  align-items: center;
}

.failedAccountWrapper {
  display: flex;
  align-items: center;
  padding: 8px 16px;

  & .icon {
    width: 20px;
    stroke-width: 2;
    color: tokens.$color-gray-400;
  }

  & .text {
    font-size: 14px;
    color: tokens.$color-gray-500;
  }
}

.dropdownMenuContent,
.dropdownMenuSubContent {
  width: 280px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(16px);
  border-radius: 8px;
  box-shadow:
    0 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  overflow: hidden;
  padding: 8px;
  z-index: 2147483001; // show account select over the Intercom popup
}

.dropdownMenuSubContent,
.dropdownMenuWorkspacesMenu {
  @include scrollbar.scrollbar(
    $color: #bcbcbc,
    $hover-color: #bcbcbc,
    $active-color: #bcbcbc,
    $track-color: transparent
  );

  overflow-y: scroll;
  scroll-behavior: smooth;
}

.dropdownMenuSubContent {
  max-height: 250px;
}

.dropdownMenuWorkspacesMenu {
  max-height: 240px;
}

.dropdownMenuContent[data-side='top'],
.dropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.dropdownMenuContent[data-side='right'],
.dropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.dropdownMenuContent[data-side='bottom'],
.dropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.dropdownMenuContent[data-side='left'],
.dropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.dropdownMenuItem,
.dropdownMenuRadioItem,
.dropdownMenuSubTrigger {
  color: tokens.$color-gray-700;
  user-select: none;
  outline: none;
  border-radius: 4px;
}
.dropdownMenuSubTrigger[data-state='open'] {
  background-color: tokens.$color-gray-50;
}
.dropdownMenuItem[data-disabled],
.dropdownMenuRadioItem[data-disabled],
.dropdownMenuSubTrigger[data-disabled] {
  color: tokens.$color-gray-300;
  pointer-events: none;
}
.dropdownMenuItem[data-highlighted],
.dropdownMenuRadioItem[data-highlighted],
.dropdownMenuSubTrigger[data-highlighted] {
  &:not(&[data-disabled]) {
    background: tokens.$color-white;

    & .actionButtonHoverOnlyIcon {
      opacity: 1;
      visibility: initial;
    }
  }
}

.dropdownMenuSeparator {
  height: 1px;
  background: tokens.$color-gray-200;
  margin: 8px 16px;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
