@use '@restream/styles/scss/tokens';

.videoCard {
  display: flex;
  flex-direction: column;

  min-width: 0;
  min-height: 0;
  border-radius: tokens.$border-radius-200;
  background: #091e42;
  box-sizing: border-box;

  overflow: hidden;

  &:focus-visible {
    outline: 2px solid #2970ff;
  }
}

.focusOutline {
  outline: 2px solid #2970ff;
}

.main {
  position: relative;

  &.disabled {
    mix-blend-mode: luminosity;
    opacity: 0.25;
  }
}

.content {
  position: relative;
  border-top-left-radius: tokens.$border-radius-200;
  border-top-right-radius: tokens.$border-radius-200;
  overflow: clip;

  &.notReady {
    opacity: 0.5;
  }

  &.clip {
    aspect-ratio: 9/16;
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.description {
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #253858;
  padding: 16px;
  border-bottom-left-radius: tokens.$border-radius-200;
  border-bottom-right-radius: tokens.$border-radius-200;
}

.duration {
  position: absolute;
  color: white;
  border-radius: tokens.$border-radius-200;
  background: rgba(0, 0, 0, 0.7);
  padding: 2px 8px;
  bottom: 8px;
  right: 8px;
  line-height: 20px;
  z-index: 2;

  &.top {
    bottom: initial;
    top: 8px;
  }
}

.textContainer {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}

.dropdownContainer {
  flex-grow: 0;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.name {
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
}

.invalidVideoCaption {
  color: #ff8f73;
  font-size: tokens.$font-size-body-xs;
}

.closeIcon {
  position: absolute;
  right: 16px;
  top: 16px;

  &:hover {
    background: rgba(9, 30, 66, 0.48);
  }
}

.metaInfoBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 16px;
  background: rgba(0, 0, 0, 0.8);
}

.metaTitle {
  font-size: 14px;
  line-height: 20px;
  color: #97a0af;
  margin-bottom: 4px;
}

.metaText {
  font-size: 14px;
  color: #ffffff;
}

.loaderWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
