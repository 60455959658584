@import '../../scss/modals';

.root {
  @include modal-width;
  overflow: hidden;
}

.body {
  margin-top: 18px;
  padding-left: 0;
  padding-right: 0;
}
