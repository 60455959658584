@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  position: relative;
}

.header {
  width: 100%;
}

.blackFriday {
  margin: 0 auto 12px;
  display: block;
  box-shadow: none;

  &.disablePointer {
    cursor: default;
  }
}

.blackFridayDiscount {
  background-color: #bf2 !important;
}

.pricingPlansWrapper {
  background: #fff;
  margin-bottom: 20px;
}

.calloutWrapper {
  margin-bottom: 20px;
}

.toggleWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 2px;
}

.loaderOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeLimitWarningWrapper {
  margin-bottom: 0;
}

.timeLimitWarningTitle {
  font-size: inherit;
  line-height: 20px;
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  color: #f46;
}

.specialOfferWarning {
  cursor: pointer;
  font-size: inherit;
  line-height: normal;
  font-family: inherit;
  font-weight: inherit;
  color: rgba(33, 44, 74, 1);
  padding: 6px 14px;
  border-radius: tokens.$border-radius-200;
  background: rgba(227, 235, 251, 1);
  margin: 8px 0 16px;
}
