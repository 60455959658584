$leftOffset: 12px;
$tabButtonLeftPadding: 12px;
$rootPaddingRight: 24px;
$rootPaddingLeft: 24px;
$rootMobilePaddingLeft: 24px;
$rootSmallMobilePaddingLeft: 12px;
$rootPaddingTop: 24px;
$rootPaddingBottom: 24px;
$rootScrollBarPadding: 2px;

$headerMarginBottom: 12px;
$headerFontSize: 22px;
$headerLineHeight: 1.3;
$headerHeight: $headerMarginBottom + $headerFontSize * $headerLineHeight +
  $rootPaddingTop;

.modalRootClassName {
  height: 613px;
  max-width: calc(100vw - 20px);
  // 99 fixes horizontal scroll bar which appears since RC dialog on top of current
  // width also has `border: 1px` which adds a bit of horizontal width beyond 100vh
  max-height: 99vh;

  @media (min-width: 820px) {
    width: 800px;
  }
}

.modalRoot {
  width: fit-content;
}

.modalHeader {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.root {
  // Used to limit tabs height vertically to the viewport of the modal
  display: flex;
  flex-direction: column;
}

.header {
  font-style: normal;
  font-weight: 700;
  font-size: $headerFontSize;
  line-height: $headerLineHeight;

  color: #172b4d;

  margin-top: $rootPaddingTop - $rootScrollBarPadding;
  margin-bottom: $headerMarginBottom;
  margin-left: $rootSmallMobilePaddingLeft + $tabButtonLeftPadding;

  @media (min-width: 400px) {
    margin-left: $rootMobilePaddingLeft + $tabButtonLeftPadding;
  }

  @media (min-width: 820px) {
    // Allows content scrollbar to reach the top of the modal
    position: absolute;

    margin-left: $tabButtonLeftPadding + $leftOffset;
  }
}

.sidebar {
  margin-top: $headerHeight;
}

.tabs {
  // Allows it to limit height to fit into the modal vertically
  // if there's overflow content inside
  min-height: 0;
  margin-left: $leftOffset;
}

.content {
  padding-top: $headerHeight !important;
  padding-bottom: $rootPaddingBottom - $rootScrollBarPadding !important;

  display: flex;
  justify-content: center;

  padding-left: $rootPaddingLeft;
  padding-right: $rootPaddingRight - $rootScrollBarPadding;
}

.tabContent {
  flex-basis: 440px;
  height: 100%;
  overflow: auto;
}

$textColor: rgba(66, 61, 72, 0.8);

.field {
  + .field {
    margin-top: 16px;
  }
}

.secondaryFieldset {
  margin-top: 24px;
}

.field__append {
  margin-top: 12px;
}

.accordion {
  overflow-y: auto;
  // Allow element to collapse and fit the modal height with an overflow
  min-height: 0;

  padding-left: $rootSmallMobilePaddingLeft;
  padding-right: 12px;

  @media (min-width: 400px) {
    padding-left: $rootMobilePaddingLeft;
    padding-right: 24px;
  }
}
