@use '@restream/styles/scss/media';

.gridContainer {
  position: relative;
  display: grid;
  width: 100%;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);

  &.twoColumns {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media.md {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media.xs {
    grid-template-columns: 1fr;
  }
}
