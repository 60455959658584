@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  padding: 8px 16px;
  justify-content: center;

  background: tokens.$color-red-800;
  backdrop-filter: blur(6px);
}

.text {
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
}

.button {
  height: fit-content;
  padding: 0;

  color: inherit;
  line-height: inherit;

  font-weight: 500;
  text-decoration: underline;

  &:hover:not(&.disabled) {
    background: transparent;
    text-decoration: initial;
  }
}
