.root {
  width: 100%;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.newStreamButton {
  margin-bottom: 12px;
}

.title {
  color: var(--Gray-300, #bac1cc);
  text-align: center;
  /* Body md - 16/Normal */
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-8, 16px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-8, 24px); /* 150% */

  margin-bottom: 8px;
}

.subtitle {
  color: var(--Gray-25, #fafbfc);
  text-align: center;

  /* Heading xs - 24/Semibold */
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-5, 24px);
  font-style: normal;
  font-weight: var(--font-weight-semibold, 600);
  line-height: var(--line-height-5, 32px); /* 133.333% */

  margin-bottom: 48px;
}

.buttonGroup {
  margin-bottom: 48px;
}
