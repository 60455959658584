.description {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #8993a4;
  margin: 0 0 20px;

  &.center {
    text-align: center;
  }
}
