@use '@restream/styles/scss/tokens';

.root {
  padding: 0 12px;
  border-radius: tokens.$border-radius-200;

  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  font-weight: 500;

  color: rgba(255, 255, 255, 0.9);
  background: #ff5630;
}
