.root {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100px;
    background-image: linear-gradient(
      90deg,
      rgb(255 255 255 / 0%) 30%,
      rgb(255 255 255 / 70%),
      rgb(255 255 255 / 0%) 70%
    );
    animation: shine 5s ease-in-out infinite; /* Animation */
  }
}

@keyframes shine {
  0% {
    left: -100px;
    opacity: 0;
  }

  20% {
    left: 100%;
    opacity: 1;
  }

  100% {
    left: 100%;
    opacity: 0;
  }
}
