@use '@restream/styles/scss/tokens';
@use 'sass:math';

$arrow-width: 14px;
$animation-default-time: 150ms;
$transition: opacity $animation-default-time ease-in-out;

.arrow {
  position: absolute;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    width: $arrow-width;
    height: $arrow-width;
    transform: rotate(45deg);
    // TODO(color): use token
    background-color: tokens.$color-white;
  }
}

.popover {
  background-color: tokens.$color-white;
  z-index: 11000;

  &[data-popper-placement^='right'] {
    .arrow::before {
      top: math.div($arrow-width, 2) * -1;
      left: math.div($arrow-width, 2) * -1;
    }
  }

  &[data-popper-placement^='left'] {
    .arrow {
      right: math.div($arrow-width, 2);

      &::before {
        top: math.div($arrow-width, 2) * -1;
      }
    }
  }

  &[data-popper-placement^='top'] {
    .arrow {
      bottom: 0;

      &::before {
        left: math.div($arrow-width, 2) * -1;
        top: math.div($arrow-width, 2) * -1;
      }
    }
  }

  &[data-popper-placement^='bottom'] {
    .arrow::before {
      left: math.div($arrow-width, 2) * -1;
      top: math.div($arrow-width, 2) * -1;
    }
  }
}

.contentEnter {
  opacity: 0;
}

.contentEnterActive {
  opacity: 1;
  transition: $transition;
}

.contentExit {
  opacity: 1;
}

.contentExitActive {
  opacity: 0;
  transition: $transition;

  &.noTransition {
    transition: none;
  }
}
