@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/colors' as color;
@use '../../../../../../components/scss/mixins' as mixins;

.root {
  margin-bottom: 16px;
}

.item {
  display: flex;
  align-items: center;

  cursor: pointer;

  min-width: 0;
}

.eventTitle {
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: #091e42;

  @include mixins.truncate-text;
}

.eventThumbnail {
  height: 24px;
  width: 43px;

  border-radius: tokens.$border-radius-200;

  margin-right: 8px;
}

.item-dropdown {
  padding: 8px;
  transition: all 200ms ease;
}

.item-dropdown.highlighted {
  background: #bde4ff;
}

.selectButton {
  padding: 8px 12px 8px 8px;
}
