@use '@restream/styles/scss/tokens';
@import '../common';
@import '@restream/styles/scss/variables';
@import '../../scss/media';

$icon-width: 24px;

.tooltipOverrides {
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: tokens.$border-radius-300;
}

.blackFridayTooltip {
  border-radius: 100%;
  overflow: hidden;
}

.productMenuDesktop {
  background: tokens.$color-gray-900;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: $padding;
  // TODO(color): use token
  border-right: solid 1px #ffffff17;
  z-index: 1;

  @include xs {
    display: none;
  }

  @include height-sm {
    height: fit-content;
  }
}

.productMenuDesktopLogo {
  text-align: center;
  margin-bottom: 7px;

  @include logo-hover;
}

.productMenuDesktopItems {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding-top: 4px;
}

.productMenuDesktopItem {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  transition: background-color $animation-hover-time;
  position: relative;
  overflow: clip;

  img {
    width: $icon-width;
    height: $icon-width;
    opacity: 0.6;
  }

  &:focus {
    // TODO(color): use token
    box-shadow: 0 0 0 1px #4c9aff;
    outline: none;
  }

  &:hover {
    // TODO(color): use token
    background: $color-background-dark3 !important;
  }
}

.productMenuDesktopItemsEnd {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  padding-bottom: 4px;
}

.productMenuDesktopUkraineItem {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  transition: background-color $animation-hover-time;
  position: relative;

  div {
    border-radius: tokens.$border-radius-200;
  }

  img {
    width: $icon-width;
    height: $icon-width;
    opacity: 0.6;
  }

  &:focus {
    // TODO(color): use token
    box-shadow: 0 0 0 1px #4c9aff;
    outline: none;
  }

  &:hover {
    // TODO(color): use token
    background: $color-background-dark3 !important;
  }
}

.productMenuDesktopItemShiny {
  // TODO(color): use token
  background: linear-gradient(
    97.31deg,
    #9ff3ee,
    rgba(#b29bff, 33%),
    rgba(#28beff, 67%),
    #9ff3ee
  );
  img {
    opacity: 1;
  }
}

.productMenuDesktopButtonContent .productMenuDesktopItemShiny {
  padding: 5px 5px 1px;
}

.productMenuDesktopItem.active {
  // TODO(color): use token
  background: $color-background-dark3;

  img {
    opacity: 1;
  }
}

.productMenuDesktopButtonContent {
  padding: 5px 5px 0 5px;
}

.productMenuDesktopItemBadge {
  position: absolute;
  right: 0;
  min-width: 16px;
  height: 16px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // TODO(color): use token
  background: #ff5230;
  color: tokens.$color-white;
  border-radius: tokens.$border-radius-200;
  font-weight: 500;
  // TODO(font): use token
  font-size: 10px;
  text-align: center;
  padding: 1px 3px;
}

.productMenuDesktopAvatar {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $icon-width + 12px;
  min-height: $icon-width + 12px;
  height: $icon-width + 12px;
  background-color: tokens.$color-gray-800;
  // TODO(color): use token
  color: $color-foreground-dark;
  cursor: pointer;
  border-radius: 50%;
  margin: 12px auto 0;
  transition: background-color $animation-hover-time;
  position: relative;

  &:hover {
    // TODO(color): use token
    background-color: lighten($color-background-dark2, 5%);
  }

  &.hasBadge {
    box-shadow: 0 0 0 1px tokens.$color-white;
    border: 1px solid tokens.$color-gray-900;
    margin-bottom: 6px;
  }

  &.warning {
  }

  .warningWrapper {
    position: absolute;
    top: 0;
    border-radius: 180px;
    left: 0;
    // TODO(color): use token
    background: rgba(9, 30, 66, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .warningEmoji {
      margin-top: -2px;
      font-size: 16px;
    }
  }
}

.avatarBadge {
  display: inherit;
  position: absolute;
  bottom: -2px;
  transform: translateY(50%);
  color: tokens.$color-gray-900;
  border: tokens.$border-width solid tokens.$color-gray-900;
  // TODO(color): use token
  background-color: #ebecf0;
  border-radius: tokens.$border-radius-200;
  padding: 2px;

  // TODO(font): use token
  font-size: 9px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  user-select: none;
}
