.root {
  display: flex;
  width: 64px;
  margin-right: 12px;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background: #172b4d;
}
