.root {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  display: flex;
  background: rgba(9, 30, 66, 0.9);
  z-index: 5;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  transition: all 0.4s;

  &.movedUpContent {
    animation: moveUp 0.25s linear;
  }
}

@keyframes moveUp {
  90% {
    transform: translate(0, -20vh);
  }

  100% {
    opacity: 0;
  }
}

.icon {
  margin-bottom: 20px;
  animation: jump 1s ease-in-out infinite 0.1s;
}

@keyframes jump {
  50% {
    transform: translate(0, -20px);
  }
}

.text {
  max-width: 235px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  color: #ffffff;
}
