@use '../../../../scss/animation' as animation;

$transition: opacity animation.$animation-default-time ease-in-out;

.root {
  position: relative;
}

.content {
  z-index: 1;
}

.content-enter {
  opacity: 0.01;
}

.content-enter-active {
  opacity: 1;
  transition: $transition;
}

.content-exit {
  opacity: 1;
}

.content-exit-active {
  opacity: 0.01;
  transition: $transition;
}
