@use '@restream/styles/scss/tokens';

@mixin base($bg-color: #000) {
  position: relative;
  width: 100%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: $bg-color;
}

.videoElementProviderWrapper {
  @include base;
  flex: 1;
}

.videoRefContainer {
  position: absolute;

  display: block;
  max-width: 100%;
}

.videoPreviewHUD {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 12px 24px;
}

.videoUnsupported {
  @include base(#fff);
  height: 100vh;
  text-align: center;

  & div {
    max-width: 540px;
  }

  & div > svg {
    margin: 0 auto 24px;
  }

  & div > h3 {
    display: block;
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
  }

  & div > p {
    font-size: 18px;
    font-weight: 500;
  }
}

.loaderWrapper {
  position: absolute;
  background-color: rgba(#000, 0.2);
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.error {
  color: tokens.$color-red-400;
}
