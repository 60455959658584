@use '@restream/styles/scss/tokens';

.root {
  h2 {
    color: rgba(250, 251, 252, 1);
    text-align: center;
    margin-bottom: 28px;
  }

  .toggleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .plans {
    display: grid;
    grid-row-gap: 12px;
    grid-template-columns: 1fr;

    .plan {
      display: grid;
      padding: 12px 24px;
      border-radius: tokens.$border-radius-200;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      grid-gap: 12px;
      overflow: hidden;
      border: 1px solid tokens.$color-gray-200;
      transition: all 0.3s;

      &.specialOffer {
        position: relative;
      }

      &:hover {
        border-color: transparent;
        box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
          0px 4px 8px -2px rgba(16, 24, 40, 0.1),
          0px 0px 0px 2px tokens.$color-blue-500;
      }

      .specialOfferBlock {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.1em;
        color: #fff;
        padding: 4px 6px;
        text-transform: uppercase;
        background: rgba(41, 112, 255, 1);
      }

      .planData {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .prices {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #091e42;
          margin: 0;
          letter-spacing: 0.01em;
        }
        .planTitle {
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 0.01em;
          color: tokens.$color-gray-600;
          line-height: 20px;
          margin: 0;
        }
      }

      .btn {
        width: 100%;
      }

      @media screen and (max-width: 525px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 8px;

        .btnWrapper {
          grid-column: 1/3;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }

  .showMore {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #97a0af;
    cursor: pointer;
    text-align: center;
    margin-top: 22px;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
}

.periodToggle {
  width: 100%;
}
