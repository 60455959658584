@use '@restream/styles/scss/tokens';

.accordionWorkspaceItem {
  box-shadow: inset 0 -1px 0 0 tokens.$color-gray-700;
}

.emptyWorkspaceText {
  font-size: 14px;
  text-align: center;
  margin-top: 0;
}

.root {
  display: grid;
  grid-template-columns: 70% auto auto;
  align-items: center;
  padding: 12px 0;
  column-gap: 16px;
  background: none;
  color: tokens.$color-gray-300;
  width: 100%;
}

.cell {
  display: flex;
  align-items: center;
  font-size: tokens.$font-size-body-sm;
  line-height: 16px;

  &:last-of-type {
    justify-content: flex-end;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.avatarWrapper {
  margin-right: 8px;
}

.title {
  color: #fff;
}

.description,
.text,
.button {
  font-size: 12px;
  line-height: 16px;
}

.button {
  color: tokens.$color-blue-300;
  font-weight: 500;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  transition: color 0.1s ease-out;

  &:hover:not(:disabled) {
    color: tokens.$color-blue-400;
  }
}
