@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.badge {
  height: 22px;
  padding: 2px 6px;
  margin-left: 6px;
  border-radius: tokens.$border-radius-150;
  background: rgba(165, 173, 186, 0.16);
  color: #a5adba;

  font-weight: 500;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  text-align: center;
  letter-spacing: 0.01em;
}

.hiddenOnMobile {
  @include media.xs {
    display: none;
  }
}
