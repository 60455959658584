@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-500;
  text-align: center;
  max-width: 320px;
}

.platformsWrapper {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 12px;

  margin-top: 8px;

  @media (max-width: 480px) {
    margin: 8px 0 0;
  }
}

.platform {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;

  background-color: tokens.$color-white;
  border: tokens.$border-width solid tokens.$color-gray-300;
  border-radius: tokens.$border-radius-200;

  cursor: pointer;
  transition: 350ms background-color;

  &:hover {
    background-color: tokens.$color-gray-50;
  }
}

.platformName {
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-500;
  margin-left: 6px;
}
