@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  padding: 8px;
  margin: 0;

  border: none;
  border-radius: tokens.$border-radius-200;
  background-color: transparent;
  transition: background-color 300ms;

  &:not(&:disabled) {
    cursor: pointer;
  }

  &:hover:not(&:disabled) {
    // TODO(color): use token
    background-color: #e2e2e9;
  }

  &:focus {
    // TODO(color): use token
    outline: grey;
  }
}

.icon {
  // TODO(color): use token
  fill: #296fff;
  pointer-events: none;
  opacity: 0.8;
  transition: opacity 300ms;

  .root:hover & {
    opacity: 1;
  }
}
