@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border-bottom: solid 1px #e4e4e4;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  .platformInfo {
    display: flex;
    align-items: center;
    .logoWrapper {
      width: 48px;
      height: 48px;
      display: flex;
      border-radius: tokens.$border-radius-200;
      background: rgb(235, 236, 240);
      align-items: center;
      justify-content: center;
      .loadingWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: blink 0.2s infinite;
      }
      img {
        width: calc(100% - 24px);
        height: auto;
        max-height: 100%;
      }
    }
    .platformTitle {
      color: #172b4d;
      font-weight: 500;
      margin-left: 10px;
    }
  }
  .btn {
    -webkit-appearance: none;
    border: none;
    color: #286fff !important;
    box-shadow: none !important;
    padding: 8px 12px;
    border-radius: tokens.$border-radius-200;
    background: #fafbfc;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background: #286fff23;
    }
  }
  &.fake {
    margin-top: 30px;
    border: 2px solid rgba(40, 111, 255, 1);
    border-radius: tokens.$border-radius-200;
    display: grid;
    grid-template-columns: 1fr auto;
    .btn {
      font-size: 14px;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
