@use '@restream/styles/scss/tokens';

.root {
  // TODO(font): use token
  font-size: 13px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.text {
  margin: 0 10px;
}

.title {
  display: inline-block;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  font-weight: 500;
  margin-bottom: 0;
  text-align: left;
  color: tokens.$color-black;

  &.noMarginBottom {
    margin-bottom: 0;
  }
}

.message {
  margin-bottom: 0;
  font-style: normal;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  text-align: left;
  color: tokens.$color-black;
}

.success,
.info {
  color: tokens.$color-white;
}

.errorTitle,
.errorMessage {
  font-family: 'Graphik', sans-serif;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
}

.errorTitle {
  margin-bottom: 3px;
  font-weight: 500;
  color: tokens.$color-gray-900;
}

.errorMessage {
  font-weight: 400;
  // TODO(color): use token
  color: #6b778c;
}

.default {
  // TODO(color): use token
  color: rgba(255, 255, 255, 0.9);
}

.warning {
  color: tokens.$color-black;
  // TODO(color): use token
  background-color: #ffab00;

  & a {
    transition: all 0.2s;
    border-bottom: tokens.$border-width solid;
    opacity: 1;

    &:hover {
      color: inherit;
      opacity: 0.7;
    }
  }
}

.icon {
  display: inline-block;
  vertical-align: top;
}
