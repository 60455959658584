@use '@restream/styles/scss/media' as media;

$itemsGap: 16px;

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px 0;
  width: 100%;

  @include media.sm {
    grid-template-columns: repeat(1, 1fr);
  }
}
