@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1400px;

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.expandButton {
  background: none;
  padding: 0;
  border: none;
  font-size: 14px;
  color: tokens.$color-gray-500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.plansContainer {
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  justify-content: center;
  //TODO fix hack to not cut most popular badge

  @include media.lg {
    grid-auto-flow: row;
    gap: 22px;

    & .firstPlanOnMobile {
      order: -1;
    }
  }
}

.hidden {
  display: none;
}

.planItem {
  height: 100%;
}

.groupWrapper {
  padding: 12px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: min-content;
  grid-template-rows: auto 1fr;

  @include media.md {
    width: fit-content;
    margin: auto;
  }

  &.creator {
    background-color: #ebebff;

    & .title {
      color: #4800cf;
    }
  }

  &.company {
    background-color: tokens.$color-blue-50;

    & .title {
      color: tokens.$color-blue-700;
    }
  }

  & .header {
    text-align: center;
    margin-bottom: 12px;
  }

  & .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    font-variant-numeric: lining-nums tabular-nums;

    margin-bottom: 4px;
  }

  & .description {
    font-size: 12px;
    line-height: 16px;
    color: tokens.$color-gray-600;
    font-variant-numeric: lining-nums tabular-nums;

    @include media.md {
      max-width: 252px;
      white-space: initial;
    }
  }

  & .content {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;

    @include media.md {
      flex-direction: column;
      row-gap: 12px;
    }
  }
}
