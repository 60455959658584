@use '@restream/styles/scss/tokens';

.alertIcon {
  height: 16px;
  width: 16px;
  min-width: 16px;
  color: #f04438;
}

.tooltipTrigger {
  display: flex;
  margin-right: 4px;
}

.tooltip {
  max-width: 180px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: none;

  &.light {
    border-color: white;
    background-color: white;
    color: tokens.$color-gray-700;

    &[data-popper-placement='top'] .tooltipArrow {
      &::before,
      &::after {
        border-color: white transparent transparent transparent;
      }
    }

    &[data-popper-placement='bottom'] .tooltipArrow {
      &::before,
      &::after {
        border-color: white transparent;
      }
    }
  }
}
