@use '@restream/styles/scss/tokens';

.root {
  @media (min-width: 1100px) {
    width: 1005px;
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  max-width: 1005px;
}

.alert {
  margin-bottom: 16px;
  border: 1px solid #48c5d1;
  position: relative;
  background: #48c5d1;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.01em;
  line-height: 20px;
  border-radius: 0;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;

  &.info {
    background-color: #fff;
    border-color: #fff;
    color: #293042;
  }
}

.upgradeBlock {
  width: 100%;
  text-align: left;
  border-radius: tokens.$border-radius-200;
}

.upgradeText {
  font-weight: 500;
  font-size: 14px;
  transition: color 250ms ease;
}

.submit {
  margin-bottom: 15px;
}

.upgradeButton {
  background: none !important;
  padding: 0;
  color: #ff5630;
  border-radius: 0;

  margin-left: 4px;
  border-bottom: 1px solid transparent;
  line-height: 20px;
  display: inline-block;
  transition: border-bottom-color 250ms ease;

  &:hover {
    border-bottom-color: #ff5630;

    & > div {
      color: #ff5630;
    }
  }
}
