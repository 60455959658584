@use '@restream/styles/scss/tokens';

.root {
  background-color: rgba(0, 0, 0, 0.88);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  z-index: 10;
}

.overlay {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
}

.content {
  margin: 72px auto;
  position: relative;
  width: 780px;
  z-index: 11;

  @media (max-width: 900px) {
    width: 85vw;
  }

  @media (max-width: 480px) {
    width: 90vw;
  }
}

.buttonCloseWrapper {
  position: absolute;
  right: -45px;
  top: 0;

  @media (max-width: 480px) {
    top: calc(-30px - 20px); // 30px - height of icon-button, 20px - margin top
    right: 0;
  }
}

.buttonClose {
  &:hover {
    background: rgba(255, 255, 255, 0.12);
    border-radius: tokens.$border-radius-200;
  }

  .iconClose {
    fill: #edf0f3;
  }
}
