@use '@restream/styles/scss/tokens';

.root {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 16px;
  color: #ffffff;
  background: #2970ff;
  border-radius: tokens.$border-radius-200;

  cursor: pointer;
  display: inline-flex;
  transition: all 200ms;

  &:hover {
    background: #4280ff;
  }
}
