@use '../../../../scss/modals' as modals;
@use '../../../../scss/animation' as animation;

.root {
  position: relative;
  display: flex;
  @include modals.modal-width;
}

.content {
  position: relative;
  display: flex;

  transition: all animation.$animation-default-time ease-in-out;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  height: fit-content;

  width: 100%;
}

.view-enter {
  opacity: 0.01;
  position: absolute;
  transition: all animation.$animation-default-time ease-in-out;
  transition-delay: 500ms;
}

.view-enter-active {
  opacity: 1;
}

.view-exit {
  opacity: 1;
}

.view-exit-active {
  opacity: 0.01;
  transition: all animation.$animation-default-time ease-in-out;
}
