@use '@restream/styles/scss/tokens';

.root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  max-width: 580px;
  padding-top: 16px;

  @media (min-width: 576px) {
    padding-top: 10%;
    padding-bottom: 24px;
    justify-content: center;
  }
}

.leftPart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 0 !important;
  overflow-y: auto;
  max-height: 100%;

  &.singleView {
    height: auto;
  }
}

.contentLabel {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0d1f40;
}

.inputContent {
  margin-top: 72px;
}

.inputWrapper {
  margin-top: 4px;
  display: flex;
}

.inputRoot {
  height: 48px;
}

.inputButton {
  height: 48px;
  margin-left: 8px;
  padding: 0 24px;

  & div[class*='right'] {
    display: flex;
    align-items: center;
  }
}

.inputTooltip {
  height: 48px;
  flex-shrink: 1;
}

.servicesContent {
  margin-top: 120px;
}

.services {
  display: grid;
  grid-gap: 16px;
  width: 100%;
  padding-bottom: 16px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 12px;

  .platform {
    width: 100%;
    height: 96px;
    padding: 16px 24px;
    cursor: pointer;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d7dce3;
    border-radius: tokens.$border-radius-300;
    transition: 0.3s;

    .imgWrapper {
      height: 64px;
      width: 64px;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      &.smallImage {
        & .img {
          width: 70%;
          height: 70%;
        }
      }

      .img {
        width: 48px;
        height: 48px;
        object-fit: contain;
      }

      & .imageLoaderWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.2s ease-in-out;

        &.hidden {
          opacity: 0;
        }
      }
      & .imageLoader {
        & circle {
          stroke: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .title {
      flex: 1;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: tokens.$color-gray-700;
      transition: color 0.3s ease-in-out;
    }
    &:hover {
      box-shadow: 0 0 1px 1px #276fff;
      .title {
        color: #172b4d;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.modalMainTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #091e42;
  display: none;

  @media (min-width: 576px) {
    width: 100%;
    text-align: center;
    display: block;
    font-size: 24px;
    line-height: 32px;
  }
}

.errorContainer {
  margin-bottom: 0;

  @media (max-width: 576px) {
    margin-bottom: 20px;
  }
}

.errorWrapper {
  width: 100%;
  margin-top: 15px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  box-sizing: border-box;
  padding: 15px;

  & p {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #253858;
  }
}

.tooltipDisabled {
  display: none;
}

.inputTooltip {
  width: 100%;
}

.buttonPlaceholderWrapper {
  position: relative;
  width: fit-content;
}

.buttonPlaceholder {
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.extraOption {
  margin-top: 4px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f8ff;
  border: 1px solid #84adff;
  border-radius: tokens.$border-radius-200;
  padding: 12px 16px;
  cursor: pointer;
  transition: 0.3s;

  @media (min-width: 576px) {
    margin-bottom: 24px;
  }

  &:hover {
    box-shadow: 0 0 1px 1px #276fff;

    & .extraOptionRightPart {
      & svg {
        transform: translateX(5px);
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
  }

  .extraOptionLeftPart,
  .extraOptionRightPart {
    display: flex;
    align-items: center;
  }

  .extraOptionLeftPart {
    & p {
      margin-left: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #155eef;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .extraOptionRightPart {
    & p {
      margin-right: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #004eeb;
    }

    & svg {
      transition: transform 0.1s ease-in;
    }

    @media (max-width: 768px) {
      & p {
        margin-left: 12px;
      }

      & svg {
        display: none;
      }
    }
  }
}
