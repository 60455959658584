.root {
  --primary: #0066ff;
  --background: #ffffff;
  --input: #e5e7eb;
  --ring: #0066ff;

  display: inline-flex;
  height: 20px;
  width: 36px;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  border-radius: 9999px;
  border: 2px solid transparent;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  padding: 0;
}

.root:focus-visible {
  outline: none;
  --ring-offset-width: 2px;
  --ring-width: 2px;
  --ring-offset-color: var(--background);
  --ring-color: var(--ring);
  box-shadow:
    0 0 0 var(--ring-offset-width) var(--ring-offset-color),
    0 0 0 calc(var(--ring-offset-width) + var(--ring-width)) var(--ring-color);
}

.root:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.root[data-state='checked'] {
  background-color: var(--primary);
}

.root[data-state='unchecked'] {
  background-color: var(--input);
}

.thumb {
  pointer-events: none;
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 9999px;
  background-color: var(--background);
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.thumb[data-state='checked'] {
  transform: translateX(16px);
}

.thumb[data-state='unchecked'] {
  transform: translateX(0);
}
