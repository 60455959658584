@use '@restream/styles/scss/tokens' as tokens;

.root {
  width: 100vw;
  height: 100vh;
}

.iframe {
  width: 100%;
  height: 100%;
}

.buttonCloseWrapper {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 20;
  display: flex;
  justify-items: flex-end;
  align-items: center;

  & div ~ div {
    margin-left: 6px;
  }
}

.buttonClose {
  &:hover {
    background: rgba(255, 255, 255, 0.12);
    border-radius: tokens.$border-radius-200;
  }

  .iconClose {
    fill: #edf0f3;
  }
}
