@use '~@restream/styles/scss/media' as media;
//@use 'src/next/scss/animation' as animation;

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  padding: 0;

  margin: 0 8px 0 0;

  color: #6c798f;
  font-size: 0.875em; /* 14px */
  line-height: 1.7143em; /* 24px */

  @include media.xs {
    margin: 0;
  }
}

.buttons {
  display: flex;
  align-items: center;
}

.button {
  font-size: 1em; /* 14px */
  line-height: 1.5em; /* 24px */
  padding: 0;
  margin: 0 4px;

  color: rgb(138, 147, 164);
  text-transform: uppercase;
  background: none;
  border: none;

  cursor: pointer;

  &:hover {
    color: #fbfbfb;
    box-shadow: inset 0 -1px 0 0 #fff;
    transition: all 300ms;
  }

  &:focus {
    outline: none;
  }
}

.delimiter {
  width: 1px;
  height: 16px;
  background: #344563;
  opacity: 0.7;
}
