@use '../../../../../scss/modals' as modals;

.modalContainer {
  @include modals.modal-width;
}

.content {
  //width: 90%;
  margin: 4px auto 8px;
  //text-align: center;

  color: var(--Gray-700, #2b3d5c);

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-9, 20px); /* 142.857% */
  text-wrap: pretty;
}

.footer {
  display: flex;
  justify-content: center;
}

.acceptButton {
  background-color: #286fff;
  transition: opacity 300ms ease;

  &:hover {
    background-color: #286fff !important;
    opacity: 0.9;
  }
}

.cancelButton {
  color: var(--Gray-700, #2b3d5c);
  box-shadow: inset 0 0 0 1px #ffffff;
  margin-right: 12px;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f5f6f8 !important;
  }
}

.submitButton {
  background: #d92d20;
  padding: 10px 16px;

  color: var(--Base-White, #fff);
  text-align: center;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: lighten(#d92d20, 10%) !important;
  }
}
