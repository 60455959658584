@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/outline';
@use '../../../../../../scss/mixins' as mixins;

$buttonHeight: 36px;

.root {
  display: flex;
}

.actionButton {
  @include outline.focus-visible-outline;

  height: $buttonHeight;
  width: 100%;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: tokens.$color-gray-600;
  color: white;

  font-weight: 500;
  font-size: tokens.$font-size-body-sm;

  border-radius: tokens.$border-radius-200 0 0 tokens.$border-radius-200;
  padding: 8px 8px 8px 10px;

  transition: all 0.2s;

  &:hover {
    background-color: tokens.$color-gray-500;
  }
}

.actionButtonIcon {
  path {
    stroke: white;
  }
}

.dropdownTriggerButton {
  background: tokens.$color-gray-600;
  height: $buttonHeight;
  width: 36px;

  padding: 0;

  border-left: 1px solid rgba(255, 255, 255, 0.05);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  transition: all 200ms ease-out;

  &[data-state='open'],
  &:not(:disabled):hover {
    background: tokens.$color-gray-500;
  }

  & .icon {
    width: 10px;
    stroke-width: 2;
    flex-shrink: 0;
  }
}
