@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';

.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  z-index: 20000;
  background-color: tokens.$color-gray-800;
  -webkit-font-smoothing: antialiased;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.01em;
  line-height: 32px;
  font-size: 28px;
  text-align: center;
  color: tokens.$color-white;
  margin-bottom: 10px;
}

.text {
  font-size: 16px;
  color: tokens.$color-white;
  opacity: 0.6;
  margin-bottom: 20px;
  text-align: center;
  padding: 0 20px;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.01em;
}
