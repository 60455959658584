@use '@restream/styles/scss/tokens';

.form {
  width: 100%;
  max-width: 500px;
}

.formGroup {
  margin-bottom: 12px;

  &:not(.error) {
    padding-bottom: 0;
  }
}

.pairChannelsButton {
  padding: 9px 12px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  margin-top: 8px;
}

.recoverPasswordContent {
  display: block;
  background-color: #daf4ff;
  color: #000;
  font-size: tokens.$font-size-body-sm;
  font-weight: 400;
  line-height: 24px;
  padding: 16px;
  border-radius: tokens.$border-radius-200;
}
