.nodeOverlayEnter {
  opacity: 0;
}

.nodeOverlayEnterActive {
  opacity: 1;
  transition: opacity 200ms;
}

.nodeOverlayExit {
  opacity: 1;
}

.nodeOverlayExitActive {
  opacity: 0;
  transition: opacity 200ms;
}
