@use '@restream/styles/scss/media';

.layout {
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: 100vh;

  background-color: #f8fafc;

  &.title {
    color: black;
  }

  &.projectPage,
  &.editorPage {
    background: transparent;

    & .title {
      color: white;
    }
  }

  &.listView {
    & .content {
      max-width: 904px;
    }
  }

  &.editorPage {
    background-color: #020617;
  }

  transition: all 0.25s ease-out;
}

.content {
  max-width: 1024px;
  width: 100%;

  margin: 0 auto;
  padding: 32px 16px;
}

.title {
  font-size: 36px;
  line-height: 40px;
  font-weight: 600;

  margin: 0 0 36px;

  &.semiBold {
    font-weight: 500;
  }

  &.noMargin {
    margin-bottom: 0;
  }

  @include media.sm {
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 24px;
  }
}

.loader {
  margin: auto;
}
