@use '@restream/styles/scss/media';

@mixin active-color($color) {
  color: $color;
  box-shadow: inset 0px -2px 0px 0px $color;
}

.root {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  @include media.sm {
    flex-direction: column;
  }
}

.tabs {
  > .tab {
    &:not(:last-child) {
      margin-right: 40px;

      @include media.sm {
        margin-right: 20px;
      }
    }
  }

  font-size: 12px;
  line-height: normal;

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.tabs.dark {
  .tab.tabActive {
    @include active-color(#ffffff);
  }
}

.tabs.light {
  .tab.tabActive {
    @include active-color(#000000);
  }
}

.tab {
  cursor: pointer;
  font-family: inherit;
  z-index: 1;
  padding-bottom: 8px;
  background: transparent;
  border: none;
  color: inherit;
  transition: 0.2s;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
}
