@use '@restream/styles/scss/tokens';

.destinationsList {
  position: relative;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;

  &.withHeightLimit {
    height: 280px;
    overflow: auto;
    margin: 0 auto 12px;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: tokens.$border-radius-200;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  ul {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
  }
}
