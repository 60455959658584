@import '../../components/scss/animation';
@import '@restream/styles/scss/colors';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  padding-bottom: 16px;
  position: relative;
}

.container.row {
  flex-direction: row;
}

.error {
  position: absolute;
  bottom: -4px;
  font-size: 12px;
  color: #ff004a;
}

.error.relative {
  position: relative;
  bottom: 0;
}

// Dark theme
:global(.dark-theme) {
  .error {
    color: $color-border-error-dark;
  }
}
