.root {
  color: #edf0f3;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  margin: 0;
  padding: 0;

  text-align: start;
}
