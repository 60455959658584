@import '@restream/styles/scss/tokens';
@import '@restream/styles/scss/typography';
@import '@restream/styles/scss/media';
.body {
  padding: 12px 24px;
  & > * {
    margin-bottom: $spacing-lg;
  }
}

.tileGroupTile {
  @media (max-width: 768px) {
    @include font-14;
  }
}

.tileGroupTileIcon {
  @include xs {
    width: 60%;
    height: auto;
  }
}

.tileGroupLegend {
  @include font-18;
  color: $color-gray-700;
  margin-bottom: $spacing-md;
}

.tileGroupLegendOptional {
  color: $color-gray-500;
  font-style: italic;
}

.description {
  text-align: center;
  font-size: 14px;
  color: $color-gray-500;
}

.footer {
  display: flex;
  justify-content: space-between;

  & > * {
    margin-right: 1rem;
  }
  & > *:last-child {
    margin-right: 0;
  }
}
