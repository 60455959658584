.root {
  padding: 12px 16px 12px 12px;
  font-size: 14px;
  line-height: 16px;
  color: #344563;
}

.emoji {
  margin-right: 8px;
}
