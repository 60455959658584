.formItem {
  display: flex;
  flex-direction: column;
  gap: 8px;

  position: relative;

  padding-bottom: 24px;
}

.formError {
  color: #ff004a;

  padding: 0;
  margin: 0;
  font-size: 14px;
}
