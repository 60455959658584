@use '@restream/styles/scss/tokens';
@use '../../../../../scss/mixins' as mixins;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0 20px 28px;
  position: relative;

  &::before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: tokens.$color-gray-700;
    margin-bottom: 16px;
  }
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b778c;
}

.textWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.upgradeButton {
  @include mixins.linkStyleButton;
  margin-right: 10px;
}

.progressBar {
  position: relative;
  width: 100%;
  height: 4px;
  background: tokens.$color-gray-700;
  border-radius: 2px;

  .progress {
    top: 0;
    left: 0;
    position: absolute;
    height: 4px;
    border-radius: 2px;

    &.ok {
      background: #2970ff;
    }

    &.warning {
      background: #ff8b00;
    }

    &.full {
      background: #ff5630;
    }
  }
}
