@use '@restream/styles/scss/tokens';

.calloutWarningWrapper {
  background: tokens.$color-yellow-50;
  border: 1px solid tokens.$color-yellow-300;
  border-radius: tokens.$border-radius-150;
  margin: 0 auto;
  padding: 9px 18px;
  width: 100%;

  .calloutWarningText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #091e42;
    margin: 0;
  }
}
