.sonar {
  position: relative;
  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #286fff;
    opacity: 0.6;
    animation: inputSonar 3.5s ease-in-out infinite;
    content: '';
    border-radius: 8px;
  }

  &::after {
    animation-delay: 1s;
  }
}

.sonarChild {
  z-index: 1;
}

@keyframes inputSonar {
  0% {
    opacity: 0.6;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform: scale(1.03, 1.3);
  }
}
