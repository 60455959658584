@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/variables' as variables;
@use '~@restream/styles/scss/media' as media;

$defaultDuration: 1s;

@mixin getSonarAnimation($duration: $defaultDuration) {
  animation: sonarWaveKeyframes $duration ease-in-out infinite;
}

.sonarWrapper {
  overflow: visible;
  position: relative;
  z-index: 10;
  min-width: fit-content;

  @include media.xs {
    width: 100%;
  }
}

.sonarEmitter {
  --sonar-animation-duration: 1s;
  &.slower {
    --sonar-animation-duration: 2s;
  }

  position: absolute;
  z-index: 100;
  border-radius: 100%;

  transform: translateZ(32px);
  animation: sonarEmitterFrames var(--sonar-animation-duration) ease-in-out
    infinite;

  width: var(--sonar-size) !important;
  height: var(--sonar-size) !important;
}

.sonarEmitter.positionTop {
  top: var(--sonar-absolute-position-y);
}

.sonarEmitter.positionLeft {
  left: var(--sonar-absolute-position-x);
}

.sonarEmitter.positionLeftOuter {
  left: var(--sonar-absolute-position-y);
}

.sonarEmitter.positionRight {
  right: var(--sonar-absolute-position-x);
}

.sonarEmitter.positionRightOuter {
  right: var(--sonar-absolute-position-y);
}

.sonarEmitter.positionBottom {
  bottom: var(--sonar-absolute-position-y);
}

.sonarEmitter.colorWhite {
  background: white;
}

.sonarEmitter.colorSuccess {
  background: variables.$color-success;
}

.sonarEmitter.colorDanger {
  background: variables.$color-danger;
}

.sonarEmitter.colorWarning {
  background: variables.$color-warning;
}

.sonarEmitter.colorInfo {
  background: tokens.$color-blue-500;
}

.sonarEmitter.colorPrimary {
  background: variables.$color-primary;
}

.sonarEmitter.clickable {
  cursor: pointer;
}

.sonarEmitter.small {
  --sonar-absolute-position-x: 0px;
  --sonar-absolute-position-y: 0px;
  --sonar-size: 8px;
}

.sonarEmitter.medium {
  --sonar-absolute-position-x: 0;
  --sonar-absolute-position-y: -4px;
  --sonar-size: 12px;
}

.sonarEmitter.large {
  --sonar-absolute-position-x: 0;
  --sonar-absolute-position-y: -8px;
  --sonar-size: 16px;
}

.sonarWave {
  width: 100%;
  height: 100%;
  border-radius: 500%;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  background: inherit;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include getSonarAnimation(var(--sonar-animation-duration));
}

@keyframes sonarWaveKeyframes {
  from {
    opacity: 0.9;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes sonarEmitterFrames {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(0.9);
  }
}
