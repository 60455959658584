@use '@restream/styles/scss/tokens';
@use '../../../mixins';

.timelineGrid {
  position: relative;
  height: 32px;
  width: 100%;

  @include mixins.mobile {
    opacity: 0;
  }
}

.timelineGridItem {
  position: absolute;
  width: 1px;
  height: 8px;
  bottom: 0;
  background-color: tokens.$color-gray-200;
  pointer-events: none;
  user-select: none;

  &:before {
    content: attr(data-label);
    position: absolute;
    top: -20px;
    left: calc(50% - 35px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 70px;
    font-size: 12px;
    color: tokens.$color-gray-400;
  }
}
