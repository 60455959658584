@use '@restream/styles/scss/tokens';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 12px;
}

.tabsRoot {
  margin-bottom: 0;
}

.tabs {
  border: none;
}

.tab {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  padding: 0;
  text-transform: initial;
  border-radius: tokens.$border-width;
  border: none;
  transition: color 200ms ease, border-bottom-color 350ms ease;

  position: relative;

  &.activeTab {
    color: tokens.$color-gray-100;

    &::after {
      content: '';
      background-color: tokens.$color-gray-100;
      border-radius: tokens.$border-radius-50;
      height: 2px;
      width: 100%;

      position: absolute;
      bottom: -8px;
      left: 0;
    }
  }

  &.initialCursor {
    cursor: initial;

    &::after {
      display: none;
    }
  }

  &:not(:first-child):last-child {
    margin-left: 16px;
  }
}
