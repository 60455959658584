@mixin button {
  color: #000;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  border-radius: 8px;
  padding: 6px 8px;

  cursor: pointer;
  border: none;
  transition: background-color 0.25s ease-in-out;

  &.blackFriday {
    background-color: #a5f600;

    &:hover {
      background-color: #8ee600;
    }
  }

  &.cyberMonday {
    background-color: #00f1f4;

    &:hover {
      background-color: #00f1f4; // todo hover
    }
  }
}

.button {
  @include button;
}
