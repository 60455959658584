@use '@restream/styles/scss/tokens';

.wrapper {
  display: flex;
  align-items: center;

  margin-right: 16px;
}

.icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;

  & path {
    stroke: tokens.$color-gray-600;
  }
}

.count {
  color: tokens.$color-gray-600;
  font-size: tokens.$font-size-body-sm;
  font-weight: 500;
}
