@use '@restream/styles/scss/tokens';

.root {
  background-color: #912018;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 12px;

  svg {
    margin-right: 8px;
  }
}

.iconWrapper,
.textWrapper {
  display: flex;
}

.title {
  color: tokens.$color-red-25;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.description {
  color: tokens.$color-red-100;

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-10, 12px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 18px;

  a,
  button {
    text-decoration: underline;
    margin: 0;
    padding: 0;
    background: unset;
    border: none;
    color: inherit;

    font-size: inherit;
    font-family: inherit;
    font-weight: 500;

    cursor: pointer;
  }
}
