@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.text {
  color: tokens.$color-gray-400;
  font-size: 14px;
  line-height: normal;

  & .bold {
    font-weight: 500;
  }
}

.button {
  color: tokens.$color-gray-400;
  font-weight: 400;
  padding: 0;
  margin: 0 0 0 6px;

  background: transparent;
  text-decoration: underline;

  &:hover:not(&.disabled) {
    background: transparent;
    color: tokens.$color-gray-500;
  }
}
