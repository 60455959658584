@use '../../../scss/modals' as modals;

.container {
  @include modals.modal-width;
}

.error {
  margin-top: 9px;
  color: #ff7452;
}
