@use '@restream/styles/scss/tokens';

.text {
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
}

.demoUploadOverlay {
  display: grid;
  margin-top: 48px;
  grid-template-columns: 1fr auto 1fr;
  position: relative;

  .upgradeComponentOverlay {
    display: flex;
    justify-content: center;

    .upgradeComponentWrapper {
      width: 90%;
      max-width: 450px;
    }
  }

  .scheduledVideoOverlay {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      color: rgba(250, 251, 252, 1);
      text-align: center;
      margin-bottom: 28px;
    }

    .videoCardWrapper {
      width: 90%;
      max-width: 450px;

      .videoCard {
        width: 100%;
      }
    }
  }

  .alreadyScheduledButton {
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;

    &:hover {
      opacity: 0.9;
      text-decoration: underline;
    }
  }

  .orOverlay {
    height: 100%;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 500;
      font-size: 18px;
      color: #cacaca;
      position: relative;
      z-index: 1000;
      padding: 10px;
      background: #172a4d;
    }

    &::before {
      position: absolute;
      height: 100%;
      width: 1px;
      background: #344563;
      content: '';
      z-index: 100;
      top: 0;
    }
  }

  @media screen and (max-width: 1028px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 1fr;
    grid-row-gap: 28px;

    .orOverlay {
      &::before {
        position: absolute;
        height: 1px;
        width: 100%;
        background: #344563;
        content: '';
        z-index: 100;
        left: 0;
        top: unset;
      }
    }

    .scheduledVideoOverlay {
      align-items: center;
    }
  }

  @media screen and (max-width: 525px) {
    .scheduledVideoOverlay {
      width: 100%;
    }
    .upgradeComponentOverlay {
      width: 100%;
    }
  }

  .scheduleBtn {
    border: solid 1px #fff;
    appearance: none;
    padding: 8px 12px;
    border-radius: tokens.$border-radius-200;
    text-align: center;
    background: rgba(255, 255, 255, 0);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.deleteItem,
.cancelItem {
  color: tokens.$color-red-600;
}
