.root {
  display: flex;
  border: none;
  margin: 0;
  padding: 0;

  label:not(:last-child) {
    margin-right: 12px;
  }
}
