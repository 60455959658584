@use '@restream/styles/scss/tokens';

@mixin fontWeight($weight) {
  font-weight: $weight !important;

  & .title {
    font-weight: $weight;

    span {
      font-weight: $weight;
    }
  }
}

.root {
  display: flex;
  align-items: flex-start;

  margin-bottom: 8px;
}

.zeroMargin {
  margin-bottom: 0;
}

.title {
  display: flex;
  flex: 1;
  justify-content: space-between;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin: 0;

  color: tokens.$color-gray-800;

  span {
    font-weight: 500;
  }

  & > strong {
    font-weight: 500;
  }
}

.lineThrough {
  text-decoration: line-through;
}

.semiBold {
  @include fontWeight(600);
}

.bold {
  @include fontWeight(700);
}

.receiptData {
  color: tokens.$color-gray-800;
}

.iconWrapper {
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 4px;
}
