@use '@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

@mixin small {
  @container (max-width: 720px) {
    @content;
  }
}

@mixin extraSmall {
  @container (max-width: 500px) {
    @content;
  }
}

.root {
  max-width: 426px;
  min-height: 400px;
  width: 100%;

  @include small {
    min-height: 100%;
  }

  padding: 24px;

  @include extraSmall {
    padding: 0;
  }
}

.title {
  color: var(--Base-White, #fff);

  @include small {
    display: none;
  }

  text-align: center;

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-5, 24px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-5, 32px); /* 133.333% */

  margin-bottom: 12px;
}

.subtitle {
  color: var(--Gray-300, #bac1cc);
  text-align: center;

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-9, 20px); /* 142.857% */

  margin-bottom: 20px;
}

.subtitle.long {
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;

  @include small {
    display: none;
  }
}

.subtitle.short {
  display: none;

  @include small {
    display: block;
  }

  @include extraSmall {
    //font-size: 12px;
    //margin-bottom: 8px;
    //white-space: nowrap;
    //text-align: start;

    display: none;
  }
}

.label {
  color: var(--Gray-100, #edf0f3);

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-9, 20px); /* 142.857% */

  @include extraSmall {
    font-size: 12px;
    line-height: 16px;
  }
}

.inputWrapper {
  background: none;

  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}

.secureInputWrapper {
  padding: 7px 8px 7px 12px;
}

.input {
  color: var(--Base-White, #fff);
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 16px; /* 142.857% */
  padding: 0;
  border-radius: 0;
}

.inputWithButtonWrapper {
  display: flex;
  margin-bottom: 20px;
  gap: 8px;

  @include small {
    margin-bottom: 8px;
  }
}

.noMargin {
  margin-bottom: 0;
}

@mixin verticalAlign {
  @media only screen and (min-width: media.$breakpoint-md) {
    @content;
  }
}

.secondaryButton.backupButton {
  display: none;

  @include verticalAlign {
    display: flex;
  }
}

.secondaryButton.streamSettingsButton {
  display: flex;

  @include verticalAlign {
    display: none;
  }
}
