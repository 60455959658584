.root {
  svg {
    transition: all 300ms ease-in-out;
    transform: rotate(0deg);
  }
}

.root[data-state='open'] {
  svg {
    transform: rotate(90deg);
  }
}

.header {
  margin-bottom: 0;
}
