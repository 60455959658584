@use '../../../../scss/modals' as modals;
@use '../../../../scss/colors' as colors;

.root {
  @include modals.modal-width;

  color: colors.$bg-color--darken-1;
}

.extraButton {
  margin-right: 8px;
}
