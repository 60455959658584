.root {
  padding: 10px;
  background-image: url('img/gradient-callout.png');
  background-repeat: no-repeat;
  width: 100%;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.button {
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 0;
  border-bottom: 1px solid white;
  border-radius: 0;

  &:hover:not(:disabled) {
    background: transparent;
  }
}
