@use '@restream/styles/scss/tokens';

.title {
  color: white;
}

.toastWrapper {
  padding: 12px 10px 16px !important;
  background: #000000 !important;
  color: tokens.$color-gray-50 !important;
  border-radius: tokens.$border-radius-200 !important;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  @media (max-width: 480px) {
    width: auto;
    max-width: 95%;
    padding: 16px !important;
    bottom: 1.5em !important;
  }
}
