@use '@restream/styles/scss/tokens';
@import '../../../../components/scss/modals';

.root {
  @include modal-width;
}

.destinations {
  margin: 0 0 16px;
  position: relative;
  max-height: 400px;
  overflow: auto;
  padding: 0;
  box-shadow: inset 0 0 4px #d6d6d6;
}

.collapsed {
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.calloutDescription {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  margin: 0;

  a,
  button {
    margin-top: 8px;
    background: transparent;
    border: none;
    color: #286fff;
    cursor: pointer;
    outline: none;
  }
}

.onOffToggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 20px 8px;
  font-size: 14px;

  .toggleAllButton {
    color: tokens.$color-gray-700;

    &:hover {
      $buttonColor: rgba(138, 147, 164, 0.7);
      color: $buttonColor;
      box-shadow: inset 0 -1px 0 0 $buttonColor;
    }
  }
}
