@import '../../../components/scss/modals';

.root {
  @include modal-width;
  overflow: hidden;
}

.body {
  padding-left: 0;
  padding-right: 0;
}

.subtitleWithTooltipWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  top: 54px;
  left: 0;
}

.subtitleWrapper {
  display: flex;
  align-items: center;
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  color: #8993a4;

  margin: 0;
  padding: 0;

  cursor: default;
}

.tooltipTrigger {
  height: 16px;
}

.tooltip {
  max-width: 400px;
}

.tooltipContent {
  p {
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    color: #fff;
  }
}

.icon {
  fill: #8993a4;
  width: 16px;
  height: 16px;
}

.iconWrapper {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}
