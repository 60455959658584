@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/colors';
@import '../../components/scss/animation';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: tokens.$color-gray-800;
  padding: 12px;
  list-style-type: none;
  border-radius: tokens.$border-radius-200;
  transition: background-color $animation-default-time;

  &.encoder {
    background-color: transparent;

    &::before {
      display: none;
    }
  }

  &:hover {
    //background-color: #1e3964;
    // TODO(color): use token
    background-color: #253858;
  }
  //&.isNew {
  //  background-color: rgba(24, 246, 10, 0.16);
  //}
  //
  //&.isNew:hover {
  //  background-color: rgba(24, 246, 10, 0.20);
  //}

  &.compactMode {
    // TODO(color): use token
    background-color: rgb(37, 56, 89);

    &:hover {
      background-color: #314567;
    }

    &::before {
      border-bottom: 2px solid #0a1e42 !important;
    }
  }
}

.root.bordered {
  position: relative;

  &::before {
    content: 'foo'; /* you need something or it will be invisible at least on Chrome */
    color: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 0;
    // TODO(color): use token
    border-bottom: tokens.$border-width solid #0a1e42;
  }
}

.rows {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statuses {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  justify-content: flex-start;
}

.content {
  display: flex;
}

.titles {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;

  &.compactMode {
    justify-content: center;
  }
}

.channelName {
  &.encoder {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    color: rgba(255, 255, 255, 0.94);
  }
}

.channelStatusContainer {
  &.encoder {
    min-height: 0;
    margin-top: 4px;
  }
}

.channelStatusTitle {
  &.encoder {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: 0.02em;
    color: #7a869a;
  }
}

.settings {
  margin-left: 12px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  align-items: center;
  height: fit-content;
}

.settingsButtonIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: rgba(122, 134, 154, 0.12);
  padding: 2px;
  transition: background-color $animation-default-time;

  &:hover {
    background-color: rgba(122, 134, 154, 0.16);
  }
}

.settingButtonIcon {
  fill: #a5adba;
  transition: fill $animation-default-time ease,
    transform $animation-default-time ease;

  .settingsButtonIconWrapper:hover & {
    fill: tokens.$color-white;
  }

  .settingsButtonIconWrapper:active & {
    transform: scale(0.9);
  }
}

.switch {
  display: flex;
  align-items: center;
}

.error {
  margin-top: 8px;
}

.viewersContainer {
  margin-right: 16px;
}
