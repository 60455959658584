@use '@restream/styles/scss/tokens';

.preview {
  position: relative;
  grid-area: preview;
  height: 100%;
  width: 100%;
}

.thumbnail {
  border-radius: tokens.$border-radius-200 0 0 tokens.$border-radius-200;
  display: block;
  min-height: 148px;
  min-width: 192px;
  width: 100%;
  object-fit: cover;

  &.previewMode {
    border-radius: tokens.$border-radius-200;
  }
}
