.root {
  max-width: 500px;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 20px;
}

.content {
  //align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  color: #0a1e42;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.01em;
  line-height: 28px;
  margin-bottom: 20px;
  text-align: center;
  transition: all 0.2s;
}

.formWrapper {
  background: #fff;
  border-radius: 8px;
  width: 500px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error {
  padding-left: 24px;
  padding-right: 24px;
}

.blueHighlight {
  background: rgb(224 237 255);
}
.contentPadding {
  padding-left: 24px;
  padding-right: 24px;
}
.formHeader {
  padding-top: 16px;
}

.selectedPlan {
  font-weight: 900;
  color: #172b4d;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-style: normal;
  justify-content: space-between;
  line-height: 20px;
  margin: 0 0 8px;
  padding: 0;
}

.separator {
  margin: 0 0 20px;
  opacity: 0;
  width: 100%;
}

.footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.security {
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  margin: 0 0 0 4px;
  color: #8993a4;
}

.buttonLoader {
  margin-right: 8px;
}
