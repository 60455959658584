@use '@restream/styles/scss/tokens';
@use '../../../../../components/scss/mixins' as mixins;

.root {
  display: flex;
  flex-direction: column;

  padding: 12px 0;
  border-top: 1px solid #eaecf0;

  &:last-of-type {
    border-bottom: 1px solid #eaecf0;
  }
}

.content {
  display: flex;
}

.description {
  display: flex;
  flex: 1;
  flex-direction: column;

  min-width: 0;

  .channelNameWrapper {
    display: flex;
    align-items: center;
  }

  .channelName {
    @include mixins.truncate-text;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: tokens.$color-gray-800;
    margin: 0;
    letter-spacing: 0.01em;
  }

  a.channelName:hover {
    text-decoration: underline;
  }

  .title {
    @include mixins.truncate-text;

    font-size: 14px;
    line-height: 20px;
    color: #505f79;

    margin: 0;

    &.notSupported {
      font-style: italic;
    }
  }

  .textLoader {
    font-size: 14px;
    line-height: 22px;
  }

  .textLoader {
    color: #aeb4bf;
  }
}

.settings {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 8px;
}

.editButtonWrapper {
  margin-right: 12px;
}

.editInfo {
  width: 28px;
  display: flex;
  height: 24px;
  align-items: center;
}

.privacyBadge {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  padding: 0 6px;
  margin-left: 6px;

  border-radius: 20px;
  color: tokens.$color-gray-500;
  background-color: tokens.$color-gray-100;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;

  .dark & {
    color: tokens.$color-gray-300;
    background: tokens.$color-gray-700;
  }
}

.capitalize {
  text-transform: capitalize;
}

.channelStatus {
  font-size: 13px;
  line-height: 20px;
}

.errorWrap {
  margin-top: 6px;
}

.skipWaitPlatformsWrapper {
  margin-top: 20px;
}

.notice {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}

.scheduledFor {
  color: tokens.$color-gray-500;
  flex-shrink: 0;
  font-size: 13px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 6px;
  border-radius: 4px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    width: 1px;
    background: tokens.$color-gray-100;
    height: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
