.popoverContent {
  padding: 16px;
}

.popoverTitle {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 16px;
  color: #344563;
}

.popoverText {
  font-size: 14px;
  line-height: 16px;
  color: #344563;
  margin-bottom: 16px;
}

.popoverButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.popoverButton {
  font-size: 13px;
  line-height: 16px;
  padding: 4px 12px;
}

.info {
  background: #286fff;
  color: #fff;

  &:hover {
    background: #4280ff !important;
    color: #fff !important;
  }
}

.outline {
  margin-right: 4px;
  background: none;
  color: #344563;

  &:hover {
    background: gainsboro !important;
    color: #344563 !important;
  }
}
