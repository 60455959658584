@use '@restream/styles/scss/tokens';

.root {
  color: tokens.$color-gray-400;

  padding: 0;

  transition: color 0.2s ease-in-out;

  &:hover:not(:disabled) {
    color: tokens.$color-gray-25;
    text-decoration: none;
  }
}

.editInfo {
  width: 28px;
  display: flex;
  height: 24px;
  align-items: center;
}
