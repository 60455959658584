.root {
  position: relative;
  display: inline-block;

  width: 36px;
  height: 20px;

  // Override browser's defaults
  margin: 0;
}

.checkbox {
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: #286fff;
  }

  &:checked + .slider::before {
    transform: translateX(14px);
  }

  &:disabled + .slider {
    opacity: 0.5;
    cursor: default;
  }

  &:focus-visible + .slider {
    outline: #015ecc auto 1px;
  }
}

.slider {
  /* stylelint-disable-line no-descending-specificity */
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #091e42;
  transition: 0.4s;
  border-radius: 36px;
  border: 1px solid rgba(#ebecf0, 0.25);

  &::before {
    /* stylelint-disable-line no-descending-specificity */
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 3px;
    background-color: #ebecf0;
    transition: 0.4s;
    border-radius: 50%;
  }
}
