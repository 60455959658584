@use '@restream/styles/scss/tokens';

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.root {
  padding: 42px 24px 24px;
  background: tokens.$color-gray-50;
  min-width: 264px;
  max-width: 264px;
  display: grid;
  height: 100%;
  max-height: 680px;
  grid-template-rows: auto 1fr auto;

  @include mobileMedia() {
    padding: 16px;
    max-width: 100%;
    min-width: 100%;
    position: relative;
  }

  @media (max-width: 576px) {
    min-width: 100%;
  }

  .featuredProductsTitle {
    padding-bottom: 6px;
    .featuredProductsTitleContent {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      color: #091e42;
      margin-bottom: 8px;

      & > p {
        font-size: 14px;
        font-weight: 500;
        color: #6b778c;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        @media (min-width: 576px) {
          color: #004eeb;
        }
      }
    }
  }

  .initialLoaderWrapper {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 20px;
  }

  .featuredProducts {
    overflow: auto;
    height: 100%;
    min-width: 100%;
    width: fit-content;

    @include mobileMedia() {
      display: flex;
      box-sizing: border-box;
      padding-bottom: 10px;
      //width: fit-content;

      margin: 0 -16px;
      padding-left: 16px;
      width: initial;
    }

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      &.alignStart {
        align-items: start;
        //padding-top: 100px;
      }
    }

    .emptyState {
      width: 60%;
      min-width: 168px;
      text-align: center;
      color: #42526e;
      position: relative;

      &.extended {
        min-width: 177px;
      }

      .emptyStateArrowImg {
        position: absolute;
        width: 50%;
        left: 0;
        transform: translate(-50%, 10px);

        &.onTop {
          bottom: calc(100% + 20px);
          transform: translate(-40%, 10px) scaleY(-1);
        }

        &.reversed {
          bottom: calc(100% + 20px);
          transform: translate(40px, 10px) scaleY(-1) scaleX(-1) rotate(20deg);
        }

        @include mobileMedia() {
          display: none;
        }
      }

      h4 {
        font-weight: 500;
        color: #42526e;
        font-size: 14px;
        margin-top: 8px;
      }

      p {
        font-size: 14px;

        .desktopOnly {
          @include mobileMedia() {
            display: none;
          }
        }
      }

      .emptyStateImg {
        width: 100%;

        @include mobileMedia() {
          display: none;
        }
      }
    }
  }
}

.clearAllBtn {
  margin: 0;
  background: transparent;
  border: none;

  color: #004eeb;
  cursor: pointer;
  outline: none;
  &:focus:not(:disabled) {
    text-decoration: underline;
  }

  &:hover:not(:disabled) {
    text-decoration: underline;
  }

  &:disabled {
    color: gray;
    cursor: not-allowed;
  }
}

.btnsWrapper {
  width: 100%;
  //display: flex;
  //justify-content: flex-start;
  display: grid;
  align-items: flex-start;

  @include mobileMedia() {
    display: flex;
    //justify-content: flex-start;

    justify-content: space-between;
  }
}

.backBtnWrapper {
  padding-top: 24px;
  width: 100%;
  min-width: 75px;
  max-width: 25%;
  margin-right: 20px;
  display: none;

  @include mobileMedia() {
    padding-top: 6px;
    display: block;
  }

  .backBtn {
    appearance: none;
    -webkit-appearance: none;
    background: #ffffff;
    border: 1px solid #bac1cc;
    border-radius: tokens.$border-radius-200;
    padding: 11px 0;
    box-sizing: border-box;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #6b778c;

    &:disabled {
      opacity: 0.4;
      cursor: auto;
    }

    &:not(:disabled):hover {
      border: 1px solid #6b778c;
      opacity: 0.9;
    }
  }
}

.nextBtnWrapper {
  flex: 1;
  padding-top: 16px;

  @include mobileMedia() {
    padding-top: 6px;
  }

  .nextBtn {
    appearance: none;
    font-weight: 500;
    -webkit-appearance: none;
    border: none;
    color: #ffffff;
    font-size: 14px;
    background: #155eef;
    border-radius: tokens.$border-radius-200;
    padding: 12px 0;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    text-align: center;

    &:disabled {
      opacity: 0.4;
      cursor: auto;
    }

    &:not(:disabled):hover {
      opacity: 0.9;
    }
  }
}

.errorWrapper {
  width: 100%;
  margin-top: 15px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  box-sizing: border-box;
  padding: 15px;

  & p {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #253858;
  }
}

.demoWrapper {
  padding-top: 16px;

  @include mobileMedia() {
    padding-top: 6px;
    margin-right: 20px;
    margin-left: 28px;
  }
}

.demoBtn {
  width: 100%;
  padding: 9px 14px;
  -webkit-appearance: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #004eeb;
  background: #eff4ff;
  border: 1px solid #b2ccff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: tokens.$border-radius-200;
  position: relative;
  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  cursor: pointer;

  .hidden {
    opacity: 0;
  }

  .loader {
    position: absolute;
  }

  &:hover:not(:disabled) {
    background: #dee5ff;
  }

  &:disabled {
    opacity: 0.9;
  }
}
