@use '@restream/styles/scss/tokens';
@import '../../constants';

.root {
  clear: both;
  // // mini-hack to get space between scroll and card (noticeable on hover)
  // margin-right: 5px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  min-height: 80px;
  padding: 6px;
  border-radius: tokens.$border-radius-200;
  overflow: hidden;
  gap: 4px;
  box-sizing: border-box;
  background-color: #0e2347;

  &,
  &:hover {
    transition: background-color 0.1s linear;
  }

  &:hover {
    background: #172b4d;
  }
}

.videoWrapper {
  position: relative;
  flex-basis: 112px;
  min-height: 62px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  border-radius: tokens.$border-radius-200;
}

.processingVideoWrapper {
  height: 100%;
  position: relative;
  background-color: black;
}

.processingVideoText {
  font-size: 12px;
  line-height: 18px;
  color: #97a0af;
}

.processingVideoProgress {
  font-weight: 500;
  margin-left: 2px;
  color: #ffffff;
}

.processingVideoProgressBar {
  width: auto;
  margin: 5px 8px 0;
}

.videoContent {
  &.notReady {
    opacity: 0.5;
  }

  .thumbnail {
    background-size: cover;

    &.bluredThumbnail {
      filter: blur(2px);
    }

    &.disabled {
      mix-blend-mode: luminosity;
      opacity: 0.25;
    }
  }
}

.mainWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 8px;
}

.btnContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.textWrapper {
  width: 200px;
  max-width: 200px;
}

.textDetailsWrapper {
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.duration,
.descriptionWrapper {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #697586;
}

.descriptionWrapper {
  display: flex;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    width: 4px;
    height: 4px;

    background-color: #697586;
    border-radius: 50%;
    margin: 0 7px;
  }
}

.nameContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.name {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #eef2f6;
  overflow: hidden;

  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.disabled {
    opacity: 0.25;
  }
}

.invalidVideoCaption {
  color: #ff8f73;
}

.actionButtonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.actionButton {
  width: 28px;
  height: 28px;
  background: rgba(0, 0, 0, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: tokens.$border-radius-200;
  border: none;
  cursor: pointer;

  &:first-child:not(:last-child) {
    margin-right: 10px;
  }

  &:hover:not(:disabled) {
    background: #004eeb;
  }
}

.scheduleIcon {
  & path {
    stroke: white;
  }
}

.detailsWrapper {
  padding: 4px 0 8px 8px;
}

.detailsRoot {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 6px;
  grid-column-gap: 6px;

  padding: 10px 8px;
  background: tokens.$color-gray-700;

  border-radius: tokens.$border-radius-200;
  position: relative;
}

.detailsTitle,
.detailsText {
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.detailsTitle {
  color: #97a0af;

  &:nth-of-type(3) {
    grid-area: 1/2/2/3;
  }
}

.detailsText {
  font-weight: 500;
  color: #d7dce3;

  margin-left: 2px;
}

.detailsCloseIcon {
  top: 5px;
  right: 6px;
  color: #bac1cc;
  padding: 5px;
}
