@use '@restream/styles/scss/tokens';

.root {
  position: relative;
  width: 100%;
  font-size: 0.875rem; /* Text-sm */
  padding: 10px 16px;

  border-radius: 8px;
  border: 1px solid var(--Blue-300, #84adff);
  background: var(--Blue-50, #eff4ff);
}

.root > svg + div {
  transform: translateY(-3px);
}

.root > svg {
  position: absolute;
  left: 1rem; /* 4 units * 0.25rem/unit */
  top: 1rem;
  color: var(
    --color-foreground
  ); /* Assuming `text-foreground` is a custom CSS variable */
}

.root > svg ~ * {
  padding-left: 1.75rem; /* Estimated padding left for content after svg */
}

.root.warning {
  border: 1px solid var(--Yellow-500, #eaaa08);
  background: var(--Yellow-100, #fef7c3);
}

.root.error {
  border: 1px solid var(--color-red-500, tokens.$color-red-500);
  background: var(--color-red-100, tokens.$color-red-100);
}

.title {
  margin-bottom: 0.25rem; /* mb-1: margin-bottom of 1 unit, usually 0.25rem in Tailwind's default config */
  font-weight: 500; /* font-medium: typically corresponds to a font-weight of 500 */
  line-height: 1; /* leading-none: line height of 1 */
  letter-spacing: -0.01562em; /* tracking-tight: usually a slightly negative letter spacing */
}

.description {
  color: var(--Gray-700, #2b3d5c);

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-9, 20px); /* 142.857% */
}
