@use '@restream/styles/scss/tokens';

.root {
  width: 100%;
  height: 250px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;

  border: 1px dashed rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  padding: 8px 16px;

  &.projectsPage {
    border: 1px solid tokens.$color-gray-700;

    & .title {
      color: tokens.$color-gray-300;
    }

    & .link,
    & .button,
    & .description {
      color: tokens.$color-gray-300;
    }

    &.error {
      & .title,
      & .button,
      & .description {
        color: #fda29b;
      }
    }
  }
}

.title {
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.description {
  margin-top: 6px;
}

.link,
.description {
  color: tokens.$color-gray-500;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.button {
  height: auto;

  color: tokens.$color-gray-500;
  font-size: inherit;
  font-weight: 400;

  padding: 0;
  background: transparent;
  text-decoration: underline;
  border: none;

  cursor: pointer;

  &:hover:not(&.disabled) {
    text-decoration: none;
  }
}

.loader {
  margin: auto;
}
