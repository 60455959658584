@use '@restream/styles/scss/tokens';
@use '../../../../scss/mixins' as mixins;
@import '../constants';
@import '../../constants';

.buttonRow {
  @include mixins.display-flex-center;
  justify-content: space-between;
  // TODO(color): use token
  background-color: #0e2347;
  border-radius: tokens.$border-radius-400;
  padding: 20px;
  width: 100%;
}

.column {
  @include mixins.column;
}

.row {
  @include mixins.display-flex-center;
  gap: 16px;
}

.sectionTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.subtitle {
  color: tokens.$color-gray-500;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
}

.seeAllButton {
  @include mixins.button-reset;

  box-sizing: border-box;
  color: tokens.$color-gray-200;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    opacity: 0.8;
  }
}

.subtitleSkeleton {
  background: $skeletonBackground;
  border-radius: tokens.$border-radius-200;
  width: 75px;
  height: 20px;
  margin-top: 4px;
}
