@use '@restream/styles/scss/tokens';
@use '../../../scss/modals' as modals;

.container {
  @include modals.modal-width;
}

.input {
  border-radius: tokens.$border-radius-200;
}

.error {
  font-size: 12px;
  color: #ff004a;
}

.textWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
}

.charactersCount {
  font-weight: 400;
  font-size: 12px;

  text-align: right;
  color: #6b778c;
  margin-left: auto;
}

.submitButton {
  display: block;
  min-width: 62px;
  height: 36px;

  font-size: 14px;
  background: #155eef;
  color: #ffffff;

  margin: 20px 0 0 auto;
  border-radius: tokens.$border-radius-200;
  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
}
