@use './layout';
@use '@restream/styles/scss/media';

.root {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;

  @include layout.horizontal-md {
    grid-template-columns: 1fr 480px;
    grid-template-rows: 100%;
  }

  @include layout.horizontal-lg {
    grid-template-columns: 1fr 480px;
    grid-template-rows: 100%;
  }

  height: calc(100vh - 61px);

  font-size: 16px;
  line-height: 24px;

  padding: 0;

  @include layout.vertical-xs {
    padding: 16px;
  }

  background: #091e42;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  margin-bottom: 12px;
}

div.tabsRoot {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  min-height: 400px;

  @include layout.horizontal-lg {
    flex-direction: row;
  }
}

.tabsContent {
  width: 100%;
}

.settingsButtonsMobile {
  display: none;

  @include media.xl {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  @include media.xs {
    padding: 0 16px;
  }
}

.monitor {
  display: flex;

  @include media.xl {
    display: none;
  }
}

.errorContainer {
  display: flex;
  height: 100vh;
}

.sidebarContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include media.xl {
    display: block;
  }
}

.settingsButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @include media.xl {
    display: none;
  }
}

.channelsList {
  padding: 16px;
}
