.root {
  width: calc(var(--productOverlayScale) * 468px);
  height: calc(var(--productOverlayScale) * 860px);
  user-select: none;
  background: linear-gradient(
    112.83deg,
    rgba(255, 255, 255, 0.93) 0%,
    rgba(255, 255, 255, 0.75) 110.84%
  );
  box-shadow: inset -1px -1px 0px rgb(255 255 255 / 20%),
    inset 1px 1px 0px rgb(255 255 255 / 50%);
  border-radius: calc(var(--productOverlayScale) * 36px);
  color: #000000;

  .wrapper {
    padding: calc(var(--productOverlayScale) * 24px);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
  }

  .content {
    .imageWrapper {
      position: relative;
      overflow: hidden;
      border-radius: calc(var(--productOverlayScale) * 18px);
      .imageBackground {
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: 0 auto;
        padding-bottom: 100%;
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;

        &.noOpacity {
          opacity: 0;
        }
      }
      .image {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 100%;
        border-radius: calc(var(--productOverlayScale) * 18px);
        backdrop-filter: blur(7px);
        position: relative;

        &.noBorder {
          border-radius: initial;
        }
      }
    }

    h2 {
      padding: calc(var(--productOverlayScale) * 24px)
        calc(var(--productOverlayScale) * 8px) 0;
      font-size: calc(var(--productOverlayScale) * 36px);
      line-height: calc(var(--productOverlayScale) * 45px);
      color: var(--color);
      width: calc(
        var(--productOverlayScale) * 468px - (var(--productOverlayScale) * 48px)
      );
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      font-style: normal;
      font-weight: 400;
      text-align: left;

      &.titlePlaceholder {
        min-height: calc(var(--productOverlayScale) * 45px);
        background: rgba(0, 0, 0, 0.2);
        max-width: 65%;
        border-radius: 6px;
      }
    }

    .description {
      color: var(--color);
      text-align: left;
      padding: calc(var(--productOverlayScale) * 9px)
        calc(var(--productOverlayScale) * 8px);
      font-style: normal;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: break-word;
      font-weight: 400;
      font-size: calc(var(--productOverlayScale) * 24px);
    }
  }
  .cta {
    display: flex;
    width: 100%;
    padding: calc(var(--productOverlayScale) * 24px)
      calc(var(--productOverlayScale) * 8px) 0;
    grid-gap: calc(var(--productOverlayScale) * 16px);
    justify-content: space-between;

    &.fullWidth {
      grid-template-columns: repeat(1, 1fr);
    }

    &.emptyPrice {
      display: flex;
      flex-flow: column-reverse;
    }

    &.reverse {
      grid-gap: calc(var(--productOverlayScale) * 0px);
      .priceWrapper {
        max-width: 55%;
        grid-row: 2;

        .ctaText {
          margin-top: 0;
          text-align: center;
        }
      }
      .qrCodeWrapper {
        grid-row: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .qrCodeImg {
          width: 60%;
        }
      }
    }

    .priceWrapper {
      display: flex;
      max-width: calc(60% - var(--productOverlayScale) * 8px);
      flex-flow: column;
      width: calc(60% - var(--productOverlayScale) * 8px);
      margin-right: calc(var(--productOverlayScale) * 24px);

      .price {
        width: 100%;
        text-align: left;
        line-height: 100%;
        //font-size: calc(var(--productOverlayScale) * 60px);
        font-weight: 600;
        color: var(--color);
        border-radius: 6px;

        &.pricePlaceholder {
          min-height: calc(var(--productOverlayScale) * 48px);
          background: rgba(0, 0, 0, 0.2);
        }

        &.medium {
          font-size: calc(var(--productOverlayScale) * 48px);
        }

        &.small {
          font-size: calc(var(--productOverlayScale) * 40px);
        }

        &.extraSmall {
          font-size: calc(var(--productOverlayScale) * 32px);
        }

        &.old {
          letter-spacing: -0.02em;
          text-decoration-line: line-through;
          color: #000000;
          font-weight: 400;
          opacity: 0.5;
          margin-top: calc(var(--productOverlayScale) * 16px);
          font-size: calc(var(--productOverlayScale) * 32px);

          &.small {
            font-size: calc(var(--productOverlayScale) * 24px);
          }
        }
      }
    }

    .qrCodeWrapper {
      width: 40%;
      height: auto;
      .qrCodeImg {
        width: 100%;
        height: auto;
        &.inverted {
          filter: invert(100%);
        }
      }
    }
  }

  &.autoHeight {
    height: auto;

    &.isDescriptionEmpty {
      h2 {
        padding-bottom: 32px;
      }
    }
  }
}
