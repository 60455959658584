@use '../../../scss/grid' as grid;

.container {
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .button {
      margin-right: grid.$grid-base-value;
    }

    .description-counter {
      display: flex;
      align-items: center;

      .icon {
        margin-right: grid.$grid-base-value;
        margin-left: grid.$grid-base-value;
      }
    }
  }
}

.inputWrapper {
  background-color: #253858;
  color: #fff;
  cursor: text;
  min-height: 40px;
}

.input {
  &::placeholder {
    color: #a5adba;
  }
}
