@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';
@use '@restream/styles/scss/mixins';
@use '../../../scss/colors';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 20px;
}

.container {
  max-width: 960px;
  @include mixins.pageContentPaddings;
}

.titleRoot {
  margin-top: 0;
}

.ingests {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 24px;

  margin-bottom: 28px;
}

.ingest {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 16px 0;
  width: 400px;
}

.ingestHeader {
  display: flex;
  align-items: center;
}

.ingestFooter {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  img {
    height: 15px;
    width: 15px;
  }
}

.name {
  color: colors.$text-color;
  font-size: 15px;
  margin-left: 5px;
}

.inputWrapper {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
}

.input {
  background-color: hsla(0, 0%, 100%, 0.14);
  border-radius: tokens.$border-radius-200;
  box-shadow: none;
  color: #fff;
  height: 40px;
}

.count {
  border-radius: tokens.$border-radius-200;
  bottom: 0;
  color: #ebecf0;
  font-size: 14px;
  font-weight: normal;
  height: fit-content;
  letter-spacing: 0.01em;
  line-height: 20px;
  margin: auto;
  outline: none;
  padding: 5px 8px;
  position: absolute;
  right: 6px;
  top: 0;
  transition: background-color 0.1s ease, color 0.1s ease;
  z-index: 1;
}

.button {
  margin-left: 8px;
}

.loader {
  height: 100%;
  border-radius: tokens.$border-radius-200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: colors.$color-border-error-dark;
}

.runTest {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 12px 0 16px;

  @include media.md {
    justify-content: flex-start;
  }
}
