@import '@restream/styles/scss/tokens';

.checkbox {
    position: relative;
}

.checkbox.topRight{
    position: absolute;
    top: $spacing-xs;
    right: $spacing-xs;
}