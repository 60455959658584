@use '@restream/styles/scss/tokens';

.tabsContainer {
  width: fit-content;
  margin: 0 auto 24px;
}

.tabsRoot {
  background: tokens.$color-gray-50;
  border: 1px solid tokens.$color-gray-100;
  box-shadow: none;
  padding: 3px;
  border-radius: 12px;
}

.tab {
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: none;
}

.tabText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-700;

  &.disabledTabText {
    color: tokens.$color-gray-500;
  }
}

.blob {
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
}
