.root {
  position: relative;
  overflow: hidden;
}

.scrollBar {
  display: flex;
  touch-action: none;
  user-select: none;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-duration: 150ms; /* or any other duration you'd like */
}

.scrollBarVertical {
  display: flex;
  touch-action: none;
  user-select: none;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-duration: 150ms; /* Modify duration as needed */
}

.thumb {
  position: relative;
  flex: 1;
  border-radius: 9999px; /* Equivalent to rounded-full */
  background-color: var(
    --border-color
  ); /* Assuming bg-border maps to a CSS variable named --border-color */
}
