@use '~@restream/styles/scss/media' as media;
@use '~@restream/styles/scss/colors' as colors;

.container {
  display: flex;

  @include media.xs {
    flex-direction: column;
    padding: 0 20px 0 20px;
  }
}

.faqSection {
  width: 100%;

  @include media.xs {
    margin-top: 40px;
  }
}

.commonQuestions {
  width: 100%;
}

.sectionTitle {
  font-size: 24px;
  padding-bottom: 16px;
}

.sectionText {
  font-size: 16px;
}

.toggleItem {
  span {
    font-size: 16px;
  }
}
