@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

$headerHeight: 54px;
$headerBorderBottomWidth: 1px;

.header {
  background: #000000;

  &.projectsPage {
    background: transparent;
    border-bottom: $headerBorderBottomWidth solid tokens.$color-gray-700;
  }

  &.editorPage {
    background: transparent;
    border-bottom: $headerBorderBottomWidth solid tokens.$color-gray-800;
  }
}

.content {
  display: grid;

  height: 54px;
  padding: 0 16px;
  margin: auto;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 480px) {
    grid-template-columns: 1fr auto;
  }

  @include media.xs {
    padding: 0 8px;
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: left;

  .link {
    color: #fff;
    font-size: 20px;
    font-weight: 900;
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
}

.link {
  color: #fff;

  font-size: 18px;
  font-weight: 500;

  &.active {
    color: #8474ff;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: right;
}
