@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/outline';
@use '../../scss/grid' as grid;
@use '../../scss/colors' as colors;

.button {
  background: colors.$primary-color;
  color: colors.$fg-color;

  background-clip: padding-box;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
  outline: 0;
  padding: grid.$grid-base-value grid.$grid-base-value * 2;
  border-radius: tokens.$border-radius-200;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all 0.2s;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;

  @include outline.focus-visible-outline;
}

.button:hover:not(:disabled) {
  background: lighten(colors.$primary-color, 5%);
}

.button:disabled {
  cursor: default;
  opacity: 0.7;
}

.button--outline {
  background: none;
  border-radius: tokens.$border-radius-200;
  box-shadow: inset 0 0 0 1px #ffffff;

  color: #ffffff;
}

.button--outline:hover:not(:disabled) {
  background: none;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ff5630;
  border-radius: tokens.$border-radius-200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.anchor-button {
  background-clip: padding-box;
  background: none;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  color: colors.$fg-color--darken-2;
  transition: all 0.2s;
  outline: 0;
  padding: 0;

  @include outline.focus-visible-outline;
}

.anchor-button:hover {
  color: colors.$primary-color;
}

.content {
  display: flex;

  .right {
    margin-left: grid.$grid-base-value;
  }
}
