@use '@restream/styles/scss/tokens';
@import '~@restream/styles/scss/media';

$position-transition: all 200ms ease-in-out;
$opacity-exit: 0;
$opacity-active-enter: 0.8;
$opacity-active-exit: 0.4;
$opacity-done: 1;

@mixin step-width {
  @include xs {
    width: 100%;
  }
}

.root {
  border-radius: tokens.$border-radius-200;

  position: relative;
  overflow: hidden;

  width: 100%;
  height: inherit;

  transition: all 200ms ease-in-out;

  @include xs {
    &.fullHeight {
      @include xs {
        height: 100% !important;
      }
    }

    & > div {
      height: inherit;
    }
  }
}

.stepContainer {
  width: 100%;
}

.stepEnterRight {
  position: absolute;
  right: -100%;
  opacity: $opacity-exit;

  @include step-width;
}

.stepEnterActiveRight {
  right: 0;
  opacity: $opacity-active-enter;
  transition: $position-transition;

  @include step-width;
}

.stepEnterDoneRight {
  right: 0;
  opacity: $opacity-done;
  transition: $position-transition;

  @include step-width;
}

.stepExitRight {
  position: absolute;
  opacity: $opacity-done;
  right: 0;

  @include step-width;
}

.stepExitActiveRight {
  right: 100%;
  opacity: $opacity-active-exit;
  transition: $position-transition;

  @include step-width;
}

.stepExitDoneRight {
  right: 100%;
  opacity: $opacity-exit;
  transition: $position-transition;

  @include step-width;
}

.stepEnterLeft {
  position: absolute;
  left: -100%;
  opacity: $opacity-exit;

  @include step-width;
}

.stepEnterActiveLeft {
  left: 0;
  opacity: $opacity-active-enter;
  transition: $position-transition;

  @include step-width;
}

.stepEnterDoneLeft {
  left: 0;
  opacity: $opacity-done;
  transition: $position-transition;

  @include step-width;
}

.stepExitLeft {
  position: absolute;
  opacity: $opacity-done;
  right: 0;

  @include step-width;
}

.stepExitActiveLeft {
  right: -100%;
  opacity: $opacity-active-exit;
  transition: $position-transition;

  @include step-width;
}

.stepExitDoneLeft {
  right: -100%;
  opacity: $opacity-exit;
  transition: $position-transition;

  @include step-width;
}

.stepComponent {
  height: inherit;
}
