@use '@restream/styles/scss/tokens';

.root {
  border-radius: tokens.$border-radius-200;
  overflow: hidden;
  min-height: 100px;
}

.header {
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: tokens.$color-gray-700;

  &.topMargin {
    margin-top: 10px;
  }

  & > span {
    font-weight: 500;
  }
}

.button {
  margin-top: 20px;
  width: 100%;
}
