@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.optionText {
  @include mixins.truncate-text();

  font-size: tokens.$font-size-body-sm;
  color: tokens.$color-gray-900;
  margin-left: 12px;
  line-height: 20px;
}

.valueLabelText {
  font-size: tokens.$font-size-body-sm;
  line-height: 20px;
  color: tokens.$color-gray-700;
  font-weight: 500;
  margin-left: 4px;
}

.selectedOptionIcon {
  width: 18px;
  stroke-width: 2;
  margin-left: auto;
}

.selectAllIconWrapper {
  display: flex;
  align-items: center;
  padding: 0 8px;
}
