@use '@restream/styles/scss/tokens';

.switch {
  height: fit-content;
  margin-right: 8px;
}

.switch-label {
  margin: 0;
}

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  height: 22px;
  border-radius: 8px;
  line-height: 1.4;
  background-color: tokens.$color-blue-500;
}

.formGroup {
  gap: 8px;
}
