@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.root {
  background-color: tokens.$color-gray-700;
  border-radius: tokens.$border-radius-200;

  &.hosted {
    background: linear-gradient(
        270deg,
        rgba(64, 50, 148, 0.5) 0%,
        rgba(49, 54, 138, 0.5) 20%,
        rgba(39, 56, 127, 0.5) 40%,
        rgba(33, 57, 115, 0.5) 60%,
        rgba(33, 57, 102, 0.5) 80%,
        rgba(37, 56, 88, 0.5) 100%
      ),
      #253858;
  }
}

.eventCard {
  padding: 0;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: minmax(100px, auto);

  @include media.sm {
    grid-template-columns: 1fr;
    height: auto;
    grid-gap: 0;
  }

  &.isError {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .commerceEvent & {
    @media (min-width: 768px) {
      height: 158px;
    }
  }

  .commerceMode & {
    background-color: tokens.$color-gray-700;
  }
}

.preview {
  height: 100%;
  position: relative;
  border-top-left-radius: tokens.$border-radius-200;
  border-bottom-left-radius: tokens.$border-radius-200;
  overflow: hidden;
}

.thumbnail {
  display: block;
  width: 260px;
  height: 100%;
  border-radius: 0;
  object-fit: cover;

  @include media.sm {
    width: 100%;
    height: auto;
  }

  &.inProgress {
    border-radius: tokens.$border-radius-200 0 0 tokens.$border-radius-200;
  }
}

.progressBar {
  position: absolute;
  bottom: 0;
}

.content {
  padding: 12px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  //height: 24px;

  @media (min-width: 576px) {
    padding: 20px 24px;
  }

  .header {
    width: 100%;

    @include media.sm {
      margin-bottom: 12px;
    }
  }

  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .hostedBy {
    width: 0;
    flex: 1;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.title {
  max-width: 300px;

  @include media.sm {
    max-width: 160px;
    // TODO(font): use token
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 0;
  }

  &.commerceEvent {
    font-weight: 600;
    font-size: tokens.$font-size-body-md;
    line-height: tokens.$font-line-height-body-md;
    color: tokens.$color-white;
    letter-spacing: 0.02em;
  }
}

.descriptionContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  // &.firstRow {
  //   grid-row: 1;
  // }
}

.description {
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-300;

  @include media.sm {
    // TODO(font): use token
    font-size: 13px;
    line-height: 18px;
  }
}

.actionButtonsContainer {
  display: flex;
  align-items: center;
}

.eventActionButton {
  margin-left: 10px;

  .eventActionButtonText {
    @media screen and (max-width: media.$breakpoint-xs) {
      width: 100%;
      text-align: center;
    }
  }

  @media screen and (max-width: media.$breakpoint-xs) {
    width: 100%;

    div {
      width: 100%;
    }
  }
}

.commerceSubmitButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  background-color: transparent;
  color: tokens.$color-gray-300;
  border: tokens.$border-width solid tokens.$color-gray-300;
  transition: color 250ms ease, border-color 250ms ease,
    background-color 250ms ease;

  &:not(:disabled):hover {
    background-color: rgb(#d7dce3, 10%);
    color: tokens.$color-gray-200;
    border-color: tokens.$color-gray-200;
  }

  &:not(:disabled):active {
    background-color: transparent;
    color: tokens.$color-gray-300;
    border-color: tokens.$color-gray-300;
  }

  &:disabled {
    color: tokens.$color-gray-600;
    border-color: tokens.$color-gray-600;
  }

  &.dropdown {
    width: 36px;
    padding: 0;
  }
}

.contentFooter {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  //align-items: center;
  align-items: flex-end;
  width: 100%;

  @media screen and (max-width: media.$breakpoint-xs) {
    grid-row-gap: 12px;
  }

  .gridItem {
    &:nth-child(2) {
      grid-column: 3;
    }

    &:nth-child(3) {
      margin-left: 8px;
      grid-column: 4;

      &.isOwn {
        @media screen and (max-width: media.$breakpoint-xs) {
          grid-column: 1/4;
          grid-row: 2;
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}

.destinationsSummary {
  margin-right: 6px;

  &.readonly {
    cursor: unset;

    &:hover {
      opacity: 1;
    }
  }

  &.error {
    border-radius: tokens.$border-radius-200;
    box-shadow: 0 0 0 2px tokens.$color-red-400;
  }
}

.destinationsSummaryWrapper {
  display: grid;
  grid-auto-flow: column;
  align-items: flex-start;
}

.groupTitle {
  font-weight: 500;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  color: tokens.$color-gray-300;
  margin-bottom: 4px;

  &.rightOffset {
    margin-right: 8px;

    @media (min-width: 576px) {
      margin-right: 12px;
    }
  }
}

// Deprecated
.summaryTitle {
  // TODO(font): use token
  font-size: 11px;
  line-height: 1;
  margin-bottom: 2px;

  // Fix for the case when there are a few channels added on both host and guests sides.
  // In this case there's no distance between guest and host summaries titles.
  padding-right: 20px;
}

.calloutWrapper {
  padding: 16px 16px 0;
}

.menuList {
  & li:first-of-type .menuItem {
    & svg {
      height: 18px;
    }
  }
}

.menuItem {
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
}

.menuButton {
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 36px;
  height: 36px;
  transition: all 0.3s;

  &:hover {
    // TODO(color): ues token
    background: #2d436a;
  }

  &.menuButtonActive {
    background: tokens.$color-gray-800;
  }

  .buttonIcon {
    height: 16px;
    width: 16px;
  }
}

.commerceActionButton {
  padding: 8px;
  color: tokens.$color-gray-300;
  transition: background-color 250ms ease, color 250ms ease;

  &:hover {
    background-color: rgb(tokens.$color-gray-200, 10%);
  }

  &:active {
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    color: tokens.$color-gray-600;
  }
}

.stats {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
}

.statsInfo {
  display: flex;
  align-items: center;
}

.statsIcon {
  margin-right: 2px;
  vertical-align: middle;
  transform: scale(0.75);
}
