@use '@restream/styles/scss/tokens' as tokens;

.root {
  color: tokens.$color-blue-700;
  font-weight: 500;
  border-left-width: 0;

  transition: color 200ms ease-in-out;
}

.root:disabled {
  color: tokens.$color-gray-500;
}

:global(.dark-theme) {
  .root {
    color: tokens.$color-blue-300;

    &:hover:not(:disabled) {
      color: tokens.$color-blue-200;
    }
  }

  .root:disabled {
    opacity: 1;
    color: tokens.$color-gray-500;
  }
}

:global(.transparent-theme) {
  .root {
    color: tokens.$color-blue-300;

    &:hover:not(:disabled) {
      color: tokens.$color-blue-200;
    }
  }

  .root:disabled {
    opacity: 1;
    color: tokens.$color-gray-500;
  }
}
