@use '~@restream/styles/scss/media' as media;

.root {
  margin-bottom: 40px;

  @media (min-width: 1100px) {
    width: 1005px;
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  max-width: 1005px;
}

.emailSection {
  padding-bottom: 40px;
}

.helpText {
  font-size: 15px;
  font-weight: normal;
  color: #c1c7d0;
  line-height: 24px;
  margin-bottom: 20px;
}

.subscriptionsDivider {
  width: 100%;
  max-width: 640px;
  height: 1px;
  background: #42526e;
  margin-top: 24px;
}

.submitButtonContainer {
  margin: 48px 0;
}

.submitButton {
  min-width: 169px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  padding: 10px 18px;

  background: #2970ff;
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  cursor: pointer;

  transition: background-color 0.2s ease-in;

  @include media.sm {
    width: 100%;
  }

  &:hover,
  &:focus {
    background: #155eef;
  }

  &.disabled {
    cursor: initial;
  }

  &.loading,
  &.loading:hover,
  &.loading:focus {
    background: rgba(41, 112, 255, 0.2);
  }

  &.error,
  &.error:hover,
  &.error:focus {
    background: #f04438;
  }

  &.success,
  &.success:hover,
  &.success:focus {
    background: #12b76a;
  }

  svg {
    margin-right: 8px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 36px;
  height: 36px;
  border: 4px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &.buttonLoader {
    width: 17.5px;
    height: 17.5px;
    border: 2.5px solid #fff;
    border-bottom-color: #2970ff;
  }
}

.submitError {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #f97066;
}
