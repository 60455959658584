.recordingBlock {
  padding: 6px 24px;

  @media (min-width: 576px) {
    padding: 6px 28px;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}
