@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';
@import '~@restream/styles/scss/media';
@import '../../components/scss/guides';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.button {
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;
  padding: 6px 16px;
}

.addChannelButton {
  // TODO(color): use token
  color: #fafbfc;
  background-color: #286fff;
  transition: color 300ms ease, background-color 300ms ease;

  &:hover:not(:disabled) {
    color: tokens.$color-white;
    background-color: rgb(#286fff, 0.8);
  }
}

.editTitlesButton {
  color: #f4f5f7;
  border: 2px solid #505f79;
  background-color: #091e42 !important;
  transition: color 300ms ease, background-color 300ms ease;

  &:hover:not(:disabled) {
    color: #fff;
    background-color: rgba(76, 104, 151, 0.2) !important;
  }
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  margin-bottom: 16px;
}

.toggle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  margin-bottom: 16px;

  &.oldPricing {
    width: unset;
    flex: 0 1;
  }

  .destinationCounter {
    @include mixins.truncate-text;

    color: tokens.$color-white;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;

    .link {
      color: #84adff;
      font-weight: 500;
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;
      opacity: 1;

      &:hover {
        opacity: 0.8;
      }
    }

    .dot {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;

      &.hidden {
        opacity: 0;
        user-select: none;
        cursor: unset;
      }
    }
  }
}

.buttonContent {
  display: flex;
  align-items: center;
}

.buttonIcon {
  margin-right: 8px;
}
