@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .title {
    color: tokens.$color-gray-700;
    text-align: center;

    font-size: tokens.$font-size-body-sm;
    font-weight: 500;
    line-height: 20px;
    margin: 12px 0 0;
  }

  & .description {
    color: tokens.$color-gray-600;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
  }
}
