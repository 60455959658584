@use '@restream/styles/scss/tokens';
@use '../../../../../../scss/typography' as typography;
@use '../../../../../../scss/animation' as animation;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #253858;

  padding: 6px 24px;

  appearance: none;
  width: 100%;
  border: none;

  font-size: tokens.$font-size-body-md;
  font-family: inherit;
  height: 48px;

  .streamingSettingsTheme & {
    padding: 16px;
    height: auto;
    border-radius: tokens.$border-radius-200;
    background-color: #ebecf0;
  }
}

.root.clickable {
  cursor: pointer;
}

.root.open {
  border-radius: tokens.$border-radius-200 tokens.$border-radius-200 0 0;
}

.content {
  display: flex;
  align-items: center;
}

.caret-wrapper {
  display: flex;
  align-items: center;
}

.caret {
  transition: animation.$animation-default-time;
}

.caret.open {
  transform: rotate(180deg);
}

.caret.close {
  transform: rotate(0);
}

.status {
  margin-right: 8px;
}

.form {
  width: 100%;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto auto;
  grid-gap: 8px;
}

.title {
  @include typography.font-14;

  margin-right: 12px;

  .streamingSettingsTheme & {
    color: #505f79;
  }
}

.input {
  @include typography.font-14;
  background: none;
  border: none;

  border-radius: tokens.$border-radius-200;
  outline: none;
  width: 100%;
  height: 24px;
  color: tokens.$color-white;

  .streamingSettingsTheme & {
    background: tokens.$color-white;
    box-sizing: border-box;
    border-radius: tokens.$border-radius-200;
    height: 40px;
    font-weight: 400;
    font-size: tokens.$font-size-body-md;
    line-height: tokens.$font-line-height-body-md;
    color: tokens.$color-gray-900;
    padding: 12px;
  }
}

.button {
  display: flex;
  align-items: center;
  min-width: 72px;
  justify-content: center;
  padding: 8px 12px;

  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;

  height: 40px;
  width: fit-content;

  &:disabled {
    opacity: 0.8;
  }
}

.button.submit {
  // TODO(color): use token
  background-color: #286fff;
  min-width: 80px;
  transition: opacity 300ms ease;
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;

  &:hover {
    background-color: #286fff;
    opacity: 0.9;
  }

  &:disabled {
    background-color: #286fff;
    opacity: 1;
  }
}

.button.cancel {
  // TODO(color): use token
  color: #6b778c;
  background-color: transparent;
  transition: background-color 300ms ease;

  &:hover {
    color: #6b778c;
    background-color: rgba(9, 30, 66, 0.08);
  }

  &:disabled {
    color: #6b778c;
    background-color: transparent;
  }
}
