@use '@restream/styles/scss/tokens';

@mixin link {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  color: currentColor;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-decoration: underline;
  vertical-align: baseline;

  transition: color 0.2s ease;

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    text-decoration: none;
  }
}

.root {
  display: flex;
  gap: 4px;
  text-wrap: pretty;

  background-color: tokens.$color-red-25;
  border: 1px solid tokens.$color-red-300;
  border-radius: 4px;
  color: tokens.$color-red-600;
  padding: 6px;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  @media (max-width: 576px) {
    padding: 12px;
  }

  &.isWarning {
    background-color: tokens.$color-yellow-25;
    border: 1px solid tokens.$color-yellow-300;
    color: tokens.$color-yellow-600;
  }

  &.isInfo {
    background-color: tokens.$color-blue-25;
    border: 1px solid tokens.$color-blue-300;
    color: tokens.$color-blue-700;
  }

  &.dark {
    color: tokens.$color-red-25;
    background-color: transparentize(tokens.$color-red-600, 0.8);
    border: 1px solid tokens.$color-red-600;

    &.isWarning {
      background-color: transparentize(tokens.$color-yellow-400, 0.8);
      border: 1px solid tokens.$color-yellow-400;
      color: tokens.$color-yellow-25;
    }

    &.isInfo {
      background-color: transparentize(tokens.$color-blue-600, 0.8) !important;
      border: 1px solid tokens.$color-blue-400;
      color: tokens.$color-blue-25;
    }

    &.link:not(.linkStyleButton) {
      color: tokens.$color-red-25;

      &:hover {
        color: tokens.$color-red-50;
      }
    }
  }

  &.link:not(.linkStyleButton) {
    @include link;
  }

  & a:not(.linkStyleButton) {
    @include link;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.link {
  @include link;
}

.linkStyleButton {
  border: none;
  background: none;
  cursor: pointer;
  color: tokens.$color-red-600;
  padding: 0;
  color: currentColor;

  display: inline-flex;

  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  transition: all 0.2s ease;
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover:not(:disabled) {
    text-decoration: none;
    background-color: transparent;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.linkStyleButton.fbGroup {
  display: unset;
  white-space: nowrap;
}

.errorIcon {
  width: 16px;
  height: 16px;
  margin-top: 2px;

  &.warning {
    color: tokens.$color-yellow-600;
  }

  &.info {
    color: tokens.$color-blue-600;
  }

  &.dark {
    color: tokens.$color-red-100;

    &.warning {
      color: tokens.$color-yellow-50;
    }

    &.info {
      color: tokens.$color-blue-100;
    }
  }
}

.inline {
  margin: 0;
}

.boldText {
  font-weight: 500;
}
