@use '@restream/styles/scss/tokens';

.root {
  padding-top: 2px;
  padding-bottom: 2px;
}

.live {
  background-color: tokens.$color-orange-dark-400;
  color: tokens.$color-orange-dark-25;
  animation: blinkingLive 1.2s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

.waiting {
  background-color: tokens.$color-yellow-500;

  .content {
    color: tokens.$color-yellow-25;
    text-transform: capitalize;
  }
}

@keyframes blinkingLive {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.content {
  text-transform: capitalize;
}
