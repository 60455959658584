@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.button {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in;

  color: tokens.$color-white;
  font-size: tokens.$font-size-body-sm;
  font-weight: 500;
  line-height: 20px;

  white-space: initial;

  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  border-radius: tokens.$border-radius-200 tokens.$border-radius-200 0 0;

  &:disabled {
    opacity: initial;
  }

  &:hover:not(&:disabled) {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.7);

    &.blue {
      background-color: rgba(41, 112, 255, 0.8);
    }
  }

  @include media.xs {
    opacity: 1;

    &.blue {
      background-color: rgba(41, 112, 255, 0.8);
    }
  }
}
