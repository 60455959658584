@use '@restream/styles/scss/tokens';

.root {
  width: 100%;
  height: 250px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  border: 1px solid tokens.$color-gray-700;

  padding: 8px 16px;
  color: tokens.$color-gray-300;
}

.title {
  color: tokens.$color-gray-300;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  max-width: 350px;
  text-wrap: pretty;

  margin-top: 16px;

  &.error {
    display: flex;
    align-items: center;
    max-width: none;

    color: tokens.$color-red-300;
    text-align: center;
  }
}

.error {
  color: tokens.$color-red-300;
}

.button {
  color: inherit;
  font-size: inherit;
  font-weight: 400;

  background: transparent;
  text-decoration: underline;
  padding: 0 4px;

  transition: all 0.3s ease-out;

  &.retry {
    color: tokens.$color-red-300;
  }

  &:hover:not(&.disabled) {
    color: inherit;
    background: transparent;
    text-decoration: none;

    &.retry {
      color: tokens.$color-red-300;
    }
  }
}

.createButton {
  margin: 16px auto;
}

.cardContainer {
  display: flex;
  justify-content: center;
  isolation: isolate;
}

.card {
  width: 56px;
  height: 56px;
  display: grid;
  place-items: center;
  border-radius: 0.75rem;
  position: relative;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
  border: 1px solid tokens.$color-gray-500;
  background-color: tokens.$color-gray-800;
  transition: all 0.25s;
}

.card:nth-child(1) {
  left: 10px;
  top: 6px;
  transform: rotate(-6deg);
}

.card:nth-child(2) {
  z-index: 10;
}

.card:nth-child(3) {
  right: 10px;
  top: 6px;
  transform: rotate(6deg);
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--muted-foreground, #6b7280);
}

.root:hover {
  .card {
    filter: brightness(1.1);
  }
}

/* Hover effects */
.root:hover .card:nth-child(1) {
  transform: translateX(-20px) rotate(-12deg) translateY(-2px);
}

.root:hover .card:nth-child(2) {
  transform: translateY(-2px);
}

.root:hover .card:nth-child(3) {
  transform: translateX(20px) rotate(12deg) translateY(-2px);
}
