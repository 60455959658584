@property --topColor {
  syntax: '<color>';
  initial-value: #2970ff;
  inherits: false;
}

@property --bottomColor {
  syntax: '<color>';
  initial-value: #155eef;
  inherits: false;
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 7px 12px;
  margin: 0;

  cursor: pointer;

  color: var(--Base-White, #fff);
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-9, 20px); /* 142.857% */

  border-radius: 8px;
  border: 1px solid #0a56ed;

  background: linear-gradient(
    180deg,
    var(--topColor) 0%,
    var(--bottomColor) 100%
  );

  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

  transition: --topColor 0.2s, --bottomColor 0.4s;
}

.root:hover {
  --topColor: #155eef;
  --bottomColor: #2970ff;
}
