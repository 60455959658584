@use '@restream/styles/scss/tokens';

.loader {
  margin: 0 auto;
}

.platformBlock {
  display: grid;
  grid-gap: 20px;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;

  @media (min-width: 576px) {
    grid-auto-flow: column;
  }
}

.platformLoader {
  margin: 0 auto;
}

.label {
  font-weight: normal;
  color: rgba(255, 255, 255, 0.3);
  font-size: 11px;
  cursor: pointer;

  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  line-height: 20px;
}

.restreamText {
  color: rgba(255, 255, 255, 0.5);
}

.icon {
  width: 24px;
}

.button {
  height: 40px;

  // added all these styles because some of them are <a> instead of <button> (discord)
  // it will make these components more resilient to changes in ui-kit's <Button>
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
  outline: 0;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all 0.2s;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  outline: none;

  display: inline-flex;
  align-items: center;
}

.disconnectButton {
  background-color: transparent;
  color: #59688e;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background-color: transparent !important;
    color: #ff5630 !important;
  }
}

.platformButtonIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.platformButton {
  color: #fff;
  padding-left: 12px;
  transition: background-color 200ms ease;

  &:hover:not(.disabled),
  &:active:not(.disabled) {
    color: #fff;
  }
}

.twitterButton {
  background-color: #1da1f2;

  &:hover:not(.disabled),
  &:active:not(.disabled) {
    background-color: #0c85d0 !important;
  }
}

.discordButton {
  background-color: #7289da;

  &:hover:not(.disabled),
  &:active:not(.disabled) {
    background-color: #4a67cf !important;
  }
}

.facebookButton {
  background-color: #1677f0;

  &:hover:not(.disabled),
  &:active:not(.disabled) {
    background-color: #1668d6 !important;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

.group {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: center;
  grid-template-columns: min-content max-content;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.74);
}

.displayName {
  color: #fff;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.checkbox {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.selectBlock {
  display: flex;
  align-items: center;
  flex: 1 1;
  justify-content: left;
}

.selectTitle {
  display: none;
  color: #aeb4bf;
  margin-right: 10px;
  font-size: 13px;
  white-space: nowrap;

  @media (min-width: 576px) {
    display: inline;
  }
}

.select {
  background-color: #253858;
  border-radius: tokens.$border-radius-200;
  color: #fff;
  height: 40px;
  width: 160px;
}

.selectDropdown {
  display: grid;
  padding: 4px 0;
  background-color: #253858;
}

.tooltipTrigger {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.selectItem {
  padding: 8px 12px;
  min-height: 20px;
  background-color: #253858;
  color: #fff;
  cursor: pointer;
  border-radius: 0 !important;
  font-size: 14px;
  white-space: nowrap;
  transition: background-color 200ms ease;

  &:not(.selected):hover {
    background-color: #286fff;
  }

  &.selected {
    padding: 0 8px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: inherit;
    width: 100%;
  }
}

.selectItemType {
  text-transform: capitalize;
  color: #aeb4bf;
  margin-right: 4px;
}

.deprecated {
  filter: grayscale(100%);
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
