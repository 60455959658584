@use '@restream/styles/scss/tokens';

.tabs {
  display: flex;
  column-gap: 8px;
  border-bottom: tokens.$border-width solid tokens.$color-gray-700;
}

.tab {
  font-size: tokens.$font-size-body-xs;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.48px;

  padding: 6px;
  background: transparent;
  border: 0;
  outline: 0;
  border-bottom: 2px solid transparent;
  color: tokens.$color-gray-300;

  transition: all 0.25s;

  &:hover:not(&:disabled),
  &[data-state='active'] {
    color: tokens.$color-gray-25;
  }

  &:hover:not(&:disabled) {
    cursor: pointer;
  }

  &[data-state='active'] {
    border-color: tokens.$color-gray-25;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.tabContent {
  margin-top: 16px;
  color: tokens.$color-white;
}
