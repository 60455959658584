@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: transparent;
  transition: background-color 0.3s ease-in-out;
  overflow: hidden;

  cursor: pointer;

  color: #6b778c;

  &:hover {
    background: #f5f6f8;
  }
}

.listItemContent {
  display: flex;
  overflow: hidden;
  gap: 8px;
  align-items: center;
}

.channelName {
  max-width: 106px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  color: #2b3d5c;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.listIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
