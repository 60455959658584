@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';
@import '@restream/styles/scss/animation';

.root {
  & > .label {
    & > .checkbox {
      width: 24px;
      height: 24px;
      line-height: tokens.$font-line-height-body-md;
      color: tokens.$color-white;
      border: tokens.$border-width solid tokens.$color-gray-300;
      border-radius: tokens.$border-radius-200;

      position: relative;
      appearance: none;
      outline: none;
      cursor: pointer;

      transition: background-color $animation-default-time ease,
        border-color $animation-default-time ease;

      margin: 0 8px 0 0;

      &:checked {
        background-color: tokens.$color-blue-500;
        border-color: transparent;

        &:after {
          position: absolute;
          content: '\2713';
          font-weight: 500;
          font-size: tokens.$font-size-body-lg;
          height: 8px * 2;
          background-color: transparent;
          top: 0;
          left: 25%;
        }
      }
    }
  }
}

:global(.dark-theme) {
  .root {
    & > .label {
      & > .checkbox {
        background-color: tokens.$color-gray-500;
      }
    }
  }
}
