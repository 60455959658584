@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  width: 350px;
  box-sizing: border-box;
  background-color: white;
  border-radius: tokens.$border-radius-200;

  overflow: hidden;
  position: relative;

  &.info {
    & .header {
      background: transparent;
    }

    & .title {
      color: tokens.$color-gray-700;
    }

    & .main,
    & .footer {
      padding-top: 0;
    }

    & .footer {
      padding-bottom: 16px;
    }
  }

  &.error {
    & .title {
      color: tokens.$color-red-700;
    }
  }
}

.main,
.header,
.footer {
  padding: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: tokens.$color-red-100;
  padding: 16px 16px 4px 16px;

  .closeButton {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;

    &:disabled {
      cursor: initial;
    }

    &:hover:not(&:disabled) {
      background: tokens.$color-gray-100;
    }
  }

  .closeButtonIcon {
    width: 20px;
    color: tokens.$color-gray-600;
    stroke-width: 2;

    & path {
      stroke: tokens.$color-gray-600;
    }
  }
}

.title {
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  font-weight: 500;
}

.main {
  &,
  & > * {
    color: tokens.$color-gray-700;
    font-size: 14px;
    line-height: 20px;
  }
}

.count {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-500;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  background: transparent;

  .backButton,
  .nextButton {
    padding: 0;
    background-color: transparent;

    &:disabled {
      cursor: initial;

      & .backButtonIcon,
      & .nextButtonIcon {
        color: tokens.$color-gray-200;
      }
    }

    &:hover:not(&:disabled) {
      background: tokens.$color-gray-50;
      border-radius: 4px;
    }
  }

  .backButton {
    margin-right: 8px;

    .backButtonIcon {
      transform: rotate(180deg);
    }
  }

  .backButtonIcon,
  .nextButtonIcon {
    width: 18px;
    stroke-width: 2;
    color: tokens.$color-gray-500;
  }
}
