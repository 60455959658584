@use '@restream/styles/scss/tokens';

.stepIcon {
  @media (max-width: 768px) {
    width: 17px;
  }

  @media (max-width: 576px) {
    display: none;
  }
}

.stepperWrapper {
  margin: 12px 0;
  flex: 1;
}

.list {
  background: #0c2144;
  border: 1px solid rgba(43, 61, 92, 0.6);
  border-radius: tokens.$border-radius-400;
  padding: 16px 24px;
}

.itemIconWrapper {
  border-radius: 12px;
}
