@use '@restream/styles/scss/tokens';

.root {
  min-height: 38px;
  //border: 1px solid #fcecf2;
  position: relative;
  border-radius: tokens.$border-radius-200;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: #fcecf2;

  color: #d62c0c;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;

  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease;

  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
  justify-content: flex-start;
  align-items: center;

  &.visible {
    opacity: 1;
    pointer-events: initial;
  }
}
