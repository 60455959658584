@use '@restream/styles/scss/tokens';
@use '../../next/scss/grid' as grid;
@use '../../next/scss/colors' as colors;

.container {
  display: flex;
  flex-direction: row;
  min-height: 100%;

  nav {
    background: none;
  }
}

.loader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.navText {
  margin: 0 8px;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1.8;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #fff !important;
}

.navLink {
  display: flex;
  flex-direction: row;
  padding: 10px;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: tokens.$border-radius-200;
  margin-bottom: 10px;

  &--dim {
    opacity: 0.3;
  }
}

.navLink:hover {
  background-color: #091e42;
  opacity: 0.8;
}

.content {
  display: grid;
  grid-gap: grid.$grid-base-value * 2;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  padding: grid.$grid-base-value * 3 grid.$grid-base-value * 3;

  .title {
    grid-column: 1 / span 2;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .subtitle {
    grid-column: 1 / span 2;
    margin-top: grid.$grid-base-value * 3;

    .container {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }
  }

  .form {
    grid-column: 1 / span 2;
  }

  .platform-item {
    grid-column: 1 / span 2;
  }

  .placeholder {
    grid-column: 1 / span 2;
  }
}

.sidebar {
  min-width: 400px;
  background: colors.$bg-color--darken-1;

  @media screen and (max-width: 1080px) {
    display: none;
  }
}
