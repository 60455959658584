.timelineSlices {
  position: absolute;
  width: 100%;
  height: 48px;
  margin: 0;

  &,
  & * {
    box-sizing: border-box;
  }
}
