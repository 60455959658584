@use '@restream/styles/scss/tokens';

.root {
  transition: padding-top 0.2s ease-in-out;
  &.opened {
    padding-top: 22px;
  }
}

.content {
  background-color: tokens.$color-gray-25;
  padding: 10px 14px;
  border-radius: tokens.$border-radius-200;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: tokens.$color-gray-800;
}

.btn {
  margin-top: 5px;

  background: none;
  border: none;
  padding: 0;

  font-weight: 500;
  font-size: 14px;
  color: tokens.$color-gray-800;

  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
