@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '~@restream/styles/scss/media-mf' as media-mf;

.upgradeContainer {
  padding: 16px;

  border: 1px solid #6927da;
  background: linear-gradient(
    90deg,
    rgba(103, 227, 249, 0.1) -99.46%,
    rgba(46, 144, 250, 0.1) -49.56%,
    rgba(0, 78, 235, 0.1) 0.47%,
    rgba(105, 39, 218, 0.1) 50.24%,
    rgba(212, 68, 241, 0.1) 100%
  );

  border-radius: tokens.$border-radius-200;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #0040c1;

  @include media.md {
    flex-direction: column;
    align-items: flex-start;
  }

  .upgradeButton {
    background: linear-gradient(
      90deg,
      #67e3f9 -99.46%,
      #2e90fa -49.56%,
      #004eeb 0.47%,
      #6927da 50.24%,
      #d444f1 100%
    );

    margin-left: 8px;

    @include media.md {
      margin-top: 10px;
      margin-left: 0;
    }

    &:hover:not(&:disabled) {
      background: linear-gradient(
        90deg,
        tokens.$color-blue-700,
        tokens.$color-blue-500
      );
    }
  }

  .upgradeExtraBoldText {
    font-weight: 500;
  }
}

.almostFullContainer {
  padding: 10px 16px;

  background: #fffaeb;
  border: 1px solid #fedf89;

  border-radius: tokens.$border-radius-200;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media.md {
    flex-direction: column;
    align-items: flex-start;
  }

  .almostFullContentWrapper {
    display: flex;
    align-items: flex-start;
  }

  .almostFullIconWrapper {
    display: flex;
    background: #fef0c7;
    border-radius: 50%;

    padding: 9px;
    margin-right: 10px;

    @media (max-width: 390px) {
      display: none;
    }
  }

  .almostFullText {
    font-size: 16px;
    line-height: 26px;
    color: #091e42;
    margin: auto;

    .almostFullBoldText {
      font-weight: 600;
    }
  }

  .almostFullButton {
    display: flex;
    align-items: center;

    height: 36px;
    padding: 9px 16px;
    font-weight: 500;
    font-size: 16px;
    border-radius: tokens.$border-radius-200;
    color: white;
    background: #155eef;
    opacity: 1;
    transition: opacity 200ms ease;

    &:hover {
      //opacity: 0.8;
      background: #004eeb;
    }

    @include media.md {
      margin-top: 10px;
    }

    @media (max-width: 390px) {
      width: 100%;

      display: flex;
      justify-content: center;
    }
  }
}
