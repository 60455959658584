@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  width: 100%;
  padding: 8px;

  align-items: center;

  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-300;

  background: none;
  border: none;

  cursor: pointer;

  border-radius: 8px;

  transition: all 200ms ease-in-out;

  & > svg path {
    transition: all 200ms ease-in-out;
    stroke: tokens.$color-gray-300;
  }

  margin-bottom: 1px;
}

.root.active,
.root:hover {
  color: tokens.$color-gray-25;

  & .iconContainer > svg path {
    stroke: tokens.$color-gray-100;
  }
}

.root.active {
  background: tokens.$color-gray-800;

  &:hover {
    cursor: default;
  }
}

.root:hover:not(.active) {
  background: var(--Gray-850, #0f2447);
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-right: 6px;
}

.leftSideContent {
  margin-left: auto;
}
