@use '@restream/styles/scss/tokens';

.button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 8px 14px;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  border-radius: tokens.$border-radius-200;
}

.content {
  width: 100%;
}
