@use '../../../scss/modals' as modals;
@import '~@restream/styles/scss/media';

.root {
  @include modals.modal-width;
  padding-bottom: 14px;
}

.modalHeader {
  padding: 20px 0;
}

.body {
  max-height: 70vh;
  position: relative;
  overflow-y: scroll;
  padding: 0;

  @include sm {
    max-height: 90%;
    height: 90%;
  }
}

.listItem {
  box-sizing: border-box;
  border-bottom: 1px solid #edf0f3;
  padding: 12px 24px;

  &:last-child {
    border-bottom: none;
  }
}
