@use '@restream/styles/scss/tokens';
@use 'sass:math';

$width: 50px;
$height: 24px;

.hiddenCursorTool {
  position: absolute;
  top: 24px;
  width: 2px;
  height: 64px;
  color: #fff;
  background-color: #000;
  pointer-events: none;
  visibility: hidden;
}

.timelineCursorTool {
  position: fixed;

  z-index: 4;
  width: 2px;
  height: 64px;
  color: #fff;
  background-color: #000;
  pointer-events: none;

  transition: opacity 60ms;
}

.timelineCursorTool:before {
  content: attr(data-timestamp);
  transform: translateX(math.div($width, -2.2));
  position: absolute;
  top: $height * (-1);
  display: flex;
  white-space: nowrap;
  min-width: $width;
  height: $height;
  justify-content: center;
  align-items: center;
  font-size: tokens.$font-size-body-xs;
  padding: 4px;
  background: tokens.$color-gray-900;
  color: white;
  border-radius: 4px;
}
