@import '@restream/styles/scss/tokens';
@import '@restream/styles/scss/typography';

.container {
    display: flex;
    width: 100%;
    & > *:not(:last-child) {
        margin-bottom: $spacing-md;
    }
}

.row {
    flex-direction: row;
    flex-wrap: wrap;
}

.column {
    flex-direction: column;
}

.items {
    display: flex;
    flex: 1;
    gap: 1rem;
}

.items > * {
    flex: 1 1 auto; // Grow and shrink from a base size
    min-width: 0; // Prevents flex items from overflowing the container
}

.legend {
    @include font-18;
    color: $color-gray-700;
}
