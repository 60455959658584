@use '@restream/styles/scss/tokens';

.root {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;

  border-radius: tokens.$border-radius-200;
  transition: background-color 200ms;

  background: #286fff;

  svg {
    opacity: 1;
    transition: opacity 200ms;
  }

  &:hover {
    background: #4280ff;

    svg {
      opacity: 1;
    }
  }

  &.active {
    background: #286fff;

    svg {
      opacity: 1;
    }
  }
}

.tooltipTrigger {
  display: flex;
}

.checkIconWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border-radius: tokens.$border-radius-200;
}

.button {
  background: #286fff;
  color: rgba(255, 255, 255, 0.9);
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
  outline: 0;
  //padding: 8px 16px;
  border-radius: tokens.$border-radius-200;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all 0.2s;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px;

  &:hover:not(&:disabled) {
    background: tokens.$color-blue-600;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
