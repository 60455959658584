@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';
@import '../scss/colors';
@import '../scss/media';

/* stylelint-disable no-descending-specificity */
.lever {
  cursor: pointer;
  content: '';
  display: inline-block;
  position: relative;
  // TODO(color): use token
  background-color: #42526e;
  border-radius: tokens.$border-radius-200;
  vertical-align: middle;
  margin: 0;
  width: 33px;
  height: 12px;
  transition: background 0.2s ease;

  &::after {
    cursor: pointer;
    content: '';
    position: absolute;
    display: inline-block;
    // TODO(color): use token
    background-color: #6c798f;
    border-radius: 50%;
    left: -2px;
    top: -4px;
    width: 19px;
    height: 19px;
    transition: left 0.2s ease, background 0.2s ease, box-shadow 1s ease;
  }
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
  box-sizing: border-box;
  padding: 0;

  &:checked,
  &:not(:checked) {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &:checked + .lever {
    // TODO(color): use token
    background-color: #0052cc;

    &:after {
      // TODO(color): use token
      background-color: $color-light;
      left: 14px;
    }
  }
}

.switch {
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    filter: grayscale(100%);
  }

  &.light {
    .input {
      &:checked + .lever {
        &::after {
          // TODO(color): use token
          background-color: $color-light-bg-1;
        }
      }
    }
  }

  &.default {
    .lever {
      height: 20px;
      width: 36px;
      border-radius: 12px;
      // TODO(color): use token
      background-color: #505f79;

      &:hover {
        // TODO(color): use token
        background-color: #344563;
      }

      &::after {
        width: 16px;
        height: 16px;
        left: 2px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transition: all 0.2s ease;
        background: tokens.$color-white;
        box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1),
          0 1px 2px rgba(16, 24, 40, 0.06);
      }

      @include xs {
        width: 44px;
        height: 24px;

        &::after {
          height: 20px;
          width: 20px;
        }
      }
    }

    .input {
      &:checked + .lever {
        // TODO(color): use token
        background-color: #528bff;

        &:hover {
          background-color: #2970ff;
        }

        &::after {
          left: 18px;
          background-color: tokens.$color-white;

          @include xs {
            left: 21.5px;
          }
        }
      }
    }

    &.light {
      .input {
        // TODO(color): use token

        & + .lever {
          background-color: #d7dce3;

          &:hover {
            background-color: #bac1cc;
          }

          &::after {
            background-color: tokens.$color-white;
          }
        }

        &:checked + .lever {
          // TODO(color): use token
          background-color: #2970ff;

          &:hover {
            background-color: #155eef;
          }
        }
      }
    }

    // base dark theme
    &.disabled {
      // TODO(color): use token
      filter: none;

      .input {
        & + .lever {
          background: #344563;

          &::after {
            background-color: #505f79;
          }
        }
      }
    }

    &.light.disabled {
      // TODO(color): use token

      .input {
        & + .lever {
          background: #ebecf0;

          &::after {
            background-color: #f4f5f7;
          }
        }
      }
    }
  }
}
/* stylelint-enable */
