@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.stepperTitle {
  color: tokens.$color-gray-700;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  font-weight: 500;
  text-transform: uppercase;
}

.stepsList {
  margin: 20px 0 0;
  position: relative;

  &::after {
    content: '';
    height: 100%;
    width: 1px;
    background: tokens.$color-gray-100;
    position: absolute;
    left: 15.5px;
    top: 0;
  }
}

.step {
  display: flex;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.centeredContent {
    align-items: center;
  }
}

.stepIcon {
  & path {
    stroke: tokens.$color-gray-400;
    transition: all 0.3s;
  }

  &.isActive {
    & path {
      stroke: rgba(21, 94, 239, 1);
    }
  }
}

.stepIconWrapper {
  height: 32px;
  min-width: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 8px;
  border-radius: 50%;

  background: tokens.$color-gray-50;
  border: 1px solid tokens.$color-gray-200;

  z-index: 1;
  transition: all 0.3s;

  &.isActive {
    background: tokens.$color-blue-50;
    border: 1px solid tokens.$color-blue-100;
  }

  &.isDone {
    background: tokens.$color-gray-100;
    border: 1px solid tokens.$color-gray-200;
    border-radius: 36px;
  }
}

.stepMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stepHeader {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: tokens.$color-gray-700;
  transition: all 0.3s;

  &.isActive {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: tokens.$color-gray-900;
  }
}

.stepDescription {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
  color: tokens.$color-gray-500;

  &.planText {
    @include media.sm {
      max-width: 200px;
    }
  }
}

.upgrade {
  font-weight: 500;
  color: tokens.$color-blue-700;

  border: none;
  box-sizing: border-box;
  font-size: inherit;
  cursor: pointer;
  background: unset;
  padding: 0;
  margin: 0 0 0 4px;

  &.disabled {
    cursor: not-allowed;
  }
}
