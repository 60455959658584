$defaultColor: #7a869a;

.root {
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  border-bottom: 1px solid transparent;
  transition: color 200ms ease, border-bottom-color 200ms ease;

  color: var(--Gray-500, #6b778c);

  font-family: Graphik, sans-serif;
  font-style: normal;

  &:hover {
    text-decoration: underline;
  }
}

.text {
  &:hover {
    border-bottom-color: $defaultColor;
  }

  .dark:hover & {
    border-bottom-color: #fff;
    color: #fff;
  }
}

.icon {
  fill: $defaultColor;
  transition: fill 200ms ease;

  .dark:hover & {
    fill: #fff;
  }
}

.capitalize {
  text-transform: capitalize;
}
