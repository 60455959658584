.root {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #091e42;

  display: flex;
  align-items: center;
  padding: 6px 0;
}

.input {
  padding: 0 !important;
  margin: 0 6px 0 0 !important;
}
