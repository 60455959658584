@use '@restream/styles/scss/tokens';
@use '../../../../scss/mixins' as mixins;
@import '../../constants';
@import '../constants';

.errorBlock {
  @include mixins.column;
  align-items: center;
  background-color: #0e2347;
  border-radius: tokens.$border-radius-400;
  width: 100%;
  padding: 32px 220px;
  text-align: center;

  @media (max-width: $tabletMaxWidth) {
    margin: auto;
    padding: 32px 0;
  }

  @media (max-width: $smallMobileMaxWidth) {
    padding: 32px 16px;
  }
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: tokens.$color-gray-400;
  margin-bottom: 8px;
}

.subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-400;
  max-width: 340px;
}

.errorIcon {
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
}

.supportLinkButton {
  @include mixins.button-reset;

  color: tokens.$color-gray-400;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}
