@use '@restream/styles/scss/media';
@use '@restream/styles/scss/variables';
@use '@restream/styles/scss/tokens';

.root {
  width: variables.$renaissance-lobby-product-menu-width;
  z-index: 12 !important;

  position: fixed;
  left: 0;
  top: 0;

  @include media.xs {
    width: 100%;
  }

  &.hidden {
    transform: translateX(
      calc(-1 * (variables.$renaissance-lobby-product-menu-width))
    );

    @include media.xs {
      transform: translateX(-100%);
    }
  }

  .productMenuBottom {
    padding: 0 20px 16px;
  }

  .productMenuContent {
    padding: 16px;

    .productMenuItem a {
      font-weight: 400;
      color: inherit;
    }
  }
}

.productMenuItemIcon {
  height: 24px;
  width: 24px;
}

.productMenuItem {
  transition: 0.2s all ease-in-out;
  padding: 8px;
  color: tokens.$color-gray-400;

  &,
  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.05);
    color: tokens.$color-gray-200;
  }

  &.productMenuItemActive {
    cursor: default;
    background: rgba(255, 255, 255, 0.05);
    color: tokens.$color-gray-200;

    & .productMenuItemIcon {
      fill: currentColor;
    }

    & a {
      cursor: default;
    }
  }
}

.productMenuSideHeaderTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}

.productMenuSideHeaderLogoButton {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  color: white;
  font-size: 22px;
  font-weight: 700;

  &:active,
  &:focus {
    outline: none;
  }
}

.productMenuSideClose {
  position: absolute;
  right: 16px;
  top: 16px;

  @include media.md {
    display: flex;
  }
}

.productMenuSideInfo {
  padding: 16px;
  margin: 0;

  &.mobilePaddingRight {
    @include media.xs {
      padding-right: 72px;
    }
  }
}
