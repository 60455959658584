@use '../../../../../components/scss/mixins' as mixins;

.root {
  display: flex;
  align-items: center;

  padding: 12px 0;
  border-top: 1px solid #eaecf0;

  &:last-of-type {
    border-bottom: 1px solid #eaecf0;
  }
}

.description {
  display: flex;
  flex: 1;
  flex-direction: column;

  min-width: 0;

  .channelNameWrapper {
    display: flex;
    align-items: center;
  }

  .channelName {
    @include mixins.truncate-text;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #172b4d;
    margin: 0;
    letter-spacing: 0.01em;
  }

  a.channelName:hover {
    text-decoration: underline;
  }

  .divider {
    width: 1px;
    height: 12px;
    background: #a5adba;
    margin-left: 12px;
    margin-right: 12px;

    color: #a5adba;
    opacity: 0.6;
  }

  .eventUrl {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #a5adba;
    width: auto;

    &:hover {
      text-decoration: underline;
    }
  }

  .title {
    @include mixins.truncate-text;

    font-size: 14px;
    line-height: 20px;
    color: #505f79;

    margin: 0;
  }
}

.settings {
  display: flex;

  margin-left: 8px;
}
