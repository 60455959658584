@use '@restream/styles/scss/tokens';

$color: #ff7452;

.root {
  display: flex;
  flex: 1;
  min-width: 0;
}

.disabled {
  opacity: 0.25;
}

.title {
  text-align: center;
  color: tokens.$color-gray-25;

  font-size: 13px;
  font-weight: normal;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 6px;
}

.error {
  color: $color;

  a {
    color: $color;
    text-decoration: underline;
  }
}

.titleContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;

  &:is(a):hover {
    text-decoration: underline;
  }
}

.scheduledFor {
  color: tokens.$color-gray-200;
  flex-shrink: 0;
  font-size: 13px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 6px;
  border-radius: 4px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    width: 1px;
    background: tokens.$color-gray-600;
    height: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
