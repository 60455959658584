$lightThemeColor: #172b4d;

@mixin link-hover {
  cursor: pointer;
  border-bottom: 1px solid $lightThemeColor;
  line-height: 16px !important;
  text-decoration: none;
  transition: border-bottom-color 200ms ease, opacity 200ms ease;

  &:hover {
    border-bottom-color: transparent;
    opacity: 0.95;
  }
}

.button {
  margin-left: 5px;
  color: $lightThemeColor;
  background: transparent;
  border: none;
  padding: 0;
  font-size: 14px;

  @include link-hover;
}
