@use '@restream/styles/scss/tokens';

$color-secondary: #758092;

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  border-radius: tokens.$border-radius-200;

  transition: background-color 200ms;

  &:hover {
    background: #253858;
  }

  &.active {
    background: #253858;
  }
}
