@use '@restream/styles/scss/tokens';

$smallMobileMaxWidth: 576px;
$modalHeaderMobileHeight: 110px;
$modalFooterMobileHeight: 60px;

.header {
  padding: 40px 40px 0 40px;

  @media (max-width: $smallMobileMaxWidth) {
    padding: 16px;
  }
}

.body {
  padding: 24px 40px;

  @media (max-width: $smallMobileMaxWidth) {
    padding: 4px 16px;
    max-height: calc(
      100% - #{$modalHeaderMobileHeight} - #{$modalFooterMobileHeight}
    );
    overflow: auto;
  }
}

.gridList {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-auto-flow: column; // insert auto-placed items in column
  width: 100%;
  margin: 0;
  padding: 0;

  @media (max-width: $smallMobileMaxWidth) {
    grid-template-columns: 1fr;
    grid-template-rows: initial;
    grid-auto-flow: row;
  }
}

.optionWrap {
  position: relative;
  width: 100%;
}

.optionInput {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 10;

  &:checked + .optionLabel {
    border: 1.5px solid tokens.$color-blue-600;
  }
}

.optionLabel {
  background: tokens.$color-white;
  border: 1px solid tokens.$color-gray-200;
  border-radius: tokens.$border-radius-200;
  color: tokens.$color-gray-800;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 20px 15px;
  transition: all 0.1s ease-in-out;
  text-align: left;

  &:hover {
    background: tokens.$color-gray-50;
  }

  @media (max-width: $smallMobileMaxWidth) {
    padding: 8px 16px;
  }
}
