@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.root {
  width: 100%;
}

.listWrapper {
  width: 100%;
  margin-bottom: 40px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: tokens.$color-gray-100;
  margin-bottom: 16px;

  @include media.xs {
    font-size: 20px;
  }
}

.eventListItem {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.eventListItemWithTitle {
    margin-top: 40px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.emptyList {
  display: flex;
  width: 100%;
  min-height: 147px;
  background: url('img/squid-star-bg-x2.png') no-repeat 50% 0;
  background-size: contain;
  background-position-x: right;

  border: 1px solid #344563;
  border-radius: tokens.$border-radius-200;

  padding: 50px 56px;

  @include media.sm {
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
  }
}

.placeholder,
.error {
  .title {
    visibility: hidden;
    line-height: 60px;

    @include media.xs {
      display: none;
    }
  }
}

.error {
  .text {
    font-size: 18px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
