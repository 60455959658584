@use '@restream/styles/scss/media';

.root {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 16px;
  row-gap: 80px;

  @include media.sm {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media.xs {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
