@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';
@import '../../common';
@import '../../../scss/media';

.productMenuSideItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  cursor: pointer;
  padding: 4px 8px;
  margin: 8px 0;
  min-height: 40px;
  border-radius: tokens.$border-radius-200;
  transition: background-color $animation-hover-time;
  // TODO(color): use token
  color: #97a0af;

  &:focus {
    // TODO(color): use token
    box-shadow: 0 0 0 1px #4c9aff;
    outline: none;
  }

  a {
    // TODO(color): use token
    color: #97a0af;
    text-decoration: none;
  }

  &.active {
    // TODO(color): use token
    background: $color-background-dark3;

    a {
      color: tokens.$color-white;
    }
  }

  &.disabled {
    a {
      // TODO(color): use token
      color: #6b778c;
    }

    &:hover {
      background: none;
      cursor: default;
    }
  }

  &:hover:not(.disabled) {
    // TODO(color): use token
    background: $color-background-dark3;
  }

  .productMenuSideItemIcon {
    width: $icon-size;
    height: $icon-size;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    img {
      opacity: 0.6;
    }

    &.gradientIcon {
      img {
        opacity: 1;
      }
    }
  }

  &.active .productMenuSideItemIcon img {
    opacity: 1;
  }

  .productMenuSideItemExpander {
    width: $icon-size;
    height: $icon-size;
    display: none;
    align-items: center;
    justify-content: center;
    user-select: none;

    @include xs {
      display: flex;
    }
  }

  .expandIcon {
    &.open {
      transform: rotate(90deg);
    }
  }

  .productMenuSideItemTitle {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    margin-left: 10px;
    overflow: hidden;
    line-height: 16px;

    .titleText {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      letter-spacing: 0.01em;
      font-weight: 500;
      font-size: 14px;
      padding: 0;
    }

    &.gradientTitle .titleText {
      background: linear-gradient(
        to right,
        rgba(103, 227, 249, 1),
        rgba(46, 144, 250, 1),
        rgba(0, 78, 235, 1),
        rgba(105, 39, 218, 1),
        rgba(212, 68, 241, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .subtitle {
      font-weight: 400;
      font-size: tokens.$font-size-body-xs;
      line-height: tokens.$font-line-height-body-xs;
      letter-spacing: 0.01em;
      // TODO(color): use token
      color: #8993a4;
    }
  }
}

.productMenuSideItemChildren {
  padding-left: $icon-size;
  height: 0;
  overflow: hidden;
  transition: height 200ms ease-in-out;
  display: none;

  @include xs {
    display: block;
  }

  .productMenuSideItem {
    /* stylelint-disable-line no-descending-specificity */
    min-height: $icon-size;

    @include xs {
      margin: 0;
      min-height: 40px;
    }

    & .titleText {
      font-weight: 400;
    }
  }
}
