.button {
  display: block;
  width: 100%;
  border-radius: 8px;
  color: black;
  border: 1px solid #dadce0;
  margin-bottom: 12px;

  transition: all 0.2s ease-out;

  .rightButtonIcon {
    margin-left: auto;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-left: 8px;
}
