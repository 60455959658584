@use '~@restream/styles/scss/media' as media;
@use '~@restream/styles/scss/colors' as colors;

$gap: 20px;
$fg-color--darken-2: rgba(255, 255, 255, 0.5);
$fg-color--darken-1: rgba(255, 255, 255, 0.7);

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.accountSelectWrapper {
  margin-left: auto;
  padding: 3px 32px 0;

  @include media.xs {
    display: none;
  }
}

.container {
  grid-area: main;
  max-width: 1260px;
  width: 100%;
  padding: 0 40px 70px 40px;
  margin: 0 auto;
  @include media.xl {
    padding: 0 40px 70px 40px;
  }
  @include media.lg {
    padding: 0 40px 70px 40px;
  }
  @include media.md {
    padding: 0 40px 70px 40px;
  }
  @include media.sm {
    padding: 0 10px 70px 10px;
  }
  @include media.xs {
    padding: 0 15px 70px 10px;
  }
}

.contentSection {
  display: flex;
  flex-direction: row-reverse;

  grid-area: main;
  gap: 20px;

  @include media.md {
    flex-direction: column;
  }
}

.contentSection {
  margin: 40px 0 40px 0;
}
