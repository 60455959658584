@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;

  position: relative;
}

.destinationsSummary {
  margin-right: 0;

  &.readonly {
    cursor: unset;

    &:hover {
      opacity: 1;
    }
  }

  &.error {
    border-radius: tokens.$border-radius-200;
    box-shadow: 0 0 0 2px #f97066;
  }
}
