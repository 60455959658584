.title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #172b4d;

  align-self: center;
  margin: 0px 4px;
}

.desc {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  color: #091e42;

  align-self: center;
  margin-bottom: 8px;
}
