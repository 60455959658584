@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 300px;
}

.chatOptions {
  padding: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid tokens.$color-gray-700;
}
