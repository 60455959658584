@use '@restream/styles/scss/tokens';
@use 'sass:color';

.stickyHeaderContainer {
  position: sticky;
  z-index: 1;
  top: 0;
  margin: -16px 0 0;
  padding: 16px 0;
  background: linear-gradient(
    180deg,
    tokens.$color-gray-800,
    color.adjust(tokens.$color-gray-800, $alpha: -0.5)
  );
  backdrop-filter: blur(10px);
}
