@use '@restream/styles/scss/tokens';

@import '../../../../components/scss/modals';

.body {
  @media screen and (max-width: 576px) {
    padding: 12px;
  }
}

.root {
  @include modal-width;
  //box-sizing: border-box;
}

.header {
  background: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.removeDestinationButton {
  // button resets
  background: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }

  color: #a5adba;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  &:focus,
  &:active,
  &:hover {
    text-decoration: underline;
  }
}

.removeIcon {
  fill: #a5adba;
  margin-right: 6px;
}

.link {
  margin-left: 4px;
  text-decoration: none;
  color: tokens.$color-black;

  &.noMargin {
    margin-left: 0;
  }
}

.linkText {
  border-bottom: tokens.$border-width solid tokens.$color-black;
  transition: border-bottom-color 250ms ease;

  .link:hover & {
    border-bottom-color: transparent;
  }
}

.linkIcon {
  margin-left: 4px;
  position: relative;
  top: 2px;
}
