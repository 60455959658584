@use '@restream/styles/scss/tokens';

.root {
  max-width: 460px;
}

.inputTitle {
  font-weight: 400;
  text-transform: none;
  font-size: 13px;
  color: #6c798f;
  margin-bottom: 10px;
  box-shadow: none;
}

.inputWrapper {
  height: 40px;
  border-radius: tokens.$border-radius-200;
  color: #fff;
  background-color: #253858;
  cursor: text;
}

.button {
  margin-top: 20px;
  height: 40px;
  border-radius: tokens.$border-radius-200;
}
