@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';
@import '../scss/colors';

.#{$ns}-card {
  // TODO(color): use token
  color: $color-dark-fg;
  background: $color-dark-bg-3;
  padding: 1rem;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.3);
  border-radius: tokens.$border-radius-200;
  margin: 0 0 1rem;
}

.#{$ns}-card.#{$ns}-fit {
  width: fit-content;
}

.testClass {
}
