@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/outline';
@use '~@restream/styles/scss/colors' as colors;
@use '~@restream/styles/scss/colors' as scssColors;
@use '~@restream/styles/scss/media' as media;

$font-color: #ebecf0;

.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: colors.$bg-color--lighten-1;
  //background-color: colors.$bg-color;

  width: 100%;
  height: auto;

  padding: 8px 4px;
  //border-radius: tokens.$border-radius-200;

  div {
    position: relative;
  }
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: $font-color;
  transition: 0.2s color ease-in-out;
  text-align: left;
  flex: 1;

  @include media.xs {
    font-size: 14px;
    font-weight: 500;
  }
}

.buttonContainer {
  //background-color: colors.$bg-color--lighten-1;
  color: $font-color;
  transition: all 0.2s ease-in;
  cursor: pointer;
  border: 0;

  &:hover {
    background: rgba(40, 111, 255, 0.8);
    color: #fff;
  }

  @include outline.focus-visible-outline;
}

.buttonContainer:hover ~ .prime {
  background-color: colors.$bg-color--lighten-1;
}

.prime {
  background: #286fff;
}

.small {
  padding: 10px 12px;

  & img {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 12px;
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }
}

.icon {
  height: 16px;
  stroke: #c1c7d0;
  stroke-width: 2.5;
  margin-right: 4px;

  & path {
    stroke-linecap: square;
    stroke-linejoin: miter;
  }
}
