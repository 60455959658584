@use '@restream/styles/scss/tokens';
@use '../../../../../../scss/modals' as modals;
@use '../video-file-list-empty/common' as common;

.root {
  @include modals.modal-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
}

.modalBody {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

.image {
  height: 128px;
  object-fit: contain;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: tokens.$color-white;

  margin: 24px 0 0;
}

.tutorialList {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.tutorialStep {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.tutorialStepIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 40px;
  height: 40px;
  background: tokens.$color-gray-700;

  margin-right: 8px;
  border-radius: 50%;
}

.tutorialIcon {
  & path {
    stroke: white;
  }
}

.tutorialStepText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-white;
}

.modalFooter {
  width: 100%;
  flex-direction: column;
  max-width: 400px;
  padding: 5.66% 24px;
  position: fixed;
  bottom: 0;
  background-color: tokens.$color-gray-800;
}

.uploadButtonWrapper {
  width: 100%;
  position: relative;
}

.uploadButton {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  height: 60px;
  color: tokens.$color-gray-50;

  background: linear-gradient(
    78.2deg,
    #2e90fa 0%,
    tokens.$color-blue-700 50.1%,
    #6927da 100%
  );
  border-radius: 60px;

  &:hover:not(:disabled) {
    background: linear-gradient(90deg, tokens.$color-blue-700 0%, #6927da 100%);
  }

  @media (max-width: 375px) {
    font-size: 15px;
  }
}

.tryDemoButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-blue-300;

  padding: 0;
  background-color: transparent;
  margin-top: 18px;

  &:hover:not(:disabled) {
    color: #2e90fa;
    background-color: transparent;
  }
}
