@use '@restream/styles/scss/tokens';

.link {
  margin-left: 4px;
  text-decoration: none;
  color: tokens.$color-black;

  &.noMargin {
    margin-left: 0;
  }
}

.linkText {
  border-bottom: tokens.$border-width solid tokens.$color-black;
  transition: border-bottom-color 250ms ease;

  .link:hover & {
    border-bottom-color: transparent;
  }
}

.linkIcon {
  margin-left: 4px;
  position: relative;
  top: 2px;
}

.button {
  width: 100%;
}
