@use '@restream/styles/scss/media';
@import '../../../../connect-platform-item/connect-platform-tile-item/constants.module';

.root {
  margin-bottom: 12px;
  position: relative;
  max-height: 400px;
  overflow: auto;

  &.mobileOnboardingRoot {
    @include media.xs {
      border-top: 1px solid #edf0f3;
      box-shadow: none;
    }
  }

  &.tileItems {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    margin-bottom: 16px;
    padding: 10px 24px 0;

    @media (max-width: $tilePlatformsMaxWidth) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.hasDestinations {
    margin-bottom: 0;
  }
}

.connectPlatformItem {
  padding: 0 12px;

  &.removeFirstItemBefore {
    &:first-of-type {
      & .connectPlatformButton {
        &::before {
          display: none;
        }
      }
    }
  }

  &:last-of-type {
    & .connectPlatformButton {
      &::after {
        display: inline-block;
      }
    }
  }
}

.connectPlatformButton {
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 1px;
    width: 95%;
    margin: auto;
    background-color: #eaecf0;
    position: absolute;
    top: 0;
  }

  &::after {
    display: none;
    bottom: 0;
    top: initial;
  }
}
