@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';
@import '../../scss/media';
@import '../common';

.bannerWrapper {
  display: none;

  @include xs {
    display: block;
  }
}

.productMenuMobile {
  display: none;
  background: tokens.$color-gray-800;
  padding: $padding;
  justify-content: space-between;
  align-items: center;

  @include logo-title;

  @include xs {
    display: flex;
    padding: 14px 16px 16px;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding-left: 0;

  & > span {
    margin-left: 0;
  }
}

.productMenuMobileRightSide {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.productMenuMobileButton {
  position: relative;
  height: 40px;
  padding-left: 6px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid tokens.$color-gray-500;

  display: flex;
  align-items: center;
  background: transparent;

  transition: 0.2s all ease;

  &:hover:not(&:disabled) {
    opacity: 0.8;
  }

  &.noAccountAvatar {
    padding-left: 0;
    padding-right: 0;
  }
}

.productMenuMobileIcon {
  width: 20px;
  height: 20px;
  margin: 10px;
}
