@use '@restream/styles/scss/tokens';

.Toastify__toast-container {
  min-width: auto !important;
  z-index: 1000;

  padding: 0;
  transform: none !important;

  position: absolute;
  top: 5em;
  //width: auto !important;
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  pointer-events: none;

  @media (min-width: 1080px) {
    top: 6em !important;
  }
}

.Toastify__toast {
  pointer-events: all;
  width: fit-content;
  margin: auto auto 10px;
  padding: 10px !important;
  min-height: auto !important;
  border-radius: 18px !important;
  font-size: tokens.$font-size-body-sm !important;
  line-height: tokens.$font-line-height-body-sm !important;
  opacity: 0.92 !important;
}

.Toastify__toast-body {
  display: contents;
  border-radius: tokens.$border-radius-200;
  //width: min-content;
  min-width: 180px;
  padding: 0 !important;
}

.Toastify__toast-container--top-right {
  top: 5em !important;

  @media (min-width: 1080px) {
    top: 6em !important;
  }
}

.Toastify__toast-container--bottom-center {
  top: initial !important;
}

.Toastify__toast-container--top-right .Toastify__toast {
  border-radius: 4px !important;
}
