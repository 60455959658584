@use '@restream/styles/scss/tokens';
@import '../../../components/scss/modals';

.root {
  @include modal-width;

  color: tokens.$color-gray-900;
}

.extraButton {
  margin-right: 8px;
}
