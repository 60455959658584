@use '@restream/styles/scss/media' as media;

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px 0;
  width: 100%;

  @include media.sm {
    grid-template-columns: repeat(1, 1fr);
  }
}

.listLoaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
}

.customLoader {
  & circle {
    stroke: white;
  }
}
