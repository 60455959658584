@use '@restream/styles/scss/tokens';

.popover {
  z-index: 2;
  padding: 16px;
  border-radius: tokens.$border-radius-200;
  position: relative;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 8px;

    color: #091e42;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #172b4d;
    margin: 0;
  }

  .closeButton {
    display: flex;
    align-items: center;
    margin: 0;
    position: absolute;
    top: 14px;
    right: 14px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
  }
}
