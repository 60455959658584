@import '../../scss/modals';

.root {
  @include modal-width;
  overflow: hidden;
}

.body {
  padding-left: 0;
  padding-right: 0;
}
