@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.avatarFormGroup {
  align-items: center;
}

.avatarWrapperButton {
  background: transparent;
  padding: 0;

  &:hover:not(&.disabled) {
    background: transparent;
  }
}

.avatarInputButton {
  background: transparent;
  color: tokens.$color-gray-600;
  margin-top: 4px;

  &:hover:not(&.disabled) {
    background: tokens.$color-gray-50;
    color: tokens.$color-gray-800;
  }
}

.avatarInput {
  display: none;
}

.button {
  background: none;
  border: none;

  color: tokens.$color-gray-500;
  font-weight: 500;

  padding-left: 0;

  &:active,
  &:focus,
  &:hover {
    &:not(&.disabled) {
      background: none;

      &.danger {
        color: tokens.$color-red-700;
      }

      &:not(&.danger) {
        color: tokens.$color-gray-600;
      }
    }
  }
}
