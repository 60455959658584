.root {
  display: flex;
  flex: 1;
  flex-direction: column;

  height: 100%;

  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 18px;
}

.image {
  margin-bottom: 20px;
}

.text {
  margin-bottom: 16px;
  font-size: 14px;

  text-align: center;
}

.emoji {
  margin-right: 8px;
}
