@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.logoWrapper {
  position: absolute;
  left: 20px;
  top: 20px;
}

.backButtonWrapper {
  position: absolute;
  right: 20px;
  top: 30px;
}

.logo {
  opacity: 1;

  transition: opacity 200ms;

  &:hover {
    opacity: 0.8;
  }
}

.contentWrapper {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}

.content {
  background: #fff;
  width: fit-content;
  border-radius: tokens.$border-radius-200;
  overflow: auto;
  margin-top: 20px;

  @include media.xs {
    width: 100%;
    margin-top: 80px;
  }

  @include media.lg {
    margin-top: 80px;
  }
}

.checkoutRoot {
  max-width: 500px;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 20px;
}

.checkoutContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.discountBanner {
  background: #fd0;
  width: 100%;
  text-align: center;
}

.discountBannerText {
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  color: #000;
  margin: 16px;
}

.noPermissionsRoot {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.noPermissionsWrapper {
  min-height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
