@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.body {
  color: #172b4d;
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  min-width: fit-content;
}

.button {
  height: 40px;
}

.cancelButton {
  box-shadow: none;
  min-width: auto;
  color: #505f79;
  background-color: transparent;
  transition: background-color 200ms ease;

  &:hover {
    background-color: rgba(9, 30, 66, 0.08) !important;
    color: #505f79 !important;
  }
}

.confirmButton {
  min-width: auto;
  background-color: #de350b;

  &:hover {
    background-color: #bf2600 !important;
  }
}

.link {
  display: inline-block;
  line-height: 18px;
  color: #286fff;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 150ms ease;

  &:hover {
    border-bottom-color: #286fff;
  }
}
