@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
}

.input {
  max-width: 48px;
  font-size: 14px;
  line-height: 20px;

  border: none;
  padding: 5px 8px;
  margin: 0;
  border-radius: tokens.$border-radius-200;

  color: #091e42;
  box-shadow: inset 0 0 0 1px #a5adba;
  outline: none;
}

.separator {
  margin: 0 6px;
}
