@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.optionTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;

  overflow: hidden;
}

.optionPrimaryText,
.optionSecondaryText {
  @include mixins.truncate-text();
  max-width: 100%;
}

.optionPrimaryText {
  font-size: tokens.$font-size-body-sm;
  color: tokens.$color-gray-900;
  line-height: 20px;
}

.optionSecondaryText {
  color: tokens.$color-gray-500;
  font-size: 12px;
  line-height: 16px;
}

.optionAddLabel {
  color: tokens.$color-blue-700;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  padding-left: 12px;

  margin-left: auto;
}

.createdOptionWrapper {
  & .option {
    min-height: auto;
  }

  & .groupText {
    margin: 8px 16px;
  }

  & .title {
    margin: 8px 12px;
  }

  & .icon {
    width: 18px;
    stroke-width: 2;
    margin: 0 20px 0 10px;
    flex-shrink: 0;
  }
}

.selectedOptionIcon {
  width: 18px;
  stroke-width: 2;
  margin-left: auto;
  flex-shrink: 0;
}

.groupText {
  color: tokens.$color-gray-700;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
}

.groupWrapper {
  &.disabled {
    cursor: default;
    background: tokens.$color-gray-25;
  }
}

.valueContainer.valueContainer {
  &:not(&.isCreated) {
    background: tokens.$color-blue-100;

    & .closeButton {
      color: tokens.$color-gray-500;

      &:focus:not(&:disabled),
      &:hover:not(&:disabled) {
        background: tokens.$color-blue-200;
      }
    }

    &.isError {
      border: 1px dashed tokens.$color-red-600;
      background-color: tokens.$color-red-50;

      & .closeButton {
        color: tokens.$color-red-600;

        &:focus:not(&:disabled),
        &:hover:not(&:disabled) {
          color: tokens.$color-red-600;
          background: tokens.$color-red-200;
        }
      }
    }
  }
}

.valueLabelText {
  font-size: tokens.$font-size-body-sm;
  line-height: 20px;
  color: tokens.$color-gray-700;
  font-weight: 500;
  margin-left: 4px;

  &.noMargin {
    margin-left: 0;
  }
}
