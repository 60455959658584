.root {
  padding: 2px 8px;
  color: #ffffff;

  text-align: center;
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-10, 12px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: 18px;

  border-radius: var(--Radius-Small, 4px);
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.5);
}

.root.warning {
  background: rgba(247, 144, 9, 0.8);
}

.root.live {
  background: rgba(255, 68, 5, 0.8);
}
