@use '~@restream/styles/scss/media' as media;
@use '@restream/styles/scss/tokens';

.root {
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 2;

  background: tokens.$color-gray-150;
  border-radius: 8px;
  padding: 2px 8px;

  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: 20px;

  color: #000000;

  @include media.xs {
    font-size: tokens.$font-size-body-xs;
  }
}
