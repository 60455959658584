@use '../../../scss/typography' as typography;
@use '@restream/styles/scss/media' as media;

$space: 16px;

.root {
  display: flex;
  justify-content: space-between;
  padding: 3px 16px 0;

  @media (min-width: 1080px) {
    align-items: flex-end;
  }

  @include media.xs {
    &.withBanner {
      padding-top: 6px;
    }
  }
}

.blackFridayBanner {
  display: block;

  @include media.xs {
    display: none;
  }
}

.mobileBlackFridayBanner {
  display: none;

  @include media.xs {
    display: block;
  }
}

.namePage {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  text-align: left;

  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin-left: 12px;
}

.headerTitleOverlay {
  display: flex;
  align-items: center;
}

.headerTitleWrapper {
  display: flex;
  flex-direction: column;
}

.headerTitle {
  @include typography.font-16;

  text-align: left;
}

.headerSubtitle {
  font-size: 10px;
  display: flex;
  align-items: center;
}

.headerSubtitleIcon {
  margin-left: 4px;
}

.headerIconWrapper {
  height: 16px;
}

.item {
  display: flex;
  align-content: center;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: flex-end;
  padding-right: 16px;
}

.blackFriday {
  @media screen and (max-width: 450px) {
    display: none;
  }
}

.upgradeButton {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.toggleTooltip {
  top: 8px !important;
}

.toggleTooltipContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blackFridayBanner,
.upgradeButton {
  margin-left: 8px;
}

.getProIcon {
  margin-left: 4px;
  color: #ffbb2c;
  will-change: transform;
  transition: transform 200ms ease;

  .upgradeButton:hover & {
    transform: scale(1.1);
  }
}

.recordingsTrigger {
  display: none;

  @media (min-width: 1080px) {
    display: block;
  }
}

.accountsSelectWrapper {
  display: block;

  @media (min-width: 1080px) {
    display: none;
  }
}

.accountsSelect {
  padding: 8px;
}
