@use '@restream/styles/scss/tokens';

.error {
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;

  min-height: 220px;

  color: red;
}

.root {
  border: 1px solid #fcdbd9;
  background-color: rgba(222, 53, 11, 0.16);
  //background-color: #FFEBE6; might be substituted to this one in the future
  color: #172b4d;

  padding: 12px 20px;
  margin: 20px 24px;
  border-radius: tokens.$border-radius-200;
}
