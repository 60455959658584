@use '../../../../../scss/colors' as colors;
@use '@restream/styles/scss/tokens' as tokens;

.icon {
  fill: rgba(colors.$fg-color--darken-1, 0);

  width: 10px;
  height: 7px;
  margin-left: 10px;
  margin-top: 2px;
  transition: all 0.3s ease-in-out;

  &.opened {
    transform: rotate(180deg);
  }

  &.single {
    margin-left: 0;
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}

.popover {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 8px 0;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
}

.popoverList {
  max-height: 300px;
  overflow-y: scroll;

  @media screen and (max-width: 768px) {
    max-height: 250px;
  }

  .item {
    padding: 4px 12px;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    transition: background-color 0.3s ease-in-out;

    .imageWrapper {
      position: relative;
      margin-right: 12px;
      display: flex;

      .image {
        width: 24px;
        border-radius: 6px;
        height: 24px;
        border: 1px solid #6b778c;
        object-fit: cover;
      }

      .initials {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        border: 1px solid #6b778c;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .imagePlatforms {
        width: 12px;
        height: 12px;
        object-fit: contain;
        bottom: 0;
        position: absolute;
        right: -2px;
      }
    }

    .content {
      display: flex;
      flex-flow: column;

      h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
      }

      .title {
        font-weight: 400;
        font-size: 10px;
        margin-top: 4px;
        line-height: 12px;
        color: #97a0af;
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .iconWrapper {
      margin-left: 6px;
      display: flex;
      align-items: flex-start;
      .icon {
        opacity: 0;
        width: 8px;
        height: 8px;
        transition: 0.3s ease-in-out;
      }
    }

    &:hover {
      background-color: #4b4b4b;

      .iconWrapper {
        .icon {
          opacity: 1;
        }
      }
    }
  }
}

.pPopover {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  padding: 4px 8px;

  .starIcon {
    width: 14px;
    height: auto;
    margin-right: 6px;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    color: #091e42;
  }

  .closeIcon {
    margin-left: 8px;
    width: 18px;
    height: 18px;
    padding: 4px;
    cursor: pointer;

    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}

.switchButton {
  padding: 0;
  display: flex;
  border-radius: tokens.$border-radius-200;
  border: none;
  cursor: pointer;
  line-height: tokens.$font-size-body-sm;
  font-size: tokens.$font-size-body-sm;
  align-items: center;
  transition: all 300ms;
  overflow: hidden;

  .mainContent {
    padding: 8px 12px;
    display: grid;
    grid-template-columns: auto 1fr 0;
    transition: all 0.2s ease-in-out;
    line-height: tokens.$font-size-body-sm;
    align-items: center;

    .linkIcon {
      opacity: 0;
      width: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      background: rgba(89, 104, 142, 0.5);
      grid-template-columns: auto 1fr 20px;

      .linkIcon {
        display: block;
        width: 16px;
        height: 12px;
        color: #fff;
        margin-top: -2px;
        opacity: 0.8;
        margin-left: 2px;
      }
    }
  }

  .title {
    opacity: 0.8;
    color: tokens.$color-white;
    line-height: 13px;
    margin-left: 6px;
    font-size: 13px;
  }

  .secondPart {
    padding: 8px 12px;
    border-left: solid 1px rgba(89, 104, 142, 0.15);
    .icon {
      margin-left: 0;
    }

    transition: background-color 300ms;

    &:hover {
      background: rgba(89, 104, 142, 0.5);
    }
  }

  .icon {
    margin-top: 0;
  }

  &:hover {
    background: rgba(89, 104, 142, 0.2);
  }
}
