@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/variables' as variables;
@use '@restream/styles/scss/media' as media;
@use '@restream/styles/scss/colors' as colors;
@use '../../../../scrollbar' as scrollbar;

$space: 16px;
$smallerSpace: 12px;
$borderBottomHeight: 1px;
$defaultScrollWidth: 7px;
$scrollColor: rgba(104, 118, 139, 0.6);

.root {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  max-width: 780px;
  width: 100%;

  &.variantD {
    margin-top: 28px;

    @include media.md {
      margin-top: 0;
    }
  }
}

.previewSection {
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-bottom: 24px;

  .streamingPreview {
    border-radius: tokens.$border-radius-200;
  }
}

.channelsPreviewWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;

  min-height: 200px;
  max-height: 65vh;
  //overflow-y: hidden;

  //background: colors.$color-dark-bg-3;
  padding: 0 16px 12px;
  border-radius: tokens.$border-radius-200;

  &.channelsEmpty {
    height: 330px;

    [class^='Channels-module_buttons'],
    [class^='Channels-module_toggle'] {
      display: none;
    }

    .channelsPreview {
      margin-top: -2px;
    }
  }

  &:not(.channelsEmpty) {
    .channelsPreview {
      min-height: 100%;
    }
  }

  .channelsPreview {
    [class^='Channels-module_header'] {
      padding-right: 18px;
    }

    [class^='Channels-module_root'] {
      height: 100%;

      [class^='SystemMessage-module_root'] {
        display: none;
      }

      li[class^='ChannelItem-module_root']:not(:hover) {
        background: rgba(37, 56, 88, 0.4);
      }
    }

    [class^='ChannelsList-module_root'] {
      // Forces scrollbar to avoid content jumps when switching between tabs with
      // and without inner scrollbar
      padding-right: $space - $defaultScrollWidth;
      overflow-y: scroll;

      @include scrollbar.scrollbar(
        $color: $scrollColor,
        $hover-color: $scrollColor,
        $active-color: $scrollColor,
        $track-color: transparent,
        $size: $defaultScrollWidth
      );
    }
  }
}

.channelsPreviewWrapper:not(.channelsEmpty) {
  padding-top: 12px;
}

.eventsSections ~ .channelsPreviewWrapper:not(.channelsEmpty) {
  max-height: calc(100vh - 450px);
}

.previewSection ~ .channelsPreviewWrapper:not(.channelsEmpty) {
  max-height: 300px;
}

.eventsSections {
  display: flex;
  width: 100%;

  margin-bottom: 24px;
  margin-top: 0;

  @media (min-width: 768px) {
    //margin-top: -56px;
  }
}

.previewSection ~ .eventsSections {
  margin-top: 0;
}

.toastBg {
  background-color: variables.$color-success !important;
  color: variables.$color-max-light !important;
}

.toastText {
  display: flex;
  margin-right: 8px;
}

.systemMessage {
  height: auto;
  width: 100%;
  overflow: inherit;
  padding: 0 16px;
  margin-right: 8px;
}

.systemMessageFullWidth {
  height: auto;
  width: 100%;
  overflow: inherit;
}

.collapsedEventsListPanel {
  width: 100%;
}

@include media.lg {
  .channelsPreviewWrapper {
    display: none;
  }
}

.shadowedContent {
  transition: opacity 250ms ease-in-out;
  opacity: 0.3;

  @include media.md {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }
}
