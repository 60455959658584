@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/colors';
@import '../../scss/typography';

.root {
  display: flex;
  flex-direction: column;

  background: #ff66331f;

  border-radius: tokens.$border-radius-200;
  border: 1px solid #ff66338a;
  margin-bottom: 24px;
  padding: 17px 18px 18px 20px;

  font-size: 16px;
  line-height: 24px;
}

.header {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  margin-right: 24px;
}

.title {
  @include font-16;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #ff6633;
  padding: 0;
  margin: 0 0 12px 10px;
}

.description {
  @include font-14;

  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;

  color: $fg-color;

  padding: 0;
  margin: 0 0 16px;
}

.button {
  //padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;

  @include font-14;

  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0em;
  margin: 0;
  outline: 0;
  padding: 6px 16px;
  border-radius: tokens.$border-radius-200;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all 0.2s;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  background: #ff5630;
  color: rgba(255, 255, 255, 0.9);
  border: none;

  &:hover {
    background: #ff6b4a;
    color: rgba(255, 255, 255, 0.9);
  }
}
