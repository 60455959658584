@use '@restream/styles/scss/tokens';

.root {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatarWrapper {
  position: relative;
}

.avatarBadgeContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  justify-content: center;
}

.avatarBadge {
  border-radius: 4px;
  padding: 1px 3px;
  border: 1px solid tokens.$color-gray-900;
  background: tokens.$color-gray-100;

  color: tokens.$color-gray-900;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  text-transform: uppercase;
}

.email {
  margin-top: 12px;
  color: tokens.$color-gray-25;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.subtitle {
  color: tokens.$color-gray-300;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
