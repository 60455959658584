@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/variables';

$color-secondary: #758092;

.headerTitleRoot {
  margin: 0 4px 0 0;
}

.subtitle {
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.teamNameWrapper {
  margin-bottom: 32px;
}

.inputLabel {
  line-height: 16px;
  font-weight: 500;
}

.inputWrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 8px;
}

.inputGroupWrapper {
  display: flex;
  max-width: 320px;
}

.label {
  font-size: 16px;
  line-height: 24px;
  color: $color-secondary;
  margin-bottom: 8px;
}

.teamName {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

.content {
  @include mixins.pageContentPaddings;

  width: 100%;
  max-width: 1100px;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  padding-top: 32px;
}

.tableActions {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.membersWrapper {
  display: flex;
  align-items: center;
  min-width: 0;
}

.membersCounterWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-left: 8px;
  margin-right: 8px;
}

.buttonLink {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  text-align: left;
  cursor: pointer;
  color: #286fff;

  &:hover {
    text-decoration: underline;
  }
}

.inviteMemberButtonWrapper {
  display: flex;
  align-items: center;
}

.membersCounter {
  color: $color-secondary;
  font-size: 12px;
  white-space: nowrap;
  line-height: 16px;
}

$table-font-size: 14px;

.dangerZoneTitle {
  margin-bottom: 16px;
}

.dangerZone {
  border-radius: tokens.$border-radius-200;
  box-shadow: inset 0 0 0 1px #253858;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dangerZoneText {
  font-size: $table-font-size;
  line-height: 24px;
}

.dangerButton {
  background: none;
  color: #758092;
  box-shadow: inset 0 0 0 1px #253858;

  &:hover {
    background: #ff004a !important;
    color: #fff;
  }
}

.editButtonWrapper {
  margin-right: 16px;
  margin-left: 8px;
}

.iconEdit {
  transition: opacity 200ms;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }
}

.errorToastWrapper {
  width: 450px;
  padding: 12px 10px 16px !important;
  background: #ffffff !important;
  border-radius: tokens.$border-radius-200 !important;
  border: 1px solid #edf0f3;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  @media (max-width: 480px) {
    width: auto;
    max-width: 95%;
    padding: 16px !important;
    bottom: 1.5em !important;
  }
}

.promoBanner {
  margin-bottom: 16px;
}
