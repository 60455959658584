//@use '~@restream/styles/colors' as colors;

.chat {
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 240px;
}

.download {
  width: 12px;
  height: auto;

  margin-right: 8px;
}

.downloadLink {
  display: flex;
  align-content: center;
  justify-content: center;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}
