@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@import '~@restream/styles/scss/media';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: normal;
}

.channelWrapper {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  color: #fff;
  background: radial-gradient(
      98.75% 98.75% at 50% -29.17%,
      rgba(39, 0, 175, 0.2) 0%,
      rgba(110, 68, 255, 0) 100%
    ),
    linear-gradient(
      225.81deg,
      rgba(255, 211, 240, 0.5) 0%,
      rgba(255, 68, 238, 0.04) 61.31%
    ),
    linear-gradient(316.62deg, #00c7e6 25.79%, #00dd4b 76.56%);

  .channelContent {
    margin-left: 12px;
    display: flex;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 18px;
    }
    .description {
      margin-left: 6px;
      margin-top: 2px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 500px) {
    .channelContent {
      flex-flow: column;
      align-items: flex-start;
      .description {
        margin-left: 0;
      }
    }
  }
}
.body {
  padding: 16px 24px 0;
  max-height: calc(88vh - 68px);
  overflow: auto;

  @include md {
    padding: 16px 0 0;
  }

  @include xs {
    max-height: calc(95vh - 100px);
  }
}

.blackFriday {
  display: flex;
  box-shadow: none;
  margin: 0 auto 16px;

  &.disablePointer {
    cursor: default;
  }
}

.blackFridayDiscount {
  background-color: #bf2 !important;
}

.toggleWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button.hidden {
  opacity: 0;
  pointer-events: none;
}

.tip {
  margin: 16px 0 0;
}

.timeLimitWarning {
  font-size: inherit;
  line-height: normal;
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  color: #ff4466;
}

.welcomeTrialWarning {
  font-size: inherit;
  line-height: normal;
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
  color: rgba(54, 179, 126, 1);
  padding: 4px 12px;
  border-radius: tokens.$border-radius-200;
  background: rgba(54, 179, 126, 0.14);
}

.limitedCampaignContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.limitedCampaignAlert {
  position: relative;
  top: 0;

  cursor: pointer;
  font-size: inherit;
  line-height: normal;
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
  color: rgba(33, 44, 74, 1);
  padding: 6px 14px;
  border-radius: tokens.$border-radius-200;
  background: rgba(227, 235, 251, 1);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: rgba(227, 235, 251, 0.7);
  }
}

.calloutWrapper {
  max-width: 452px;

  &.calloutWrapperMargin {
    margin-bottom: 20px;
  }
}
