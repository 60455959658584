@use '@restream/styles/scss/tokens' as tokens;
@use '../colors';

.root {
  //display: flex;
  //width: 100%;
  border-radius: 8px;
  padding: 7px 14px;
  border: solid 1px tokens.$color-gray-300;

  //border: none;

  overflow: hidden;
  color: var(--Gray-900, #091e42);
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 20px;

  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
}

:global(.dark-theme) {
  .root {
    background-color: colors.$dark-color-input-bg;
    color: colors.$dark-color-input-fg;
    border-color: colors.$dark-color-border;
  }
}

:global(.transparent-theme) {
  .root {
    color: colors.$transparent-theme-color-input-fg;
    background: colors.$transparent-theme-color-input-bg;
    border-color: colors.$transparent-theme-color-border;
  }
}

.root::file-selector-button {
  border: 0;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--foreground);
}

.root::placeholder {
  color: tokens.$color-gray-300;
}

.error {
  border-color: tokens.$color-red-500;
}

.root[aria-invalid='true'] {
  border-color: tokens.$color-red-500;
}

.root:focus-visible {
  outline: none;
  &:not(.error):not([aria-invalid='true']) {
    box-shadow: inset 0 0 0 1px #286fff;
  }

  &.error {
    box-shadow: inset 0 0 0 1px #ff453a;
  }
}

.root:disabled {
  cursor: not-allowed;
  //opacity: 0.5;
}

.inputGroup {
  display: inline-flex;

  & > *:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > *:not(:last-child):is(button, input) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }

  & > *:not(:last-child):not(button, input) > button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }

  & > *:not(:first-child):not(:last-child):is(button) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > *:not(:first-child):not(:last-child):not(button) > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
