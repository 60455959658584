@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/tokens';

.root {
  @include mixins.modal-width;
}

.modalBody {
  padding: 40px 24px 18px;
}

.channelsList {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, 136px);
  align-items: center;
  justify-content: center;

  @media (min-width: 576px) {
    grid-gap: 16px;
  }
}

.channelIconInitials {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf0f3;

  color: #14171f;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.addAnotherChannelButton {
  display: flex;
  margin: 32px auto 0;

  color: tokens.$color-blue-500;
  background: transparent;

  &:hover:not(&:disabled) {
    color: tokens.$color-blue-700;
    background: transparent;
  }
}
