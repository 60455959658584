.root {
  .heading {
    font-size: 20px;
    text-align: center;
    line-height: 1.3;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: rgba(52, 69, 99, 1);
    &.gradient {
      background-color: #286fff;
      background-image: linear-gradient(#00d1b1, #286fff);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;

      @media (min-width: 576px) {
        font-size: 22px;
      }
    }
  }
  .content {
    margin-top: 20px;
    font-size: 14px;
    color: rgba(52, 69, 99, 1);
    text-align: center;
  }
  .buttonWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .btn {
      background: #286fff;
      &:hover {
        opacity: 0.8;
        background: #286fff;
      }
    }
  }
}
