@use '@restream/styles/scss/tokens';
@use '../../../scss/mixins' as mixins;
@import 'constants';
@import '../constants';

$lastEventItemMarginBottom: 16px;
$contentWidth: 780px;

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  @media (max-width: $smallMobileMaxWidth) {
    height: calc(100vh - #{$headerMobileHeight});
  }
}

.header {
  @include mixins.display-flex-center;
  justify-content: space-between;
  background-color: #172b4d;

  padding: 14px 12px 14px 20px;
  height: $headerHeight;

  @media (max-width: $tabletMaxWidth) {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - #{$headerMobileHeight});
  }

  @media (max-width: $smallMobileMaxWidth) {
    display: none;
  }

  .title {
    color: tokens.$color-gray-100;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .triggerDesktop {
    margin-left: 16px;
  }

  .headerRightSide {
    @include mixins.display-flex-center;
    justify-content: center;
  }
}

.upgradeTriggersWrapper {
  @include mixins.display-flex-center;
  flex-direction: column;
  justify-content: center;
}

.streamAllVideosUpgradeCallout {
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex: 1 1 auto;

  @media (max-width: $tabletMaxWidth) {
    margin-top: $headerHeight;
    margin-bottom: $buttonsBlockTabletHeight;
    flex-direction: column;

    overflow: auto;
  }

  @media (max-width: $smallMobileMaxWidth) {
    margin-top: 0;
    margin-bottom: $buttonsBlockMobileHeight;
    overflow: auto;
  }
}

.contentLeftSide {
  @include mixins.display-flex-center;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  margin: 0 0 20px 20px;
  min-width: 400px;
}

.sidebarWrapper {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
}

.contentRightSide {
  @include mixins.display-flex-center;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - #{$headerHeight});
  overflow: auto;

  @media (max-width: $tabletMaxWidth) {
    margin-top: 20px;
    overflow: unset;
  }
}

.eventsListWrapper {
  @media (max-width: $tabletMaxWidth) {
    margin-bottom: 0;
  }
}

.eventsSection {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  width: $contentWidth;
  height: 100%;

  padding-top: $oneFourthOfFullScreen;

  @media (max-width: $tabletMaxWidth) {
    // we have to reduce $headerHeight because on this media query we add margin-top: $headerHeight to the content class
    padding-top: calc(#{$oneFourthOfFullScreen} - #{$headerHeight});
  }

  @media (max-width: $mobileMaxWidth) {
    padding: 0;
  }

  &.shouldApplyOneThirdPaddingTop {
    padding-top: $oneThirdOfOneFourthOfFullScreen;

    @media (max-width: $tabletMaxWidth) {
      // we have to reduce 76 more px because on this media query we add 76px to the content class
      padding-top: calc(#{$oneThirdOfOneFourthOfFullScreen} - #{$headerHeight});
    }

    @media (max-width: $mobileMaxWidth) {
      padding: 0;
    }
  }

  &.isEmptyEvents {
    width: calc(#{$contentWidth} + 2 * #{$emptyEventsBlockShadowWidth});
  }

  &.isEmptyEvents,
  &.isError {
    padding-top: $oneFourthOfFullScreen;

    @media (max-width: $tabletMaxWidth) {
      padding-top: calc(#{$oneFourthOfFullScreen} - #{$headerHeight});
    }

    @media (max-width: $mobileMaxWidth) {
      padding-top: $oneThirdOfOneFourthOfFullScreen;
    }
  }

  &.hasUsedSourcedEvents {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: 1300px) {
    width: 100%;
  }
}

.eventsListRoot {
  height: 100%;
}

.errorToastWrapper {
  width: 450px;
  padding: 12px 10px 16px !important;
  background: tokens.$color-white !important;
  border-radius: tokens.$border-radius-200 !important;
  border: 1px solid tokens.$color-gray-100;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  @media (max-width: 480px) {
    width: auto;
    max-width: 95%;
    padding: 16px !important;
    bottom: 1.5em !important;
  }
}
