@use '@restream/styles/scss/tokens';

@mixin overlay {
  display: flex;
  flex-direction: column;

  position: absolute;
  width: 100%;
  //height: 100%;
  top: 0;
  left: 0;
  background: white;
}

@mixin border-shadow($color) {
  box-shadow: inset 0 0 0 1px $color;
}

@mixin truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin button-reset {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
}

@mixin display-flex-center {
  display: flex;
  align-items: center;
}

@mixin column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin linkStyleButton {
  border: none;
  background: none;
  cursor: pointer;
  color: tokens.$color-blue-300;
  padding: 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background-color: transparent;
    color: tokens.$color-blue-400;
  }
}
