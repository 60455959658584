@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/colors';
@import '../../components/scss/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageWrapper {
  width: 100%;
  display: flex;

  //background: url("src/assets/img/squids/bg-dsbrd-welcome-squid.svg") no-repeat 50% 0;

  img {
    margin: 70px auto 50px auto;
  }
}

.connectChannelsButton {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 0 24px;

  @include font-18;

  cursor: pointer;
  //background-color: transparent;
  outline: none;
  border: none;
  //box-shadow: 0 0 0 1px rgb(255 255 255 / 80%);
  //color: #EBECF0;
  //padding: 8px 12px;
  //border-radius: 12px;
  transition: background-color 200ms ease, color 200ms ease,
    box-shadow 200ms ease;

  color: #fafbfc;
  background-color: #286fff;
  font-weight: 600;
  box-shadow: none;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  border-radius: tokens.$border-radius-200;

  &:hover {
    color: #fff;
    background-color: rgba(40, 111, 255, 0.8);
    //box-shadow: 0 0 0 1px #fff;
  }
}

.button {
  font-size: 1em;
  line-height: 1.5rem;
  padding: 6px 1rem;

  &:first-child {
    margin-right: 8px;
  }
}

.buttonContent {
  display: flex;
  align-items: center;
}

.buttonIcon {
  margin-right: 8px;
}
