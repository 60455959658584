@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.root {
  display: flex;
  align-items: center;
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: tokens.$color-gray-100;

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-left: 16px;

  @include media.xs {
    max-width: 104px;
    margin: 0;
  }
}

.backButton {
  &:hover {
    background: tokens.$color-gray-600;
  }

  &.editorPage {
    &:hover {
      background: #0f172b;
    }
  }
}
