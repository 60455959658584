.metaInfoBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 16px;
  background: rgba(0, 0, 0, 0.8);
}

.metaTitle {
  font-size: 14px;
  line-height: 20px;
  color: #97a0af;
  margin-bottom: 4px;
}

.metaText {
  font-size: 14px;
  color: #ffffff;
}
