@use '@restream/styles/scss/tokens';
@use '../../../../scss/colors' as colors;

.root {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 240px;
}

.videoContainer {
  aspect-ratio: 16/9;
  position: relative;
  margin: 0 auto;
  border-radius: tokens.$border-radius-200;
  overflow: hidden;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  background: tokens.$color-black;
}

.video {
  width: 100%;
}

.controls {
  position: absolute;
  bottom: 12px;
  width: calc(100% - 24px);
  right: 12px;
  left: 12px;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  .main {
    display: flex;
  }

  .controlButton:first-child {
    margin-right: 6px;
  }
}

.embedControls {
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  z-index: 1;
}

.chatControls {
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  z-index: 1;
  transition: right 0.2s ease-in-out;

  &.chatShowed {
    right: calc(40% + 12px);
  }
}

.chatWrapper {
  position: absolute;
  right: 0;
  height: 100%;
  width: 0;
  z-index: 2;
  opacity: 0.9;
  transition: transform 0.2s ease-in-out;
  transform: translate(100%, 0);
  overflow: hidden;

  &.chatShowed {
    width: 40%;
    transform: translate(0%, 0);
  }

  &.normal {
    background: #172a4d61;
  }
}

.streamingLabel {
  display: none;
  z-index: 1;

  @media (min-width: 768px) {
    display: block;
  }
}

.icon {
  width: 16px;
  height: 16px;
}

.tooltip {
  background: colors.$fg-color;
  color: colors.$bg-color;
}

.offline {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;

  font-size: 24px;
  font-weight: 400;
  color: #ebecf0;
}
