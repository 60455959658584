@use '@restream/styles/scss/tokens';

.root {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: tokens.$border-radius-200;

  &.white {
    background: #344563;
  }

  &.blue {
    background: #fff;
  }
}

.iconWrapper {
  position: absolute;

  width: 12px;
  height: 12px;
  bottom: 1px;
  right: 1px;
  display: block;
}

.icon {
  display: block;
}
