@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/colors' as colors;
@use '../../../../components/scss/animation' as animation;

$bg-color: #fff;
$color-fg: #091e42;
$color-placeholder: rgba(9, 30, 66, 0.25);

$transition-time: 0.25s;
$opacity-disabled: 0.5;

$font-size: 14px;

.root {
  position: relative;
  width: 127px;
}

.icon {
  height: 20px;
  fill: colors.$color-border-default;

  &.focus {
    fill: colors.$color-border-focus;
  }

  &.error {
    fill: colors.$color-border-error;
  }

  transition: all animation.$animation-default-time;
}

.button {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size;
  line-height: 20px;
  text-align: left;

  //cursor: pointer;

  width: 100%;
  transition: all 0.3s;
  padding: 10px 12px;
  border-radius: tokens.$border-radius-200;
  outline: none;

  color: $color-fg;
  background: $bg-color;
  border: 1px solid colors.$color-border-default;
}

.button:focus {
  border-color: colors.$color-border-focus;
}

.button:disabled {
  opacity: $opacity-disabled;
  cursor: not-allowed;
}

.button.error {
  border-color: colors.$color-border-error;
}

.list-wrapper {
  z-index: 2;
  outline: none;
}

.list {
  max-height: 200px;
  overflow: auto;
  outline: none;

  background: $bg-color;
  border-radius: tokens.$border-radius-200;
  box-shadow: 0 10px 12px rgba(0, 0, 0, 0.3);
  border: 1px solid #c1c7d0;

  list-style: none;

  padding: 6px;
  margin: 0;
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 12px;
  cursor: pointer;
  color: black;

  font-size: $font-size;
  line-height: 20px;

  border-radius: tokens.$border-radius-200;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

.iconCenter {
  margin: auto 0;
}

.mobileInput {
  height: 22px;
  box-sizing: border-box;
}

.inputContainer {
  flex: initial;
}
