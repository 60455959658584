@import '../../../scss/typography';

.callout {
  padding: 10px 12px;
  margin-bottom: 24px;
}

.calloutDescription {
  @include font-14;

  color: #172b4d;

  strong {
    font-weight: 500;
  }
}

.calloutDescription.error {
  color: #ff004a;
}
