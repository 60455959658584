@use '@restream/styles/scss/tokens';

.container {
  max-width: 500px;
}

.body {
  color: tokens.$color-black;
  text-align: center;
}

.cancelButton {
  // TODO(font): use token
  color: #8993a4;
}
