@use '../../../scss/grid' as grid;

.container {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;

  .content {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    min-width: 0;

    .title {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .game {
      display: flex;
      align-items: center;
    }
  }

  .icon {
    width: grid.$grid-base-value * 3;
    height: grid.$grid-base-value * 3;
    margin-right: grid.$grid-base-value;
  }

  .switcher {
    display: flex;
    white-space: nowrap;
  }

  .game-icon {
    display: flex;
    height: 100%;
    margin: 0 grid.$grid-base-value;
  }

  .game-title {
    opacity: 0.5;
  }
}
