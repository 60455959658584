@import '@restream/styles/scss/colors';

.targeting-form-group {
  align-items: center;
}

.targeting-label {
  margin-bottom: 0;
}

.targeting-value {
  font-weight: 500;
  color: #091e42;
  font-size: 16px;
}

.notice {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
}
