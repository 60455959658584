@import '../../constants';

.productMenuMobile {
  display: none;
  background: #172b4d;
  padding: 12px 20px;

  @media (max-width: $smallMobileMaxWidth) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-left: 12px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.productMenuButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform-origin: center;
  transition: all 200ms;

  &.hidden {
    opacity: 0;
    transform: rotate(90deg);
  }
}

.helpButtonWrapper {
  margin-right: 8px;
}
