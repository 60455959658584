.root {
  display: grid;
  grid-gap: 20px;
}

.text {
  font-size: 14px;
  color: #aeb4bf;
  letter-spacing: 0.01em;
  line-height: 22px;
}

.recoveryCode {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.01em;
  color: #172b4d;
}
