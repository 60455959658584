.root {
  display: flex;
  gap: 4px;
  position: absolute;
  cursor: pointer;
  right: 6px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  align-items: center;
  top: -100%;

  &.menu {
    top: 6px;
    @media (min-width: 1080px) {
      opacity: 0;
    }
  }

  &.open {
    top: 6px;
    opacity: 1;
  }

  @media screen and (max-width: 1080px) {
    &.open,
    &.menu {
      opacity: 1;
      transform: none;
      top: 4px;
      right: 4px;
    }
  }

  &.alwaysOnScreen {
    opacity: 1;
  }
}

.title {
  color: #edf0f3;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin-right: 6px;

  &.hide {
    opacity: 0;
  }

  &.alwaysOnScreen {
    opacity: 1;
  }
}

.confirmationButton {
  color: #fff;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: unset;
  border-color: unset;

  @media screen and (max-width: 1080px) {
    width: 36px;
    height: 36px;
  }

  &:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.4);
    &.edit {
      background-color: #bedaff;
      color: #286fff;
    }

    &.delete {
      background-color: #ffbdad;
      color: #bf2600;
    }
  }

  &:not(:disabled).popoverOpen {
    background-color: #0a1f42;
  }

  &.hide {
    opacity: 0;
  }

  &.alwaysOnScreen {
    opacity: 1 !important;
  }
}

.hide {
  opacity: 0;
}
