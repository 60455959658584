@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/mixins' as mixins;

.wrapper {
  display: flex;
  justify-content: space-between;

  //background: rgba(9, 30, 66, 0.08);
  color: #172b4d;

  padding: 9px 12px;

  border-radius: tokens.$border-radius-200;

  border: 1px solid #f4f5f7;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

.wrapper.error {
  border: 1px solid #ffbdad;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

.inputWrapper {
  display: flex;
  align-items: center;

  min-width: 0;
}

.iconWrapper {
  position: relative;
}

.icon {
  margin-right: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;

  &.completed {
    background: rgba(9, 30, 66, 0.08);
  }
}

.icon.error {
  background: #ffebe6;
}

.button {
  background: none;
  border: none;

  font-size: 14px;
  font-weight: 500;

  padding: 0;
  margin: 0 0 0 8px;

  line-height: 20px;
  cursor: pointer;

  color: #6b778c;

  &:hover {
    color: #172b4d;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.title {
  margin: 0;
  padding: 0;

  line-height: 20px;
  font-size: 14px;

  background: transparent;
  color: inherit;

  &:disabled {
    color: inherit;
  }

  @include mixins.truncate-text;
}

.titleBold {
  font-weight: 500;
}

.status {
  color: #505f79 !important;
}

.status.error {
  color: #de350b !important;
}

.titleWrapper {
  display: flex;
  flex-direction: column;

  min-width: 0;
}
