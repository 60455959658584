@use '@restream/styles/scss/tokens';

.root {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr auto 100px;
    align-items: center;
    padding: 12px 24px;
    border-bottom: solid 1px #ededed;

    .title {
      color: tokens.$color-gray-700;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
    }

    .subTitle {
      color: #aeb4bf;
      font-size: 14px;
    }

    .connected {
      margin-right: 16px;
      color: #aeb4bf;
      font-size: 14px;

      .connectedContent {
        display: flex;
        align-items: center;
        gap: 4px;

        .connectedContentText {
          @media screen and (max-width: 428px) {
            display: none;
          }
        }

        .checkImg {
          padding-top: 1px;
          width: 14px;
          height: auto;
        }
      }
    }

    .btn {
      padding: 8px 14px;
      margin-right: 12px;
      -webkit-appearance: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #004eeb;
      background: #eff4ff;
      border: 1px solid #b2ccff;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: tokens.$border-radius-200;
      position: relative;
      transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
      cursor: pointer;

      .loader {
        width: 26px !important;
        height: 26px !important;
        position: absolute;
        top: calc(50% - 13px);
        left: calc(50% - 13px);
        transition: opacity 0.2s ease-in-out;
        circle {
          transition: stroke 0.1s ease-in-out;
          stroke: #004eeb;
        }
      }

      .hidden {
        opacity: 0;
      }

      .loader {
        position: absolute;
      }

      &:hover:not(.disabled) {
        background: #dee5ff;
      }

      &.disabled {
        opacity: 0.8;
        cursor: default;
      }
    }
  }

  .reconnectError {
    grid-column: 1/3;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 12px;
    justify-content: flex-start;
    align-self: flex-start;

    padding: 12px 24px;
    border-radius: 0 0 4px 4px;
    box-sizing: border-box;

    position: relative;
    overflow: hidden;

    background-color: rgba(222, 53, 11, 0.16);

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: #de350b;
      top: 0;
      left: 0;
      right: 0;
    }

    & .descriptionWrapper {
      display: flex;
      align-items: center;
    }

    & .description {
      color: #172b4d;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }
}
