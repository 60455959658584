@use '@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: tokens.$color-gray-900;
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-900;

  & a {
    color: inherit;
    text-decoration: underline;
  }
}

.inputWithCopy {
  flex: 1;
}

.icon {
  width: 16px;
  height: 16px;
}

.buttons {
  display: flex;
  gap: 12px;

  @include media.xs {
    flex-direction: column;
  }
}
