@use '@restream/styles/scss/tokens';

.timelineContainer {
  width: 100%;
  height: 110px;
  margin-top: 22px;
  padding: 0 24px 0;
  scrollbar-width: thin;
  scrollbar-color: tokens.$border-radius-200 transparent;
  scroll-padding-left: 24px;
  scroll-padding-right: 24px;
  user-select: none;
  white-space: nowrap;
  overflow-x: auto;

  &,
  & *,
  & *::before,
  & *::after {
    box-sizing: border-box;
  }
}

.timelineContainer::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.timelineContainer::-webkit-scrollbar-track {
  background-color: tokens.$color-gray-50;
}

.timelineContainer::-webkit-scrollbar-thumb {
  border-radius: tokens.$border-radius-200;
  background-color: tokens.$color-gray-200;
}

.timelineView {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 100%;
  flex-shrink: 0;
  transform-origin: center center;
  box-sizing: border-box;
}

.timelineWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.timelineBackground {
  position: absolute;
  top: 32px;
  left: 0;
  width: 100%;
  height: 48px;
  border-radius: tokens.$border-radius-100;
  background-color: tokens.$color-gray-50;
}

.loadingBackground {
  cursor: progress;
  background: linear-gradient(110deg, #ececec 0%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 50%;
  animation: loading 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes loading {
  from {
    background-position-x: 100%;
  }
  to {
    background-position-x: -90%;
  }
}
