@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;

  &.buttonBelowText {
    flex-direction: column-reverse;

    & .button {
      width: auto;
      margin: 16px auto 0;
    }
  }
}

.button {
  width: 100%;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 60px 0;
}

.text {
  margin-top: 16px;

  color: tokens.$color-gray-500;
  text-align: center;

  font-size: 16px;
  line-height: 24px;

  max-width: 276px;
}
