@use '@restream/styles/scss/tokens';

.rootWrapper {
  position: relative;
}
.popoverArrow {
  z-index: unset;
}
.wrapper {
  filter: drop-shadow(0px 18px 25px rgba(9, 30, 66, 0.12))
    drop-shadow(0px 1.47438px 8.11069px rgba(9, 30, 66, 0.0571745));
}

.root {
  padding: 12px;
  display: flex;
  max-width: 280px;
  border-radius: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  margin-bottom: 4px;
  margin-top: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;

  color: tokens.$color-gray-900;
}

.descriptionHighlight {
  font-weight: 500;
}

.logo {
  width: 32px;
  height: 32px;
}

.text {
  font-style: normal;
  white-space: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: tokens.$color-gray-700;
  margin: 0;
}

.button {
  margin: 12px 0 0;
}

.closeIcon {
  width: 12px;
  height: 12px;
  fill: grey;
  opacity: 0.9;
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
  margin: 0;
  padding: 6px;
  border-radius: tokens.$border-radius-200;
  line-height: 10px;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: rgba(226, 226, 233, 0.7);
  }
}
