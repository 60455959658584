@use '@restream/styles/scss/tokens';

.title {
  color: tokens.$color-gray-900;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin: 8px 0 0;
  text-align: center;
}

.subtitle {
  color: tokens.$color-gray-600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 30px;
}

.buttonWrapper {
  width: 100%;
  max-width: 280px;
}

.uploadButtonLoader {
  & circle {
    stroke: tokens.$color-blue-700;
  }
}
