@use 'sass:math';
@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/colors';
@use '../../../scss/typography' as typography;
@use '../../../../scrollbar' as scrollbar;

@mixin buttonBadge {
  letter-spacing: 0.13em;
  position: absolute;
  right: -5px;
  top: 1px;
  padding: 2px 2px 0 3px;
  background: #fff;
  border-radius: tokens.$border-radius-200;
  font-size: 8px;
  color: #091f42;
  line-height: 12px;
  font-style: normal;
  font-weight: 700;
}

$unit: 24px;
$accordionAnimationMs: 300ms;
$smallerSpace: 12px;
$space: 16px;

$overlayImageWidth: 84px;
$aspectRation16n9: 1.77777;

$sidebarPaddingSides: 20px;
$sidebarPaddingEnds: 10px;
$tabPadding: math.div($unit, 2);
$defaultScrollWith: 7px;

.root {
  width: 100%;
  height: 100%;
  border-radius: tokens.$border-radius-200;
  overflow: hidden;
}

.root__sidebar {
  height: 100%;
  margin: 0;

  @media (min-width: 1080px) {
    background-color: #071835;
  }
}

.section {
  // stylelint-disable-next-line declaration-no-important
  flex: 0 !important;

  transition: margin-bottom $accordionAnimationMs ease-in,
    padding $accordionAnimationMs ease-in;

  &.open {
    &:not(:last-of-type) {
      margin-bottom: $unit * 1.5;
    }
  }
}

.section--field {
  + .section--field {
    margin-top: math.div($unit, 2);
  }
}

.section__heading {
  line-height: 1.1;
  font-size: 20px;
  font-weight: 500;
  color: #fff;

  margin-bottom: math.div($unit, 2);
}

.section__text {
  margin-left: 8px;
  margin-right: 2px;
  vertical-align: middle;
}

.section__info {
  width: 16px;
  vertical-align: middle;
}

// @todo Maybe it's better move this into ImageSelect component as optional mode
.overlayImages,
.stingers,
.backgroundImages {
  // stylelint-disable-next-line declaration-no-important
  grid-template-columns: repeat(
    auto-fill,
    minmax($overlayImageWidth, 1fr)
  ) !important;
}

.overlayImages__buttonContainer,
.stingers__buttonContainer,
.backgroundImages__buttonContainer {
  // stylelint-disable-next-line declaration-no-important
  width: $overlayImageWidth !important;
  // stylelint-disable-next-line declaration-no-important
  height: math.div($overlayImageWidth, $aspectRation16n9) !important;
}

.overlayImages__button,
.stingers__button,
.backgroundImages__button {
  // stylelint-disable-next-line declaration-no-important
  padding: 0 !important;
  overflow: hidden;
}

.setup__section {
  display: flex;
  flex-direction: column;

  + .setup__section {
    margin-top: $unit * 1.5;
  }
}

.setup__section--titles {
  // stylelint-disable-next-line declaration-no-important
  flex-grow: 0 !important;
  min-height: 282px;
}

.tabs {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  padding: 0 16px 2px;
  margin: 0;
  border: 0;

  @media (min-width: 1080px) {
    padding: $space $space 0;
  }
}

.tabs__tab {
  height: 100%;

  // stylelint-disable-next-line selector-max-universal
  > * {
    flex-grow: 1;
  }
}

$hideChatMessageButtonBottomShift: 12px;

.tabs__hideChatMessageButton {
  flex-grow: 0;

  // To make it same width as Chat message hover button
  margin-left: 10px;
  margin-right: 10px;

  // @todo Those are approximate values
  margin-top: -13px;
}

.hideChatMessageButton {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;
  border-radius: tokens.$border-radius-200;

  padding: 8px;

  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: #538cff;
  fill: #538cff;

  background-color: rgba(#538cff, 0.2);

  transition: background-color 100ms ease-out;

  &:hover,
  &:focus {
    background-color: #276cf8;
    color: #fff;
    fill: #fff;
  }
}

.hideChatMessageButton__icon {
  flex-shrink: 0;
  width: 18px;
  margin-right: 10px;
}

.chatIframe {
  min-height: 200px;
  border: 0;
}

.blockedChatOverlay {
  position: relative;
}

.unsetOverflow {
  // stylelint-disable-next-line declaration-no-important
  overflow: unset !important;
}

.overflowHidden {
  // stylelint-disable-next-line declaration-no-important
  overflow: hidden !important;
}

.blockedChatOverlay__overlay {
  position: absolute;

  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(8, 30, 66, 0.75);
}

.blockedChatOverlay__text {
  $tabContentBottomPadding: 20px;
  $chatInputHeight: 52px;
  $chatInputOptionalWarningHeight: 50px;

  position: absolute;

  align-self: flex-end;

  width: 100%;

  bottom: $chatInputHeight + $tabContentBottomPadding +
    $chatInputOptionalWarningHeight + 24px;

  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 16, 40, 0.25);
}

.root__badgeGroup {
  position: relative;
}

.root__badge {
  @include buttonBadge;
}

.notSupportedVideos {
  opacity: 0.3;
  pointer-events: none;
}

.content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height $accordionAnimationMs ease,
    opacity $accordionAnimationMs ease;

  .open & {
    // TODO: come up with a proper number of max-height
    max-height: 3000px;
    opacity: 1;
    overflow: inherit;
    transition: max-height ($accordionAnimationMs * 1.5) ease,
      opacity ($accordionAnimationMs * 1.25) ease;
  }
}

.title {
  color: #ebecf0; // #8993a4; - can be reused for subsection titles later
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.accordion {
  padding: 4px;
  margin-left: -3px;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.triangleArrowIcon {
  transition: transform $accordionAnimationMs ease,
    color $accordionAnimationMs ease;
  color: rgba(255, 255, 255, 0.6);

  .open & {
    transform: rotate(90deg);
    color: #fff;
  }
}

.subSection {
  display: block;

  &:not(:first-child) {
    margin-top: math.div($unit, 2);
  }

  &:not(:last-child) {
    margin-bottom: math.div($unit, 4);
  }
}

.toggleTextSpace {
  margin-left: math.div($unit, 2);
  margin-right: math.div($unit, 4);
}

.loader {
  margin: 50px auto;

  @media (max-width: 1080px) {
    margin: 25px auto;
  }
}

.tabs__buttonContainer {
  @media (min-width: 1080px) {
    flex: 0;
  }
}

.tabs__button {
  @media (min-width: 1080px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

$reservedIndent: 116px;

.contentWrapper {
  padding-bottom: 12px;
  margin-bottom: 36px;
}

.moreTabTitle {
  @include typography.font-18;

  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.moreTabIcon {
  margin-right: 12px;
  fill: colors.$fg-color--darken-1;
}

.tabContentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.chatContent {
    min-height: 300px;
  }
}

.chatOptions {
  flex: 0;
  margin-top: -$smallerSpace;
  margin-bottom: 4px;
  margin-left: -$smallerSpace;
  margin-right: -$smallerSpace;

  @media (min-width: 1080px) {
    margin-left: -$space;
    margin-right: -$space;
  }

  padding: 8px 16px;

  // chat options should be reachable when all chat tab is blocked
  z-index: 1;
}

.tab {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  justify-content: center;
  align-items: center;
}
