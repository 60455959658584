@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.button {
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  display: flex;
  padding: 8px;
  transition: all 0.3s;

  &:hover {
    background: #2d436a;
  }

  &.withToolTip {
    background: rgba(215, 220, 227, 0.1);
  }

  &.active {
    background: tokens.$color-gray-800;
  }

  .buttonIcon {
    height: 16px;
    width: 16px;
  }
}

.menuContent {
  z-index: 3;
}

.mainText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-700;

  .semiBold {
    font-weight: 500;
  }
}

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  height: 22px;
  border-radius: 8px;
  line-height: 1.4;
  background-color: #2970ff;
}

.deleteItem {
  color: tokens.$color-red-600;
}

.dropdownButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #fff;
  border: 0;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:active,
  &:hover,
  &:focus-within {
    outline: none;
  }

  &.focused,
  &:focus {
    outline: none;
    outline-offset: 2px;
    background-color: #374866;
  }

  & > svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  & .srOnly {
    display: none;
  }

  &[data-state='open'] {
    background-color: #374866;
  }
}

.popover {
  z-index: 2;
}

.popoverContent {
  min-width: 320px;
  border-radius: 8px;
  padding: 16px;

  @include media.xs {
    min-width: 200px;
  }
}

.popoverHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.popoverHeaderCloseButton {
  padding: 5px;
}

.popoverHeader {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-700;
}

.iconBoxModel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
}

.dropdownMenuItem {
  &.danger {
    color: tokens.$color-red-600;
  }

  &.danger:hover {
    background-color: tokens.$color-red-50;
  }
}
