@use '@restream/styles/scss/tokens';

.wrapper {
  position: absolute;
  left: 10px;
  z-index: 1;

  &.top {
    top: 8px;
  }

  &.bottom {
    bottom: 8px;
  }
}

.content {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);

  color: tokens.$color-gray-25;
  font-size: tokens.$font-size-body-sm;
  font-weight: 500;
  line-height: 20px;

  user-select: none;
}

.icon {
  color: tokens.$color-gray-25;
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.text {
  margin-left: 4px;

  &:first-of-type {
    margin-left: 6px; // more space from icon
  }

  &.noMargin {
    margin-left: 0;
  }
}
