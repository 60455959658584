@use '~@restream/styles/scss/media' as media;
@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;

  @include media.xs {
    margin-bottom: 0;
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: tokens.$color-gray-100;

  margin: 12px 0 24px;
}

.button,
.buttonWrapper {
  width: 100%;
  max-width: 320px;
}

.buttonWrapper {
  &:first-of-type {
    margin-bottom: 16px;
  }
}

.button {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  height: 44px;
  box-sizing: border-box;
  border-radius: 44px;
  padding: 10px 80px;

  @include media.xs {
    padding: 8px 16px;
  }
}

.startButton {
  background: linear-gradient(78.2deg, #2e90fa 0%, #004eeb 50.1%, #6927da 100%);
  transition: all 0.2s ease;

  &:hover:not(&:disabled) {
    background: linear-gradient(90deg, #004eeb 0%, #6927da 100%);
  }
}

.startButtonIcon {
  display: flex;
}

.uploadButton {
  background: transparent;
  border: 1px solid tokens.$color-gray-500;
  height: auto;

  &:hover:not(&:disabled) {
    background: rgba(215, 220, 227, 0.1);
  }
}
