@use '~@restream/styles/scss/media' as media;
@use '../../../../scss/mixins' as mixins;

@import '../constants';

.root {
  @include mixins.display-flex-center;
  justify-content: space-between;

  box-sizing: border-box;
  cursor: pointer;
  padding: 8px 16px;
  gap: 12px;
  height: $upgradeTriggerHeight;

  background-color: rgba(168, 249, 255, 0.08);
  border: 1px solid rgba(168, 249, 255, 0.16);
  border-radius: $upgradeTriggerHeight;

  max-width: 400px;
  white-space: nowrap;
  transition: opacity 0.3s ease;

  &:hover {
    background-color: rgba(168, 249, 255, 0.12);
    border-color: rgba(168, 249, 255, 0.22);
  }

  @include media.xs {
    padding: 8px 12px;
  }
}

.blackFridayBanner {
  @include media.xs {
    display: none;
  }
}

.text {
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  @include media.xs {
    font-size: 12px;
    line-height: 18px;
  }
}

.divider {
  width: 1px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.16);
  border: none;
  margin: 0;
}

.upgradeText {
  font-weight: 500;
}
