@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/outline';
@use '../../../../../../scss/typography';

.root {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: grid;
  grid-gap: 12px;
  padding: 0;
  margin: 0;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
}

.switcher-wrapper {
  display: flex;
}

.switcher {
  display: flex;
  margin-right: 8px;
}

.switcher-label {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;

  .streamingSettingsTheme & {
    color: tokens.$color-gray-800;
  }
}

.remove-button {
  @include typography.font-14;

  color: #6b778c;

  background: none;
  line-height: 20px;
  padding: 0;
  font-weight: 400;

  &:hover:not(:disabled) {
    background: none;
    color: #6b778c;
    text-decoration: underline;
  }
}

.togglik {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
}

.inputRow {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
  align-items: center;
}

.input {
  height: 40px;
  background-color: tokens.$color-gray-800;
  border: none;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.7);

  .streamingSettingsTheme & {
    color: tokens.$color-gray-900;
    background-color: tokens.$color-white;
    border-radius: tokens.$border-radius-200;
  }
}

.copyButton {
  cursor: pointer;
  min-width: 56px;
  width: fit-content;
  height: 32px;

  font-weight: 600;
  // TODO(font): use token
  font-size: 13px;
  line-height: 22px;
  border-radius: tokens.$border-radius-200;
  color: tokens.$color-white;
  background-color: #286fff;
  border: none;
  transition: background-color 100ms ease, opacity 300ms ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    box-shadow: none;
  }

  @include outline.focus-visible-outline;

  &.copiedButton {
    background-color: #0a7;
  }

  @media (min-width: 768px) {
    min-width: 66px;
    height: 40px;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
  }
}
