@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';

$padding: 14px 12px 16px;
$icon-size: 32px;

@mixin logo-title {
  span {
    color: tokens.$color-white;
    // TODO(color): use token
    font-size: 26px;
    font-weight: 700;
    margin-left: 10px;
  }
}

@mixin logo-hover {
  opacity: 1;
  cursor: pointer;
  transition: opacity $animation-hover-time;
  &:hover {
    opacity: 1;
  }
}
