@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.modalFooter {
  &.mobileModalFooter {
    @include media.xs {
      position: sticky;
      bottom: -1px;
      z-index: 3;

      background: white;
      padding-top: 24px;
      border-radius: 0 0 tokens.$border-radius-300 tokens.$border-radius-300;

      box-shadow: 0 -20px 24px -4px rgb(16 24 40 / 8%),
        0 8px 8px -4px rgb(16 24 40 / 3%);
    }
  }

  &.mobileOnboardingModalFooter {
    @include media.xs {
      border-top: 1px solid #edf0f3;
      box-shadow: none;
    }
  }

  &.extendedOnboardingInterface {
    padding: 24px 40px;
    border-top: 1px solid #edf0f3;
  }
}
