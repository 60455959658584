@use '@restream/styles/scss/tokens';

.button {
  background: transparent;
  color: tokens.$color-gray-400;

  margin-top: 8px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;

  &:hover:not(&:disabled) {
    background: initial;
    color: tokens.$color-blue-600;

    &.danger {
      color: tokens.$color-red-600;
    }
  }

  &:first-of-type {
    margin-right: 12px;
  }

  .leftIcon {
    margin-right: 4px;
  }

  .rightIcon {
    margin-left: 4px;
  }
}
