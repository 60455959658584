@use '@restream/styles/scss/tokens';

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.container {
  padding: 16px 20px;

  @media (min-width: 576px) {
    padding: 12px 24px 8px;
  }
}

.header {
  display: flex;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #091e42;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.serviceImgWrapper {
  display: flex;
  align-items: center;
  border-radius: tokens.$border-radius-200;
  position: relative;

  .serviceImg {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  & .imageLoaderWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fafbfc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .imageLoader {
    & circle {
      stroke: rgba(0, 0, 0, 0.5);
    }
  }
}

.inputWrapper {
  margin-top: 12px;
  width: 100%;
  position: relative;

  @media (min-width: 576px) {
    margin-top: 16px;
  }

  .inputLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: tokens.$color-gray-700;
    margin-bottom: 8px;

    &.disabled {
      opacity: 0.4;
    }
  }

  .inputLabelSwitch {
    margin-right: 10px;
  }

  .switchRoot {
    & [class*='lever'] {
    }
  }

  .input {
    border-radius: tokens.$border-radius-200;
    width: 100%;
    font-size: 16px;

    &.hidden {
      opacity: 0;
      transition: opacity 0.3s ease-in;
      pointer-events: none;
    }

    &.hidden.visible {
      opacity: 1;
      pointer-events: initial;
    }
  }

  .inputInnerWrapper {
    padding-right: 40px;
  }

  .inputLoader {
    position: absolute;
    bottom: 9px;
    right: 9px;
    width: 24px;
    height: 24px;
    opacity: 0;
    transition: opacity 0.4s ease-in;

    &.visible {
      opacity: 1;
      transition: opacity 0.4s ease-out;
    }
  }

  .inputs {
    display: flex;
    align-items: center;
  }
}

.buttonWrapper {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;

  @include mobileMedia() {
    padding-top: 6px;
  }

  .saveButton {
    appearance: none;
    font-weight: 500;
    -webkit-appearance: none;
    border: none;
    color: #ffffff;
    font-size: 14px;
    background: #155eef;
    border-radius: tokens.$border-radius-200;
    padding: 12px 0;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    width: 216px;
    text-align: center;

    &:disabled {
      opacity: 0.4;
      cursor: auto;
    }

    &:not(:disabled):hover {
      opacity: 0.9;
    }
  }
}

.errorWrapper {
  width: 100%;
  margin-top: 15px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  box-sizing: border-box;
  padding: 15px;

  & p {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #253858;
  }
}

.rightPartWrapper {
  padding: 16px 20px;
  background: tokens.$color-gray-50;
  min-width: 264px;
  max-width: 264px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 576px) {
    padding: 24px;
  }

  @include mobileMedia() {
    max-width: 100%;
  }

  & .text {
    font-size: 14px;
    line-height: 20px;
    color: tokens.$color-gray-700;

    & span {
      display: block;
      font-weight: 600;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}
