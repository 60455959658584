@use '@restream/styles/scss/tokens';
@use '../../../../../scss/mixins' as mixins;

.root {
  display: flex;
}

.buttonIcon {
  margin-right: 8px;
}

.dropdown {
  background: tokens.$color-white;
  padding: 0;
  position: relative;
  right: -8px;
  margin-top: 4px;
  overflow: hidden;
}

.dropdownItem {
  border-radius: tokens.$border-radius-200;
  color: tokens.$color-gray-800;
}

.dropdownButton {
  @include mixins.button-reset;

  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-700;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  transition: background-color 250ms ease;

  &:hover {
    background-color: tokens.$color-gray-50;
  }
}

.dropdownItemIcon {
  //fill: #172b4d;
  margin-right: 8px;
}

.dropdownTriggerButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.actionButton {
  display: flex;
  height: 36px;

  font-size: 14px;
  font-weight: 500;

  padding: 8px 14px;

  &:focus {
    box-shadow: none;
  }

  &.switch {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
}

.content {
  width: 100%;
}
