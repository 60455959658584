@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/modals' as modals;

.root {
  @include modals.modal-width;
  background: white;
  border-radius: tokens.$border-radius-200;
}

.body {
  position: relative;
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.content {
  border-radius: tokens.$border-radius-200;
  border: 1px solid #d7dce3;
  overflow: hidden;
  background: #fafbfc;
}

.cropperWrapper {
  height: 352px;
}

.cropperBoxLogo {
  position: absolute;
  width: 28.125% !important;
  height: auto !important;
  top: 6.66%;
  right: 3.75%;
}

.actionsWrapper {
  display: flex;
  flex-direction: column;

  margin: 16px;
}

.brandingHeader {
  padding: 0;
  margin: 0 0 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: tokens.$color-gray-700;
}

.switch {
  margin-right: 8px;
  opacity: 0.5;
}

.switchLabel {
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-700;

  display: flex;
  align-items: center;
  height: 20px;

  width: fit-content;

  margin-right: 8px;
}

.switchWrapper {
  display: flex;
}

.upgradeButton {
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin: 0;
}
