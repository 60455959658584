@use '@restream/styles/scss/tokens';

.recordings {
  margin: 12px 0;

  &:last-child {
    margin-bottom: 28px;
  }

  @media (min-width: 576px) {
    margin: 16px 0;
  }
}

.upgradeBlock {
  margin: 6px 28px 0;
  padding: 12px 16px 16px;
  font-size: 14px;
  color: #091e42;
  background-color: #c9d6f4;
  border-radius: tokens.$border-radius-200;
  font-weight: 400;

  .upgradeLink {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
