@use '~@restream/styles/scss/media';
@use '~@restream/styles/scss/colors';
@use '~@restream/styles/scss/outline';
@use '@restream/styles/scss/tokens';

.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.container {
  width: fit-content;
  margin: auto;
  height: 100%;
  display: grid;
  grid-gap: 0;

  @media (min-width: 350px) {
    grid-gap: 12px;
  }

  @media (min-width: 576px) {
    grid-gap: 20px;
  }

  @media (min-width: 768px) {
    grid-gap: 60px;
  }

  @media (min-width: 1080px) {
    grid-gap: 36px;
  }

  @media (min-width: 1200px) {
    grid-gap: 60px;
  }
}

.titleBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.title {
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ebecf0;

  display: none;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  grid-gap: 8px;
  margin-bottom: 10px;

  @media (min-width: 576px) {
    display: grid;
    font-size: 24px;
    line-height: 28px;
  }

  @media (min-width: 768px) {
    display: grid;
    font-size: 28px;
    line-height: 32px;
  }
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #a5adba;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
  }

  display: none;

  @media (min-width: 576px) {
    display: block;
  }
}

.shortDescription {
  display: none;

  @media (min-width: 350px) {
    display: block;
  }

  @media (min-width: 576px) {
    display: none;
  }
}

.tutorialsModal {
  width: 608px;
}

.tutorials {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #538cff;

  display: none;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: transparent;
  border: none;

  @include outline.focus-visible-outline;

  @media (min-width: 576px) {
    display: grid;
    font-size: 15px;
    line-height: 20px;
  }

  @media (min-width: 768px) {
    display: grid;
    font-size: 16px;
    line-height: 20px;
  }
}

.tutorialsTitle {
  line-height: 16px;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 200ms ease;

  .tutorials:hover & {
    border-bottom: 1px solid;
  }
}

.iconPlay {
  width: 20px;
  height: 20px;
}

.form {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 8px;

  @media (min-width: 576px) {
    grid-gap: 16px;
  }
}

.formGroup {
  margin: 0;
  padding: 0;
  height: fit-content;
}

.input-row {
  display: flex;
}

.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.label {
  padding-left: 2px;
  padding-bottom: 4px;
  margin: 0;
}

.inputRtmpLink {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;

  // applied for reach child after 1st
  & > * + * {
    margin-left: 6px !important;
  }

  @media (min-width: 576px) {
    & > * + * {
      margin-left: 10px !important;
    }
  }

  @media (min-width: 768px) {
    height: auto;
  }
}

.inputWrapper {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
}

.input {
  color: tokens.$color-white;
  // TODO(color): use token
  background-color: rgba(255, 255, 255, 0.14);
  box-shadow: none;
  height: 40px;
}

.inputRoot {
  padding: 0;
  margin: 0;
}

.label-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a5adba;
}

.label-column {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
}

.rtmpLinkBlock {
  flex: 1 1;
}

.select-input {
  display: flex;
  flex: 2;
}

.button-anchor {
  background: none;
  outline: none;
  margin: 0;
  padding: 0;
  border: none;

  font-size: 14px;
  cursor: pointer;
  color: #286fff;
}

.textButton {
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  text-align: right;
  letter-spacing: 0.01em;
  // TODO(color): use token
  color: #ebecf0;
  background-color: transparent;
  border-radius: tokens.$border-radius-200;
  padding: 1px 4px;
  transition: background-color 100ms ease, color 100ms ease;

  &:hover {
    background-color: rgb(#fff, 0.2);
    color: #fff;
  }
}

.resetButton {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6px;
  padding: 5px 8px;
  border-radius: tokens.$border-radius-200;
  height: fit-content;
  margin: auto;
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  letter-spacing: 0.01em;
  // TODO(color): use token
  color: #ebecf0;
  background-color: #242424;
  transition: background-color 100ms ease, color 100ms ease;
  z-index: 1;

  &:hover {
    background-color: #505050;
    color: tokens.$color-white;
  }

  @include outline.focus-visible-outline;
}

.copyForMobile {
  @media (min-width: 1080px) {
    display: none;
  }
}

.copyButton {
  cursor: pointer;
  min-width: 56px;
  width: fit-content;
  height: 40px;

  font-weight: 600;
  // TODO(font): use token
  font-size: 13px;
  line-height: 22px;
  border-radius: tokens.$border-radius-200;
  color: tokens.$color-white;
  // TODO(color): use token
  background-color: #286fff;
  border: none;
  transition: background-color 100ms ease, opacity 300ms ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    box-shadow: none;
  }

  @include outline.focus-visible-outline;

  &.copiedButton {
    background-color: #0a7;
  }

  @media (min-width: 768px) {
    min-width: 66px;
    font-size: 14px;
    line-height: 24px;
  }
}

.addBackupStreamButton {
  padding: 0;
  width: fit-content;
  // TODO(color): use token
  color: #538cff;
  border-radius: tokens.$border-radius-200;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  letter-spacing: 0.01em;
  cursor: pointer;
  background: none;
  border: none;
  display: none;
  position: absolute;
  bottom: -32px;
  left: 0;

  @media (min-width: 576px) {
    display: block;
  }

  &:hover {
    text-decoration: underline;
  }

  @include outline.focus-visible-outline;
}
