@use '@restream/styles/scss/tokens';

$width: 300px;
$backgroundColor: #fff;
$boxShadow: 0 0 5px 1px rgb(0 0 0 / 10%);

.root {
  width: $width;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.5;
  color: tokens.$color-gray-700;
  border-radius: tokens.$border-radius-300; // necessary for background + box-shadow
  animation: slideIn 0.4s;
  will-change: opacity;

  &.withBackgroundUrl {
    box-shadow: $boxShadow; // necessary for background + box-shadow
  }

  p {
    margin: 0;

    + p {
      margin-top: 10px;
    }
  }
}

.root__image {
  display: block;
  width: $width;
  height: 86px;
  background-color: $backgroundColor;
  border-radius: tokens.$border-radius-300 tokens.$border-radius-300 0 0;
}

.root__content {
  padding: 12px 16px;
  background-color: $backgroundColor;
  border-radius: tokens.$border-radius-300;
  box-shadow: $boxShadow;

  .withBackgroundUrl & {
    border-radius: 0 0 tokens.$border-radius-300 tokens.$border-radius-300; // necessary for background + box-shadow
    box-shadow: none; // necessary for background + box-shadow
  }
}

.heading {
  text-align: left;

  margin-bottom: 4px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;

  color: tokens.$color-gray-900;
}

.text {
  margin-bottom: 12px;
}

@keyframes slideIn {
  from {
    transform: translateY(12px);
    opacity: 0;
  }

  20% {
    opacity: 0.8;
  }

  to {
    opacity: 1;
  }
}
