@use '@restream/styles/scss/tokens';

.uploadButtonAnimationWrapper {
  width: 100%;
  max-width: 260px;
}

.uploadButtonWrapper {
  width: 100%;
  position: relative;
}

.uploadButton {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  height: 44px;
  color: tokens.$color-gray-50;

  background: linear-gradient(78.2deg, #2e90fa 0%, #004eeb 50.1%, #6927da 100%);
  border-radius: 44px;

  transition: all 0.2s ease;

  &:hover:not(&:disabled) {
    background: linear-gradient(90deg, #004eeb 0%, #6927da 100%);
  }

  @media (max-width: 375px) {
    // TODO(font): use token
    font-size: 15px;
  }
}
