@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/colors';
@use '~@restream/styles/scss/outline';

.root {
  padding: 12px 16px;
  border-radius: tokens.$border-radius-300;
  background-color: #fafbfc;

  max-width: 300px;

  animation: slideIn 0.4s;

  will-change: opacity, transform;

  box-shadow: 0 0 5px 1px rgb(0 0 0 / 10%);
}

.header {
  margin-bottom: 4px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;

  color: tokens.$color-gray-900;
}

.text {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 12px;
  color: tokens.$color-gray-700;
}

@keyframes slideIn {
  from {
    transform: translateY(12px);
    opacity: 0;
  }

  20% {
    opacity: 0.8;
  }

  to {
    opacity: 1;
  }
}
