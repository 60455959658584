@use '@restream/styles/scss/modals';
@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/outline';
@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/media';

.root {
  @include modals.modal-width;
}

.label {
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: #a5adba;
}

.button-anchor {
  background: none;
  outline: none;
  margin: 0;
  padding: 0;
  border: none;

  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  cursor: pointer;
  color: #286fff;
}

.streamKeyInputWrapper {
  display: flex;
}

.first {
  display: flex;
  flex: 1;
  margin-right: 10px;

  @include media.xs {
    width: 100%;
  }
}

.copyButton {
  cursor: pointer;
  min-width: 56px;
  width: fit-content;
  height: 40px;

  font-weight: 600;
  // TODO(font): use token
  font-size: 13px;
  line-height: 22px;
  border-radius: tokens.$border-radius-200;
  color: tokens.$color-white;
  background-color: #286fff;
  border: none;
  transition: background-color 100ms ease, opacity 300ms ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    box-shadow: none;
  }

  @include outline.focus-visible-outline;

  &.copiedButton {
    background-color: #0a7;
  }

  @media (min-width: 768px) {
    min-width: 66px;
    height: 40px;
    font-size: 14px;
    line-height: 24px;
  }
}

.inputContainer {
  position: relative;
}

.inputWrapper {
  color: tokens.$color-gray-900;
  background-color: tokens.$color-white;
  border-radius: tokens.$border-radius-200;

  .dark & {
    color: tokens.$color-white;
    // TODO(color): use token
    background-color: #253858;
  }
}

.inputAbsoluteWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.input {
  @include mixins.truncate-text();

  &.withButtonInside {
    width: calc(100% - 42px);
  }
}

.resetButton {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6px;
  padding: 5px 8px;
  border-radius: tokens.$border-radius-200;
  height: fit-content;
  margin: auto;
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  letter-spacing: 0.01em;
  transition: background-color 300ms ease, color 300ms ease;
  z-index: 1;
  background-color: white;

  &:hover {
    // TODO(color): use token
    background-color: #deebff;
  }

  .dark & {
    color: tokens.$color-white;
    background-color: #253858;
  }
  .dark &:hover {
    background-color: #42526e;
  }

  @include outline.focus-visible-outline;
}
