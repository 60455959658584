@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/variables';
@import '../../scss/media';

.modal-view {
  width: auto;
  margin: variables.$modal-view-margin;

  &.modal-view-full-height {
    height: 100%;
    margin: 0;

    @media (min-width: 576px) {
      height: initial;
      margin: 10px;
    }
  }
}

.modal-view-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal-view-content {
  position: relative;
  background-color: tokens.$color-white;
  border-radius: tokens.$border-radius-300;
  // TODO(color): use token
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
  height: inherit;
}
.modal-view-header {
  // TODO(color): use token
  border-bottom: tokens.$border-width solid #e5e5e5;
  padding: 15px;
}

.modal-view-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  right: 20px;
  top: 12px;
  // TODO(font): use token
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: tokens.$color-black;
  text-shadow: 0 1px 0 tokens.$color-white;
  filter: alpha(opacity=20);
  opacity: 0.1;
  text-decoration: none;
}
.modal-view-close-x:after {
  content: '×';
}
.modal-view-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  text-decoration: none;
}
.modal-view-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-view-body {
  position: relative;
  padding: 15px;
  height: inherit;
}
.modal-view-footer {
  padding: 15px;
  text-align: right;
  // TODO(color): use token
  border-top: tokens.$border-width solid #e5e5e5;
}
.modal-view-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-view-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-view-footer .btn-block + .btn-block {
  margin-left: 0;
}
@media (min-width: $breakpoint-xs) {
  .modal-view {
    width: 500px;
    margin: 10vh auto 30px;
  }
  .modal-view-content {
    // TODO(color): use token
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
.modal-view-slide-fade-enter,
.modal-view-slide-fade-appear {
  transform: translate(0, -25%);
}
.modal-view-slide-fade-enter,
.modal-view-slide-fade-appear,
.modal-view-slide-fade-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-play-state: paused;
}
.modal-view-slide-fade-enter.modal-view-slide-fade-enter-active,
.modal-view-slide-fade-appear.modal-view-slide-fade-appear-active {
  animation-name: modalViewSlideFadeIn;
  animation-play-state: running;
}
.modal-view-slide-fade-leave.modal-view-slide-fade-leave-active {
  animation-name: modalViewSlideFadeOut;
  animation-play-state: running;
}
@keyframes modalViewSlideFadeIn {
  0% {
    transform: translate(0, -25%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes modalViewSlideFadeOut {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -25%);
  }
}
.modal-view-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // TODO(color): use token
  background-color: rgba(9, 30, 66, 0.8);
  height: 100%;
  filter: alpha(opacity=50);
  z-index: 1050;
  backdrop-filter: blur(5px);
}
.modal-view-mask-hidden {
  display: none;
}
.modal-view-fade-enter,
.modal-view-fade-appear {
  opacity: 0;
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.1);
  animation-play-state: paused;
}
.modal-view-fade-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.1);
  animation-play-state: paused;
}
.modal-view-fade-enter.modal-view-fade-enter-active,
.modal-view-fade-appear.modal-view-fade-appear-active {
  animation-name: modalViewFadeIn;
  animation-play-state: running;
}
.modal-view-fade-leave.modal-view-fade-leave-active {
  animation-name: modalViewFadeOut;
  animation-play-state: running;
}
@keyframes modalViewFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes modalViewFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal-view-root.modal-view-width-fit-content .modal-view {
  width: fit-content;
  @include xs {
    width: unset;
  }
}
