@use '@restream/styles/scss/tokens';

.dialogOverlay {
  background-color: rgba(0, 0, 0, 0.12);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 102;
  backdrop-filter: blur(5px);
}

.dialogContent {
  background-color: tokens.$color-gray-800;
  box-shadow: hsl(206 22% 7% / 35%) 0 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: auto;

  animation: contentShow 550ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 102;

  width: fit-content;
}

.dialogContent:focus {
  outline: none;
}

@keyframes overlayShow {
  from {
    opacity: 0;
    backdrop-filter: blur(0);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(5px);
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loaderPlaceholder {
  width: 100vw;
  height: 100vh;
}
