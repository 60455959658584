// mobile first approach

$breakpoint-tablet: 576px;
$breakpoint-desktop: 1080px;

@mixin tablet {
  @media (min-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}
