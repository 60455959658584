@use '@restream/styles/scss/tokens';

.root {
  @media (min-width: 1100px) {
    width: 1005px;
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  max-width: 1005px;
}

.header {
  font-size: 14px;
  color: #aeb4bf;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  line-height: 28px;
  color: tokens.$color-gray-100;
  letter-spacing: 0.01em;
  font-weight: 400;
}

.contactSalesLink {
  background: none;
  border: none;
  padding: 0;
  color: tokens.$color-blue-400;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.promoBanner {
  margin-bottom: 16px;
}

.titleWrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .title {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.invoicePromoIcon {
  width: 14px;
  height: 14px;
}

.invoicePromoWrapper {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.extraData {
  margin-bottom: 16px;
}

.tableResponsive {
  margin-bottom: 50px;
}

.tooltipTrigger {
  margin: auto;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  line-height: 1.4;
  border: none;
  border-radius: tokens.$border-radius-200;
  user-select: none;
}

.inactive {
  opacity: 0.4;
}
