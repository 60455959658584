@use '@restream/styles/scss/tokens';
@use '../../../scss/mixins' as mixins;

.button {
  @include mixins.button-reset;

  height: 36px;
  width: 36px;
  background-color: transparent;
  border-radius: tokens.$border-radius-200;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 350ms background-color;

  &:hover {
    background-color: tokens.$color-gray-800;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.icon {
  // TODO(color): use token
  fill: #ebecf0;
  height: 16px;
  width: 16px;
}
