@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';
@use '../scss/outline';
@import '../scss/grid';

.button {
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  border-radius: tokens.$border-radius-200;
  padding: 8px 14px;
  margin: 0;
  outline: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all 0.2s;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;

  &:disabled {
    cursor: default;
    opacity: 0.8;
  }

  @include outline.focus-visible-outline;
}

.ghost.loading {
  @include mixins.always-lowlight-overlay;
}

.outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px tokens.$color-white;
  color: tokens.$color-white;

  &:hover:not(:disabled) {
    background-color: transparent;
  }
}

.ghost {
  background-color: transparent;
  color: tokens.$color-gray-600;
  border: none;
  box-shadow: none;
  overflow: hidden;

  &:disabled {
    color: tokens.$color-gray-300;
  }

  &:hover:not(:disabled) {
    background: tokens.$color-gray-50;
    color: tokens.$color-gray-700;
  }
}

.primary {
  background-color: tokens.$color-orange-dark-400;
  color: tokens.$color-white;

  &:hover:not(:disabled) {
    background-color: tokens.$color-orange-dark-300;
    color: tokens.$color-white;
  }
}

.secondary {
  background-color: transparent;
  color: tokens.$color-blue-500;
  box-shadow: inset 0 0 0 2px tokens.$color-blue-500;

  &:hover:not(:disabled) {
    background-color: tokens.$color-blue-500;
    color: tokens.$color-white;
    box-shadow: inset 0 0 0 2px transparent;
  }
}

.default {
  background-color: tokens.$color-gray-600;
  color: tokens.$color-white;

  &:hover:not(:disabled) {
    background-color: tokens.$color-gray-500;
    color: tokens.$color-white;
  }
}

.info {
  background-color: tokens.$color-blue-500;
  color: tokens.$color-white;

  &:disabled {
    opacity: 0.4;
  }

  &:hover:not(:disabled) {
    background-color: tokens.$color-blue-600;
    color: tokens.$color-white;
  }
}

.sizeSmall {
  height: 30px;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  padding: 6px 12px;
}

.sizeMedium {
  height: 40px;
  padding: 10px 16px;
}

.fill {
  width: 100%;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  border-radius: tokens.$border-radius-200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.anchorButton {
  background-color: transparent;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-300;
  transition: all 0.2s;
  outline: 0;
  padding: 0;

  &:not(:disabled):hover {
    color: tokens.$color-gray-200;
    text-decoration: underline;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;

  .left {
    display: inline-flex; // in order to make svg take area only of its dimensions
    margin-right: $grid-base-value;
  }

  .right {
    display: inline-flex; // in order to make svg take area only of its dimensions
    margin-left: $grid-base-value;
  }
}
