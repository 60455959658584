@use '@restream/styles/scss/tokens';

.root {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 12px;
  color: tokens.$color-gray-700;
  padding: 12px 24px;
  align-items: center;
  border-bottom: solid 1px #d7dce3;

  .photo {
    width: 44px;
    height: 44px;
    background-position: center;
    background-size: cover;
    position: relative;

    & .imageLoaderWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: tokens.$color-gray-50;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.2s ease-in-out;

      &.hidden {
        opacity: 0;
      }
    }
    & .imageLoader {
      & circle {
        stroke: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .data {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    .title {
      font-weight: 500;
      display: block;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 2.8em;
      line-height: 1.4em;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .button {
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
    -webkit-appearance: none;
    color: tokens.$color-blue-700;
    display: flex;
    background: #eff4ff;
    border-radius: tokens.$border-radius-200;
    position: relative;
    border: tokens.$border-width solid tokens.$color-blue-200;
    padding: 8px 16px 8px 16px;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    transition: background-color 0.2s ease-in-out;

    .loader {
      width: 26px !important;
      height: 26px !important;
      position: absolute;
      top: calc(50% - 13px);
      left: calc(50% - 13px);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      circle {
        transition: stroke 0.1s ease-in-out;
        color: tokens.$color-blue-700;
      }
    }

    &:disabled {
      cursor: default;
    }

    span {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    &.loading {
      span {
        opacity: 0;
      }
      .loader {
        opacity: 1;
      }
    }

    &:not(:disabled):hover {
      // TODO(color): use token
      background: #dee5ff;
    }

    &.alreadyAdded {
      background: tokens.$color-gray-50;
      transition: 0.2s background ease-in-out, 0.2s border ease-in-out,
        color 0.2s ease-in-out;
      border: tokens.$border-width solid tokens.$color-gray-300;
      padding: 8px 20px 8px 20px;
      position: relative;
      color: tokens.$color-gray-700;

      .loader {
        circle {
          stroke: tokens.$color-gray-700;
        }
      }

      .onHover {
        opacity: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        transition: 0.2s opacity ease-in-out;
      }

      .addedWrapper {
        display: flex;
        align-items: center;
        transition: 0.2s opacity ease-in-out;
        .okImg {
          width: 16px;
          margin-right: 4px;
          height: auto;
        }
      }

      &:not(:disabled):hover {
        border: tokens.$border-width solid rgba(205, 32, 31, 0.8);
        background: rgba(205, 32, 31, 0.1);
        color: #cd201f;
        cursor: pointer;

        .addedWrapper {
          opacity: 0;
        }

        .onHover {
          opacity: 1;
        }
      }
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
