@use '@restream/styles/scss/tokens';

.secondaryButton {
  padding: 8px 14px;
  margin-right: 12px;
  -webkit-appearance: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #004eeb;
  background: #eff4ff;
  border: 1px solid #b2ccff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: tokens.$border-radius-200;
  position: relative;
  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  cursor: pointer;

  .hidden {
    opacity: 0;
  }

  .loader {
    position: absolute;
  }

  &:hover:not(:disabled) {
    background: #dee5ff;
  }

  &:disabled {
    opacity: 0.9;
  }
}
