@use '@restream/styles/scss/tokens';

$height: 40px;

.root {
  display: grid;
  grid-gap: 16px;
  max-width: 360px;
}

.labelText {
  align-items: center;
  display: flex;
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a5adba;
}

.inputWrapper {
  height: $height;
  color: tokens.$color-white;
  // TODO(color): use token
  background-color: #253858;
  cursor: text;

  &.invalid {
    box-shadow: 0 0 0 1px #fc8471 !important;
    border-color: transparent;
  }
}

.button {
  margin: 8px 0;
  padding: 0;
  height: $height;
}

.error {
  color: tokens.$color-white;
  background-color: rgba(222, 53, 11, 0.16);
  border: none;
}
