@use '@restream/styles/scss/tokens';

.root {
  background-color: tokens.$color-gray-50;
  color: #212121;
  min-height: 100vh;
  padding: 24px 0;

  .content {
    padding: 0 24px;
    max-width: 768px;
    margin: 0 auto;

    .products {
      display: grid;
      grid-gap: 8px;
      grid-template-columns: 1fr 1fr;
      padding-top: 128px;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  }
}
