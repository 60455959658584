@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.main,
.footer {
  display: flex;
  justify-content: center;
}

.main {
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 36px;

  & .text {
    margin: 18px 0;
    max-width: 290px;
  }
}

.title {
  color: tokens.$color-gray-900;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

  text-align: center;
  margin: 0;
}

.text {
  font-size: 14px;
  color: tokens.$color-gray-700;
  line-height: 20px;

  margin: 0;
  text-align: center;

  & .bold {
    font-weight: 500;
  }
}

.button {
  color: tokens.$color-blue-500;
  background: transparent;
  padding: 0;
  transition: color 0.2s ease-out;

  &:hover:not(&:disabled) {
    background: transparent;
    color: tokens.$color-blue-600;
  }
}

.icon {
  width: 48px;
  color: tokens.$color-blue-500;
}
