.body {
  display: grid;
  grid-gap: 12px;
  padding: 24px 24px 12px;
}

.inputWrapper {
  height: 40px;
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
}

.button {
  height: 40px;
}

.cancelButton {
  color: #505f79;
  background-color: transparent;

  &:hover {
    color: #505f79 !important;
    background-color: rgba(9, 30, 66, 0.08) !important;
  }
}
