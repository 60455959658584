.root {
  overflow: hidden;
}

.root[data-state='open'] {
  animation: openAnimation 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.root[data-state='closed'] {
  animation: closeAnimation 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

@keyframes openAnimation {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes closeAnimation {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}
