@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.button {
  width: 100%;

  padding: 12px 16px;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid transparent;

  background: #2970ff;
  color: #fff;

  &:hover {
    background-color: #155eef;
    color: #fff;
  }

  &:last-of-type {
    border: 1px solid #b2ccff;
    margin-top: 10px;
    color: #004eeb;
    background: #eff4ff;

    &:hover {
      color: #0040c1;
      background: #d1e0ff;
      border: 1px solid #b2ccff;
    }
  }
}
