@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../../scss/mixins' as mixins;
@import '../constants';
@import '../../constants';

$borderRadius: 32px;

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.wrapWithShadow {
  background: linear-gradient(
    90deg,
    rgba(14, 134, 210, 0.16) -0.12%,
    rgba(23, 82, 193, 0.16) 31.44%,
    rgba(73, 17, 209, 0.16) 64.06%,
    rgba(171, 36, 219, 0.16) 97.02%
  );
  box-shadow: inset 0 0 16px 16px rgba(23, 43, 77, 100%);
  border-radius: 40px;
  padding: 0 $emptyEventsBlockShadowWidth;
  width: 100%;
}

.wrapWithBorder {
  @include mixins.display-flex-center;
  background: linear-gradient(
      79.1deg,
      #72f8fd -18.07%,
      #5dbbf7 19.47%,
      #235fcf 45.61%,
      #5d2cd2 76.44%,
      #c43ff3 110.63%
    ),
    linear-gradient(102.33deg, rgba(21, 112, 239, 0.8) 0%, #53b1fd 100%);
  border-radius: $borderRadius;
  padding: 1px;
  width: 100%;
  overflow: visible;
}

.block {
  @include mixins.display-flex-center;
  flex-direction: column;
  justify-content: center;
  background: #172b4d;
  border-radius: $borderRadius;
  box-sizing: border-box;
  min-height: 320px;
  width: 100%;

  @media (max-width: $smallMobileMaxWidth) {
    padding: 42px 20px;
    min-height: 260px;
  }
}

.title {
  color: tokens.$color-gray-100;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  @media (max-width: $smallMobileMaxWidth) {
    font-size: 18px;
    line-height: 28px;
  }
}

.subtitle {
  color: tokens.$color-gray-400;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0 28px;
  text-align: center;

  @media (max-width: $smallMobileMaxWidth) {
    font-size: 14px;
    line-height: 20px;
    width: 200px;
  }
}

.helpText {
  color: tokens.$color-gray-400;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
}

.demoButton {
  @include mixins.button-reset;
  color: tokens.$color-blue-300;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: lighten(tokens.$color-blue-300, 5%);
  }
}

.demoFileSkeleton {
  height: 10px;
  width: 170px;
  margin-top: 15px;
  background: $skeletonBackground;
}
