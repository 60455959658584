.root {
  position: relative;
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: white;
  z-index: 11;
}

:global(.dark-theme) {
  .loaderWrapper {
    background: #172b4d;
  }
}
