@use '@restream/styles/scss/tokens';
@use '../../scss/grid' as grid;
@use '../../scss/colors' as colors;

.button-link {
  background: colors.$primary-color;
  color: colors.$fg-color;

  background-clip: padding-box;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
  outline: 0;
  padding: grid.$grid-base-value grid.$grid-base-value * 2;
  border-radius: tokens.$border-radius-200;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all 0.2s;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
}

.button-link:focus {
  box-shadow: 0 0 0 1px colors.$ui-focus-color;
}

.button-link:hover:not(:disabled) {
  background: lighten(colors.$primary-color, 5%);
}

.button-link:disabled {
  cursor: default;
  opacity: 0.7;
}

.button-link--outline {
  background: none;
  border-radius: tokens.$border-radius-200;
  box-shadow: inset 0 0 0 1px #ffffff;

  color: #ffffff;
}

.button-link--outline:hover:not(:disabled) {
  background: none;
}

.content {
  display: flex;

  .right {
    margin-left: grid.$grid-base-value;
  }
}
