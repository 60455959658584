.content {
  border-top: 1px solid #e0e0e0;
}

.searching {
  opacity: 0.5;
}

.scrollView {
  max-height: 200px;
  overflow: auto;
}

.icon {
  opacity: 0.5;
}
