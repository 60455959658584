@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  padding-left: 24px;
  padding-right: 24px;

  .link {
    color: #286fff;
    position: unset;
    text-decoration: underline;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.toggleWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 2px;
}

.cardFormGroup {
  width: 100%;

  &.editMode {
    margin-bottom: 2px;
    padding-bottom: 0;
  }
}

.cardCancelChangingWrapper {
  display: flex;
  justify-content: flex-end;
  .cardCancelChanging {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #286fff;
    margin-top: 8px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      text-decoration: underline;
      opacity: 0.9;
    }
  }
}
//@media screen and (max-width: 576px) {
//  .paymentRequestWrapper {
//    max-width: 452px;
//    overflow: hidden;
//  }
//}

.additionalInfoFormGroup {
  width: 100%;
  flex-direction: row;
}

.countrySelectWrapper {
  width: 70%;
  transition: width 0.2s ease-in-out;
  &.unwrapped {
    transition: width 0.2s ease-in-out;
    width: 100%;
  }
}

.countrySelectPlaceholder {
  color: rgba(9, 30, 66, 0.25);
}

:global(.dark-theme) {
  .countrySelectPlaceholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

.zipInput {
  padding: 0;
}

.zipInputWrapper {
  width: calc(30% - 20px);
  transform: translate(0%, 0%);
  margin-left: 20px;
  position: relative;
  opacity: 1;
  right: 0;
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  &.hidden {
    opacity: 0;
    transition:
      transform 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    position: absolute;
    transform: translate(130%, 0%);
  }
}

.cardInputBase {
  background: white;
  border-radius: tokens.$border-radius-200;
  padding: 10px 12px;
  box-shadow: inset 0 0 0 1px #a5adba;
}

.cardInputDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.cardInputError {
  box-shadow: inset 0 0 0 1px #ff004a;
}

.cardInputFocus:not(.cardInputError) {
  box-shadow: inset 0 0 0 1px #286fff;
}

:global(.dark-theme) {
  .cardInputBase {
    background: #253858;
    box-shadow: inset 0 0 0 1px #253858;
  }

  .cardInputError {
    box-shadow: inset 0 0 0 1px #fc8471;
  }

  .cardInputFocus:not(.cardInputError) {
    box-shadow: inset 0 0 0 1px #286fff;
  }
}

.button {
  padding: 10px 12px;
  line-height: 20px;
  margin-bottom: 20px;
}

.button.zeroMargin {
  margin-bottom: 0;
}

.total {
  span {
    font-weight: bold !important;
  }
}
.totalContainer {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 16px;
}

.selectedPlan {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  margin: 0 0 8px;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: #172b4d;

  .price {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.01em;
    text-align: left;

    color: inherit;
  }
}

:global(.dark-theme) {
  .selectedPlan {
    background: #253858;
    border: 1px solid lighten(#253858, 7%);
    color: #f5f5f5;
  }
}

.promoCodeButtonWrapper {
  position: relative;
  min-height: 40px;
}

.promoCodeButtonWrapper.noHeight {
  min-height: unset;
}

.promoCodeError {
  padding: 0;
  font-size: 12px;
  color: #fc8471;
  margin: 4px 0 0;
}

.promoCodeButton {
  position: absolute;
  top: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  background: none;
  cursor: pointer;

  flex-grow: 0;
  border: none;
  color: #286fff;

  &:hover {
    text-decoration: underline;
  }

  transition: opacity 200ms;
}

.promoCodeButton:disabled {
  cursor: not-allowed;
}

.promoCodeButton.remove {
  color: #286fff;
  position: unset;
  margin-left: 22px;
  width: fit-content;
}

.separator {
  width: 100%;
  opacity: 0;
  margin: 0 0 20px;
}

:global(.dark-theme) {
  .separator {
    opacity: 0;
  }
}

.promoCode {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promoCodeInputWrapper {
  display: flex;
  align-items: center;

  width: 100%;
}

.promoCodeInputRoot {
  flex: 2;
  margin-right: 20px;
}

.promoCodeInput {
  padding: 0;
}

.promoCodeButtonsWrapper {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promoCodeApplyButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 10px 10px 10px 0;
  margin: 0;
  color: #286fff;
}

.promoCodeApplyButton:disabled {
  color: #a5adba;
  cursor: not-allowed;
}

.promoCodeForm {
  display: contents;
}

.hidden {
  opacity: 0;
  cursor: default;
}

.closeButton {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.closeButton:disabled {
  cursor: not-allowed;
}

.discountTitleWrapper {
  display: flex;
  flex-direction: column;
}

.errorCalloutDescription {
  color: #ff004a;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin: 0;
}

:global(.dark-theme) {
  .errorCalloutDescription {
    color: #fc8471;
  }
}

ul.errorCalloutDescription {
  list-style-type: none;
}

.securityTipWrapper {
  display: flex;
  justify-content: center;
}

.securityTip {
  padding: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;

  margin: 0 0 0 4px;

  color: #8993a4;
}

.buttonLoader {
  margin-right: 8px;
}

.existingCard {
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #172b4d;
  margin: 12px 0 20px;

  .link {
    color: #286fff;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.zeroMarginTop {
    margin-top: 0;
  }
}

:global(.dark-theme) {
  .existingCard {
    color: #f5f5f5;
  }
}

.detailsLoaderWrapper {
  width: calc(100% - 40px);
  left: 20px;
  background: #fafbfc;
  min-height: 40px;
}

.paymentRequestOrCard {
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;

  &:before,
  &:after {
    position: absolute;
    background: #ebecf0;
    content: ' ';
    width: calc(50% - 110px);
    height: 1px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.triggerBillButton {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 12px;
  line-height: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  cursor: pointer;
  color: #286fff;
  padding-left: 24px;
  padding-right: 24px;

  &:hover {
    text-decoration: underline;
  }
}

.pricing {
  margin-bottom: 28px;
}

.description {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #8993a4;
  text-align: left;

  padding: 0;
  margin: 0 0 20px;

  a {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;

    text-decoration: underline;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}

.transcodingPriceList {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 16px;
}

.transcodingPrice {
  margin-bottom: 8px;
}

.price {
  margin-left: 8px;
}

.price__active {
  font-weight: 500;
}

.inputRadio {
  height: 21px;
  width: 21px;
}
.labelInputRadio {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.9;
  }
}

.clipsFiledSet {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  margin: 24px 0 16px;

  label:not(:last-child) {
    margin-right: 0;
  }
}

// todo clips refactor (remove after ui-kit ghost button improvements)
.skipButton {
  background: transparent;
  color: #42526e;
  text-align: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  margin-right: 8px;

  &:hover:not(&.disabled) {
    border-radius: 8px;
    background: var(--Gray-50, #f5f6f8);

    color: var(--Gray-700, #2b3d5c);
  }
}
