.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.message {
  font-size: 16px;
  margin-bottom: 26px;
}

.icon {
  margin-bottom: 20px;
}
