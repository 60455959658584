.root {
  opacity: 1;
  transition: opacity 0.7s ease-in-out 0.5s;

  &.done {
    opacity: 0;
  }
}

.loaderWrapper {
  position: absolute;
}

.progressIcon {
  width: 40px;
  height: 40px;
  transform: rotate(-90deg);
}

.progressIconRotate {
  animation: processing 1.5s infinite;
}

@keyframes processing {
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(270deg);
  }
}

.default {
  fill: none;
  stroke: #deebff;
  stroke-width: 5;
}

.progress {
  stroke-dasharray: 100;
  fill: none;
  stroke: #286fff;
  stroke-width: 5;

  &.transition {
    transition: all 0.5s;
  }
}

.checkmarkIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #286fff;
  stroke-miterlimit: 10;
  fill: none;
  box-shadow: inset 0 0 0 #286fff;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px #deebff;
  }
}
