@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/colors' as colors;
@use '~@restream/styles/scss/media' as media;

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.placeholder,
.error {
  .title {
    visibility: hidden;
    line-height: 60px;
  }
}

.error {
  .text {
    font-size: 18px;
  }
}

.loader {
  .emptyList {
    background: transparent;
    border: none;
  }
}

.emptyList {
  display: flex;
  width: 100%;
  min-height: 147px;
  background: url('img/squid-star-bg-x2.png') no-repeat 50% 0;
  background-size: contain;
  background-position-x: right;

  border: 1px solid #344563;
  border-radius: tokens.$border-radius-200;

  padding: 50px 56px;

  @include media.sm {
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
  }
}

.info {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
}

.anchorButton {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.01em;
  color: colors.$color-border-default;
}

.titleBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.allEventsRedirect {
  margin-top: 22px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.allEvents {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  text-align: right;
  color: #97a0af;
  transition: all 0.1s ease-in;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
}

.allEventsAlt {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #bac1cc;
  transition: opacity 250ms ease;

  &:hover {
    opacity: 0.8;
  }
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #a7aeba;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;
  text-align: left;
  letter-spacing: 0.01em;

  color: #ebecf0;

  @include media.xl {
    text-align: center;
  }
}

.icon {
  position: relative;
  top: 1px;
}
