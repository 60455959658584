@use '@restream/styles/scss/tokens';
@use '../../../../scss/colors' as colors;
@use '../../../../scss/typography' as typography;

.info {
  @include typography.font-14;
  margin-bottom: 24px;

  .streamingSettingsTheme & {
    margin-top: 24px;
    margin-bottom: 12px;
    letter-spacing: 0.01em;
    font-weight: 400;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
    color: tokens.$color-gray-900;
  }
}

.link {
  // TODO(color): use token
  color: tokens.$color-blue-500;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

.icon {
  margin-right: 12px;
  fill: colors.$fg-color--darken-1;
}

.button-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: tokens.$color-gray-700;
}

.button-wrapper.zero-margin {
  margin-bottom: 0;
}

.button {
  padding: 12px 24px;
  transition: opacity 300ms ease;

  .streamingSettingsTheme & {
    height: 40px;
    // TODO(font): ues token
    font-size: 15px;
    padding: 8px 12px;
    background-color: tokens.$color-blue-500;
    border-radius: tokens.$border-radius-200;

    &:hover {
      opacity: 0.9;
      background-color: tokens.$color-blue-500;
    }

    &:disabled {
      opacity: 0.8;
    }
  }
}

.counter {
  margin-left: 8px;

  .streamingSettingsTheme & {
    font-weight: 400;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
    letter-spacing: 0.01em;
  }
}

.callout {
  .streamingSettingsTheme & {
    margin-bottom: 24px;
  }
}

.calloutDescription {
  font-size: 16px;
  color: #ff6633;

  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.upgrade {
  color: tokens.$color-blue-500;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.upgradeContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 12px;
  gap: 8px;
}
