.bold {
  font-weight: 500;
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
