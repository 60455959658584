@use '@restream/styles/scss/variables' as variables;

.root {
  min-width: 500px;
}

.modal-header {
  color: variables.$color-background-dark2;
}

.header {
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4;

  color: variables.$color-background-dark2;
  border-bottom: none;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  min-height: 120px;
}

.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.saveButton {
  margin-left: 10px;
  height: 38px;
}

.title {
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: variables.$color-background-dark;
}

.toast {
  display: flex;
  flex-direction: column;
}

.email {
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.01em;
  color: variables.$color-background-dark2;
  font-size: 1.1rem;

  margin-right: 16px;
}

.anchorButton {
  color: variables.$color-primary2;
  margin: 0 4px;
  transition: all 0.3s;
  font-weight: 500;

  &:hover {
    color: variables.$color-primary2;
    box-shadow: inset 0 -1px 0 0 variables.$color-primary2;
  }
}

.payout {
  display: flex;
  align-content: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;

  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 16px;
  padding-bottom: 8px;
}

.footer {
  text-align: center;
  min-height: 24px;
}

.error {
  color: red;
}

.loader {
  display: flex;
  height: 100%;
  align-items: center;

  .spinner > div {
    background-color: variables.$color-primary2;
  }
}
