@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 420px;
  min-width: 420px;

  padding: 20px;
  background: tokens.$color-blue-25;

  border: tokens.$border-width solid tokens.$color-blue-100;

  border-radius: tokens.$border-radius-400;
  box-sizing: border-box;
}

.cameraIcon {
  margin-bottom: 25px;
}

.tryDemoFileButtonWrapper {
  max-width: 260px;
  width: 100%;
}
