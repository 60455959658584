@use '@restream/styles/scss/tokens';

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.root {
  .btnClose {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 100;

    @include mobileMedia() {
      display: none;
    }
  }

  .onlyDesktop {
    @include mobileMedia() {
      display: none;
    }
  }

  .leftPart {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 24px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      color: #091e42;
    }

    @include mobileMedia() {
      padding-top: 18px;
    }
  }

  .services {
    position: relative;
    overflow: auto;
    height: 100%;
    width: 100%;
  }

  .servicesOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 10px;

    &.hidden {
      height: 0;
    }
  }

  .bottomGradient {
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    width: 100%;
    height: 22px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.0816599) 20%,
      rgba(255, 255, 255, 0.231775) 33.33%,
      rgba(255, 255, 255, 0.96449) 86.67%,
      #ffffff 100%
    );
  }

  .service {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    border-bottom: solid 1px #ededed;

    .actions {
      display: flex;
      align-items: center;
      .trashIconWrapper {
        display: flex;
        align-items: center;
        .trashIcon {
          width: 20px;
          fill: #e4e4e4;
          transition: fill 0.2s ease-in-out;
          margin-right: 6px;
          &:hover {
            fill: #cd201f;
            cursor: pointer;
          }
        }
      }
    }

    .leftSide {
      display: flex;
      align-items: center;
    }

    &:first-child {
      margin-top: 0;
    }

    .serviceImgWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: tokens.$border-radius-200;
      background: #f1f1f1;

      .serviceImg {
        max-width: 50px;
        width: auto;
        max-height: 50px;
        height: auto;
      }
    }

    .serviceContent {
      margin-left: 20px;

      .serviceName {
        color: #212121;
        font-size: 16px;
      }
    }

    .button {
      appearance: none;
      -webkit-appearance: none;
      border: none;
      background: transparent;
      color: #0a66c2;
      cursor: pointer;
      font-weight: 500;
      padding: 10px 12px;
      border-radius: tokens.$border-radius-200;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background: rgba(10, 102, 194, 0.1);
      }
    }
  }

  .triggerWrapper {
    width: 100%;
  }
  .buttonWrapper {
    width: 100%;
    margin-top: 24px;
    padding: 0 24px;

    .mainButton {
      font-size: 14px;
      text-align: center;
      width: 100%;
      -webkit-appearance: none;
      color: #004eeb;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eff4ff;
      border-radius: tokens.$border-radius-200;
      padding: 10px 0;
      position: relative;
      border: solid 1px #b2ccff;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      &.disabled {
        pointer-events: none;
        cursor: default;
        background: #f5f8ff;
        color: #84adff;
      }
      &:not(:disabled):hover {
        background: #dee5ff;
      }
      &:hover {
        background: rgba(10, 102, 194, 0.1);
      }
    }
  }
}

.addMoreBtn {
  appearance: none;
  -webkit-appearance: none;
  background: #fff;
  transition: background-color 0.2s ease-in-out;
  border: solid 1px rgba(186, 193, 204, 1);
  color: tokens.$color-gray-700;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: tokens.$border-radius-200;
  .addMoreBtnPlus {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: tokens.$color-gray-50;
  }
}

.loadingContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.errorWrapper {
  width: 100%;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  box-sizing: border-box;
  padding: 15px;

  & p {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #253858;
  }
}
