@use '@restream/styles/scss/media';
@use 'CampaignButton.module';

.root {
  border: none;
  appearance: none;

  background-position: center;
  background-size: cover;

  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(1, 12, 30, 0.12),
    0 11px 11px 0 rgba(1, 12, 30, 0.1), 0 25px 15px 0 rgba(1, 12, 30, 0.06),
    0 44px 18px 0 rgba(1, 12, 30, 0.02), 0 69px 19px 0 rgba(1, 12, 30, 0);

  overflow: hidden;
  padding: 2px;
  cursor: pointer;
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    aspect-ratio: 1 / 1;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    background-size: cover;
    background-position: center;

    transition: all 3s ease;
  }

  &:hover:not(&.fullWidth)::before {
    transform: rotate(45deg);
  }

  &.fullWidth {
    width: 100%;
    padding: 8px 16px;
    border-radius: 0;

    & .discount {
      display: inline-block;
      font-size: 18px;
      line-height: 24px;

      padding: 10px 22px;

      @include media.xs {
        font-size: 16px;
        padding: 8px 16px;
      }
    }

    &::before {
      height: 100%;
      aspect-ratio: initial;
    }
  }

  &.blackFriday {
    &::before {
      background-image: url('assets/blackFridayBackground.jpg');
    }

    &:hover {
      & .discount {
        background-color: #8ee600;
      }
    }

    & .discount {
      background-color: #a5f600;
    }
  }

  &.cyberMonday {
    &::before {
      background-image: url('assets/blackFridayBackground.jpg');
    }

    &:hover {
      & .discount {
        background-color: #00f1f4;
      }
    }

    & .discount {
      background-color: #00f1f4;
    }
  }
}

.overlay {
  display: inline-flex;
  align-items: center;

  background: #000;
  border-radius: 10px;
  padding: 2px;
  margin: auto;

  z-index: 1;
  position: relative;

  &.withTitle {
    @include media.xs {
      flex-direction: column;
    }
  }
}

.title {
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  text-shadow: 0 4px 4px rgba(255, 255, 255, 0.12);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  margin: 0 8px;
  overflow: hidden;

  @include media.xs {
    margin: 4px 0;
  }
}

.discount {
  @include CampaignButton.button;

  font-size: 14px;
  padding: 9px 12px;
}
