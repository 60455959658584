@use '@restream/styles/scss/tokens';

.linkInputContainer,
.linkInputWrapper {
  overflow: hidden;
}

.linkInput {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
}

.copyButton {
  height: 36px;
  margin: 16px 0;
  background: #2970ff;
  border-radius: tokens.$border-radius-200;
}

.pairsTip {
  margin: 8px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #505f79;
}

.pairsTipLink {
  color: #505f79;
  font-weight: 500;
  border-bottom: 1px solid;
}
