@use '@restream/styles/scss/tokens';
@use '../../scss/colors' as colors;
@use '../../scss/grid' as grid;

.container {
  background: colors.$bg-color--lighten-1;
  border-radius: tokens.$border-radius-200;
  padding: grid.$grid-base-value * 3;
  position: relative;
}

.container.disabled {
  opacity: 0.5;
}

.error {
  width: 4px;
  height: 100%;
  background: colors.$error-color;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-left-radius: tokens.$border-radius-200;
  border-top-left-radius: tokens.$border-radius-200;
}
