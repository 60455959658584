@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.root {
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  display: grid;
  overflow: hidden;
  grid-template-columns: minmax(auto, 504px) auto;

  &.singleView {
    grid-template-columns: 1fr;

    .leftPart {
      padding: 0 24px;
    }
  }

  @include mobileMedia() {
    max-width: 768px;
    min-width: 768px;
    height: calc(90vh - 84px);
    grid-gap: 10px;
    grid-template-rows: 1fr auto;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
  }

  .btnBack {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 100;

    @include mobileMedia() {
      display: none;
    }
  }

  .btnClose {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 100;

    @include mobileMedia() {
      display: none;
    }
  }

  @include mobileMedia() {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    height: 100%;
  }

  .leftPart {
    height: 680px;
    padding: 42px 0 0;
    width: 100%;
    max-width: 768px;
    position: relative;

    @include mobileMedia() {
      padding: 0;
      height: auto;
    }
  }
}
