@use '@restream/styles/scss/tokens';

.root {
  min-height: 24px;
  display: flex;
  align-items: center;
  opacity: 0.8;
  transition: opacity 300ms ease;

  &.disabled {
    opacity: 0.25;
  }
}

.status-wrapper {
  margin-right: 8px;
}

@keyframes opacity-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.icon {
  margin-right: 6px;

  &.blinking {
    animation: opacity-in-out 2s infinite;
  }
}

.title {
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;

  &.online {
    color: #36b37e;
  }

  &.connecting {
    color: #4c9aff;
  }

  &.offline {
    color: #6c798f;
  }

  &.unable {
    color: #ff7452;
  }
}

.divider {
  width: 1px;
  height: 12px;
  margin: 0 8px;
  background: tokens.$color-gray-600;
}

.transcodingButton {
  background: none;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;

  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all 0.2s;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;

  .title.online &:hover {
    text-decoration: underline;
  }
}
