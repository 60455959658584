@use '@restream/styles/scss/media';

.player {
  width: 100%;
  height: 100%;
  aspect-ratio: 9/16;

  border-radius: 8px;
  overflow: hidden;

  @include media.xs {
    display: none;
  }
}
