@use '@restream/styles/scss/tokens';

@property --buttonTopColor {
  syntax: '<color>';
  initial-value: #2970ff;
  inherits: false;
}

@property --buttonBottomColor {
  syntax: '<color>';
  initial-value: #155eef;
  inherits: false;
}

.root {
  padding: 24px;
  width: 252px;
  height: fit-content;

  background: tokens.$color-white;
  // TODO(color): use token
  border: tokens.$border-width solid tokens.$color-gray-200;
  background: var(--Base-White, #fff);
  box-sizing: border-box;
  border-radius: tokens.$border-radius-200;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

:global(.dark-theme) {
  .root {
    // TODO(color): use token
    background: #253858;
    border: tokens.$border-width solid lighten(#253858, 7%);
    color: #f5f5f5;
  }
}

.root.mostPopular {
  border: 2px solid tokens.$color-blue-600;
}

.badge {
  position: absolute;
  top: -21px;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  p {
    padding: 2px 6px;
    font-weight: 600;
    // TODO(font): use token
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;

    background: tokens.$color-blue-600;
    border-radius: tokens.$border-radius-100;
    color: tokens.$color-white;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
}

.lightBadge {
  p {
    background: #deebff;
    color: tokens.$color-blue-600;
  }
}

.title {
  // TODO(font): use token
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  padding: 0;
  margin: 0 0 16px;
  color: tokens.$color-gray-700;
}

:global(.dark-theme) {
  .title {
    color: #f5f5f5;
  }
}

.priceWrapper {
  position: relative;
}

.priceOld {
  position: absolute;
  // TODO(color): use token
  color: #ff4466;
  text-decoration-line: line-through;
  top: -8px;
  left: -24px;

  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;

  padding: 0;
  margin: 0;
}

.price {
  // TODO(font): use token
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  padding: 0;
  margin: 0 0 8px;
  color: tokens.$color-gray-900;

  .value {
    font-weight: 600;
    font-size: 24px;
  }
}

:global(.dark-theme) {
  .price {
    color: #f5f5f5;
  }
}

.priceYearly {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  min-height: 16px;
  text-align: center;
  color: tokens.$color-gray-600;
  margin: 0 0 16px;
}

:global(.dark-theme) {
  .priceYearly {
    color: #a5adba;
  }
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 8px 42px;
  margin-bottom: 20px;

  cursor: pointer;
  text-decoration: none;
  background: #286fff;
  border-radius: tokens.$border-radius-200;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  font-weight: 600;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  text-align: center;
  letter-spacing: 0.2px;

  background: tokens.$color-blue-50;
  color: tokens.$color-blue-700;
  border: 1px solid tokens.$color-blue-200;

  transition: all 300ms ease;

  &:not(:disabled):hover {
    color: tokens.$color-blue-800;
    background: tokens.$color-blue-100;
  }

  &:disabled {
    cursor: not-allowed;
    color: tokens.$color-blue-300;
    border-color: tokens.$color-blue-50;
    background: tokens.$color-blue-25;
  }
}

.button.mostPopular {
  color: white;
  border: 1px solid #0a56ed;
  background: linear-gradient(
    180deg,
    var(--buttonTopColor) 0%,
    var(--buttonBottomColor) 100%
  ) !important;

  transition:
    --buttonTopColor 0.2s,
    --buttonBottomColor 0.4s;

  &:not(:disabled):hover {
    --buttonTopColor: #155eef;
    --buttonBottomColor: #2970ff;

    color: white;
  }

  &:disabled {
    opacity: 0.2;
  }
}

:global(.dark-theme) {
  .button.mostPopular {
    color: inherit;
    background: #286fff;
    box-shadow: inherit;

    &:not(:disabled):hover {
      color: inherit;
      background: lighten(#286fff, 3%);
    }
  }
}

.description {
  display: grid;
  gap: 12px;
  margin: 0 auto 0 0;
  padding: 0;

  // TODO(font): use token
  font-size: 12px;
  letter-spacing: normal;
  line-height: 16px;
  color: tokens.$color-gray-700;

  list-style: initial;

  & .feature {
    font-size: 12px;
    letter-spacing: normal;
    color: tokens.$color-gray-700;

    margin-left: 10px;

    &.bold {
      list-style: none;
      margin-left: 0;
      text-transform: uppercase;
      font-weight: 600;
    }

    & + .bold {
      margin-top: 4px;
    }

    &.highlighted {
      list-style: none;
      position: relative;

      animation: highlight-text 0.5s calc(1s + (var(--index, 0) - 1) * 0.25s)
        forwards;

      & svg {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 2px;
        left: -14px;
        color: currentColor;
      }
    }
  }
}

@keyframes highlight-text {
  0% {
    transform: scale(1);
  }
  50% {
    color: #4338ca;
    font-weight: 500;
    transform: scale(1.05);
  }
  100% {
    color: #4338ca;
    font-weight: 500;
    transform: scale(1);
  }
}

:global(.dark-theme) {
  .description {
    // TODO(color): use token
    color: #a5adba;

    & .feature {
      color: #a5adba;
    }
  }
}

.iconWrapper {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.infoIcon {
  fill: tokens.$color-gray-500;
  width: 16px;
  height: 16px;
}

.tooltip {
  max-width: 400px;
}

.tooltipContent {
  // TODO(font): use token
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  padding: 0;
  color: tokens.$color-white;
}

.hiddenFeaturesButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  padding: 0;
  margin: 0 auto -8px;
  background: none;
  cursor: pointer;

  border: none;
  // TODO(color): use token
  color: #b1b1b1;

  &:hover {
    text-decoration: underline;
  }

  transition: opacity 200ms;
}

.hiddenFeatures {
  display: grid;
  gap: 12px;
}

.separator {
  border: none;
  width: 100%;
  height: 1px;
  background: tokens.$color-gray-150;
}
