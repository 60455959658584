@use '@restream/styles/scss/tokens';

.root {
  max-width: 800px;
}

.button {
  height: 40px;
}

.recoveryCodeModalRoot {
  width: 400px;
}

.disableModalRoot {
  width: 480px;
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content min-content;
  grid-gap: 12px;
}

.promoBanner {
  margin-bottom: 16px;
}

.titleWrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .title {
    color: tokens.$color-gray-25;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.authPromoIcon {
  width: 14px;
  height: 14px;
}

.authPromoWrapper {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.contactSalesLink {
  background: none;
  border: none;
  padding: 0;
  color: tokens.$color-blue-400;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}
