@use '@restream/styles/scss/media';
@use '@restream/styles/scss/variables';

@mixin fullHeightWithOffset($offset) {
  height: calc(100vh - $offset); // fallback if svh is not compatible
  height: calc(100svh - $offset);
}

.root {
  width: 800px;
  height: 700px;

  @include media.md {
    width: 720px;
  }

  @include media.md {
    width: 540px;

    max-height: 90%;
  }

  @include media.xs {
    @include fullHeightWithOffset(variables.$modal-view-top-bottom-margins-sum);

    width: 100%;
    max-height: 90%;
  }

  &.isFullHeightOnMobile {
    @include media.xs {
      @include fullHeightWithOffset(0px);
    }
  }
}

.modalButtonsWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 11;

  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
