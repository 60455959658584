@use '@restream/styles/scss/tokens';

$card-height: 60px;
$card-width: 60px;

@property --rotate {
  syntax: '<angle>';
  initial-value: 132deg;
  inherits: false;
}

.root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  transition: opacity 200ms ease;
  background-color: tokens.$color-gray-800;

  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
}

.card {
  color: #fff;
  background: #000000;
  width: $card-width;
  height: $card-height;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  padding: 12px 16px;
  border-radius: 50%;
  box-sizing: border-box;
  font-family: arial, sans-serif;
  font-size: 34px;
  font-weight: bold;
  margin: 0 auto;
  transition: all 1s;
  background: rgba(0, 0, 0, 0.7);

  & .text {
    position: relative;
    z-index: 10;
    transform: scale(0.8);
    top: 4px;
  }

  &::before {
    content: '';
    width: calc($card-width + 8px);
    height: calc($card-height + 8px);
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    top: -4px;
    left: -4px;
    background-image: linear-gradient(
      var(--rotate),
      #67e3f9,
      #155eef 43%,
      #d444f1
    );
    animation: spin 1.5s linear infinite;
  }
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}
