@use '@restream/styles/scss/tokens';

.button {
  display: flex;
  border-radius: tokens.$border-radius-200;
  border: none;

  background: none;

  padding: 8px;
  margin: 0;
  cursor: pointer;

  transition: all 0.2s;

  background: rgba(20, 20, 20, 0.5);
  backdrop-filter: blur(4px);

  &:hover,
  &:focus-visible {
    .icon {
      opacity: 1;
    }
  }

  &:focus-visible {
    outline: 1px solid grey;
  }
}

.icon {
  transition: all 0.2s;
  fill: tokens.$color-white;
  pointer-events: none;
  opacity: 0.7;
}

.title {
  opacity: 0.8;
  color: tokens.$color-white;
  margin-left: 6px;
  font-size: 14px;
}
