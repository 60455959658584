.container {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 4px;
}

.viewersCount {
  font-size: 12px;
  font-weight: normal;
  color: #7a869a;
}

.channels {
  max-width: 170px;
  padding: 10px 14px 12px;
}

.channelItem {
  display: flex;
  align-items: center;
  padding: 2px 0;
  width: 100%;
  color: #091e42;
}

.channelItemIcon {
  display: flex;
  width: 16px;
}

.channelItemIconImage {
  width: 100%;
}

.channelItemName {
  padding: 0 8px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channelItemViewers {
  padding: 0 4px;
  font-weight: 500;
}

.counterContainer {
  display: flex;
  align-items: center;
}
