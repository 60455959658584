.root {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: #97a0af;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
