@use '../../../../scss/mixins' as mixins;

@import '../../../../scss/colors';
@import '../constants';
@import '../../constants';

.root {
  @include mixins.display-flex-center;
  flex-direction: column;
  justify-content: center;
  background: #172b4d;
  gap: 12px;
  padding: 20px;
  width: calc(100vw - #{$productMenuWidth});

  position: fixed;
  bottom: 0;
  z-index: 9;

  @media (max-width: $smallMobileMaxWidth) {
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 20px;
    width: 100%;
  }
}

.mobileButtonWrap {
  width: 50%;
}
