@use '@restream/styles/scss/tokens';

.root {
  height: 92px;
  position: relative;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  border-radius: 6px;

  // this is kind of hack because there was no way found how to pass hover colors on jsx
  // that is the reason why there was no point in passing default bg colors as an inline css
  &.facebook {
    background-color: #edf4fe;

    &:hover:not(:disabled) {
      background-color: #e3effd;

      & .badge {
        border-color: #e3effd;
      }
    }
  }

  &.twitch {
    background-color: #f7f1ff;

    &:hover:not(:disabled) {
      background-color: #f1e8ff;
    }
  }

  &.twitter {
    background-color: #edf7fe;

    &:hover:not(:disabled) {
      background-color: #e3f3fd;
    }
  }

  &.youtube {
    background-color: #fff0f0;

    &:hover:not(:disabled) {
      background-color: #ffe6e6;
    }
  }

  &.linkedin {
    background-color: #ecf3fb;

    &:hover:not(:disabled) {
      background-color: #e1ecf7;
    }
  }

  &:last-of-type {
    border: 1px solid tokens.$color-gray-100;
    background-color: tokens.$color-white;

    &:hover:not(:disabled) {
      background-color: tokens.$color-gray-25;
    }
  }
}

.content {
  flex-direction: column;
}

.badge {
  display: flex;
  position: absolute;
  top: -10px;

  background-color: white;
  padding: 2px 8px;
  border-radius: 6px;

  &.facebook {
    border: 2px solid #edf4fe;
  }
}

.badgeText {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: tokens.$color-blue-500;
}

.iconWrapper {
  width: 28px;
  height: 28px;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  margin-bottom: 14px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: tokens.$color-gray-700;
}
