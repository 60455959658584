@use '@restream/styles/scss/tokens';

.dropdown {
  width: 112px;
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  border-radius: tokens.$border-radius-200;
  background: #fff;
  animation: fadeIn 300ms ease;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.43);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.43);

  .dropdownItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    border: none;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    color: #091e42;

    &:hover {
      background: #deebff;
      color: #286fff;
    }

    &.remove:hover {
      color: #fb5630;
      background-color: #ffebe6;
    }

    &.disabled {
      color: #b1b1b2;
      cursor: default;
      &:hover {
        background: transparent;
        color: #b1b1b2;
      }
    }

    & span {
      margin-left: 10px;
    }
  }
}
