@use '@restream/styles/scss/tokens';

.image {
  width: 100%;
  margin-bottom: 12px;
  height: 255px;
}

.emoji {
  margin-right: 6px;
}

.body {
  padding-bottom: 0;
}

.button {
  width: 100%;
}

.copyInput {
  filter: blur(1px);
  width: 100%;
  background-color: tokens.$color-gray-50;
  margin-bottom: 24px;
}
