@import '@restream/styles/scss/media';

$min-width-desktop: 500px;

@mixin modal-width($width: $min-width-desktop) {
  width: $width;

  @include xs {
    width: 100%;
  }
}
