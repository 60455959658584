.root {
  margin-bottom: 10px;
  .importButtonsGroup {
    display: flex;
    flex-flow: column;
    align-items: center;
    .button {
      font-size: 14px;
    }
    .splitter {
      margin: 20px 0;
      font-weight: 300;
      color: #a5adba;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}
