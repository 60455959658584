.root {
  max-width: 100vw;
  min-height: 150px;

  @media (min-width: 576px) {
    width: 500px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px auto 16px;
}

.buttonWrapper {
  width: 100%;
  margin-top: 24px;
  padding: 0 24px;

  .mainButton {
    width: 100%;
  }
}

.heading {
  color: rgba(23, 43, 77, 1);
  font-size: 16px;
  letter-spacing: 0.01em;
  text-align: center;
}
