@use '@restream/styles/scss/variables' as variables;
@use '~@restream/styles/scss/media' as media;
@use '~@restream/styles/scss/colors' as colors;

.header {
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4;

  color: variables.$color-background-dark2;
  border-bottom: none;
}

.facebookButton {
  background: #3b5998;
  color: colors.$color-dark-fg;
  border: 1px solid #3b5998;
  padding-left: 20px;
  margin: 0 5px 16px 0;
  display: flex;
  align-items: center;
  max-width: 215px;
  width: 100%;

  &:hover {
    background: #3c83c3 !important;
    border: 1px solid #3c83c3 !important;
    color: colors.$color-dark-fg;
  }

  &:before {
    background: url(../../img/facebook.svg) no-repeat 50% 50%;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    content: '';
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: -10px;
  }
}

.twitterButton {
  background: #1da1f2;
  color: colors.$color-dark-fg;
  border: 1px solid #1da1f2;
  padding-left: 20px;
  margin: 0 5px 16px 0;
  display: flex;
  align-items: center;
  max-width: 215px;
  width: 100%;

  &:before {
    background: url(../../img/twitter.svg) no-repeat 50% 50%;
    width: 24px;
    height: 24px;
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    content: '';
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: -10px;
  }
}

.linkedInButton {
  background: #2867b2;
  color: colors.$color-dark-fg;
  border: 1px solid #2867b2;
  padding-left: 20px;
  margin: 0 5px 16px 0;
  display: flex;
  align-items: center;
  max-width: 215px;
  width: 100%;

  &:before {
    background: url(../../img/linkedin.svg) no-repeat 50% 50%;
    width: 24px;
    height: 24px;
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    content: '';
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: -10px;
  }
}

.emailButton {
  background: #989493;
  color: #fff;
  border: 1px solid #989493;
  padding-left: 20px;
  margin: 0 5px 16px 0;
  display: flex;
  align-items: center;
  max-width: 215px;
  width: 100%;

  &:hover {
    background: #767271 !important;
    color: colors.$color-dark-fg;
  }

  &:before {
    background: url(../../img/email.svg) no-repeat 50% 50%;
    width: 24px;
    height: 24px;
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    content: '';
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: -10px;
  }
}

.messengerButton {
  background: #0a7cff;
  color: colors.$color-dark-fg;
  border: 1px solid #0a7cff;
  padding-left: 20px;
  margin: 0 5px 16px 0;
  display: flex;
  align-items: center;
  max-width: 215px;
  width: 100%;

  &:before {
    background: url(../../img/messenger.svg) no-repeat 50% 50%;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    content: '';
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: -10px;
  }
}

.redditButton {
  background: #ff4500;
  color: colors.$color-dark-fg;
  border: 1px solid #ff4500;
  padding-left: 20px;
  margin: 0 5px 16px 0;
  display: flex;
  align-items: center;
  max-width: 215px;
  width: 100%;

  &:hover {
    background: #e63800 !important;
    color: colors.$color-dark-fg;
  }

  &:before {
    background: url(../../img/reddit.svg) no-repeat 50% 50%;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    content: '';
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: -10px;
  }
}

.whatsAppButton {
  background: #128c7e;
  color: colors.$color-dark-fg;
  border: 1px solid #128c7e;
  padding-left: 20px;
  margin: 0 5px 16px 0;
  display: flex;
  align-items: center;
  max-width: 215px;
  width: 100%;

  &:hover {
    background: #12ad9f !important;
    border: 1px solid #12ad9f !important;
    color: colors.$color-dark-fg;
  }

  &:before {
    background: url(../../img/whats_app.svg) no-repeat 50% 50%;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    content: '';
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: -10px;
  }
}
.gmailButton {
  background: #4285f4;
  color: colors.$color-dark-fg;
  border: 1px solid #4285f4;
  padding-left: 20px;
  margin: 0 5px 16px 0;
  display: flex;
  align-items: center;
  max-width: 215px;
  width: 100%;

  &:before {
    background: #fff url(../../img/gmail.svg) no-repeat 50% 50%;
    background-size: 16px 16px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border-left: 0;
    border-top: 0;
    content: '';
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: -10px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  max-width: 450px;
  flex-wrap: wrap;
  justify-content: space-between;
}
