@use '../../../scss/modals' as modals;

.root {
  @include modals.modal-width;
}

.modalHeader {
  color: #172b4d;
}

.cancelButton {
  color: var(--Gray-700, #2b3d5c);
  box-shadow: inset 0 0 0 1px #ffffff;
  margin-right: 12px;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f5f6f8 !important;
  }
}

.submitButton {
  background: #d92d20;
  padding: 10px 16px;

  color: var(--Base-White, #fff);
  text-align: center;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: lighten(#d92d20, 10%) !important;
  }
}

.modalBody {
  padding: 20px;
}

.modalFooter {
  padding: 0 20px 20px 20px;
}
