@use '~@restream/styles/scss/media' as media;

.root {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
}

.content {
  width: 520px;
  padding-top: 140px;

  @include media.sm {
    width: 100%;
    padding: 50px 15px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.headerContainer {
  margin-bottom: 32px;
}

.header {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #ebecf0;
  margin-bottom: 4px;
}

.subheader {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #a5adba;
}

.steps {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #a5adba;
  margin-bottom: 32px;
}

.joinStreamButton {
  padding: 12px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.reminderText {
  margin-bottom: 32px;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  color: #ebecf0;
  font-weight: 500;
}
