@use '@restream/styles/scss/tokens';
@use 'sass:math';

$width: 50px;
$height: 24px;

$color: tokens.$color-blue-700;
$border-radius: tokens.$border-radius-200;
$border-width: 2px;

.boundaryContainer {
  height: 48px;
  position: absolute;
}

.timelineSliceItem {
  position: absolute;
  height: 48px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  border: $border-width solid lighten($color, 20%);
  border-radius: math.div($border-radius, 2);
  background: rgba(0, 78, 235, 0.25);

  &.selected {
    border-color: $color;
    background: rgba(0, 78, 235, 0.35);
  }
}

.border {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &.grabbing {
    background: rgba(0, 78, 235, 0.35);
  }
}

.timelineSliceItem.selected,
.timelineSliceItem:active {
  .handler {
    background-color: $color;
  }
}

.timelineSliceItem:hover:not(.selected) {
  .handler {
    background-color: lighten($color, 20%);
  }
}

.resizeHandlerWrapper {
  cursor: inherit !important;
}
