@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.root {
  display: flex;
  flex-direction: column;

  position: relative;
  height: 100vh;

  font-size: 16px;
  line-height: 24px;

  padding: 0 0 12px;
}

.header {
  margin-bottom: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @media (min-width: 1080px) {
    flex: 1;
    min-height: 0; // use as much height as flex grow allows (https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size)
    flex-direction: row;
  }
}

.blackFriday {
  align-self: center;
  margin-top: 12px;
  margin-bottom: 12px;
  display: none;

  @media screen and (max-width: 450px) {
    display: block;
    height: 64px;
  }
}

.sidebarWrapper {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  height: 100%;
  background: tokens.$color-gray-900;
  border-radius: tokens.$border-radius-200;
  overflow: hidden;

  margin: 12px 0 0 0;

  @media (min-width: 1080px) {
    // important to know width, we set Intercom with offset from this value
    width: 36%;
    padding-left: 0;
    margin: 0 0 0 12px;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 1.5rem; /* 24px */
    line-height: 1.5rem; /* 24px */

    display: flex;
    align-items: center;
  }
}

.main {
  position: relative;

  display: flex;
  flex-direction: column;

  min-width: 0;

  @media (min-width: 1080px) {
    flex: 2;
  }
}

.toastLink {
  /* stylelint-disable declaration-no-important */
  color: inherit !important;

  svg {
    margin-left: 8px;
    fill: #000;
  }
}

.controls {
  margin-top: 6px;
}

.toastLink {
  color: inherit !important;

  svg {
    margin-left: 8px;
    fill: tokens.$color-black;
  }
}

.streamingMonitor {
  margin-top: 16px;
}
