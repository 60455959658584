@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../../components/scss/modals' as modals;

.root {
  &.fullHeight {
    display: grid;
    grid-template-rows: min-content auto min-content;
    height: inherit;
    grid-template-columns: 100%;
  }

  &.mobileOnboardingRoot {
    @include media.xs {
      grid-template-rows: auto min-content;
    }
  }
  @include modals.modal-width(510px);
}

.modalHeaderAndBodyWrapper {
  @include media.xs {
    position: initial;
    overflow: auto;
    height: inherit;
    display: grid;
    grid-template-rows: max-content auto;
    width: 100%;
    grid-template-columns: 100%;
  }
}

.submitButtonWrapper {
  display: flex;
  position: relative;
}

.submitButtonLoader {
  position: absolute;
  left: -27px;
  top: -1px;
}

.checkbox {
  margin-right: 4px;
}

.checkboxFormGroup {
  align-items: center;
}

.uploadButton {
  height: 36px;
  color: tokens.$color-gray-700;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;

  padding: 7px 14px 7px 16px;
  border: 1px solid #bac1cc;
  border-radius: tokens.$border-radius-200;

  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

  transition: all 0.2s;

  &:not(&:disabled) {
    cursor: pointer;
  }

  &:disabled {
    color: tokens.$color-gray-300;
    background: tokens.$color-white;
    opacity: 1;
    cursor: not-allowed;

    .uploadIcon {
      path {
        stroke: #bac1cc;
      }
    }
  }

  &:not(&:disabled) {
    &:hover {
      color: tokens.$color-gray-800;
      background: tokens.$color-gray-50;

      .uploadIcon {
        & path {
          stroke: tokens.$color-gray-800;
        }
      }
    }
  }
}

.uploadIconWrapper {
  display: flex;
}

.modalHeader {
  &.mobileModalHeader {
    @include media.xs {
      position: sticky;
      top: 0;
      z-index: 5;

      background: tokens.$color-white;
      padding-bottom: 24px;
      border-radius: tokens.$border-radius-300 tokens.$border-radius-300 0 0;

      box-shadow: 0 20px 24px -4px rgb(16 24 40 / 8%);
    }
  }

  &.mobileOnboardingModalHeader {
    @include media.xs {
      position: initial;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
}

.modalBody {
  &.mobileModalBody {
    @include media.xs {
      padding: 24px 24px 0;
      max-height: calc(100vh - 385px);
      min-height: 295px;
      overflow: scroll;

      .fullHeight & {
        max-height: initial;
        min-height: initial;
        height: inherit;
      }
    }
  }

  &.mobileOnboardingModalBody {
    @include media.xs {
      overflow: initial;
      max-height: initial;
      min-height: initial;
    }
  }

  &.extendedOnboardingInterface {
    padding: 40px 40px 24px;
  }
}

.modalFooter {
  &.mobileModalFooter {
    @include media.xs {
      position: sticky;
      bottom: -1px;
      z-index: 3;

      background: tokens.$color-white;
      padding-top: 24px;
      border-radius: 0 0 tokens.$border-radius-300 tokens.$border-radius-300;

      box-shadow: 0 -20px 24px -4px rgb(16 24 40 / 8%);
    }
  }

  &.mobileOnboardingModalFooter {
    @include media.xs {
      border-top: tokens.$border-width solid tokens.$color-gray-100;
      box-shadow: none;
    }
  }

  &.extendedOnboardingInterface {
    padding: 24px 40px;
    border-top: tokens.$border-width solid tokens.$color-gray-100;
  }
}

.submitButton {
  &.fullWidthButton {
    @include media.xs {
      width: 100%;
      height: 36px;
      background: tokens.$color-blue-500;
      border-radius: tokens.$border-radius-200;
    }
  }

  &.extendedOnboardingInterface {
    font-weight: 500;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;

    background-color: tokens.$color-blue-600;
    border-radius: tokens.$border-radius-200;

    cursor: pointer;
    box-shadow: 0 1px 2px rgb(16 24 40 / 5%);

    &.noLoader {
      min-width: fit-content;
    }

    & .submitButtonIcon {
      display: block;

      & path {
        stroke: tokens.$color-white;
      }
    }
  }
}

.formGroup {
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;

    @media (max-width: 576px) {
      // these styles are from FormGroup container
      margin-bottom: 8px;
      padding-bottom: 16px;
    }
  }
}

.skipButton {
  border: none;
  background: none;
  margin: 0 20px 0 0;
  padding: 0;
  cursor: pointer;

  color: var(--Gray-550, #56647d);
  text-align: center;

  /* Body sm - 14/Medium */
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-9, 20px); /* 142.857% */

  &:hover {
    text-decoration: underline;
  }
}

.contactSalesButton {
  border: none;
  background: none;
  cursor: pointer;
  color: tokens.$color-red-500;
  text-align: left;
  font-size: inherit;
  text-decoration: underline;
  padding: 0;

  &:focus-visible {
    outline: none;
  }

  span {
    font-weight: 500;
  }
}

.concurrentError {
  text-wrap: pretty;
}
