.root {
  p {
    font-size: inherit;
    color: initial;
    padding: 0;
    margin: 10px 0;
    a {
      text-decoration: underline;
    }
  }
}
