@use '@restream/styles/scss/tokens';
@use '../../.././../../../components/scss/modals';

.root {
  @include modals.modal-width;
}

.callout {
  margin: 5px 0 25px;
}

.list {
  list-style: disc;
  margin: 8px 0 8px 20px;
}

.listItem {
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-700;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.link {
  color: inherit;
  text-decoration: underline;
}
