@use '@restream/styles/scss/mixins';

.modalTitle {
  @include mixins.truncate-text;
  line-height: normal;
}

.modalBody {
  padding-top: 16px;
}

.modalBodyContentWrapper {
  overflow: scroll;
}

.videoCard {
  .videoCardThumbnail {
    background-size: cover;
  }
}
