@use '@restream/styles/scss/tokens';

.button {
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 14px;
  height: 16px;
  border: 0 none;
  margin-top: 6px;
  background-color: transparent;

  .srOnly {
    display: none;
  }

  &:hover .buttonIcon,
  &[data-state='open'] .buttonIcon {
    background-color: #fff;
  }
}

.buttonIcon {
  content: ' ';
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  transition: color 0.2s linear;
  background-color: #6b778d;
}

.menuContent {
  z-index: 3;
}

.menuList {
  min-width: 200px;
  border-radius: tokens.$border-radius-200;
  padding: 8px 0;

  background: tokens.$color-white;
  box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.2);
}

.menuItem {
  color: tokens.$color-gray-700;
  font-weight: 500;
  font-size: 14px;

  padding: 8px 16px;
  margin: 0;
  border-radius: 0;

  &:hover {
    background-color: tokens.$color-gray-100;
  }
}

.menuItemDanger {
  color: tokens.$color-red-500;

  & svg path {
    fill: tokens.$color-red-500;
  }

  &:hover,
  &.focus {
    color: #fbfbfb;
    background-color: tokens.$color-red-500;

    & svg path {
      fill: #fbfbfb;
    }
  }
}
