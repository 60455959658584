@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.chatWrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: tokens.$border-radius-200;
  flex: 1;

  @include media.sm {
    flex: none;
    width: 100%;
    min-height: 300px;
  }
}

.chat {
  border-radius: tokens.$border-radius-200;
  opacity: 0;
  transform: translate(-50%, 0);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition-delay: 2s;
  z-index: 2;
  position: relative;

  &.loaded {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.chatBackground {
  background: #112849;
  border-radius: tokens.$border-radius-200;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
