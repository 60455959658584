.eventsList {
  gap: 12px;
  padding-bottom: 80px;
}

.highlighted {
  .highlightedItem {
    animation: highlight 0.35s ease-out calc(var(--i) * 0.01s);
  }
}

@keyframes highlight {
  0% {
    filter: brightness(1);
  }
  35% {
    filter: brightness(1.1);
  }
  100% {
    filter: brightness(1);
  }
}
