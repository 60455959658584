@use '@restream/styles/scss/tokens';

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.root {
  @include mobileMedia() {
    .leftPart {
      padding: 0 24px;
    }
  }

  .leftPart {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;

    .contentWrapper {
      min-width: 472px;
      box-sizing: border-box;
      padding: 40px 0 0;
      width: 100%;

      @media (min-width: 576px) {
        padding-left: 19%;
        padding-right: 19%;
      }

      @media (max-width: 765px) {
        min-width: 100%;
      }

      @media screen and (max-width: 576px) {
        padding: 0 24px;
      }
    }

    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      &.requireShopDomain {
        & > .domainInputWrapper {
          order: 1;
        }

        & > .lineDivider {
          order: 2;
        }

        & > .connectButton {
          order: 3;
          margin-top: 0;
        }

        & > .textDivider {
          order: 4;
        }

        & > .connectButton.manualButton {
          order: 5;
          margin-top: 12px;
        }

        & > .scrapeLoadingText {
          order: 6;
        }
      }

      &.disableOAuth {
        & > .lineDivider {
          display: none;
        }

        & > .textDivider {
          display: none;
        }

        & > .connectButton:not(.manualButton) {
          display: none;
        }
      }
    }

    .platformImgWrapper {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 48px;
      margin-top: 39px;

      & .platformImg {
        width: auto;
        height: auto;
        max-height: 48px;
        max-width: 200px;
      }

      &.small {
        height: 24px;
        width: initial;
        margin-top: 0;

        & .platformImg {
          max-height: 24px;
        }
      }

      & .imageLoaderWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fafbfc;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.2s ease-in-out;

        &.hidden {
          opacity: 0;
        }
      }
      & .imageLoader {
        & circle {
          stroke: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .domainInputWrapper {
      width: 100%;
      min-width: 100%;
      margin-top: 27px;
    }

    .inputLabel {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: tokens.$color-gray-700;
    }

    .lineDivider {
      width: 100%;
      height: 1px;
      background-color: #ebecf0;
      margin: 32px 0;
      position: relative;

      &::after {
        content: 'OR';
        width: 74px;
        height: 24px;
        position: absolute;
        left: calc(50% - 37px);
        top: -12px;
        background-color: #fff;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #d7dce3;
      }

      &.noAfter::after {
        display: none;
      }
    }

    .textDivider {
      width: 100%;
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      color: #bac1cc;
      margin-top: 12px;

      display: none;

      &.visible {
        display: inline-block;
      }
    }

    .connectButton {
      margin-top: 27px;
      transition: opacity 150ms ease-in-out;

      @media (min-width: 576px) {
        height: 40px;
      }

      &:hover:not(:disabled) {
        opacity: 0.9;
      }

      &:disabled {
        opacity: 0.4;
      }

      &.manualButton {
        margin-top: 12px;
        background: #eff4ff;
        border: 1px solid #b2ccff;
        color: #004eeb;

        &:disabled {
          opacity: 0.4;
        }
      }
    }

    .description {
      margin-top: 18px;
      padding-top: 18px;
      border-top: solid 1px #e4e4e4;
      max-width: 500px;
      color: #212121;
    }

    .manuallyContainer {
      margin-top: 16px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .manually {
      transition: opacity 150ms ease-in-out;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        opacity: 0.9;
      }
    }

    .scrapeLoadingText {
      width: 100%;
      margin-top: 5px;

      p {
        width: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 18px;
        color: #bac1cc;
      }
    }

    .etsyTradeMarkText {
      margin-top: auto;
      padding: 16px 10%;
      text-align: center;
      font-size: 8pt;
      color: #aeb4bf;
    }
  }
}

.modalHeader {
  padding: 0;
}

.errorWrapper {
  width: 100%;
  margin-top: 15px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  box-sizing: border-box;
  padding: 15px;
  border-radius: tokens.$border-radius-200;

  & p {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #253858;
  }

  .errorLink {
    margin-left: 5px;
    color: tokens.$color-gray-700;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid tokens.$color-gray-700;
    transition: border-bottom-color 200ms ease, opacity 250ms ease;

    &:hover {
      opacity: 0.9;
      border-bottom-color: #fff2f0;
    }
  }
}

.localeWrapper {
  margin-top: 10%;
  width: 100%;
  min-width: 100%;

  & .localeTitle {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #091e42;
  }

  & .localeDesc {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
      margin-left: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b778c;
    }
  }

  & .localeTags {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  & .localeTag {
    background: #eff4ff;
    border: 1px solid #b2ccff;
    border-radius: tokens.$border-radius-200;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #004eeb;
    padding: 8px 14px;
    margin-right: 10px;
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
      border: 1px solid #004eeb;
    }
  }

  & .localeLoader {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
