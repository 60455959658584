@use '@restream/styles/scss/tokens';

.root {
  cursor: pointer;
  padding: 2px; // border-width
  border-radius: tokens.$border-radius-200;

  background: linear-gradient(
    71.43deg,
    #9ff3ee 13.87%,
    #28beff 45.06%,
    #b29bff 75.6%
  );
  border: none;
  display: none;

  @media (min-width: 576px) {
    display: block;
  }
}

.content {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: flex-start;
  align-items: center;

  // TODO(color): use token
  color: #b3f5ff;
  background: #182b4d;
  border: none;
  border-radius: tokens.$border-radius-150;
  padding: 0 8px;

  font-weight: 600;
  font-size: tokens.$font-size-body-xs;
  line-height: 1;
  height: 24px;
  text-align: center;
}
