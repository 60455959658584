@use '@restream/styles/scss/tokens' as tokens;

.root {
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;

  display: flex;
  flex-direction: column;

  background: white;
}

.modalHeader {
  margin-bottom: 20px;
}

.videoEditorWrapper {
  //margin-bottom: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.previewWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.controlWrapper {
  display: flex;
  flex-direction: column;
  height: 216px;
}

.upgradeCallout {
  width: 100%;
  border-radius: 0;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.submitButton {
  min-width: fit-content;
  margin-left: auto;
}

.controlWrapperEnter {
  opacity: 0;
}
.controlWrapperEnterActive {
  opacity: 1;
  transition: opacity 0.4s, transform 1300ms;
}
.controlWrapperExit {
  opacity: 1;
}
.controlWrapperExitActive {
  opacity: 0;
  transition: opacity 0.4s, transform 1300ms;
}

.buttonCloseWrapper {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 20;
  display: flex;
  justify-items: flex-end;
  align-items: center;

  & div ~ div {
    margin-left: 6px;
  }
}

.buttonClose {
  &:hover {
    background: rgba(255, 255, 255, 0.12);
    border-radius: tokens.$border-radius-200;
  }

  .iconClose {
    fill: #edf0f3;
  }
}
