@import '~@restream/styles/scss/media';
@import '@restream/styles/scss/colors';
@import '../../scss/typography';

$breakpoint: 1100px;

.root {
  padding: 12px 24px 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 12px;

  @include xl {
    justify-content: center;
  }

  @include md {
    justify-content: center;
    width: 100%;
    grid-gap: 8px;
  }

  @include xs {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    padding: 12px 8px;
  }
}
