@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
}

.button {
  padding: 12px 16px;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
  display: flex;
  align-items: center;
  flex: 1;
  outline: 0;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    background: #eff4ff;
    border-color: #004feb;
    //box-shadow: inset 0 0 0 1px #004FEB; // todo substitute with box shadow
    border-radius: tokens.$border-radius-200;
  }
}

.nameWrapper {
  display: flex;
  flex: 1;
}

.name {
  margin: 0;
  color: #85898d;
  font-size: 14px;
  line-height: 24px;
}

.connectButton {
  color: #286fff !important;
  box-shadow: none !important;
  padding: 8px 12px;
  border-radius: tokens.$border-radius-200;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
}
