@use '@restream/styles/scss/tokens';

.root {
  position: relative;
  width: 100%;
  height: 100%;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    flex-flow: column;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    h2 {
      color: tokens.$color-gray-700;
      margin-top: 16px;
    }

    .description {
      margin-top: 8px;
      font-size: 14px;
      color: tokens.$color-gray-700;
    }
  }
}
