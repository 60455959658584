.root {
}

.content {
  width: 100%;
  max-width: 1216px;
  margin: 0 auto;
  padding: 16px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerButton {
  padding: 10px 16px;
  gap: 8px;

  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}
