@use '@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

.thumbnailOverlayContainer {
  position: absolute;
  inset: 0;

  .preview {
    transition: opacity 0.25s ease-in-out;
    opacity: 0;
  }

  &:hover {
    .preview {
      opacity: 1;
    }
  }
}

.thumbnailOverlayActions {
  position: absolute;
  inset: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  transition: 0.3s all ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.36);

    & .overlayButton {
      opacity: 1;
    }

    @include media.xs {
      background-color: transparent;
    }
  }

  & .overlayButton {
    color: tokens.$color-gray-200;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    border: 0 solid tokens.$color-gray-500;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    padding: 8px;
    opacity: 0;

    &:hover:not(&:disabled) {
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(4px);
    }

    @include media.xs {
      opacity: 1;
    }
  }

  & .icon {
    width: 20px;
  }
}
