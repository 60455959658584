.image {
  width: 100%;
  aspect-ratio: 888 / 400;

  margin-bottom: 24px;
}

.title {
  color: var(--UI-N900, #091e42);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.16px;

  margin-bottom: 8px;
}

.description {
  color: var(--Gray-700, #2b3d5c);

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-9, 20px); /* 142.857% */

  margin-bottom: 24px;
}
