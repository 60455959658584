@use '@restream/styles/scss/tokens';
@import '../../../../../scss/colors';
@import '../../../constants';
@import '../../constants';

.buttonSkeleton {
  background: $skeletonBackground;
  border-radius: tokens.$border-radius-200;
  width: 60px;
  height: 40px;

  @media (max-width: $smallMobileMaxWidth) {
    width: 100%;
  }
}

.actionButtonWrap {
  width: 100%;
}

.actionButton {
  padding: 10px;
  width: 100%;

  @media (max-width: $smallMobileMaxWidth) {
    color: tokens.$color-gray-50;
    font-size: 14px;
    line-height: 20px;
    height: 40px;
  }
}
