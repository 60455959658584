@use '@restream/styles/scss/tokens';

.root {
  padding: 24px;
  background: #fafbfc;
  .description {
    color: rgba(80, 95, 121, 1);
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-top: 2px;
  }
  .platformList {
    margin-top: 24px;
  }
  .skipWrapper {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    .skip {
      cursor: pointer;
      letter-spacing: 0.01em;
      text-decoration-line: underline;
      color: #a5adba;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  .btnWrapper {
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
    padding: 12px 18px;
    .btn {
      -webkit-appearance: none;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 14px;
      border-radius: tokens.$border-radius-200;
      letter-spacing: 0.01em;
      text-decoration-line: underline;
      color: #a5adba;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  @media screen and (max-width: 576px) {
    padding: 12px;
  }
}
