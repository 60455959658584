$tabletMaxWidth: 1170px; // Should equal to DESKTOP_BREAKPOINT from './constants.ts'

$productMenuWidth: 64px;

$headerHeight: 76px; // must be the same as HEADER_HEIGHT in the constants.ts
$headerMobileHeight: 62px;

$upgradeTriggerHeight: 36px;

$buttonsBlockTabletHeight: 238px;
$buttonsBlockMobileHeight: 56px;

$destinationsBlock: 94px; // must be the same as DESTINATION_BLOCK in the constants.ts

$skeletonBackground: linear-gradient(
  270.3deg,
  #233656 0.26%,
  rgba(35, 54, 86, 0) 99.74%
);

$videoCardThumbnailWidth: 112px;
$videoCardThumbnailHeight: 62px; // must the same as VIDEO_CARD_THUMBNAIL_HEIGHT in the constants.ts

$sumOfSectionsMargins: 40px; // must be the same as SUM_OF_SECTIONS_MARGINS in the constants.ts
$videoCardSkeletonMarginBottom: 12px; // must be the same as VIDEO_CARD_SKELETON_MARGIN_BOTTOM in the constants.ts

$emptyEventsBlockShadowWidth: 20px;

$oneFourthOfFullScreen: calc((100vh - #{$headerHeight}) / 4);
$oneThirdOfOneFourthOfFullScreen: calc(#{$oneFourthOfFullScreen} / 3);
