.loaderRoot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  .noConnectionSquid {
    margin-bottom: 20px;
  }

  .text {
    text-align: center;
  }
}

.children {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
}
