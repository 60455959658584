@use '@restream/styles/scss/tokens' as tokens;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dropDownButton {
  padding: 8px 12px;
  border: tokens.$border-width solid tokens.$color-gray-300;
  border-radius: tokens.$border-radius-200;
  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
  width: 80px;
}

.dropDownButtonIcon {
  transform: rotate(90deg);

  & path {
    fill: #6b778c;
  }
}

.dropDown {
  max-height: 150px;
  width: 120px;
  margin-top: 6px;
  padding: 6px;
  box-sizing: border-box;
  background-color: tokens.$color-white;
  border: tokens.$border-width solid tokens.$color-gray-100;
  border-radius: tokens.$border-radius-200;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.dropDownItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  border-radius: tokens.$border-radius-200;
}

.selectedDropDownItem {
  background-color: rgb(235, 236, 240);
}
