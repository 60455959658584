@use '@restream/styles/scss/tokens';
@use '../../../../../scss/typography' as typography;
@use '../../../../../scss/colors' as colors;

$transition: 500ms ease;

@mixin text {
  border-radius: tokens.$border-radius-200;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.7;
  cursor: default;
  color: #ebecf0;
  font-style: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin badge {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 4px 6px;
  border-radius: tokens.$border-radius-200;

  height: 28px;
}

.root {
  position: absolute;
  left: 16px;
  top: 16px;

  opacity: 0;
  will-change: opacity;
  transition: opacity $transition;
}

.shouldShow {
  cursor: default;
  pointer-events: auto;
  opacity: 1;
}

.container {
  display: grid;
  grid-template-areas: 'status time loopsCount viewers';
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: minmax(auto, auto);
}

.badge {
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.time {
  grid-area: time;
  background-color: #06152f;
  min-width: 52px;
  white-space: nowrap;
  justify-content: left;

  @include badge;

  @include text;
}

.viewers {
  grid-area: viewers;

  background: rgba(9, 30, 66, 0.71);
  @include badge;
}

.viewersContainer {
  & svg path {
    fill: white;
  }
}

.viewersText {
  letter-spacing: 0.01em;
  color: #fafbfc;
}

.longTimeFormat {
  min-width: 64px;
}

.status {
  grid-area: status;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  background-color: #f63;
  border-radius: tokens.$border-radius-200;
  font-size: 12px;
  line-height: 1.7;
  cursor: default;
  font-style: normal;
  text-align: center;
  justify-content: center;

  @include badge;
}

.status.reconnecting {
  background-color: #06152f;
}

.loopsCount {
  grid-area: loopsCount;

  background: rgba(9, 30, 66, 0.71);
  @include badge;
  align-items: center;

  font-weight: 600;
  font-size: tokens.$font-size-body-xs;
  line-height: 16px;

  text-align: center;
  padding: 4px 8px;
  letter-spacing: 0.1em;
  color: #ffffff;
}
