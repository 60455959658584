@import '../../../components/scss/modals';

.root {
  @include modal-width;
  background: #fff;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;

  text-align: center;
  letter-spacing: 0.01em;

  color: #172b4d;

  padding: 0;
  margin: 0 0 16px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.01em;

  color: #172b4d;

  padding: 0;
  margin: 0;

  strong {
    font-weight: 500;
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 8px;

  min-height: 197px;
}

.image {
  width: 280px;
}

.button {
  height: 44px;
}
