@use '@restream/styles/scss/tokens';

.root {
  display: grid;
  gap: 16px;
}

.ingestsWrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
}

.singleColumn {
  grid-template-columns: 1fr;
}

.keyWrapper {
  display: flex;
}

.inputWithCopy {
  display: flex;
  flex: 1;

  &[type='password'] {
    text-overflow: initial;
  }
}

.alert {
  margin-top: 24px;
}
