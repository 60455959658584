.root {
  width: fit-content;
}

.label {
  display: flex;
  align-items: center;

  color: #172b4d;

  cursor: pointer;

  box-sizing: border-box;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  user-select: none;
}

.labelDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.checkbox {
  height: 21px;
  width: 21px;
  padding: 0;
  appearance: none;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid #bbc1e1;
  background: #fff;
  transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s;
  border-radius: 7px;

  &:after {
    content: '';
    width: 5px;
    height: 9px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(20deg);
    display: block;
    position: absolute;
    opacity: 0;
    box-sizing: inherit;
    transition:
      transform 0.3s ease,
      opacity 0.2s;
  }

  &:checked {
    background: #275efe;
    border: 1px solid #275efe;

    &:after {
      transform: rotate(43deg);
      opacity: 1;
    }
  }

  &:disabled {
    background: #f6f8ff;
    cursor: not-allowed;

    &:checked {
      border: 1px solid #bbc1e1;
      background: #e1e6f9;
    }
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        border: 1px solid #275efe;
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(39, 94, 254, 0.3);
  }
}

.childrenWrapper {
  display: flex;
  min-height: 24px;
  margin: 0 0 0 8px;
}
