@use '@restream/styles/scss/tokens';

.root {
  max-width: 800px;
}

.description {
  font-size: 15px;
  font-weight: normal;
  color: #c1c7d0;
  line-height: 24px;
  margin-bottom: 20px;
}

.controls {
  margin-top: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  justify-content: flex-start;
}

.counterContainer {
  display: inline-block;
  line-height: 40px;
  font-size: 13px;
  color: #aeb4bf;
  letter-spacing: 0.01em;
}

.counter {
  font-size: 13px;
  color: #aeb4bf;
  letter-spacing: 0.01em;
}

.textarea {
  display: block;
  width: 100%;
  border: 0 none;
  // TODO(font): use token
  font-size: 15px;
  font-family: inherit;
  height: 90px;
  padding: 12px 16px;
  outline: none;
  transition: box-shadow 300ms ease;

  color: tokens.$color-white;
  background-color: #253858;
  box-shadow: inset 0 0 0 1px #a5adba;
  border-radius: tokens.$border-radius-200;
  cursor: text;

  &:focus {
    box-shadow: inset 0 0 0 1px #286fff;
  }
}

.platform {
  padding: 30px 20px;
  border-bottom: 1px solid #253858;
}

.socialMessage {
  margin-top: 40px;
}

.socialMessageLabel {
  font-size: 18px;
  color: #ebedf2;
  letter-spacing: 0.01em;
  font-weight: normal;
  margin-bottom: 20px;
  display: block;
}
