@use '@restream/styles/scss/tokens';

.button {
  width: 100%;
  height: 44px;

  margin-top: 12px;

  background: linear-gradient(#eff4ff, #eff4ff) padding-box,
    linear-gradient(to right, #2e90fa, #004eeb, #6927da) border-box;

  border: 1px solid transparent;
  border-radius: 44px;
  transition: all 0.5s ease;

  &:hover:not(&:disabled) {
    background: tokens.$color-blue-500;

    & svg {
      color: white;
    }

    & .buttonText {
      color: white;
      -webkit-text-fill-color: initial;
      background: initial;
    }
  }
}

.buttonText {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(78.2deg, #2e90fa 0%, #004eeb 50.1%, #6927da 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 375px) {
    font-size: 15px;
  }
}
