@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';
@use '@restream/styles/scss/outline';
@use '../../../../scss/colors';

$space: 16px;

.description {
  // TODO(color): use token
  background: #ebecf0;
  color: #505f79;
  border-radius: tokens.$border-radius-200;
  margin-bottom: 24px;
  padding: 16px;
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  letter-spacing: 0.01em;
}

.formGroup {
  margin-bottom: 12px;
  padding: 0;
}

.switch {
  display: flex;
  align-items: center;

  .label {
    margin-bottom: 0;
    margin-left: 15px;
    cursor: pointer;
  }
}

.input-row {
  display: flex;

  @include media.xs {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.label {
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: #a5adba;
}

.labelRtmpLink {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inputRtmpLink {
  width: 100%;
}

.input {
  margin: 0;
  padding: 0;
}

.label-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.streamKeyInputWrapper {
  display: flex;
}

.label-column {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
}

.rtmpLinkBlock {
  flex: 1 1;

  @include media.xs {
    margin-top: 24px;
  }
}

.select {
  display: flex;
  flex: 1;
}

.first {
  display: flex;
  flex: 1;
  margin-right: 10px;

  @include media.xs {
    width: 100%;
  }
}

.selectRegion {
  max-width: 170px;

  @include media.xs {
    width: 100%;
    max-width: 100%;
  }
}

.select-input {
  display: flex;
  flex: 2;
}

.item {
  display: flex;
  align-items: center;

  cursor: pointer;
  color: tokens.$color-gray-900;

  padding-right: 8px;
  min-height: 20px;
  min-width: 123px;
}

.item-dropdown {
  min-height: 20px;
  border-radius: 0 !important;
  padding: 8px;
  transition: all 200ms ease;

  .dark &:hover {
    background-color: #286fff !important;
  }
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-dropdown.highlighted {
  background: #bde4ff;
}

.icon {
  width: 24px;
  height: 18px;
  margin-right: 8px;
}

.button-anchor {
  background: none;
  outline: none;
  margin: 0;
  padding: 0;
  border: none;

  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  cursor: pointer;
  color: #286fff;
}

.copyLinkForMobile {
  @media (min-width: 1080px) {
    display: none;
  }
}

.copyButton {
  cursor: pointer;
  min-width: 56px;
  width: fit-content;
  height: 40px;

  font-weight: 600;
  // TODO(font): use token
  font-size: 13px;
  line-height: 22px;
  border-radius: tokens.$border-radius-200;
  color: tokens.$color-white;
  background-color: #286fff;
  border: none;
  transition: background-color 100ms ease, opacity 300ms ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    box-shadow: none;
  }

  @include outline.focus-visible-outline;

  &.copiedButton {
    background-color: #0a7;
  }

  @media (min-width: 768px) {
    min-width: 66px;
    height: 40px;
    font-size: 14px;
    line-height: 24px;
  }
}

.inputContainer {
  position: relative;
}

.inputWrapper {
  color: tokens.$color-gray-900;
  background-color: tokens.$color-white;
  border-radius: tokens.$border-radius-200;

  .dark & {
    color: tokens.$color-white;
    // TODO(color): use token
    background-color: #253858;
  }
}

.inputAbsoluteWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.resetButton {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6px;
  padding: 5px 8px;
  border-radius: tokens.$border-radius-200;
  height: fit-content;
  margin: auto;
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  letter-spacing: 0.01em;
  transition: background-color 300ms ease, color 300ms ease;
  z-index: 1;

  &:hover {
    // TODO(color): use token
    background-color: #deebff;
  }

  .dark & {
    color: tokens.$color-white;
    background-color: #253858;
  }
  .dark &:hover {
    background-color: #42526e;
  }

  @include outline.focus-visible-outline;
}

.calloutDescription {
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: #505f79;
}
