@use '@restream/styles/scss/tokens';

.dropdownButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #fff;
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.25s ease-out;

  &:active,
  &:hover,
  &:focus-within {
    outline: none;
  }

  &.focused,
  &:hover,
  &:focus {
    outline: none;
    outline-offset: 2px;
    background-color: #374866;
  }

  & > svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  & .srOnly {
    display: none;
  }

  &[data-state='open'] {
    background-color: #374866;
  }
}

.iconBoxModel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
}

.dropdownMenuItem {
  &.danger {
    color: tokens.$color-red-600;
  }

  &.danger:hover {
    background-color: tokens.$color-red-50;
  }
}
