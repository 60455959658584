.value {
  display: flex;
  align-items: center;

  width: 100%;
  min-width: 0;

  img {
    margin-right: 4px;
  }
}

.valueText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.time {
  opacity: 0.5;
  margin-left: 8px;
}
