@use '@restream/styles/scss/tokens';

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.root {
  .leftPartWrapper {
    display: grid;
    grid-template-rows: auto 1fr;
  }
}

.singleProductsHeader {
  padding: 0 24px 8px;

  @include mobileMedia() {
    padding-top: 16px;
  }
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #091e42;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productsWrapper {
  position: relative;

  .products {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.searchbarWrapper {
  margin-top: 18px;
  width: 100%;
  position: relative;

  .searchbarHeader {
    display: flex;
  }

  .searchbarLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: tokens.$color-gray-700;
    margin-bottom: 8px;
  }

  .searchbarIcon {
    margin-left: 5px;
  }

  .searchbarContent {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto 95px;
  }

  .searchbar {
    border-radius: 0;
    border-top-left-radius: tokens.$border-radius-200 !important;
    border-bottom-left-radius: tokens.$border-radius-200 !important;
    // border-radius: tokens.$border-radius-200;
    width: 100%;
    font-size: 14px;

    &:focus ~ .searchbarButton {
      border: 1px solid #286fff;
    }
  }

  .searchbarInputWrapper {
    padding: 0;
  }

  .searchbarInputContainer {
    padding: 10px 12px;
  }

  .searchbarButton {
    border: 1px solid #a5adba;
    border-left: none !important;
    background: none;
    padding: 0 14px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    border-radius: 0 tokens.$border-radius-200 tokens.$border-radius-200 0;
    line-height: 24px;
    color: tokens.$color-gray-700;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out;
    position: relative;

    &.error {
      border: 1px solid #ff004a;
    }

    .loader {
      position: absolute;
    }

    &:hover {
      background: tokens.$color-gray-50;
    }

    .hidden {
      opacity: 0;
    }
  }

  .searchbarNote {
    width: 100%;
    padding-top: 5px;

    .searchbarNoteText {
      font-size: 12px;
      color: tokens.$color-gray-700;
    }

    .searchbarErrorText {
      font-size: 12px;
      color: tokens.$color-red-500;
    }
  }
}

.addMoreBtn {
  border: solid 1px rgba(186, 193, 204, 1);
  color: tokens.$color-gray-700;
  font-weight: 500;
  display: flex;
  appearance: none;
  transition: background-color 0.2s ease-in-out;
  -webkit-appearance: none;
  background: #fff;
  align-items: center;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: tokens.$border-radius-200;

  &:hover {
    background: tokens.$color-gray-50;
  }
}

.headerFirstLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
