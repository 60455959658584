@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../../../next/scss/mixins' as mixins;

.eventCard {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-areas: 'preview info';
  grid-template-columns: auto 1fr;
  grid-template-rows: minmax(100px, auto);
  grid-gap: 16px;
  margin-bottom: 32px;
  background: #253858;
  border-radius: tokens.$border-radius-200;
  padding: 16px;

  @include media.sm {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.preview {
  position: relative;
  grid-area: preview;
}

.thumbnail {
  border-radius: tokens.$border-radius-200;
  width: 192px;
  height: 108px;
  display: block;

  @include media.sm {
    width: 100%;
    height: auto;
  }
}

.info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  grid-area: info;

  min-width: 0;
}

.eventTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #fcfbfc;
  margin-bottom: 4px;

  @include mixins.truncate-text;
}

.eventTime {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.54);
  letter-spacing: 0.01em;
}

.eventHostedBy {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  color: #a5adba;
  letter-spacing: 0.01em;

  @include mixins.truncate-text;
}

.eventDestinationsSummary {
  width: fit-content;
}
