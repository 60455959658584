@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/media';

.dropdownMenuContent {
  min-width: 180px;
  padding: 6px 0;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  overflow: clip;
  z-index: 500;
}

.dropdownMenuItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  color: #2b3d5c;
  cursor: pointer;

  &,
  &:hover {
    transition: background-color 0.2s linear;
  }

  &:hover {
    background-color: #f5f6f8;
    outline: none;
  }

  &.danger {
    color: tokens.$color-red-700;

    &:hover {
      background-color: tokens.$color-red-50;
    }
  }

  & svg {
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  & svg ~ span,
  & span ~ div,
  & span ~ span {
    margin-left: 12px;
  }
}

.dropdownMenuSeparator {
  display: block;
  height: 1px;
  background-color: #eee;
}

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  height: 22px;
  border-radius: 8px;
  line-height: 1.4;
  background-color: #2970ff;
}

.deleteItem {
  color: tokens.$color-red-600;
}

.dropdownButton {
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 24px;

  border: 0;
  outline: 0;
  color: tokens.$color-gray-600;

  margin-bottom: auto;
  padding: 0;

  opacity: 1;
  transition: all 0.2s linear;
  background-color: transparent;

  &:hover,
  &.active {
    background: tokens.$color-gray-50;
  }

  @include xs {
    background-color: transparent;
  }

  & > svg {
    width: 20px;
    height: 24px;
    flex-shrink: 0;
  }

  & .srOnly {
    display: none;
  }

  &[data-state='open'] {
    background: tokens.$color-gray-50;
  }
}

.ellipsis {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 24px;
  gap: 2px;

  & > span {
    display: block;
    width: 4px;
    height: 4px;
    background-color: #2b3d5c;
    border-radius: 100%;
  }
}

.button {
  background: transparent;

  & > svg > path {
    fill: red;
  }
}

.button:hover,
.button[data-state='open'] {
  background-color: tokens.$color-gray-100 !important;
}
