.root {
  background-color: #ffff;
}

.content {
  display: flex;

  width: 100%;
  min-height: 100vh;
}

.main {
  min-width: 480px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 0 40px;

  // hack: use margin-bottom to put form a bit higher than center
  margin-bottom: 5%;

  @media (max-width: 1024px) {
    min-width: 0;
  }

  @media (max-width: 1024px) {
    padding: 0 20px;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  border-radius: 50%;

  font-size: 42px;
  font-weight: 700;

  background: black;
  color: white;
}

.formWrapper {
  max-width: 300px;
  width: 100%;
}

.formHeader {
  font-weight: 700;
  //font-size: 36px;
  font-size: 32px;
  color: #181818;

  margin: 30px 0;

  @media (max-width: 480px) {
    font-size: 28px;

    margin: 20px 0 15px;
  }
}

.formBottomContent {
  justify-content: flex-start;

  .formButtonLinkLabel {
    color: #181818;
    margin-right: 8px;
  }

  .formAnchorButton {
    font-weight: 400;
    color: #0060ff;
  }
}

.aside {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 0 40px;
  background: #111927;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;

    padding: 0 60px 0 70px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.videoContainer {
  display: flex;
  flex-direction: column;

  @media (min-width: 1201px) and (max-width: 1400px) {
    align-items: center;
  }
}

.videoHeader {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 40px;

  color: white;

  @media (min-width: 1201px) and (max-width: 1400px) {
    font-size: 32px;
    margin-bottom: 25px;
  }
}

.video {
  width: 416px;
  height: 234px;

  @media (min-width: 1201px) and (max-width: 1400px) {
    width: 350px;
    height: 200px;
  }
}

.stepsContainer {
  margin-left: 80px;

  @media (max-width: 1400px) {
    margin-left: 40px;
  }

  @media (max-width: 1200px) {
    margin: 50px 0 0;
  }
}

.stepsHeader {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  color: #6c737f;
}

.stepsList {
  display: flex;
  flex-direction: column;

  max-width: 350px;

  @media (min-width: 1201px) and (max-width: 1400px) {
    max-width: 400px;
  }
}

.step {
  display: flex;
  margin-top: 32px;

  &:first-of-type {
    margin-top: 24px;
  }

  @media (min-width: 1201px) and (max-width: 1400px) {
    margin-top: 24px;

    &:first-of-type {
      margin-top: 16px;
    }
  }
}

.stepCount {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 24px;
  height: 24px;

  margin-top: 5px;
  border-radius: 50%;

  color: white;
  background: #2970ff;
}

.stepTextWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-left: 12px;
}

.stepHeader {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: white;
}

.stepText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: white;

  margin-top: 6px;
}
