.root {
  width: 100%;
}

.switchFormItem {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
