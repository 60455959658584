.root {
  width: 320px;
  background: #ffffff;

  padding: 16px 24px 24px;
  border-radius: 12px;

  @media (max-width: 320px) {
    width: 100%;
  }
}
