@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

@property --calloutUpgradeButtonGradientPrimaryColor {
  syntax: '<color>';
  initial-value: #6e71ff;
  inherits: false;
}

@property --calloutUpgradeButtonGradientSecondaryColor {
  syntax: '<color>';
  initial-value: #ff2af6;
  inherits: false;
}

@property --calloutUpgradeButtonGradientRotationDegree {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: false;
}

.content {
  width: 800px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;

  @include media.sm {
    width: 100%;
    padding: 0 16px;
  }
}

.subtitle {
  margin: 0 4px 0 10px;
}

.retryButton {
  background: transparent;
  padding: 5px 4px;
  color: tokens.$color-gray-400;
  text-decoration: underline;

  &:hover:not(&.disabled) {
    background: transparent;
  }
}

.volumeTriggerWrapper {
  margin: 0 16px;
}

.editOrganizationButton {
  padding: 6px;

  &:hover:not(&:disabled) {
    background: tokens.$color-gray-700;

    & .icon {
      color: tokens.$color-white;
    }
  }

  & .icon {
    fill: none;
    stroke-width: 2;
    color: tokens.$color-gray-300;
  }
}

.calloutRoot {
  margin-bottom: 24px;
}

.calloutUpgradeButton {
  background-color: tokens.$color-blue-500; // fallback in case @property is not support by the browser

  background: linear-gradient(
    var(--calloutUpgradeButtonGradientRotationDegree),
    var(--calloutUpgradeButtonGradientPrimaryColor) 0%,
    var(--calloutUpgradeButtonGradientSecondaryColor) 100%
  );
  transition: --calloutUpgradeButtonGradientRotationDegree 0.2s,
    --calloutUpgradeButtonGradientPrimaryColor 0.4s,
    --calloutUpgradeButtonGradientSecondaryColor 0.8s;

  &:hover:not(&.disabled) {
    background-color: tokens.$color-blue-600; // fallback in case @property is not support by the browser

    --calloutUpgradeButtonGradientPrimaryColor: #155eef;
    --calloutUpgradeButtonGradientSecondaryColor: #2970ff;
    --calloutUpgradeButtonGradientRotationDegree: 180deg;
  }
}

.promoBanner {
  margin-top: 8px;
  margin-bottom: 24px;
}
