@use '@restream/styles/scss/tokens';

$width: 50px;
$height: 24px;

$color: tokens.$color-blue-700;
$border-radius: tokens.$border-radius-100;
$border-width: 2px;

.handler {
  width: 8px;
  height: 46px;
  background-color: lighten($color, 14%);
  cursor: ew-resize;
  position: absolute;
  top: -1px;
  z-index: 100;

  &.selected {
    border-color: $color;
    background: $color;
  }
}

.handler.disabled {
  pointer-events: none;
}

.handler.selected {
  opacity: 1;
}

.root.selected {
  .handler {
    display: unset;
  }
}

.handler.left {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  left: 4px;
}

.handler.right {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  right: 4px;
}
