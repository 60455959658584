@use '@restream/styles/scss/tokens';

.container {
  max-width: 500px;
}

.header {
  height: 240px;
  display: flex;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  background: radial-gradient(
      67.53% 67.53% at 50% 100%,
      rgba(39, 0, 175, 0.2) 0%,
      rgba(110, 68, 255, 0) 100%
    ),
    linear-gradient(
      225.81deg,
      rgba(255, 211, 240, 0.5) 0%,
      rgba(255, 68, 238, 0.04) 61.31%
    ),
    linear-gradient(114.74deg, #ff8822 0%, #ff44ee 33.57%, #9944ff 60.63%);

  .headerImage {
    width: 100%;
    filter: drop-shadow(0px 88.3333px 70.6667px rgba(16, 5, 1, 0.07))
      drop-shadow(0px 36.9036px 29.5228px rgba(16, 5, 1, 0.0503198))
      drop-shadow(0px 19.7304px 15.7843px rgba(16, 5, 1, 0.0417275))
      drop-shadow(0px 11.0607px 8.84856px rgba(16, 5, 1, 0.035))
      drop-shadow(0px 5.87426px 4.69941px rgba(16, 5, 1, 0.0282725))
      drop-shadow(0px 2.44441px 1.95553px rgba(16, 5, 1, 0.0196802));
  }

  .backButtonContainer {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
  }

  .closeButtonContainer {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .backButton,
  .closeButton {
    padding: 5px;
  }

  .backButtonIcon,
  .closeButtonIcon {
    height: 14px;
    width: 14px;
    fill: grey;
  }
}

.body {
  color: #000;
  text-align: center;
  padding-top: 20px;

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: tokens.$color-gray-900;
    margin-bottom: 16px;
  }

  .text {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: tokens.$color-gray-900;
  }
}
