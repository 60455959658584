@use '@restream/styles/scss/tokens';
@use '../../../scss/typography' as typography;

.eventCard {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 147px;
  background: #253858;
  border-radius: tokens.$border-radius-200;
  padding: 16px;

  opacity: 1;
  transition: all 0.2s;
}
