@use '@restream/styles/scss/tokens';

.callout {
  display: flex;
  flex-direction: column;
  border: 1px solid tokens.$color-gray-200;
  border-radius: tokens.$border-radius-150;
  padding: 16px;
  margin-bottom: 24px;
}

.calloutTextWrapper {
  display: flex;
  align-items: center;
}

.calloutIcon {
  min-width: 32px;
  margin-right: 12px;
}

.calloutText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-900;
}

.calloutButton {
  width: 100%;
  font-weight: 500;
  font-size: 14px;

  color: tokens.$color-white;
  background-color: tokens.$color-blue-600;
  border-radius: tokens.$border-radius-200;

  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
  margin-top: 12px;

  &:hover:not(:disabled) {
    background-color: tokens.$color-blue-700;
  }
}

.skipWaitPlatformsWrapper {
  margin-top: 24px;
}
