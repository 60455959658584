@use '@restream/styles/scss/tokens';

.root {
  color: #aeb4bf;
  background: transparent;
  font-weight: 500;
  align-items: center;
  display: flex;
  gap: 4px;

  padding: 0;
  transition: border 0.3s ease-in-out, padding 0.3s ease-in-out;
  position: relative;

  .contentButton {
    appearance: none;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;

    transition: background 0.3s ease-in-out, padding 0.3s ease-in-out;
    padding: 8px 0;
    cursor: pointer;

    color: #fafbfc;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &:hover .contentButtonIcon {
      opacity: 1;
      width: 16px;
      margin: 2px 2px 2px 6px;
    }
  }

  .contentButtonIcon {
    margin: 0;
    opacity: 0;
    width: 0;

    transition: width 0.2s ease-in-out, opacity 0.3s ease-in,
      margin 0.3s ease-in-out;
  }

  .hoveredContent {
    appearance: none;
    border: none;
    margin-left: 0;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    color: #bac1cc;
    display: flex;
    align-items: center;
    padding: 2px;
    background: transparent;
    cursor: pointer;
    transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;

    &:hover,
    &.isPopoverOpened {
      background: #2b3d5c;
      color: #fafbfc;
    }

    &.isPopoverOpened .hoveredContentIcon {
      transform: rotate(180deg);
    }
  }

  .hoveredContentIcon {
    transition: transform 0.3s ease-in-out;
  }
}

.popover {
  min-width: 186px;
  max-width: 186px;
  border-radius: 8px;
  padding: 8px 0;
  border: 0 solid tokens.$color-blue-500;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03),
    0 20px 24px -4px rgba(16, 24, 40, 0.08);
  overflow: hidden;
  max-height: 280px;
  overflow-y: auto;
}

.listIcon {
  height: 20px;
  width: auto;
}

.icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
