@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.root {
  display: grid;
  height: inherit;
  grid-template-columns: 212px 1fr;
  grid-template-rows: minmax(min-content, max-content);
  transition: width 0.2s ease-in-out;

  &.initialSize {
    grid-template-columns: auto;
    grid-template-rows: auto;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
      grid-template-rows: 84px auto;
    }
  }

  @include mobileMedia() {
    grid-template-columns: 1fr;
    grid-template-rows: 84px auto;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-rows: 84px auto;
  }

  &.hideStepper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.contentWrapper {
  height: 100%;
  //min-height: 680px;
  min-height: 0;
  min-width: 768px;
  //max-width: 768px;

  &.initialSize {
    //min-height: auto;
    min-width: auto;
  }

  &:not(.initialSize) > * {
    min-width: 100%;

    & > * {
      min-width: 100%;
      height: inherit;

      & > * {
        min-width: 100%;
        height: inherit;
      }
    }
  }

  @include mobileMedia() {
    &:not(.initialSize) {
      min-width: 100%;
      min-height: 100%;
      max-width: initial;
    }
  }
}

.stepperWrapper {
  position: relative;
  width: 100%;
  background: linear-gradient(45deg, #00359e 0%, #155eef 100%);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  box-sizing: border-box;
  transition: width 0.2s ease-out;

  .isStepperHiddenOnDesktop & {
    display: none;
  }

  @include mobileMedia() {
    display: initial !important;

    max-height: 116px;
    border-radius: 0;
  }

  &.hidden {
    width: 0;

    & > * {
      display: none;
    }
  }
}

.closeOnMobile {
  display: none;

  @include mobileMedia() {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
}
