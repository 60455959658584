.tabsList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  margin-bottom: 24px;
}

.tab {
  animation: fadeIn 0.2s ease-in-out;
}

.badge {
  margin-left: 4px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
