@use '@restream/styles/scss/tokens';

.root {
  width: fit-content;
  height: 40px;
}

.inputWrapper {
  padding: 0;
  overflow: hidden;
  border: 1px solid tokens.$color-gray-300;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
}

.inputContainer {
  width: 40px;
}

.input {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  color: tokens.$color-gray-700;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}

.buttonWrapper {
  margin: 0;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;

  width: 40px;
  transition: all 0.3s ease-out;

  &:focus-visible:not(&:disabled),
  &:hover:not(&:disabled) {
    background: tokens.$color-gray-50;

    .buttonIcon {
      color: tokens.$color-gray-700;
    }
  }

  &:disabled {
    .buttonIcon {
      color: tokens.$color-gray-300;
    }
  }
}

.buttonIcon {
  color: tokens.$color-gray-500;
  transition: all 0.3s ease-out;
}
