@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/variables' as _variables;

// values
$destinationWidth: 64px;
$buttonWidth: 154px;
$rootHeight: 36px;

.root {
  display: flex;
  flex-direction: row;
  animation: fadeIn 0.5s ease-in 1 both alternate;
  height: $rootHeight;
}

.destinations {
  width: $destinationWidth;
  margin-left: _variables.$grid-base-value;

  border-radius: tokens.$border-radius-200;

  animation: SkeletonGlow 1s linear infinite alternate;
}

.button {
  width: $buttonWidth;

  border-radius: tokens.$border-radius-200;

  animation: SkeletonGlow 1s linear infinite alternate;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes SkeletonGlow {
  from {
    background-color: _variables.$color-background-dark3;
  }

  to {
    background-color: #3c4d69;
  }
}
