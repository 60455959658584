@mixin font-12 {
  font-size: 0.75em; /* 12px */
  line-height: 2em; /* 24px */
}

@mixin font-14 {
  font-size: 0.875em; /* 14px */
  //line-height: 1.7143rem; /* 24px */
  line-height: 24px; /* 24px */
}

@mixin font-16 {
  font-size: 1em; /* 16px */
  //line-height: 1.5000em; /* 24px */
  line-height: 24px; /* 24px */
}

@mixin font-18 {
  font-size: 1.125em; /* 18px */
  line-height: 1.3333em; /* 24px */
}

@mixin font-24 {
  font-size: 1.5em; /* 24px */
  line-height: 1em; /* 24px */
}

@mixin font-32 {
  font-size: 2em; /* 24px */
  line-height: 1em; /* 24px */
}
