.root {
  display: flex;

  .switch {
    background: #091e42;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 999px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    height: 18px;
    span,
    .rs1-lever {
      background: transparent;
      overflow: hidden;
      margin-top: -9px;
      border-radius: 999px;
      height: 18px;
      &::after {
        width: 12px;
        height: 12px;
        top: 3px;
        background: #fff;
        border-radius: 9999px;
        left: 3px;
      }
    }
    input:checked + span::after {
      left: 18px;
    }
    input:checked + span {
      background: rgba(40, 111, 255, 1);
    }
  }
  .text {
    margin-left: 12px;
    color: #ebecf0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }
}

.switchWrapper {
  display: flex;
  align-items: center;
}
