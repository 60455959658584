@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/mixins';

$blob-animation-duration: var(--blob-animation-duration);
$blob-opacity-animation: var(--blob-opacity-animation);
$blob-filter-animation: var(--blob-filter-animation);
$blob-transition-timing-function: var(--blob-transition-timing-function);

.srOnly {
  @include srOnly();
}

.root {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  // TODO(color): use token
  background-color: tokens.$color-gray-50;
  border: tokens.$border-width solid tokens.$color-gray-100;
  border-radius: tokens.$border-radius-300;
  padding: 4px;
}

.fieldset {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-width: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}

.leftLabel,
.rightLabel {
  flex: 1 1;

  z-index: 1;

  user-select: none;
  cursor: pointer;
  will-change: transform;
  transform: translateZ(0);

  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  font-weight: 500;
  padding: 8px 16px;
  color: tokens.$color-gray-500;
  transition: transform 125ms ease, filter 125ms ease;

  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }

  &.labelActive {
    color: tokens.$color-gray-700;
  }
}

.blob {
  background: tokens.$color-white;
  z-index: 0;
  top: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  border-radius: tokens.$border-radius-200;
  animation-duration: $blob-animation-duration;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transition: transform 150ms $blob-transition-timing-function,
    background 150ms $blob-transition-timing-function;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1),
    0 1px 2px 0 rgba(16, 24, 40, 0.06);

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    background-color: inherit;
    height: 100%;
    width: 50%;
    border-radius: tokens.$border-radius-200;
    transition: transform 150ms $blob-transition-timing-function,
      background 150ms $blob-transition-timing-function;
    animation-duration: $blob-animation-duration;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  &::before {
    left: 0;
    animation-delay: 0.15s;
  }

  &::after {
    right: 0;
  }
}

.blobInitialLeft {
  left: 0;
}

.blobInitialRight {
  right: 0;
}

.disabled .blob {
  // TODO(font): use token
  background: #f6f4f4;
}

:global(.dark-theme) {
  .root {
    // TODO(font): use token
    background-color: #344563;
    box-shadow: #344563 0 0 0 1px;
    border: 1px solid #344563;
  }

  .blob {
    background: tokens.$color-gray-800;
  }

  .leftLabel,
  .rightLabel {
    color: tokens.$color-white;
  }

  .disabled {
    .blob {
      // TODO(font): use token
      background: #1a2f51;
    }
  }
}

.disabled .leftLabel,
.disabled .rightLabel {
  // TODO(font): use token
  color: #707a8d;
  cursor: not-allowed;

  &:hover {
    opacity: 1;
  }
}

.leftInput.initialLeft {
  ~ .blob {
    transform-origin: right center;
  }
}

.leftInput.initialRight {
  ~ .blob {
    transform-origin: left center;
  }
}

.rightInput.initialLeft {
  ~ .blob {
    transform-origin: left center;
  }
}

.rightInput.initialRight {
  ~ .blob {
    transform-origin: right center;
  }
}

.leftInput.initialRight:checked {
  ~ .blob {
    animation-name: fromRightToLeftInitialRight;
  }
}

.rightInput.initialLeft:checked {
  ~ .blob {
    animation-name: fromLeftToRightInitialLeft;
  }
}

.leftInput.initialLeft.changed:checked {
  ~ .blob {
    animation-name: fromRightToLeft;
  }
}

.rightInput.initialRight.changed:checked {
  ~ .blob {
    animation-name: fromLeftToRightInitialRight;
  }
}

@keyframes fromRightToLeft {
  0% {
    transform: translateX(100%) scaleX(1);
  }

  50% {
    transform: translateX(0) scaleX(2);
    opacity: $blob-opacity-animation;
    filter: $blob-filter-animation;
  }

  100% {
    transform: translateX(0) scaleX(1);
  }
}

@keyframes fromRightToLeftInitialRight {
  0% {
    transform: translateX(0) scaleX(1);
  }

  50% {
    transform: translateX(0) scaleX(2);
    opacity: $blob-opacity-animation;
    filter: $blob-filter-animation;
  }

  100% {
    transform: translateX(-100%) scaleX(1);
  }
}

@keyframes fromLeftToRightInitialLeft {
  0% {
    transform: translateX(0) scaleX(1);
  }

  50% {
    transform: translateX(0) scaleX(2);
    opacity: $blob-opacity-animation;
    filter: $blob-filter-animation;
  }

  100% {
    transform: translateX(100%) scaleX(1);
  }
}

@keyframes fromLeftToRightInitialRight {
  0% {
    transform: translateX(-100%) scaleX(1);
  }

  50% {
    transform: translateX(0) scaleX(2);
    opacity: $blob-opacity-animation;
    filter: $blob-filter-animation;
  }

  100% {
    transform: translateX(0) scaleX(1);
  }
}
