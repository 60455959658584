.container {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.viewers-icon {
  font-size: 12px;
  margin-right: 4px;
  margin-bottom: 2px;
}

.icon {
  margin-right: 4px;
}

.viewers-value {
  font-size: 12px;
  font-weight: normal;
  color: #7a869a;
}
