@use '@restream/styles/scss/tokens';

.file {
  display: flex;
  justify-content: space-between;
  background: tokens.$color-white;
  border: 1px solid tokens.$color-gray-200;
  height: 55px;
  border-radius: tokens.$border-radius-200;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 12px 16px;
  transition: box-shadow 0.25s, border-color 0.25s;

  @media (min-width: 576px) {
    height: 64px;
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled),
  &:has(:focus-visible) {
    box-shadow: 0px 2px 4px -2px #1018280f, 0px 4px 8px -2px #1018281a;
    border-color: tokens.$color-blue-500;
  }
}

.clickableFile {
  width: 100%;
  outline: none;
  text-align: left;
  align-items: center;
  cursor: pointer;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    & > .actionIcon {
      color: tokens.$color-blue-500;
    }
  }
}

.neighbors {
  display: flex;
  align-items: center;
}

.fileData {
  margin-left: 12px;
}

.fileName {
  color: tokens.$color-gray-700;
  font-size: 14px;
  line-height: 13px;
  margin: 5px 0 3px 0;
  font-weight: 500;

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 480px) {
    max-width: 150px;
  }

  @media (max-width: 375px) {
    max-width: 120px;
  }
}

.fileInfo {
  color: #6b778c;
  margin-right: 4px;
  font-size: 11px;
  line-height: 7px;

  &:last-of-type {
    margin-right: 0;
  }
}

.thumbnail {
  width: 64px;
  height: 36px;
  margin-right: 12px;
}

.fileIcon {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    display: block;
    background: tokens.$color-blue-50;
    inset: 0;
    position: absolute;
    border-radius: 999px;
  }

  & svg {
    color: tokens.$color-blue-500;
    z-index: 1;
  }
}

.actionIcon {
  vertical-align: middle;
  height: 20px;
  aspect-ratio: 1;
  transition: color 0.25s;
  color: tokens.$color-gray-500;
}
