@use '~@restream/styles/scss/media' as media;

.modalBody {
  padding-top: 0;
}

.mobileDestinationList {
  @include media.xs {
    max-height: none;
    overflow: initial;
  }
}
