@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/outline';

.root {
  background: #ffffff;
  border: 1px solid #edf0f3;
  //box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
  //  0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  box-shadow: 0 4px 8px -2px rgb(10 82 227 / 10%),
    0px 2px 4px -2px rgb(0 85 255 / 6%);
  border-radius: tokens.$border-radius-200;
  padding: 8px 12px 8px 8px;
  margin: 0 auto 12px;
  max-width: 452px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: border-color 500ms ease, box-shadow 500ms ease,
    background-color 300ms ease;

  &:hover {
    border: 1px solid #b2ccff;
    box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
    background-color: #eff4ff;
  }

  @include outline.focus-visible-outline;

  .list {
    display: flex;

    .imageContainer {
      height: 40px;
      width: 40px;
      margin-left: -8px;
      border-radius: tokens.$border-radius-200;
      border: 2px solid #edf0f3;
      background-color: #fff;
      z-index: var(--order, 0);

      &:first-child {
        margin-left: 0;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      border-radius: tokens.$border-radius-200;
    }
  }

  .placeholder {
    display: flex;
    align-items: center;

    .image {
      height: 40px;
      width: 40px;
      border-radius: tokens.$border-radius-200;
      border: 2px solid #edf0f3;
      background: #d79bfd;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      margin-left: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;

      background: linear-gradient(
        95.97deg,
        #d79bfd 10%,
        #a313fe 57.37%,
        #6c87f1 81.77%,
        #3c24e2 96.61%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .moreImagesBlock {
    width: 40px;
    height: 40px;
    display: flex;
    margin-left: -8px;
    border-radius: tokens.$border-radius-200;
    align-items: center;
    justify-content: center;
    background: #edf0f3;
    color: #42526e;
    font-size: 14px;
    border: 2px solid #edf0f3;
  }
}

.placeholderButton {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;

  margin-left: 12px;
  margin-right: 2px;
  //padding: 8px 12px;
  border-radius: tokens.$border-radius-200;
  //color: #172b4d;
  //color: #6b778c;
  color: #286fff;
  transition: color 300ms ease, background-color 300ms ease;

  .root:hover & {
    color: #004eeb;
  }
}
