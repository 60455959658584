@use '@restream/styles/scss/tokens';
@use '../../../scss/modals' as modals;

.root {
  @include modals.modal-width;
}

.modalHeader {
  color: #172b4d;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #091e42;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.event {
  display: flex;

  background: #ebecf0;
  border-radius: tokens.$border-radius-200;
  padding: 8px;

  margin-bottom: 22px;

  &:last-child {
    margin-bottom: 0;
  }
}

.thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 45px;
  width: 78px;

  img {
    height: 45px;
    max-width: 78px;
    border-radius: tokens.$border-radius-200;
  }
}

.info {
  display: flex;
  flex-direction: column;

  margin-left: 12px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #253858;
}

.scheduledTime {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.01em;

  color: #97a0af;
}

.checkBox {
  display: flex;
  align-items: center;

  margin-bottom: 12px;
  cursor: pointer;

  input {
    margin-bottom: 4px;
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #253858;

    cursor: pointer;

    margin-left: 4px;
    margin-bottom: 0;
  }
}

.cancelButton {
  color: #97a0af;
  box-shadow: inset 0 0 0 1px #ffffff;

  &:hover {
    box-shadow: inset 0 0 0 1px #97a0af;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  button {
    margin-left: 12px;
  }
}
