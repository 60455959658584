@use '@restream/styles/scss/tokens';
@import '../../home/constants';

.minHeightBody {
  @media (max-width: $smallMobileMaxWidth) {
    min-height: 100%;
  }
}

.layoutHeight {
  @media (max-width: $smallMobileMaxWidth) {
    height: 100%;
  }
}

.root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba(9, 30, 66, 0.8);
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $smallMobileMaxWidth) {
    height: 100%;
    background-color: #172b4d;
    position: fixed;
  }
}

.dragOverlay {
  z-index: 11;
}

.clickOutsideWrapper {
  width: 100%;
  height: 100%;
  position: absolute;

  @media (max-width: $smallMobileMaxWidth) {
    display: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $smallMobileMaxWidth) {
    width: 100%;
    padding-top: 20px;
  }

  &.contentMobileFullHeight {
    @media (max-width: $smallMobileMaxWidth) {
      height: 100%;
      min-height: 0;
      padding-top: 20px;
    }
  }

  &.emptyMobileVideosStep {
    @media (max-width: $smallMobileMaxWidth) {
      padding: 0 5%;
    }
  }
}

.eventFlowModalRoot {
  &.emptyStepBorders {
    border-radius: 20px;
  }

  &.zeroBorderRadius {
    @media (max-width: $smallMobileMaxWidth) {
      border-radius: 0;
    }
  }

  &.transparentBackground {
    @media (max-width: $smallMobileMaxWidth) {
      background-color: transparent;
    }
  }
}

.eventFlowWrapper {
  width: fit-content;
  margin-top: 8px;
  margin-bottom: 24px;

  @media (max-width: $smallMobileMaxWidth) {
    width: 100%;
    height: 100%;
    min-height: 0;
    margin: 0;
    //margin: 0 0 20px; was used when there was a tutorial
  }

  &.eventFlowWrapperNoStepper {
    margin-top: 20vh;

    @media (max-width: $smallMobileMaxWidth) {
      margin-top: 82px;
    }
  }

  &.eventFlowWrapperNoStepperUpgradeFlow {
    margin-top: 10vh;

    @media (max-width: $smallMobileMaxWidth) {
      margin-top: 0;
    }
  }

  &.eventFlowWrapperMobileFullHeight {
    @media (max-width: $smallMobileMaxWidth) {
      height: 100%;
      min-height: 0;
      margin: 0;
    }
  }
}

.dragOverlayEnter {
  opacity: 0;
}

.dragOverlayEnterActive {
  opacity: 1;
  transition: opacity 200ms;
}

.dragOverlayExit {
  opacity: 1;
}

.dragOverlayExitActive {
  opacity: 0;
  //transition: opacity 200ms; // was commented out because of new DnD icon animation (it takes 200 more ms which impacts the DnD animation and makes it look like it renders twice)
}

.stepperWrapper {
  display: flex;
  align-items: center;

  width: 100%;
}

.errorToastWrapper {
  width: 450px;
  padding: 12px 10px 16px !important;
  background: #ffffff !important;
  border-radius: tokens.$border-radius-200 !important;
  border: 1px solid #edf0f3;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  @media (max-width: 480px) {
    width: auto;
    max-width: 95%;
    padding: 16px !important;
    bottom: 1.5em !important;
  }
}

.skipOnboardingButton {
  display: block;
  margin: 48px auto 0;
  background: transparent;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-300;

  &:hover:not(&:disabled) {
    background: transparent;
    color: tokens.$color-gray-200;
  }
}
