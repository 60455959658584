@use '@restream/styles/scss/tokens';
@import '../scss/grid';
@import '../scss/colors';

.button-link {
  background-clip: padding-box;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;
  border-radius: tokens.$border-radius-200;
  margin: 0;
  outline: 0;
  padding: $grid-base-value $grid-base-value * 2;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s;

  &:focus {
    // TODO(color): use token
    box-shadow: 0 0 0 1px $ui-focus-color;
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
}

.button-link--outline {
  background: none;
  box-shadow: inset 0 0 0 1px tokens.$color-white;
  color: tokens.$color-white;

  &:hover:not(:disabled) {
    background: none;
  }
}

.button-link--primary {
  background: tokens.$color-orange-dark-400;
  color: tokens.$color-white;

  &:hover:not(:disabled) {
    background: tokens.$color-orange-dark-300;
    color: tokens.$color-white;
  }
}

.button-link--default {
  background-color: tokens.$color-gray-600;
  color: tokens.$color-white;

  &:hover:not(:disabled) {
    background-color: tokens.$color-gray-500;
    color: tokens.$color-white;
  }
}

.button-link--info {
  background-color: tokens.$color-blue-500;
  color: tokens.$color-white;

  &:disabled {
    opacity: 0.4;
  }

  &:hover:not(:disabled) {
    background-color: tokens.$color-blue-600;
    color: tokens.$color-white;
  }
}

.button-size--small {
  height: 30px;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  padding: 6px 12px;
}

.button--fill {
  width: 100%;
}

.content {
  display: flex;
  align-items: center;

  .left {
    margin-right: $grid-base-value;
  }

  .right {
    margin-left: $grid-base-value;
  }
}

.childrenWrapper {
  display: flex;
  align-items: center;

  &.hidden {
    visibility: hidden;
  }
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
}
