@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/modals' as modals;

.root {
  @include modals.modal-width;
}

.capitalize {
  text-transform: capitalize;
}

.noteBlock {
  display: grid;
  grid-gap: 8px;
  background: rgba(40, 111, 255, 0.12);
  border-radius: tokens.$border-radius-200;
  padding: 16px;
  margin-bottom: 24px;
}

.noteTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #091e42;
  text-transform: uppercase;

  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 8px;
  margin: 0;
}

.noteText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #172b4d;
  margin: 0;
}

.list {
  display: grid;
  grid-gap: 6px;
  margin-bottom: 24px;
  padding: 0;
  list-style-type: none;
}

.listLink {
  display: inline-grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 6px;

  color: #286fff;
  text-decoration: underline;
  transition: opacity 100ms ease;

  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }
}

.listItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #172b4d;
}

.mediaWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.media {
  height: 340px;
  border-radius: tokens.$border-radius-200;
  border: 1px solid #ebecf0;
}
