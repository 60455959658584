@use '@restream/styles/scss/tokens';
@use '../../../../../../components/scss/modals' as modals;

.root {
  @include modals.modal-width;

  display: flex;
  flex-direction: column;

  &.extendedOnboardingInterface {
    width: 880px;

    @media (max-width: 1024px) {
      @include modals.modal-width;
    }
  }
}

.blackFriday {
  display: flex;
  margin: 0 auto 24px;
  box-shadow: none;

  &.disablePointer {
    cursor: default;
  }
}

.blackFridayDiscount {
  background-color: #bf2 !important;
}

.modalBody {
  &.extendedOnboardingInterface {
    max-width: 500px;
    margin: auto;

    @media (max-width: 1024px) {
      max-width: none;
    }
  }
}

.pricingWrapper {
  margin-bottom: 32px;
}

.filesData {
  display: flex;
  flex-direction: column;
}

.filesDataText {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: tokens.$color-gray-600;
  margin: 0;

  &.bold {
    font-weight: 500;
    color: tokens.$color-gray-900;
    font-size: 14px;
  }

  &.uploadAndStreamPricing {
    font-size: 14px;
  }
}

.allFeaturesBtn {
  display: block;
  background: transparent;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #505f79;
  margin: 0 auto;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.calloutWrapper {
  margin-bottom: 20px;
}
