@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  margin: 4px 0 0 2px;
}

.text {
  color: tokens.$color-red-600;
  font-size: 14px;
  line-height: 22px;
}

.button {
  padding: 0;
  background: transparent;
  color: tokens.$color-blue-700;
  font-weight: 500;
  outline: none;
  line-height: 22px;
  margin-left: 8px;

  &:focus,
  &:focus-visible,
  &:active,
  &:hover:not(&.disabled) {
    color: tokens.$color-blue-600;
    background: transparent;
    text-decoration: underline;
    outline: none;
  }
}
