@use '@restream/styles/scss/tokens';
@import '../../../constants';

.root {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: auto 0;
  border-radius: tokens.$border-radius-200 0 0 tokens.$border-radius-200;
  overflow: hidden;

  position: relative;

  &.previewMode {
    border-radius: tokens.$border-radius-200;
  }

  @media (max-width: $mobileMaxWidth) {
    border-radius: tokens.$border-radius-200 tokens.$border-radius-200 0 0;
  }
}

.image {
  width: 100%;
  height: auto;
}

.content {
  display: flex;
  width: 100%;
}

.progressBar {
  position: absolute;
  bottom: 0;

  @media (max-width: $mobileMaxWidth) {
    border-radius: 0;
  }

  &.progressInModal {
    border-radius: 0 0 tokens.$border-radius-200 tokens.$border-radius-200;
  }
}

.thumbnail {
  @media (max-width: $mobileMaxWidth) {
    border-radius: tokens.$border-radius-200 tokens.$border-radius-200 0 0;
  }
}

.isModal {
  border-radius: tokens.$border-radius-200;
}

.isError {
  border-radius: 0 0 0 tokens.$border-radius-200;

  @media (max-width: $mobileMaxWidth) {
    border-radius: 0;
  }
}
