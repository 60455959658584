$animationDuration: 150ms;
$transition: opacity $animationDuration ease-in-out;
$popover: 10000;

.root {
  z-index: $popover;
}

.contentEnter {
  opacity: 0;
}

.contentEnterActive {
  opacity: 1;
  transition: $transition;
}

.contentExit {
  opacity: 1;
}

.contentExitActive {
  opacity: 0;
  transition: $transition;
}
