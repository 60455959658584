@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  height: 100%;

  position: relative;
}

.loader {
  position: absolute;
}

.loaderWrapper {
  width: fit-content;
  height: fit-content;

  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

  margin: auto;
  z-index: 1;
}

.image {
  filter: blur(4px);
  border-radius: tokens.$border-radius-200;
}
