@use '../../scss/grid' as grid;

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.switcher {
  display: flex;

  label {
    margin-left: grid.$grid-base-value;
  }
}

.buttons {
  display: flex;

  .button {
    margin-right: grid.$grid-base-value;
  }
}

.inputWrapper {
  background-color: #253858;
  color: #fff;
  cursor: text;
  min-height: 40px;
}

.input {
  &::placeholder {
    color: #a5adba;
  }
}
