@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

.emptyStateContainer {
  position: relative;
  width: 100%;
  height: 100%;
  //display: grid;
  //grid-template-columns: auto;
  //grid-template-rows: auto auto 1fr;
}

.emptyStateWrapper {
  height: 100%;

  flex-direction: column;
  padding: 0 20px 60px;

  display: flex;
  align-items: center;
  //justify-content: flex-start;
  justify-content: center;

  position: fixed;
  top: 30%;
  right: 0;
  //bottom: 0;
  left: 0;
  margin: 0;
  height: initial;
  padding-top: 20px;
  padding-bottom: 180px;

  &.versionTwo {
    top: 15%;
  }

  @media (min-width: 576px) {
    position: absolute;
    margin-top: 0;
    padding: 0;
  }
}

.emptyStateContent {
  max-width: 790px;
  padding: 0 20px;

  @media (max-width: 576px) {
    padding: 0;
  }
}

.emptyState {
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
}

.upgradeButtonGradient {
  display: block !important;
}

.accountWrapper {
  @media screen and (max-width: 364px) {
    max-width: 180px;
  }
}

.emptyStateTitle {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;

  @media (min-width: 576px) {
    font-size: 32px;
  }

  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    background: radial-gradient(
      50% 6968.56% at 49.88% 50%,
      rgba(171, 36, 219, 0.407) 0%,
      rgba(73, 17, 209, 0.275) 31.56%,
      rgba(23, 82, 193, 0.1375) 64.18%,
      rgba(14, 134, 210, 0.11) 100%
    );
    filter: blur(120px);
    border-radius: 999px;
    width: 100%;
    //height: 100%;
    height: 200px;
  }

  &.variation2 {
    &::before {
      background: radial-gradient(
        80% 6968.56% at 49.88% 50%,
        rgba(171, 36, 219, 0.655) 0%,
        rgba(73, 17, 209, 0.7) 31.56%,
        rgba(23, 82, 193, 0.1) 64.18%,
        rgba(14, 134, 210, 0.01) 100%
      );
      border-radius: 999px;
      content: '';
      display: block;
      -webkit-filter: blur(20px);
      filter: blur(120px);
      height: 300px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      width: 100%;
      z-index: -1;
    }
  }

  &.variantLinkInput {
    text-align: start;
  }
}

.textGradient {
  background: linear-gradient(
    160deg,
    rgba(209, 224, 255, 1),
    rgba(238, 170, 253, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes waving {
  0% {
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.12);
  }

  50% {
    box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.12);
  }

  100% {
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.12);
  }
}

.emptyStateDesc {
  margin-top: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;

  & span {
    font-style: italic;
  }
}

.emptyStateInputWrapper {
  margin-top: 20px;
  display: flex;
}

.emptyStateInput {
  padding: 13px 12px;
  &:focus-within {
    box-shadow: inset 0 0 1px 3px #286fff !important;
  }
}

.emptyStateInputButton {
  margin-left: 12px;
  width: 128px;
  height: 48px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  & [class*='left'] {
    display: flex;
    justify-content: center;
  }
}

.iconButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyStateButton {
  position: relative;
  margin-top: 40px;
  padding: 16px 44px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #091e42 !important;

  background: linear-gradient(
    95.53deg,
    rgba(255, 255, 255, 0.9) 0.21%,
    #ffffff 98.14%
  ) !important;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.12);
  border-radius: tokens.$border-radius-200;
  transition: box-shadow 300ms ease;
  animation: waving 2200ms ease-in-out infinite;

  @media (min-width: 576px) {
    padding: 20px 60px;
    font-size: 24px;
    line-height: 32px;
  }

  &:hover {
    animation-play-state: paused;
  }

  &:active {
    animation: none;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.12) !important;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    background: radial-gradient(
      50% 6968.56% at 49.88% 50%,
      rgba(171, 36, 219, 0.607) 0%,
      rgba(73, 17, 209, 0.375) 31.56%,
      rgba(23, 82, 193, 0.4375) 64.18%,
      rgba(14, 134, 210, 0.21) 100%
    );
    filter: blur(32px);
    border-radius: tokens.$border-radius-200;
    z-index: -1;
  }
}

.emptyStateDemoLinkButton {
  margin-top: 20px;
  background: none !important;
  border: none;
  padding: 4px;
  font: inherit;
  outline: inherit;
  cursor: pointer;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #d7dce3 !important;

  @media (min-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.emptyStateNotSureText {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  margin-right: 6px;
  color: #d7dce3;
  opacity: 0.8;
}

.emptyStateStateText {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #d7dce3 !important;
  border-bottom: 1px solid rgb(#d7dce3, 0.8);
  transition: border-bottom-color 300ms ease;

  .emptyStateDemoLinkButton:hover & {
    border-bottom-color: #d7dce3;
  }
}

.emptyStateArrowIcon {
  position: relative;
  top: 1px;
  vertical-align: middle;
  margin-left: 4px;

  .emptyStateDemoLinkButton:hover & {
    animation: move 1s ease backwards;
  }
}

.gridWrapper {
  padding-top: 40px;
  max-width: 480px;
  width: 100%;
  z-index: 20;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }

  60% {
    transform: translateX(4px);
  }

  100% {
    transform: translateX(0);
  }
}

.inputTooltip {
  width: 100%;
}

.tooltipDisabled {
  display: none;
}

.buttonPlaceholderWrapper {
  position: relative;
  width: fit-content;
}

.buttonPlaceholder {
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
