@use '@restream/styles/scss/media';

.root {
  width: 600px;

  @include media.xs {
    width: 100%;
  }
}

.modalBody {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  @include media.xs {
    grid-template-columns: 1fr;
  }
}

.errorWrapper {
  grid-area: 1 / 1 / 2 / 3;
}
