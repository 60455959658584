@use '~@restream/styles/scss/colors' as colors;
@use '~@restream/styles/scss/outline';
@use '@restream/styles/scss/tokens';
@use '../../../../../scrollbar' as scrollbar;

$space: 16px;
$smallerSpace: 12px;
$borderBottomHeight: 1px;
$defaultScrollWidth: 7px;
$scrollColor: rgba(104, 118, 139, 0.6);

.root {
  display: flex;
  flex-direction: column;
}

.tabs {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 2px;
  border: none;
  padding: $smallerSpace $space;
  margin: 0;
}

.tabs__item {
  flex: 0;
}

.tab {
  width: 100%;
  height: auto;
  margin: 0;
  padding: $smallerSpace;
  border-radius: tokens.$border-radius-200;
  background-color: transparent;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity 300ms ease;

  &:disabled {
    opacity: 0.4;

    // @todo This is legacy to make Bootstrap `js-tooltip` work
    pointer-events: none;
  }

  @include outline.focus-visible-outline;
}

.tab--active {
  cursor: default;
  color: #fff;
  background-color: #253858;
}

.content {
  overflow: hidden;
  height: 100%;
  position: relative;
  animation: fadeInWithSlideDown 600ms ease;

  @include scrollbar.scrollbar(
    $color: $scrollColor,
    $hover-color: $scrollColor,
    $active-color: $scrollColor,
    $track-color: transparent,
    $size: $defaultScrollWidth
  );

  @media (max-width: 576px) {
    min-height: 220px;
  }
}

@keyframes fadeInWithSlideDown {
  from {
    opacity: 0;
    transform: translateY(-6px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden {
  /* stylelint-disable declaration-no-important */
  display: none !important;
  visibility: hidden !important;
}

.scrollView {
  height: 100%;
  overflow: hidden;
  padding: 8px $smallerSpace $smallerSpace;

  @media (min-width: 1080px) {
    padding: $smallerSpace $space;
  }

  // Forces scrollbar to avoid content jumps when switching between tabs with
  // and without inner scrollbar
  .withForcedScrollbar & {
    padding-right: $space - $defaultScrollWidth;
    overflow-y: scroll;

    @include scrollbar.scrollbar(
      $color: $scrollColor,
      $hover-color: $scrollColor,
      $active-color: $scrollColor,
      $track-color: transparent,
      $size: $defaultScrollWidth
    );
  }

  &.noOffset {
    padding: 0;
  }
}
