@use '../../../../components/scss/colors' as colors;
@use '../../../../components/scss/animation' as animation;

.icon {
  fill: colors.$color-border-default;

  &.focus {
    fill: colors.$color-border-focus;
  }

  &.error {
    fill: colors.$color-border-error;
  }

  transition: all animation.$animation-default-time;
}
