.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.icon {
  margin-bottom: 28px;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 36px;
  text-align: center;
}
