@use '@restream/styles/scss/tokens';

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.root {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 12px;
  max-width: 250px;
  color: tokens.$color-gray-700;
  padding: 8px;
  background: tokens.$color-white;
  border: tokens.$border-width solid tokens.$color-gray-200;
  position: relative;
  border-radius: tokens.$border-radius-200;
  align-items: center;
  margin-top: 8px;

  &.error {
    // TODO(color): use token
    background-color: #fff2f0;
    border: tokens.$border-width solid #ffccc7;
  }

  @include mobileMedia() {
    max-width: 240px;
    margin-top: 0;
    margin-right: 8px;
    flex-shrink: 0;
  }

  &:hover .onHoverContainer {
    background-color: rgba(39, 108, 248, 0.7);

    & .editBtn,
    & .removeBtn {
      opacity: 1;
    }
  }

  & .onHoverContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    border-radius: tokens.$border-radius-200;
    background-color: rgba(39, 108, 248, 0);
    transition: background-color 200ms ease-in-out;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    .editBtn {
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      color: tokens.$color-white;
      opacity: 0;
      transition: opacity 200ms ease-in;

      .editBtnImg {
        width: 20px;
        height: auto;
        margin-right: 10px;
      }
    }
  }

  .removeBtn {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 100;
    background: tokens.$color-white;
    border: tokens.$border-width solid tokens.$color-gray-300;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: tokens.$border-radius-200;
    cursor: pointer;
    opacity: 0;
    transition: all 200ms ease-in;

    .removeBtnContent {
      box-sizing: border-box;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      transition: background 200ms ease-in;
    }

    .removeBtnImg {
      width: 16px;
    }

    &:hover {
      border: solid 1px rgba(205, 32, 31, 0.8);

      .removeBtnContent {
        background: rgba(205, 32, 31, 0.1);
      }

      & path {
        stroke: #cd201f;
      }
    }
  }

  .loader {
    background: rgba(228, 228, 228, 0.6);
    top: 0;
    height: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: opacity 0.1s ease-in-out;

    &.open {
      height: 100%;
      opacity: 1;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  .data {
    width: 100%;
    overflow: hidden;
    max-width: 100%;
    .title {
      font-weight: 500;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .photo {
    width: 48px;
    height: 48px;
    background-position: center;
    background-size: cover;
    position: relative;

    @include mobileMedia() {
      width: 40px;
      height: 40px;
    }

    & .imageLoaderWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: tokens.$color-gray-50;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.2s ease-in-out;

      &.hidden {
        opacity: 0;
      }
    }
    & .imageLoader {
      & circle {
        stroke: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .removeWrapper {
    display: flex;
    height: 100%;
    align-items: flex-start;
  }
  .removeImg {
    width: 14px;
    height: 14px;
    cursor: pointer;
    transition: stroke 0.2s ease-in-out;

    &:hover {
      stroke: #cd201f !important;
    }
  }
}
