@use '../../../../../scss/colors' as colors;
@use '../../../../../scss/modals' as modals;

.body {
  @include modals.modal-width;

  color: colors.$bg-color;
}

.text {
  span {
    font-weight: 500;
  }
  margin-bottom: 24px;
}

.checkbox-wrapper {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
}

.checkbox {
  display: flex;
  margin-right: 8px;
}

.buttons {
  button:first-child {
    margin-right: 8px;
  }
}
