.app-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  justify-content: center;
  align-items: center;

  height: 100vh;

  animation: fade-in 500ms ease both alternate;
}

/* stylelint-disable-next-line scss/selector-nest-combinators */
.app-loader::after {
  content: ' ';
  display: block;

  width: 64px;
  height: 64px;

  margin: 8px;

  border-radius: 50%;
  border-width: 6px;
  border-style: solid;
  border-color: #fff transparent #fff transparent;

  animation: loader-ring 1.2s linear infinite;
}

@-webkit-keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
