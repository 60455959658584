@use '@restream/styles/scss/tokens';

.divider {
  width: 1px;
  height: 12px;
  background: #a5adba;
  margin-left: 12px;
  margin-right: 12px;

  color: #a5adba;
  opacity: 0.6;
}

.eventUrl {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: tokens.$color-gray-600;
  width: auto;
  white-space: nowrap;

  .text {
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.15s ease-in-out;
    padding-bottom: 1px;

    &:hover {
      border-bottom: 1px solid tokens.$color-gray-600;
    }
  }

  .icon {
    margin-left: 4px;
  }
}
