@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/colors';

.label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-900;
}

.label.disabled {
  opacity: 0.5;
}

.text {
  margin-right: 4px;
}

:global(.dark-theme) {
  .label {
    // TODO(color): use token
    color: $fg-color--darken-1;
  }
}
