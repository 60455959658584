@use '@restream/styles/scss/tokens';
@use '../scss/outline';

.container {
  // TODO(color): use token
  background: #253858;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.6);
  border-radius: tokens.$border-radius-200;

  margin: 8px;
  padding: 8px;
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;

  @include outline.focus-visible-outline;
}
