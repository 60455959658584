.videoEditorWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  & * {
    // it is imperative to set the box-sizing to border-box on all the
    // children elements to reduce the amount setting up box models.
    box-sizing: border-box;
  }
}

.errorWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.editorTools {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  // --@todo: 25vh height should only be set when VideoEditorPreview is set to go full screen
  // --@todo: 25vh combined with VideoEditorPreview height of 70vh should be 100vh
  height: 25vh;
}
