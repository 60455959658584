@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.selectedOverlay {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: no-repeat;
  border: 0;
  outline: none;
  line-height: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(40, 111, 255, 0.89);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 350ms ease;

  &.withVideoCount {
    align-items: flex-start;
    justify-content: flex-end;
    padding: 12px;

    @include media.xs {
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
}

.videoCount {
  display: flex;
  align-items: center;

  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;

  border: 2px solid tokens.$color-white;
  color: tokens.$color-white;
  background-color: tokens.$color-blue-600;

  font-size: tokens.$font-size-body-sm;
  font-weight: 500;
  line-height: 24px;

  user-select: none;

  @include media.xs {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }
}
