@use '~@restream/styles/scss/colors';
@use '~@restream/styles/scss/outline';
@use '@restream/styles/scss/tokens';

.root {
  position: relative;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: tokens.$border-radius-200;
  background-color: transparent;
  cursor: pointer;

  @include outline.focus-visible-outline;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;
  stroke: #fff;
}

.copyIcon {
  color: #286fff;
}

.hidden {
  display: none;
}
