@import '@restream/styles/scss/variables';
@import '../scss/media';

.#{$ns}-layout {
  display: flex;
  height: 100vh;

  @include xs {
    flex-direction: column;
  }

  &-content {
    width: 100%;
    overflow: auto;
  }

  .productMenuDesktop {
    animation: slideInFromLeft 500ms ease 1 both alternate;
  }

  .productMenuMobile {
    animation: slideInFromTop 500ms ease 1 both alternate;
  }

  @keyframes slideInFromTop {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideInFromLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
