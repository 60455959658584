@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/outline';

$accordionAnimationMs: 300ms;

.root {
  border-bottom: 1px solid #ebecf0;

  &:last-child {
    border-bottom: 0;
  }
}

.button {
  display: flex;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-height: 40px;
  min-width: 220px;

  padding: 12px;

  margin: 0;

  border-radius: tokens.$border-radius-200;
  border: none;

  text-align: left;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  color: #172b4d;
  background-color: transparent;
  user-select: none;
  cursor: pointer;

  transition: background-color 0.3s ease;

  &:disabled {
    opacity: 0.5;
  }

  @include outline.focus-visible-outline;

  &.isExpanded {
    background-color: #deebff;
    color: inherit;
    font-weight: 500;
  }
}

.button__content {
  flex: 1;
}

.chevronIcon {
  width: 16px;
  height: 16px;

  color: #a5adba;

  margin-left: 8px;

  transition: transform $accordionAnimationMs ease,
    color $accordionAnimationMs ease;

  .isExpanded & {
    transform: rotate(90deg);
  }

  .isExpanded &,
  .root:not(:disabled):hover & {
    color: #172b4d;
  }
}

.contentWrapper {
  max-height: 0;
  opacity: 0;

  margin-left: 12px;
  margin-right: 12px;

  overflow: hidden;

  transition: max-height $accordionAnimationMs ease,
    opacity $accordionAnimationMs ease;

  .isExpanded & {
    // TODO: come up with a proper number of max-height
    max-height: 1500px;
    opacity: 1;
    overflow: inherit;
    transition: max-height ($accordionAnimationMs * 1.5) ease,
      opacity ($accordionAnimationMs * 1.25) ease;
  }
}

.content {
  margin-top: 8px;
  margin-bottom: 24px;
}
