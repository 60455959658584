@use '@restream/styles/scss/tokens';
@use '../colors';

.root {
  display: flex;
  align-items: center;
  gap: 4px;

  color: tokens.$color-gray-700;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  margin: 0;
}

.root.peer-disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

:global(.dark-theme) {
  .root {
    color: tokens.$color-gray-400;
  }
}

:global(.transparent-theme) {
  .root {
    color: colors.$transparent-theme-color-input-fg-darken;
  }
}
