.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  background: none;
  cursor: pointer;

  flex-grow: 0;
  margin: 30px auto 0;
  border: none;
  color: #286fff;

  &:hover {
    text-decoration: underline;
  }

  transition: opacity 200ms;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
