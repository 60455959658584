@use '@restream/styles/scss/media' as media;
@use '../../../scss/typography' as typography;

.container {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}

.root {
  display: flex;
  flex-direction: column;

  padding-top: 15vh;
  padding-bottom: 15vh;

  @include media.sm {
    padding: 0;
  }
}

.root.hidden {
  display: none;
}

.imageWrapper {
  height: 236px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../../../assets/img/squids/squid-stars.png) no-repeat 50% 0;

  margin-bottom: 24px;
}

.image {
  height: 146px;
  margin-top: 90px;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include typography.font-24;
  margin-bottom: 12px;
}

.subtitle {
  @include typography.font-16;
  text-align: center;

  margin-bottom: 12px;
}
