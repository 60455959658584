@use '@restream/styles/scss/tokens';

// TODO(color): use token
$danger-color: #ff7452;
$min-height: 28px;

.container {
  display: flex;
  align-items: center;
  //padding: 0 24px;

  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  color: #aeb4bf;
  min-height: $min-height;

  //font-size: 14px;
  //line-height: 2;
  cursor: pointer;
  transition: all 200ms;
  //color: #fbfbfb;

  white-space: pre;

  margin: 4px 0;
  padding: 4px 8px;

  &:hover {
    background-color: tokens.$color-gray-800;
  }

  &:last-child,
  &:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  user-select: none;
}

.container.danger {
  // TODO(font): use token
  color: $danger-color;

  .icon {
    svg {
      fill: $danger-color;
    }
  }
}

.container.danger:hover {
  // TODO(font): use token
  background-color: tokens.$color-orange-dark-400;
  color: #fbfbfb;

  .icon {
    svg {
      fill: #fbfbfb;
    }
  }
}

.container.disabled {
  opacity: 0.5;
}

.icon {
  display: flex;
  justify-content: center;
  margin-right: 8px;

  svg {
    transition: 200ms all;
    height: $min-height - 6px;
    width: auto;

    // in case there are more that 1 svg inside
    &:not(:last-of-type) {
      margin-right: 4px;
    }
  }
}
