@use '@restream/styles/scss/tokens';

.overlay {
  position: fixed;
  z-index: 10;
}

.icon {
  height: 20px;
}

.dateSelect {
  position: relative;
  overflow: hidden;

  :global(.DayPickerInput-Overlay) {
    border-radius: tokens.$border-radius-200;
    margin-top: 7px;
  }

  :global(.rdp) {
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.3);
    border: 1px solid #c1c7d0;
    border-radius: tokens.$border-radius-200;
    margin: 0;

    :global(.rdp-table) {
      border-collapse: separate;
      border-spacing: 5px;
    }

    :global(.rdp-month) {
      border-collapse: separate;
      border-spacing: 5px;
      padding: 1em;
    }

    :global(.rdp-head_cell) {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #42526e;
    }

    :global(.rdp-cell) {
      width: 34px;
      height: 34px;
    }

    :global(.rdp-day) {
      width: inherit;
      height: inherit;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      text-align: center;
      letter-spacing: 0.25px;

      color: #091e42;
      border-radius: tokens.$border-radius-200;
      opacity: 1;
    }

    :global(.rdp-day.rdp-day_disabled) {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      text-align: center;
      letter-spacing: 0.25px;

      color: #a5adba;
    }

    :global(.rdp-day:not(.rdp-day_disabled, .rdp-day_outside)) {
      &:hover {
        background-color: #2684ff !important;
        color: #fff;
        border-radius: tokens.$border-radius-200;
      }
    }

    :global(.rdp-day_selected) {
      background-color: #4a90e2 !important;
      color: #fff;

      &:hover {
        background-color: #2684ff !important;
        border-radius: tokens.$border-radius-200;
      }
    }

    :global(.rdp-caption) {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.25px;
      margin-bottom: 0.5em;
      color: #000000;
    }

    :global(.rdp-caption_label) {
      font-size: 1.15em;
      font-weight: 500;
    }

    :global(.rdp-nav_button) {
      width: 25px;
      height: 25px;

      &:hover {
        background: transparent;
      }
    }
  }
}

//input[type="date"]::-webkit-inner-spin-button,
//input[type="date"]::-webkit-calendar-picker-indicator {
//  display: none;
//  -webkit-appearance: none;
//}

.iconCenter {
  margin: auto 0;
}

.mobileInput {
  height: 22px;
  box-sizing: border-box;
}

.disabledInput {
  opacity: 0.5;
  cursor: not-allowed;
}

.nativeInput {
  height: 38px;
  width: 150px;
  min-width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;

  &:disabled {
    cursor: not-allowed;
  }
}

.nativeInput[type='date']::-webkit-calendar-picker-indicator {
  height: auto;
  width: auto;
  background: transparent;
  color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
