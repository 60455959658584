@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../scss/colors' as colors;

.root {
  width: 100%;
  //min-height: 100vh;
  //display: grid;
  //grid-template-columns: auto;
  //grid-template-rows: auto 1fr auto;
  padding-bottom: 40px;
}

.content {
  max-width: 1024px;
  margin: 0 auto;
}

.errorContent {
  margin-top: 15%;
  padding: 0 20px;
}

.loadingContent {
  width: 100%;
  padding: 0 20px;
  margin-top: 25%;

  display: flex;
  justify-content: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 36px;
  height: 36px;
  border: 4px solid tokens.$color-white;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &.buttonLoader {
    width: 17.5px;
    height: 17.5px;
    border: 2.5px solid tokens.$color-white;
    border-bottom-color: #2970ff;
  }
}

.submitError {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: tokens.$color-red-400;
}

.header {
  padding: 16px;
  width: 100%;
  text-align: center;
}

.mainText {
  color: rgba(255, 255, 255, 0.87);
  padding: 26px 20px;
  font-size: 22px;
  text-align: center;

  &.extraTopMargin {
    margin-top: 20px;
  }
}

.button {
  padding: 8px 14px;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  min-height: 40px;
  height: auto;
  color: tokens.$color-white;
  background: rgb(255, 102, 51);
  border-radius: 4px;
  box-shadow: unset;
  border: none;

  &:hover {
    background: rgb(255, 102, 51, 0.8);
  }

  &.alternative {
    margin-right: 20px;
    background: transparent;
    border: 1px solid rgb(255, 102, 51);
    color: rgb(255, 102, 51);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.cardWrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.cardWrapperGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;

  @include media.md {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-row-gap: 20px;
  }
}

.card {
  width: 100%;
  padding: 30px;
  background: rgb(37, 56, 88);
  border-radius: 6px;

  &.noPadding {
    padding: 0;
  }

  .cardTitle {
    color: tokens.$color-white;
    font-weight: 500;
    font-size: 20px;
  }
}

.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  @include media.md {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}

.feature {
  margin-top: 24px;
  display: flex;

  .iconContainer {
    min-width: 44px;
  }

  .icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: rgba(255, 102, 51, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      width: 24px;
      height: 24px;
    }
  }

  & .content {
    padding-left: 9px;
    margin: 0;
  }

  .title {
    color: rgba(255, 255, 255, 0.87);
    font-weight: 500;
    font-size: 16px;
  }

  .description {
    color: rgba(255, 255, 255, 0.87);
    font-weight: 400;
    font-size: 14px;
  }
}

.cardGridWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr 30px auto;
}

.errorContainer {
  & .text {
    color: tokens.$color-red-500;
    font-weight: 400;
    font-size: 16px;
  }
}

.cardGridContent {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .inner {
    display: flex;

    .text {
      color: rgba(255, 255, 255, 0.87);
      font-weight: 400;
      font-size: 16px;
    }

    .img {
      margin-left: 16px;
      width: 200px;

      @include media.sm {
        display: none;
      }
    }
  }
}

.verticalTextContent {
  padding: 40px 20px 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: rgba(255, 255, 255, 0.87);
    font-weight: 500;
    font-size: 16px;
  }

  .description {
    margin-top: 4px;
    margin-bottom: 8px;
    max-width: 700px;
    text-align: center;

    color: rgba(255, 255, 255, 0.87);
    font-weight: 400;
    font-size: 16px;
  }

  & > .button {
    margin-top: 20px;
  }
}

.questionErrorContainer {
  width: 100%;
  padding: 30px;
  padding-bottom: 0;

  .questionError {
    width: 100%;
    padding: 20px;
    background-color: colors.$error-color;
    border-radius: 6px;

    .title {
      font-size: 24px;
      color: tokens.$color-white;
    }

    .desc {
      font-size: 16px;
      color: tokens.$color-white;
    }
  }
}

.questionContainer {
  width: 100%;
  padding: 30px;

  display: flex;

  @include media.md {
    flex-direction: column;
  }
}

.questionTitle {
  padding-right: 10px;
  width: 55%;
  margin-bottom: 20px;

  @include media.md {
    width: 100%;
  }

  .title {
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    font-weight: 500;

    &.error {
      color: tokens.$color-red-500;
    }
  }

  .required {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }
}

.question {
  width: 100%;
}

.questionHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
  }
}

.questionContent {
  margin-top: 9px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.questionItem {
  display: flex;
  margin-bottom: 9px;
}

.questionText {
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  cursor: pointer;

  &.error {
    color: tokens.$color-red-500;
  }
}

.questionLabel {
  text-align: center;

  &.error {
    color: tokens.$color-red-500;
  }
}

.otherReason {
  width: 100%;
  padding-left: 30px;

  &.collapsed {
    height: 0;
  }

  & > .otherReasonInput {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    opacity: 1;
    width: 100%;
    height: 84px;
    padding: 10px;
    background-color: #21324f;
    border: 1px solid tokens.$color-gray-500;
    border-radius: tokens.$border-radius-200;
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    font-family: Graphik, sans-serif !important;
    resize: none;

    &::placeholder {
      color: tokens.$color-gray-400;
    }

    &.error {
      border: 1px solid tokens.$color-red-500;
    }

    &:focus {
      border: 1px solid rgba(255, 255, 255, 0.87);
    }
  }

  &.collapsed > .otherReasonInput {
    opacity: 0;
  }
}

.submitContainer {
  width: 100%;
  padding: 30px;
  background: rgb(33, 50, 79);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.checkboxContainer {
  display: flex;

  .text {
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.87);
    font-size: 14px;
    cursor: pointer;
  }
}

.buttonsContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customCheckboxContainer {
  position: relative;

  .customCheckbox {
    appearance: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    margin: 0;
    cursor: pointer;

    border: 2px solid rgba(255, 255, 255, 0.54);
    border-radius: 50%;

    &.error {
      border: 2px solid tokens.$color-red-500;
    }
  }

  .checked {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    pointer-events: none;

    display: none;
  }

  .customCheckbox:checked {
    border: 2px solid rgb(255, 102, 51);

    & ~ .checked {
      display: block;
      background-color: rgb(255, 102, 51);
    }
  }
}

.confirmCheckboxContainer {
  position: relative;

  .confirmCheckbox {
    appearance: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    margin: 0;
    cursor: pointer;

    border: 2px solid rgba(255, 255, 255, 0.54);
    border-radius: 6px;
  }

  .checked {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    pointer-events: none;

    display: none;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  .confirmCheckbox:checked {
    border: 2px solid rgb(255, 102, 51);

    & ~ .checked {
      display: block;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 36px;
  height: 36px;
  border: 4px solid tokens.$color-white;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &.buttonLoader {
    width: 17.5px;
    height: 17.5px;
    border: 2.5px solid tokens.$color-white;
    border-bottom-color: tokens.$color-blue-500;
  }
}

.requiredErrorText {
  margin-top: 5px;
  font-size: 14px;
  cursor: pointer;
  color: tokens.$color-red-500;
}
