@use '~@restream/styles/scss/media' as media;

.root {
}

.content {
  width: 100%;
  max-width: 1216px;
  margin: 0 auto;
  padding: 24px 24px 28px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @include media.lg {
    flex-wrap: wrap;

    & > :nth-child(1) {
      order: 2;
    }

    & > :nth-child(2) {
      width: 100%;
      margin-bottom: 20px;
      order: 1;
    }

    & > :nth-child(3) {
      order: 4;
    }

    & > :nth-child(4) {
      order: 3;
    }
  }

  @include media.md {
    & > :nth-child(1) {
      order: 3;
    }

    & > :nth-child(2) {
      order: 4;
    }

    & > :nth-child(3) {
      width: 100%;
      margin-bottom: 20px;
      order: 1;
    }

    & > :nth-child(4) {
      order: 2;
    }
  }

  .copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #97a0af;

    @include media.md {
      margin-top: 20px;
      width: 100%;
    }

    .mobileOnly {
      display: none;

      @include media.md {
        display: inline;
      }
    }
  }

  .linksDesktop {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media.md {
      display: none;
    }
  }

  .linksMobile {
    display: none;

    @include media.md {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .linksSection {
      margin-top: 40px;
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      @include media.sm {
        width: 50%;
      }

      & > .link {
        margin: 20px 0 0;
      }
    }
  }

  .linksTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #a0a0ab;
  }

  .link {
    width: fit-content;
    margin: 0 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #d7dce3;

    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .socialLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media.md {
      margin-top: 20px;
    }

    .socialLogo {
      margin-left: 12px;
      cursor: pointer;

      @include media.md {
        margin-left: 0;
        margin-right: 12px;
      }

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }
}
