@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.alert {
  margin-bottom: 16px;
}

.cancelButton {
  color: var(--Gray-700, #2b3d5c);
  margin-right: 12px;
  border: none;
  background-color: transparent;
  box-shadow: none;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f5f6f8 !important;
  }
}
