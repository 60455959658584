@import '@restream/styles/scss/tokens';

.root {
  padding: 12px;
  max-width: 275px;
}

.title,
.text,
.link {
  font-size: $font-size-body-sm;
  color: $color-gray-700;
  line-height: 20px;
}

.title {
  font-weight: 600;
  margin-bottom: 4px;
}

.link {
  text-decoration: underline;
}
