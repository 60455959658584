@use '@restream/styles/scss/tokens';

.root {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
}

.singleColumn {
  grid-template-columns: 1fr;
}

.inputWithCopy {
  display: flex;
  flex: 1;
}

.alert {
  grid-column: span 2;
  margin-top: 24px;
}
