@use '@restream/styles/scss/tokens';
@import '../../../../components/scss/animation';
@import '@restream/styles/scss/colors';

.item {
  display: flex;
  align-items: center;

  cursor: pointer;
  color: $bg-color--darken-1;
}

.item-dropdown {
  padding: 8px;
  transition: all 200ms ease;
  font-size: 14px;
}

.item-dropdown.highlighted {
  background: tokens.$color-gray-50;
}

.icon {
  height: 18px;
  margin-right: 8px;
}
