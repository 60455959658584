@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/colors';
@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/scrollbar';

.organizationSelectWrapper {
  &.organizationSelectWrapperError {
    .organizationMultiSelect {
      &__control {
        border-color: tokens.$color-red-500;
      }
    }
  }

  &:not(.organizationSelectWrapperError) {
    .organizationMultiSelect {
      &__control {
        &--is-open,
        &--is-focused {
          border-color: tokens.$color-blue-500;
        }
      }
    }
  }

  .organizationMultiSelect {
    &__control {
      border-radius: 8px;
      border: 1px solid tokens.$color-gray-300;
      box-sizing: border-box;
      box-shadow: none;
      cursor: pointer;
    }

    &__placeholder {
      color: tokens.$color-gray-300;
    }

    &__indicators {
      display: none;
    }

    &__value-container {
      min-height: 44px;
      padding: 10px;
      column-gap: 8px;
      row-gap: 8px;
      line-height: normal;
      box-sizing: border-box;

      &--has-value {
        padding: 8px 10px;
      }
    }

    &__multi-value {
      height: 28px;
      align-items: center;

      color: tokens.$color-gray-700;
      background: tokens.$color-gray-100;
      border-radius: 4px;
      padding: 4px 10px;
      margin: 0;
      box-sizing: border-box;

      &__label {
        display: flex;
        align-items: center;
        padding: 0;
        overflow: initial;
      }

      &__remove {
        width: 12px;
        height: 12px;
        padding: 0;
        cursor: pointer;
        margin-left: 4px;
        color: tokens.$color-gray-500;
        flex-shrink: 0;
        opacity: 0.8;

        &:focus:not(&:disabled),
        &:hover:not(&:disabled) {
          color: tokens.$color-gray-500;
          background-color: tokens.$color-gray-200;
          opacity: 1;
        }
      }
    }

    &__menu {
      width: 280px;
      padding: 4px 0;
      border-radius: 8px;
      overflow: hidden;
    }

    &__menu-list {
      @include scrollbar.scrollbar(
        $color: #bcbcbc,
        $hover-color: #bcbcbc,
        $active-color: #bcbcbc,
        $track-color: transparent
      );
    }

    &__menu-notice {
      color: tokens.$color-gray-900;
      font-size: tokens.$font-size-body-sm;
      line-height: 20px;
      text-align: left;
      padding: 8px 16px;
    }

    &__group-heading {
      margin-bottom: 8px;
      padding: 0 16px;
    }

    &__option {
      min-height: 52px;
      background-color: transparent;
      color: tokens.$color-gray-700;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      cursor: pointer;
      outline: none;
      transition: opacity 0.2s ease-out;

      &--is-disabled {
        opacity: 0.2;
      }

      &:active:not(&--is-disabled),
      &--is-focused:not(&--is-disabled) {
        background-color: tokens.$color-gray-50;
      }
    }
  }
}
