.liveEventsSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px 24px;
}

.buttonLink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 500;
  margin-top: 12px;
  padding: 0;
}

.arrowLinkRight {
  margin-left: 8px;
}
