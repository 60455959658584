@use '@restream/styles/scss/tokens';

.button {
  width: 100%;
  padding: 10px 16px;
  background-color: tokens.$color-blue-600;
  margin-top: 12px;

  &:hover:not(&:disabled) {
    background-color: tokens.$color-blue-700;
  }
}
