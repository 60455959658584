@use '@restream/styles/scss/tokens';
//@use "src/next/scss/colors" as colors;

$background: #172b4d;
//$background: colors.$bg-color--lighten-1;

.root {
  margin-bottom: 12px;
}

.root.zero-margin {
  margin-bottom: 0;
}

.header {
  border-radius: tokens.$border-radius-200;
  overflow: auto;
}

.header.open {
  border-radius: tokens.$border-radius-200 tokens.$border-radius-200 0 0;
}

.content {
  position: relative;
  padding: 24px 16px;
  background-color: $background;
  border-radius: 0 0 tokens.$border-radius-200 tokens.$border-radius-200;
}

.divider {
  height: 1px;
  background-color: rgba(9, 30, 66, 0.08);
  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;
}
