@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/variables' as variables;
@use '../../../../components/scss/modals' as modals;

$padding: variables.$grid-base-value;

.root {
  max-width: 100vw;

  @media (min-width: 576px) {
    width: 500px;
  }
}

.cards {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, 136px);
  align-items: center;
  justify-content: center;
  margin: 16px 0;

  @media (min-width: 576px) {
    grid-gap: 16px;
    margin: 24px 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;

  @include modals.xs {
    min-width: 300px;
  }
}

.upgrade {
  position: absolute;
  padding: 2px 4px;
  top: 5px;
  right: 5px;

  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ff6633;
}

.link {
  width: fit-content;
  margin: 24px auto 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  text-decoration: underline;
  color: #538cff;

  &:hover {
    text-decoration: none;
  }
}

.followBlock {
  user-select: none;
  margin: 0 auto;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 6px;
  color: #95a5a6;

  a {
    color: #95a5a6;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }

  input[type='checkbox'] {
    accent-color: #ecf0f1;
    margin-right: 6px;
    transition: opacity 0.2s ease-in-out;
    border: solid 1px #ecf0f1;

    &:checked {
      &:hover {
        accent-color: #ecf0f1 !important;
        background: #ecf0f1 !important;
        opacity: 0.9;
      }
    }
  }
}
