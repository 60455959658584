@use '~@restream/styles/scss/media' as media;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
  }

  .title {
    margin-top: 24px;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;

    color: #ffffff;

    @include media.sm {
      font-size: 36px;
      line-height: 44px;
    }
  }

  .desc {
    margin-top: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;

    color: #d7dce3;

    @include media.sm {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
