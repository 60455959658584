@use 'sass:math';
@use '@restream/styles/scss/outline';
@use '@restream/styles/scss/tokens';

$unit: 24px;
$accordionAnimationMs: 400ms;

.file {
  justify-content: center;
  flex-direction: column;
  height: auto;
  padding: 0;
}

.headingWrapper {
  background: transparent;
  border: none;
  padding: 12px 14px;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    & .chevronIcon {
      color: tokens.$color-blue-500;
    }
  }
}

.headingTitle {
  max-width: none;
}

.headingWrapper,
.headingTitle {
  display: flex;
  align-items: center;
}

.headingIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.headingIconContent {
  width: 16px;
  height: 16px;
  background: #2970ff;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 6px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.01em;
  color: #fff;
}

.headingTitle {
  margin: 0;
  max-width: none;
}

.headingBadge {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 42px;
  height: 22px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #175cd3;
  background-color: #eff8ff;
  border-radius: 999px;
  margin-left: 3px;
}

.recordingBlock {
  padding: 0 0 0 38px;
}

.recordingFile {
  background: inherit;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  box-shadow: none;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background: inherit;
    box-shadow: none;
  }
}

@keyframes delay-overflow {
  from {
    overflow: hidden;
  }
}

.contentWrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid $accordionAnimationMs;

  .isExpanded & {
    grid-template-rows: 1fr;
    overflow: visible;
    animation: $accordionAnimationMs delay-overflow;
  }
}

.content {
  min-height: 0;
}

.chevronIcon {
  height: 20px;
  aspect-ratio: 1;
  color: tokens.$color-gray-500;
  transition: color 0.25s, transform $accordionAnimationMs;

  .isExpanded & {
    transform: rotate(90deg);
  }
}

.buttonIconWrapper {
  margin-left: auto;
}
