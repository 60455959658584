@use '@restream/styles/scss/tokens';

.root {
  background-color: tokens.$color-yellow-25;
  border: 1px solid tokens.$color-yellow-300;
  color: tokens.$color-gray-700;
  padding: 8px;
  border-radius: tokens.$border-radius-150;

  & a {
    color: inherit;
    text-decoration: underline;
  }
}
