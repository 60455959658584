@use '@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

.root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  border-radius: 8px 8px 0 0;

  transition: 0.2s all ease-out;

  &.withOverlay,
  &:hover {
    background-color: rgba(0, 0, 0, 0.36);

    & .buttonWrapper,
    & .button {
      opacity: 1;
    }

    @include media.xs {
      background-color: transparent;
    }
  }

  &.withFadeOverlay {
    background-color: rgba(0, 0, 0, 0.36);

    &:hover {
      background-color: rgba(0, 0, 0, 0.46);
    }
  }

  &.withBlurOverlayOnHover {
    &:hover {
      & .blurredOverlay {
        backdrop-filter: blur(3px);
      }
    }
  }

  &.withClipsButton {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 8px;

    & .button {
      color: white;
    }
  }
}

.blurredOverlay {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;

  transition: all 0.2s ease-out;
}

.iconButtonsWrapper {
  display: flex;
  gap: 8px;
}

.button {
  color: tokens.$color-gray-200;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  border: 0 solid tokens.$color-gray-500;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  padding: 8px;
  opacity: 0;

  &:hover:not(&:disabled) {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
  }

  &.highlighted {
    opacity: 1;

    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.09) 100%
      ),
      rgba(255, 255, 255, 0.24);
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.13),
      0px 1px 0px 0px rgba(255, 255, 255, 0.07) inset;
    backdrop-filter: blur(7.5px);
  }
}

.button {
  opacity: 0;

  @include media.xs {
    opacity: 1;
  }
}

.icon {
  width: 20px;
}
