@use '@restream/styles/scss/tokens';

.root {
  background: #ff5630;
  border-radius: tokens.$border-radius-200;
  padding: 8px 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 12px;
  max-width: 500px;
}
