@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.stepperInnerWrapper {
  width: 100%;
  height: 100%;
  justify-content: start;

  @include mobileMedia() {
    display: none;
  }

  .stepperList {
    width: 100%;
    height: fit-content;
    background: none;
    border-radius: 0;
    flex-direction: column;
    align-items: start;
    padding: 32px 24px;

    @include mobileMedia() {
      padding: 16px;
    }

    @media (max-width: 768px) {
      padding: 24px 16px;
    }
  }

  .stepItem {
    margin-right: 0;
    margin-bottom: 10px;
    grid-column-gap: 6px;
  }

  .iconWrapper {
    &.notActive {
      background: transparent;
    }
  }
}

.stepIconOuter {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #eff4ff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 28px;
    width: 2px;
    height: 14px;

    background: #84adff;
    border-radius: 2px;
  }

  &.active:after {
    background: #2970ff;
  }

  &.final:after {
    display: none;
  }
}

.stepIconInner {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #b2ccff;

  &.active {
    background: #155eef;
  }
}

.stepTitle {
  font-weight: 500;
  font-size: 14px;
  color: #84adff;

  &.active {
    color: #ffffff;
  }

  &.completed {
    color: #b2ccff;
  }
}

.mobileStepper {
  display: none;
  width: 100%;
  height: 84px;
  box-sizing: border-box;
  padding: 16px 16px 20px;
  justify-content: space-between;
  align-items: center;

  @include mobileMedia() {
    display: flex;
  }
}

.mobileStepTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #fff;

  & span {
    font-weight: 500;
  }
}

.mobileStepDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #d1e0ff;
}
