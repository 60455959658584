@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/colors';

// TODO(color): use token
$color-placeholder: rgba(9, 30, 66, 0.25);
$color-border-focus: #286fff;
$color-border-error: #ff004a;
$color-border-default: #a5adba;

$transition-time: 0.25s;
$opacity-disabled: 0.5;

.root {
  display: flex;
  width: 100%;
  position: relative;
}

.textarea {
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  font-family: inherit;

  width: 100%;
  transition: border-color 0.3s;
  padding: 10px 12px;
  border-radius: tokens.$border-radius-200;
  outline: none;
  resize: none;

  color: tokens.$color-gray-900;
  background: tokens.$color-white;
  border: tokens.$border-width solid $color-border-default;
}

:global(.dark-theme) {
  .textarea {
    background: $bg-color--lighten-1;
    color: $fg-color;
    border: tokens.$border-width solid $bg-color--lighten-1;

    &::placeholder {
      color: $fg-color--darken-3;
    }
  }
}

.textarea:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.textarea:focus {
  border-color: $color-border-focus;
}

.textarea.error {
  border-color: $color-border-error;
}

.textarea::placeholder {
  /* stylelint-disable-line no-descending-specificity */
  color: $color-placeholder;
}

.counter {
  position: absolute;
  right: 8px;
  bottom: -8px;
  padding: 2px 4px;

  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  font-family: inherit;

  background: $color-border-default;
  color: tokens.$color-white;

  border-radius: tokens.$border-radius-200;
  transition: background-color $transition-time;
}

.textarea:focus + .counter {
  background: $color-border-focus;
}

.textarea.error + .counter {
  background: $color-border-error;
}

.textarea:disabled + .counter {
  opacity: $opacity-disabled;
}
