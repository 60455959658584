@use '@restream/styles/scss/tokens';

@mixin small {
  @container (max-width: 720px) {
    @content;
  }
}

@mixin extraSmall {
  @container (max-width: 500px) {
    @content;
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  padding: 16px;

  container-type: inline-size;

  @include extraSmall {
    padding: 16px;
    background: red;
  }

  @include small {
    padding: 8px;
  }
}

.tip {
  color: rgba(255, 255, 255, 0.3);
  text-align: center;

  /* Body xs - 12/Normal */
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-10, 12px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 18px;

  margin-top: 16px;

  @include small {
    display: none;
  }
}

.tutorialButton {
  color: var(--Blue-400, #528bff);
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */

  margin: 0 0 8px;
  padding: 0;
  background: none;
  border: none;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @include small {
    display: none;
  }
}

.tutorialsModal {
  width: 608px;
}

.rtmpSettingsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  transform: translatey(-5%);

  @include small {
    transform: translatey(0);
  }
}

.backupButtonWrapper {
  @include small {
    display: none;
  }
}

.playIcon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.promoBanner {
  margin-top: 20px;

  & > div {
    background: #191333;
  }
}

.promoBannerPrompt {
  padding-right: 6px;
}

.backupButton {
  color: tokens.$color-blue-400;
  font-size: 12px;

  margin-top: 8px;

  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
