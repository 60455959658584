@use '@restream/styles/scss/media';

.root {
  width: 800px;

  @include media.md {
    width: 720px;
  }

  @include media.md {
    width: 540px;

    max-height: 90%;
    height: 90%;
  }

  @include media.xs {
    width: 100%;

    max-height: 90%;
    height: 90%;
  }

  &.fullHeight {
    @include media.xs {
      display: grid;
      grid-template-rows: min-content auto min-content;
      height: inherit;
    }
  }

  &.mobileOnboardingModalRoot {
    @include media.xs {
      max-height: none;
      height: inherit;
      grid-template-rows: auto min-content;
    }
  }

  &.extendedOnboardingInterface {
    width: 500px;
  }
}
