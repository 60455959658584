@use '@restream/styles/scss/tokens';

.subtitleLink {
  font-weight: 500;
  text-decoration: underline;
  color: inherit;
  margin-left: 4px;
}

.copyButton {
  font-size: tokens.$font-size-body-sm;
}

.footer {
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}
