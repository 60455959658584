@use '@restream/styles/scss/tokens';

.surveyContent {
  padding: 24px 40px 40px;
}

.surveyTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: tokens.$color-gray-900;
  text-align: center;
}

.surveyOptionLabel {
  text-align: center;
}

.modalFooter {
  padding: 24px 40px;
  border-top: 1px solid tokens.$color-gray-100;
}

.submitButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  min-width: fit-content;
  background-color: tokens.$color-blue-600;
  border-radius: tokens.$border-radius-200;

  cursor: pointer;
  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  &:hover:not(:disabled) {
    background: #4280ff;
  }
}

.submitButtonIcon {
  display: block;

  & path {
    stroke: tokens.$color-white;
  }
}
