.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  background: none;
  cursor: pointer;

  flex-grow: 0;
  margin: 0;
  border: none;
  color: #286fff;

  font-size: inherit;
  line-height: inherit;
  font-weight: 500;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #286fff;
  }

  transition: opacity 200ms;
}

a.button {
  text-decoration: none;
}
