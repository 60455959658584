@use '@restream/styles/scss/tokens';

.root {
  background-color: tokens.$color-red-25;
  border: 1px solid tokens.$color-red-300;
  border-radius: tokens.$border-radius-100;
  color: tokens.$color-red-600;
  padding: 6px 8px;
  display: flex;
  gap: 8px;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.linkStyleButton {
  border: none;
  background: none;
  cursor: pointer;
  color: tokens.$color-red-600;
  padding: 0;
  color: currentColor;

  display: inline-flex;

  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  transition: all 0.2s ease;
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover:not(:disabled) {
    text-decoration: none;
    background-color: transparent;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.link {
  color: tokens.$color-red-600;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;

  text-decoration: underline;
  transition: color 0.2s ease;

  &:hover:not(:disabled) {
    text-decoration: none;
    background-color: transparent;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
