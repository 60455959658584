@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media';
@use '~@restream/styles/scss/colors';
//@use '~@restream/styles/scss/media-mf' as media-mf;
@use '~@restream/styles/scss/outline';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -32px;

  &:before {
    margin-top: 60px;
    content: '';
  }

  @include media.lg {
    flex: initial;

    &:before {
      margin-top: 30px;
      content: '';
    }
  }

  @include media.lg {
    margin-top: 0;
  }

  @include media.md {
    margin-top: -32px;
  }

  @include media.xs {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  @include media.xs {
    width: 100%;
  }
}

.destinations {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 22px;

  @include media.xl {
    justify-content: center;
  }

  @include media.xs {
    margin-bottom: 0;
  }
}

.playlistsWrapper {
  margin-top: 16px;
}

.playlistsWrapperB {
  margin-top: 0;
  width: 100%;
}

.homeDestinationsMobileWrapper {
  margin-top: 24px;
}

.destinationsNext {
  margin-top: -16px;
}

.coreFeatures {
  display: grid;
  grid-template-columns: repeat(1, 100%);

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 400px;
  max-width: 494px;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    &:not(:last-child) {
      margin-right: 16%;
    }
  }

  //display: grid;
  //gap: 16px;

  //justify-items: center;
  //
  //grid-template-columns: repeat(3, minmax(0px, 147px));

  @include media.xl {
    //display: flex;
    //align-items: center;

    //width: 100%;
    //max-width: 780px !important;
  }
}

.coreFeature {
  width: auto;
  height: auto;
}

button.coreFeature {
  padding: 0;
  outline: none;
  border: none;
  background: none;

  &:focus {
    opacity: 0.8;
  }
}

.coreFeatureWrapper {
  width: auto;
  height: auto;

  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 16px;

  &.mainButton {
    margin-left: 0;
    margin-right: 0;
  }

  div,
  button {
    width: 100%;
    height: 100%;
  }
}

.coreFeatureButton {
  font-family: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;

  @include outline.focus-visible-outline;
}

.button {
  margin-right: 8px;
}

.buttonIcon {
  margin-right: 8px;
}

.secondaryCoreFeatureWrapper {
  //height: 48px;
  margin: 0;
}

.listItemsContainer {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  row-gap: 1px;
  margin: 0;
  border-radius: tokens.$border-radius-200;
  height: auto;
}

.destinationsVariantD {
  margin-bottom: 42px;
  justify-content: center;
}

@media (max-width: 410px) {
  .coreFeatures {
    width: 100% !important;
  }
}

.mainCoreFeaturesWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  column-gap: 16px;
  margin: 0;
  flex: 1;
}

.onBoardingImageRoot {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: colors.$color-dark-bg-2;
  transition: opacity 250ms ease-in-out;
  opacity: 1;

  // padding-bottom: 16px;
  margin-top: -120px;

  .imageWrapper {
    width: 100%;
    display: flex;
    position: relative;

    @include media.xs {
      background-size: 100% !important;
    }

    img {
      margin: 50px auto 30px auto;
      opacity: 0.85;
      z-index: 1;
    }
  }

  .imageWrapperContent {
    height: 120px;
  }

  @include media.xs {
    margin-top: -60px;
  }
}

.onBoardingImageRootHidden {
  transition: none;
  margin-top: 0;
  opacity: 0;
  height: 0;
}

.contentOnBoarding {
  //width: 420px;
  overflow: hidden;
  position: relative;
  border-radius: tokens.$border-radius-200;
  margin: -12px 0;
  padding: 4px;

  .contentOnBoardingHighlight {
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;

    background: linear-gradient(
      45deg,
      rgb(243 193 36 / 80%),
      rgb(243 193 36 / 10%)
    );
    animation: ContentOnBoardingHighlightKeyframes 2s linear infinite;
  }

  .coreFeatures {
    width: 416px;
    padding: 8px;
    border-radius: tokens.$border-radius-200;
    background: #182a4e;
    z-index: 1;
  }
}

@keyframes ContentOnBoardingHighlightKeyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.videoStorageBanners {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -30px;
  margin-top: 0;
}
