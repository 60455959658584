@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 16px 12px;

  background: #fffcf5;
  border: 1px solid #fec84b;
  border-radius: tokens.$border-radius-200;
}

.warningIcon {
  min-width: 20px;
  margin-right: 10px;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: #b54708;
}
