@use './colors' as colors;
//@import '~@restream/ui-kit/dist/ui-kit.cjs.production.min.css';
@import '../src/views/titles/elements/select/Select';
@import './intercom';
@import './animate';

* {
  box-sizing: border-box;
}

html {
  font-family: Graphik, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.25;
  //font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  background: colors.$body-background;
  color: colors.$body-color;
  font-size: colors.$body-font-size;
  line-height: colors.$body-line-height;
  min-height: 100vh;

  code {
    color: colors.$body-background;
  }
}

header {
  padding: 14px 24px;
  text-align: center;
  font-size: 12px;
  color: white;
  width: 100%;
}

main {
  flex: 1;
  width: 100%;
}

nav {
  padding: 20px;
}

aside {
  padding: 20px;
}

h1 {
  font-size: 48px;
  line-height: 1.25;
  color: white;
  margin-bottom: 20px;
  font-weight: 500;
}

h3 {
  font-size: 32px;
  line-height: 1.5;
  color: white;
  margin-bottom: 20px;
  font-weight: 500;
}

h4 {
  font-size: 24px;
  line-height: 1.4;
  color: white;
  margin-bottom: 18px;
  font-weight: 500;
}

h5 {
  font-size: 18px;
  line-height: 1.66;
  color: #ebecf0;
  margin-bottom: 12px;
  font-weight: 200;
}

p {
  font-size: 18px;
  line-height: 1.6;
  padding: 10px 0;
  color: #a5adba;
}

a {
  color: #4c9aff;
  text-decoration: none;
}

.link {
  color: #4c9aff;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.link:hover {
  box-shadow: inset 0 -1px 0 0 #4c9aff;
}

.body {
  height: 100%;
  background: colors.$body-background;
  color: colors.$body-color;
  font-size: colors.$body-font-size;
  line-height: colors.$body-line-height;
  min-height: 100vh;
}

button {
  font-family: inherit;
}

.grecaptcha-badge {
  visibility: hidden;
}
