@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '~@restream/styles/scss/colors' as colors;

.container {
  background-color: #253858;
  border-radius: tokens.$border-radius-200;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;

  max-width: 380px;
  min-height: 280px;
  @include media.md {
    max-width: none;
  }
}

.statsSection {
  margin: 15px 0 15px 0;
}

.sectionTitle {
  font-size: 24px;
  color: #ffffff;
}

.statsPart {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;

  font-size: 16px;
}

.statsLabel {
  color: #aeb4bf;
  font-weight: normal;
}

.statsNumber {
  color: #ffffff;
  font-weight: 700;
}

.progressBar {
  background: #344563;
  width: 100%;
  height: 8px;
  margin-top: 6px;
  border-radius: tokens.$border-radius-200;
}

.progress {
  border-radius: tokens.$border-radius-200;
  height: 100%;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#28beff+0,00d1b1+50,b29bff+100 */
  background: #28beff; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #28beff 0%,
    #00d1b1 50%,
    #b29bff 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #28beff 0%,
    #00d1b1 50%,
    #b29bff 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #28beff 0%,
    #00d1b1 50%,
    #b29bff 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28beff', endColorstr='#b29bff',GradientType=1 ); /* IE6-9 */
}

.withdrawBtn {
  width: 100%;
  height: 48px;

  color: #091e42;

  background: #28beff; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #28beff 0%,
    #00d1b1 50%,
    #b29bff 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #28beff 0%,
    #00d1b1 50%,
    #b29bff 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #28beff 0%,
    #00d1b1 50%,
    #b29bff 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28beff', endColorstr='#b29bff',GradientType=1 ); /* IE6-9 */

  &:enabled {
    &:hover {
      border: 2px solid #fff;
      background: #1f84b1 !important; /* Old browsers */
      background: -moz-linear-gradient(
        right,
        #28beff 0%,
        #00d1b1 50%,
        #b29bff 100%
      ) !important; /* FF3.6-15 */
      background: -webkit-linear-gradient(
        right,
        #28beff 0%,
        #00d1b1 50%,
        #b29bff 100%
      ) !important; /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to left,
        #28beff 0%,
        #00d1b1 50%,
        #b29bff 100%
      ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#28beff', endColorstr='#b29bff', GradientType=1) !important; /* IE6-9 */
    }
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.7);

    background: #253858;
    border: 2px solid #344563;
  }
}
