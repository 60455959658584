@use '@restream/styles/scss/tokens';

.root {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;

  height: 32px;
  width: 32px;

  border-radius: tokens.$border-radius-200;
  transition: background-color 200ms;

  svg {
    opacity: 0.5;
    transition: opacity 200ms;
  }

  &:hover {
    background: #253858;

    svg {
      opacity: 1;
    }
  }

  &.active {
    background: #253858;

    svg {
      opacity: 1;
    }
  }
}

.tooltipTrigger {
  display: flex;
}

.checkIconWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
