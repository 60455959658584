@use '~@restream/styles/scss/outline';
@use '~@restream/styles/scss/media' as media;

@mixin active-color($color) {
  color: $color;
  border-bottom-color: $color;
}

.root {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-start;

  @include media.sm {
    flex-direction: column;
  }
}

.tabs {
  font-size: 12px;
  line-height: normal;
  border-bottom: 1px solid #253858;

  .dark & {
    border-bottom-color: #253858;
  }
  .light & {
    border-bottom-color: #ebecf0;
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.tab {
  cursor: pointer;
  font-family: inherit;
  z-index: 1;
  padding: 10px 24px;
  background: transparent;
  border: none;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  color: #97a0af;
  transition: color 200ms ease, border-bottom-color 350ms ease;

  @media (max-width: 768px) {
    padding: 10px 5px;
  }

  @media (max-width: 1080px) {
    font-size: 10px;
    padding: 10px;
  }

  & + & {
    margin-left: 1px;
  }

  &:hover {
    color: #fff;
    border-bottom-color: #fff;
  }

  &.tabActive {
    color: #fff;
    border-bottom-color: #fff;
  }

  .light &:hover {
    @include active-color(#000);
  }
  .light &.tabActive {
    @include active-color(#000);
  }

  .dark &:hover {
    @include active-color(#fff);
  }
  .dark &.tabActive {
    @include active-color(#fff);
  }

  @include outline.focus-visible-outline;
}
