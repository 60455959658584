@use '~@restream/styles/scss/colors';
@use '~@restream/styles/scss/outline';
@use '@restream/styles/scss/tokens';

.root {
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px;
  border: 0;
  border-radius: tokens.$border-radius-200;

  // TODO(color): use token
  color: rgba(#fff, 0.9);
  background-color: #344563;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;

  position: relative;
  cursor: pointer;
  transition: background-color 200ms ease-out;

  &:hover {
    color: tokens.$color-white;
    background-color: #374561;
  }

  @include outline.focus-visible-outline;
}

.icon {
  margin: auto;
  vertical-align: middle;
}
