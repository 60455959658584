.root {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 128px;
}

.image {
  width: 128px;
  margin: 16px;
}

.message {
  background: white;
  border-radius: 50%;
  padding: 16px;
  text-align: center;
  color: #172b4d;
}

.bubble {
  background: white;
  border-radius: 50%;
  width: 20px;
  height: 25px;
}
