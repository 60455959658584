@use '@restream/styles/scss/tokens';
@import '../../../../components/scss/modals';
@import '../../../../components/scss/typography';

.root {
  @include modal-width;
}

.body {
  @media screen and (max-width: 576px) {
    padding: 12px;
  }
}

.buttons {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.moreEventsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.moreEvents {
  color: tokens.$color-gray-700;
  font-size: 14px;
  text-wrap: pretty;
}

.eventCards {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.eventCard {
  margin-bottom: 0;
}
