@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.root {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;

  color: var(--Gray-300, #bac1cc);
  text-align: right;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  display: flex;
  align-items: center;

  transition: all 200ms ease-in-out;

  svg {
    width: 18px;
    height: 18px;
    stroke: currentColor;

    path {
      stroke-width: 1.2px;
    }
  }

  &:hover {
    color: #ffffff;
  }

  @include media.sm {
    font-size: 12px;
    gap: 8px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.compact {
  flex-direction: column;
  border-radius: 8px;
  font-size: 11px;
  line-height: 12px;
  width: 56px;
  height: 56px;
  padding: 10px 0 8px 0;
  font-weight: 400;

  &:hover {
    background: tokens.$color-gray-700;
  }
}
