.root {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;

  .button {
    &:focus-visible {
      outline: none;
    }
  }

  // we hide drag cursor on all elements
  // to enable it only on drag handle icon
  & > * {
    cursor: initial !important;
  }
}

.container {
  margin-bottom: 10px;
}
