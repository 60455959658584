@import '../scss/colors';

.container {
  display: flex;
  opacity: 1;
}

.container-hoverable {
  display: flex;
  opacity: 0.5;
}

.container-hoverable:hover {
  opacity: 0.8;
}

.icon {
  color: $fg-color;
}
