@use '@restream/styles/scss/tokens';
@import '../scss/colors';
@import '../scss/grid';

$bg-color: $bg-color--darken-3;
$grid-base-value: 6px;
$border-width: 1px;

.trigger {
  width: fit-content;
  height: fit-content;
}

.container {
  // TODO(color): use token
  background-color: $bg-color;
  color: $fg-color;
  border: tokens.$border-width solid $bg-color;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  font-weight: normal;
  // TODO(radius): use token
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: $grid-base-value;
  transition: opacity 0.3s;
  z-index: 2147483647;
  max-width: 300px;
  overflow-wrap: break-word;

  .arrow {
    height: 10px;
    width: 10px;
    position: absolute;
    pointer-events: none;

    &::before {
      border-style: solid;
      content: '';
      display: block;
      height: 0;
      margin: auto;
      width: 0;
    }

    &::after {
      border-style: solid;
      content: '';
      display: block;
      height: 0;
      margin: auto;
      position: absolute;
      width: 0;
    }
  }

  &[data-popper-placement='bottom'] {
    .arrow {
      top: 0;
      margin-top: -5px;

      &::before {
        // TODO(color): use token
        border-color: transparent transparent $bg-color transparent;
        border-width: 0 5px 4px 5px;
        position: absolute;
      }

      &::after {
        // TODO(color): use token
        border-color: transparent transparent $bg-color transparent;
        border-width: 0 5px 4px 5px;
      }
    }
  }

  &[data-popper-placement='top'] {
    .arrow {
      bottom: 0;
      margin-bottom: -11px;
      // TODO(color): use token

      border-color: $bg-color transparent transparent transparent;
      border-width: 4px 5px 0 5px;

      &::before {
        border-color: $bg-color transparent transparent transparent;
        border-width: 4px 5px 0 5px;
        position: absolute;
      }

      &::after {
        border-color: $bg-color transparent transparent transparent;
        border-width: 4px 5px 0 5px;
      }
    }
  }

  &[data-popper-placement='right'] {
    .arrow {
      left: 0;
      margin-left: -8px;
      // TODO(color): use token

      &::before {
        border-color: transparent $bg-color transparent transparent;
        border-width: 5px 4px 5px 0;
      }

      &::after {
        border-color: transparent $bg-color transparent transparent;
        border-width: 5px 4px 5px 0;
        top: 0;
        left: 3px;
      }
    }
  }

  &[data-popper-placement='left'] {
    .arrow {
      right: 0;
      margin-right: -8px;
      // TODO(color): use token

      &::before {
        border-color: transparent transparent transparent $bg-color;
        border-width: 5px 0 5px 4px;
      }

      &::after {
        border-color: transparent transparent transparent $bg-color;
        border-width: 5px 0 5px 4px;
        top: 0;
        left: 3px;
      }
    }
  }
}
