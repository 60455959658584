@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 16px;
  min-width: 300px;
}

.mainContent {
  display: flex;
  margin-bottom: 12px;
}

.iconRoot {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.iconChannel,
.iconWrapper {
  width: 32px;
  height: 32px;
  border-radius: tokens.$border-radius-150;
}

.iconPlatform {
  width: 20px;
  height: 20px;
  bottom: -2px;
  right: -2px;
}

.errorsCount {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-500;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.arrowBack,
.arrowNext {
  padding: 0;
  background-color: transparent;

  &:disabled {
    cursor: initial;

    & .arrowBackIcon path,
    & .arrowNextIcon path {
      fill: tokens.$color-gray-200;
    }
  }

  &:hover:not(&:disabled) {
    background: tokens.$color-gray-50;
    border-radius: 4px;
  }
}

.arrowBack {
  margin-right: 8px;

  .arrowBackIcon {
    transform: rotate(180deg);
  }
}

.arrowBackIcon path,
.arrowNextIcon path {
  fill: tokens.$color-gray-500;
}
