@use '@restream/styles/scss/tokens';

.dropDown {
  margin-top: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid tokens.$color-gray-100;

  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  & .selectedItem {
    background-color: tokens.$color-gray-50;
  }

  & .comingSoonItem {
    justify-content: space-between;
  }
}

.comingSoonLabel {
  color: tokens.$color-blue-500;
  font-weight: 500;
}

.dropDownTriggerButton {
  padding-top: 12px;
  padding-bottom: 12px;
  color: black;

  &:focus-visible,
  &.active {
    outline: none;
    box-shadow: inset 0 0 0 1px tokens.$color-blue-500;
  }

  &.active {
    & .icon {
      transform: rotate(180deg);
    }
  }

  & .icon {
    width: 12px;
    color: tokens.$color-gray-500;

    transition: transform 0.15s ease-out;
  }
}
