@use '../../scss/grid' as grid;

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: grid.$grid-base-value * 12;
}

.image {
  margin-bottom: grid.$grid-base-value * 2;
}

.title {
  margin-bottom: grid.$grid-base-value;
}

.subtitle {
  margin-bottom: grid.$grid-base-value * 4;
  text-align: center;
}
