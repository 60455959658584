@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@import '../../../../scss/mixins';

.root {
  background-color: tokens.$color-gray-700;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: none;
  width: 32px;
  height: 32px;

  color: #edf0f3;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  position: relative;

  @include media.sm {
    @include display-flex-center;
    justify-content: center;
  }
}
