@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/modals' as modals;

.root {
  @include modals.modal-width;
}

.mediaWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.media {
  width: 100%;
  border-radius: tokens.$border-radius-200;
  max-height: 256px;
}
