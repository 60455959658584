@use '@restream/styles/scss/tokens' as tokens;

.container {
  position: relative;
}

.root {
  animation: skeleton-glow 1s linear infinite alternate;
}

@keyframes skeleton-glow {
  from {
    opacity: 0.1;
    border-color: tokens.$color-white;
    background-color: tokens.$color-white;
  }

  to {
    opacity: 0.2;
  }
}

.skeletonWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: tokens.$color-gray-100;

  z-index: 1;
}

.skeleton {
  animation: skeleton-glow 1s linear infinite alternate;
}

@keyframes skeleton-glow {
  from {
    opacity: 0.1;
    border-color: tokens.$color-white;
    background-color: tokens.$color-white;
  }

  to {
    opacity: 1;
  }
}
