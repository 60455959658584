.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.channelName {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #091e42;
  transition: color 0.2s ease;
  z-index: 1; // to be above modal header buttons container

  &:hover {
    text-decoration: underline;
    color: #344563;
  }
}
