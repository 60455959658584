@use '@restream/styles/scss/tokens';

$dark-color-input-fg: tokens.$color-gray-300;
$dark-color-input-bg: tokens.$color-gray-700;
$dark-color-border: tokens.$color-gray-600;

$transparent-theme-color-input-fg: rgba(255, 255, 255, 0.9);
$transparent-theme-color-input-fg-darken: rgba(255, 255, 255, 0.5);
$transparent-theme-color-input-bg: rgba(255, 255, 255, 0.1);
$transparent-theme-color-border: rgba(255, 255, 255, 0.15);
