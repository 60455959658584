@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/outline';
@use '../../../../scss/typography';
@use '../../../../scss/colors';

.root {
  display: grid;
  grid-gap: 20px;

  .form-group:last-child {
    padding-bottom: 0;
  }
}

.info {
  // TODO(color): use token
  background-color: #ebecf0;
  border-radius: tokens.$border-radius-200;
  padding: 16px;

  display: grid;
  grid-gap: 8px;
}

.infoHeader {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.infoTitle {
  color: tokens.$color-gray-900;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.infoText {
  // TODO(color): use token
  color: #505f79;
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  letter-spacing: 0.01em;
}

.learn {
  @include typography.font-14;
  margin-bottom: 24px;

  .streamingSettingsTheme & {
    margin-top: 12px;
    margin-bottom: 0;
    letter-spacing: 0.01em;
    font-weight: 400;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
    color: tokens.$color-gray-900;
  }
}

.link {
  color: #286fff;

  &:hover {
    text-decoration: underline;
  }
}

.form-group {
  display: grid;
  grid-gap: 12px;
  margin: 0;
  padding: 0;
}

.label {
  //TODO (color): use token
  color: #a5adba;
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  margin-bottom: -4px;
}

.inputWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.inputRoot {
  padding: 0;
  margin: 0;
}

.input {
  color: colors.$fg-color--darken-1;
  background: rgb(37, 56, 88);
  border: none;
  box-shadow: none;
  height: 40px;

  .streamingSettingsTheme & {
    color: tokens.$color-gray-900;
    background-color: tokens.$color-white;
    box-shadow: inset 0 0 0 1px #a5adba;
  }
}

.icon {
  margin-right: 12px;
}

.copyButton {
  cursor: pointer;
  min-width: 56px;
  width: fit-content;
  height: 40px;

  font-weight: 600;
  // TODO(font): use token
  font-size: 13px;
  line-height: 22px;
  color: tokens.$color-white;
  // TODO(color): use token
  background-color: #286fff;
  border: none;
  transition: background-color 100ms ease, opacity 300ms ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    box-shadow: none;
  }

  @include outline.focus-visible-outline;

  &.copiedButton {
    background-color: #0a7;
  }

  @media (min-width: 768px) {
    min-width: 66px;
    height: 40px;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
  }
}
