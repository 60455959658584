@use '~@restream/styles/scss/colors' as colors;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-bottom: 16px;
}

.imageWrapper {
  width: 100%;
  display: flex;
  position: relative;

  img {
    margin: 50px auto 30px auto;
    opacity: 0.85;
    z-index: 1;
  }
}

.connectChannelsButton {
  align-items: center;
  text-align: center;
  margin: 0 0 24px;
  font-size: 18px;

  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  box-shadow: 0 0 0 1px rgb(255 255 255 / 80%);
  color: #ebecf0;
  padding: 8px 12px;
  border-radius: 12px;
  opacity: 0.9;
  transition: opacity 200ms ease, color 200ms ease, box-shadow 200ms ease;

  &:hover {
    color: #fff;
    opacity: 1;
    box-shadow: 0 0 0 1px #fff;
  }
}

.button {
  font-size: 1em;
  line-height: 1.5rem;
  padding: 6px 1rem;

  &:first-child {
    margin-right: 8px;
  }
}

.buttonContent {
  display: flex;
  align-items: center;
}

.buttonIcon {
  margin-right: 8px;
}

.addChannelButton {
  min-width: 160px;
  justify-content: center;
}
