@use '@restream/styles/scss/tokens';
@import '../../constants';

.videoCardSkeleton {
  display: grid;
  grid-template-columns: $videoCardThumbnailWidth auto;
  grid-column-gap: 8px;
  margin: 0 8px 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.thumbnailSkeleton {
  width: $videoCardThumbnailWidth;
  height: $videoCardThumbnailHeight;
  background: $skeletonBackground;
  border-radius: tokens.$border-radius-200;
}

.titleSkeleton {
  width: 100%;
  height: 15px;
  margin-bottom: 8px;
  border-radius: tokens.$border-radius-50;
  background: $skeletonBackground;
}

.descriptionSkeleton {
  width: 40%;
  height: 15px;
  border-radius: tokens.$border-radius-50;
  background: $skeletonBackground;
}
