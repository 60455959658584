.root {
  color: #bac1cc;

  text-align: right;
  leading-trim: both;

  text-edge: cap;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  span {
    color: #fafbfc;
  }
}
