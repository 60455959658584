.subtitle {
  margin-top: 6px;
  margin-bottom: 2px;
}

.body {
  padding: 12px 24px;
}

.footer {
  padding-top: 6px;
  padding-bottom: 26px;
}

.image {
  width: 100%;
  border-radius: 12px;
}

.gradientLinkContainer {
  position: relative;
  display: block;
  z-index: 1;

  animation: Bounce 5000ms 2000ms ease infinite;
  transition: transform 300ms ease;

  &:hover {
    animation-play-state: paused;
  }

  &::after {
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    right: -2px;
    left: -2px;
    z-index: -1;
    border-radius: 12px;
    background: linear-gradient(
      30.49deg,
      #0044cc -4.93%,
      #9700cc 27.92%,
      #ba7bd1 47.18%,
      #9700cc 70.41%,
      #0044cc 98.81%
    );
  }
}

.link {
  display: block;
  font-family: inherit;
  border-radius: 10px;
  background: #fff;
  padding: 12px 24px;
  position: relative;

  font-weight: 500;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.gradientText {
  font-weight: 500;
  font-size: 18px;
  background: linear-gradient(
    95.97deg,
    #b048f3 10%,
    #a313fe 57.37%,
    #e24bdf 81.77%,
    #6859ca 96.61%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  border-bottom: 0;
}

.invisibleLink {
  background-color: transparent;
  color: transparent;
  text-decoration: none;
  outline: none;
}

@keyframes Bounce {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.1);
  }

  40% {
    transform: scale(1);
  }
}
