@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 8px 14px;
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  border-radius: tokens.$border-radius-200;
  color: tokens.$color-white;
  background-color: tokens.$color-blue-500;
  transition: all 0.2s;

  &:hover:not(&:disabled) {
    background-color: tokens.$color-blue-600;
  }
}

.camIcon {
  margin-right: 8px;
}
