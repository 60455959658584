@use '~@restream/styles/scss/media' as media;

.root {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
}

.content {
  width: 100%;
  max-width: 640px;
  margin: 24px auto 0;

  @include media.sm {
    margin-top: 12px;
    padding: 0 24px;
  }

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.contentHeader {
  .contentTitle {
    margin-top: 16px;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;

    color: #ffffff;

    @include media.sm {
      font-size: 36px;
      line-height: 44px;
    }
  }

  .contentText {
    margin-top: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;

    color: #d7dce3;

    @include media.sm {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.emailContainer {
  margin-top: 32px;
  padding: 12px 44px;
  width: 100%;

  background: #2b3d5c;
  border: 1px solid #2b3d5c;
  border-radius: 8px;

  @include media.sm {
    padding: 12px 24px;
  }

  .emailTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #6b778c;
  }

  .emailText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #97a0af;
  }
}

.subscriptions {
  padding-top: 16px;
}

.subscriptionsDivider {
  width: 100%;
  height: 1px;
  background: #42526e;
  margin-top: 24px;
}

.submitButtonContainer {
  margin: 48px 0;

  @include media.sm {
    margin: 40px 0;
  }
}

.submitButton {
  min-width: 169px;
  min-height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  padding: 10px 18px;

  background: #2970ff;
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  cursor: pointer;

  transition: background-color 0.2s ease-in;

  @include media.sm {
    width: 100%;
  }

  &:hover,
  &:focus {
    background: #155eef;
  }

  &.disabled {
    cursor: initial;
  }

  &.loading,
  &.loading:hover,
  &.loading:focus {
    background: rgba(41, 112, 255, 0.2);
  }

  &.error,
  &.error:hover,
  &.error:focus {
    background: #f04438;
  }

  &.success,
  &.success:hover,
  &.success:focus {
    background: #12b76a;
  }

  svg {
    margin-right: 8px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 36px;
  height: 36px;
  border: 4px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &.buttonLoader {
    width: 17.5px;
    height: 17.5px;
    border: 2.5px solid #fff;
    border-bottom-color: #2970ff;
  }
}

.submitError {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #f97066;
}
