@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
}

.text {
  font-size: 16px;
  line-height: 32px;
  color: tokens.$color-gray-400;
}
