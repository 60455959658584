@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/outline';
@use '@restream/styles/scss/media';

.root {
  display: grid;
  grid-template-columns: 1fr 3fr;

  background-color: tokens.$color-gray-700;
  width: 100%;

  border-radius: tokens.$border-radius-200;
  overflow: hidden;

  max-height: 400px;
  aspect-ratio: 36 / 16;

  &.highlight {
    animation: highlight 1.2s ease-out;
  }

  @include media.xs {
    display: flex;
    flex-direction: column;
    aspect-ratio: initial;

    max-height: none;
  }
}

@keyframes highlight {
  50% {
    background-color: tokens.$color-gray-600;
  }
}

.videoContent {
  position: relative;
}

.badgeWrapper {
  position: absolute;
  top: 8px;
  right: 8px;
}

.badge {
  font-weight: 400;
  font-size: 12px;

  padding: 8px 16px;
  border-radius: 9999px;
  background: transparent;
  backdrop-filter: blur(12px);
}

.mainContent {
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 24px;

  overflow: hidden;

  @include media.xs {
    padding: 16px;
  }
}

.viralityScoreWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;

  background: #3e20e2;

  &.low {
    background: #42526e;

    & .viralityScoreTextValue {
      border-color: rgba(255, 255, 255, 0.1);
    }
  }

  &.medium {
    background: #564cff;
  }

  &.high {
    background: #3e20e2;
  }
}

.viralityScoreText,
.viralityScoreTextValue {
  color: white;
  font-size: 12px;
  line-height: 18px;
}

.viralityScoreText {
  padding-right: 4px;
}

.viralityScoreTextValue {
  display: inline-flex;
  align-items: center;

  height: 100%;
  padding-left: 4px;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  font-weight: 600;
}

.progressWrapper,
.videoIframe {
  width: 100%;
  height: 100%;

  flex-shrink: 0;

  @include media.xs {
    height: auto;
    aspect-ratio: 9/16;
  }
}

.progressWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: tokens.$color-gray-900;
}

.progress {
  width: 80%;

  &::after {
    background-color: tokens.$color-blue-500;
  }
}

.title {
  font-size: 24px;
  line-height: 30px;

  margin: 12px 0;

  @include media.sm {
    font-size: 16px;
    line-height: 20px;
    margin: 12px 0;
  }
}

.subtitle {
  font-size: 14px;
  line-height: 22px;
}

.title,
.subtitle {
  @include mixins.truncate-text();

  color: tokens.$color-gray-100;
  font-weight: 500;
}

.viralityScoreWrapperSkeleton {
  display: inline-block;

  height: 24px;
  width: 124px;

  border-radius: 4px;
  vertical-align: middle;
}

.transcript,
.description {
  color: tokens.$color-gray-300;
  font-size: 13px;
  line-height: 20px;
}

.transcript {
  width: 100%;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  font-style: italic;

  @include media.sm {
    -webkit-line-clamp: 4;
  }

  @media (max-width: 680px) {
    -webkit-line-clamp: 3;
  }
}

.buttonsWrapper {
  display: flex;
  column-gap: 8px;
  row-gap: 8px;

  margin-top: auto;

  @include media.xs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    margin-top: 16px;
  }
}

.editButton {
  @include media.xs {
    width: 100%;
  }
}

.publishButton {
  background: white;
  color: black;
  margin-left: auto;

  &:hover:not(&.disabled) {
    background: white;
    opacity: 0.8;
    color: black;
  }

  @include media.xs {
    grid-area: 1 / 1 / 2 / 3;
    margin-left: initial;
  }
}

.transcriptWrapper {
  margin-bottom: 16px;

  @include media.xs {
    display: none;
  }

  .skeleton {
    height: 12px;
    width: 100%;
    border-radius: 4px;

    margin-bottom: 8px;

    &:first-of-type {
      margin-top: 8px;
    }

    &:nth-of-type(3n) {
      width: 80%;
    }

    &:nth-of-type(3n + 1) {
      width: 95%;
    }

    &:nth-of-type(3n + 2) {
      width: 100%;
    }

    &:nth-of-type(1) {
      animation-delay: 0s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.1s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(4) {
      animation-delay: 0.3s;
    }

    &:nth-of-type(5) {
      animation-delay: 0.4s;
    }

    &:nth-of-type(6) {
      animation-delay: 0.5s;
    }

    &:nth-of-type(7) {
      animation-delay: 0.6s;
    }

    &:nth-of-type(8) {
      animation-delay: 0.7s;
    }

    &:nth-of-type(9) {
      animation-delay: 0.8s;
    }

    @include media.sm {
      &:nth-of-type(n + 7) {
        display: none;
      }
    }

    @media (max-width: 640px) {
      &:nth-of-type(n + 4) {
        display: none;
      }
    }
  }
}
