@use '@restream/styles/scss/tokens';

$table-font-size: 14px;
$color-secondary: #758092;

.row {
  display: table-row;
  background: none;
  color: tokens.$color-gray-300;

  &:hover {
    background: none;
  }

  &:last-of-type {
    .cell {
      box-shadow: none;
    }
  }
}

.row.disabled {
  opacity: 0.5;
}

.cell {
  background: none;
  font-size: $table-font-size;
  line-height: 16px;
  text-align: left;
  padding: 16px;
  box-shadow: inset 0 -1px 0 0 tokens.$color-gray-700;
  border-bottom: none;
}

.lastCell {
  display: flex;
  flex-direction: row-reverse;
}

.firstCellWrapper {
  display: flex;
  align-items: center;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.avatarWrapper {
  margin-right: 8px;
}

.title {
  color: #fff;
}

.description {
  font-size: 12px;
  line-height: 16px;

  &.capitalize {
    text-transform: capitalize;
  }

  &.warning {
    color: tokens.$color-yellow-400;
  }

  &.danger {
    color: tokens.$color-red-400;
  }

  &.withMinWidth {
    display: inline-block;
    min-width: 48px;
  }
}

.pendingStatus {
  margin-right: 4px;
  font-size: 12px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeUserButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  border-radius: tokens.$border-radius-200;

  transition: background-color 200ms;

  svg {
    fill: $color-secondary;
    opacity: 0.5;
    transition: opacity 200ms;
  }

  &:hover {
    background: #253858;

    svg {
      opacity: 1;
    }
  }

  &.active {
    background: #253858;

    svg {
      opacity: 1;
    }
  }
}
