@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.image {
  width: 100%;
  height: 255px;
  object-fit: contain;
  object-position: left;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: tokens.$color-gray-900;
}

.copyInput {
  filter: blur(1px);
  width: 100%;
  background-color: tokens.$color-gray-50;
}

.button {
  margin-top: 12px;
}
