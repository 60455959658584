@use '../../../../../scrollbar' as scrollbar;

$scrollbarWidth: 5px;
$fadeHeight: 20px;
$space: 16px;

.root {
  display: flex;
}

.content {
  position: relative;

  width: 100%;
  height: 100%;

  overflow: scroll;

  @media (min-width: 1080px) {
    overflow: hidden;

    &.withForcedScrollbar::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: $space;
      height: $fadeHeight;
      // fixes inconsistent safari linear-gradient.
      background: linear-gradient(to top, rgb(#fff, 0), #fff);
      z-index: 1;
      pointer-events: none;
    }

    &.withForcedScrollbar::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: $space;
      height: $fadeHeight;
      // fixes inconsistent safari linear-gradient.
      background: linear-gradient(to bottom, rgb(#fff, 0), #fff);
      z-index: 1;
      pointer-events: none;
    }
  }
}

.scrollView {
  overflow: hidden;

  height: 100%;

  padding-top: $fadeHeight;
  padding-bottom: $fadeHeight;

  animation: fadeIn 500ms ease;

  // Forces scrollbar to avoid content jumps when switching between tabs with
  // and without inner scrollbar
  .withForcedScrollbar & {
    padding-right: $space - $scrollbarWidth;
    overflow-y: scroll;

    @include scrollbar.scrollbar(
      $size: $scrollbarWidth,
      $color: var(--scrollBarColor),
      $hover-color: var(--scrollBarColor),
      $active-color: var(--scrollBarColor),
      $track-color: transparent
    );
  }
}

.isHidden {
  display: none !important;
  visibility: hidden !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
