@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
}

.preWrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.copyButtonWrapper {
  position: absolute;
  right: 12px;
  top: 12px;
}

.disabledOverlay {
  opacity: 0.33;
}

.copyButtonDisabled {
  opacity: 1;
}

.textarea {
  resize: none;
  font-family: monospace;
  border: 1px solid #a5adba;

  font-size: 13px;
  line-height: 20px;

  padding: 5px 8px;
  margin: 0;
  border-radius: tokens.$border-radius-200;

  color: #091e42;
  width: 100%;
  outline: none;
}

.noSelect {
  user-select: none;
  -webkit-user-select: none;
}

.tab {
  margin-left: 12px;
}

.codeFormGroup {
  margin-bottom: 0;
}

.loadingOverlay {
  background: hsl(0, 0%, 96.5%);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  border-radius: tokens.$border-radius-200;
}

.switcherOverlay {
  width: fit-content;
}

.upgradeCallout {
  background-color: #c5e6ee;
  background-image: linear-gradient(
    90deg,
    rgba(40, 190, 255, 0.2) 0,
    rgba(0, 196, 248, 0.2) 9.79%,
    rgba(0, 201, 236, 0.2) 19.58%,
    rgba(0, 205, 219, 0.2) 29.38%,
    rgba(0, 208, 199, 0.2) 39.17%,
    rgba(0, 209, 177, 0.2) 48.96%,
    rgba(0, 209, 177, 0.2) 48.96%,
    rgba(0, 206, 215, 0.2) 59.17%,
    rgba(0, 200, 248, 0.2) 69.38%,
    rgba(0, 190, 255, 0.2) 79.58%,
    rgba(106, 174, 255, 0.2) 89.79%,
    rgba(178, 155, 255, 0.2) 100%
  );
  margin: 8px 0 0;
  padding: 8px 16px;
  border-radius: tokens.$border-radius-200;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #091e42;

  button {
    padding: 0;
    border: 0;
    border-bottom: 1px solid #172b4d;

    color: #091e42;
    outline: 0;
    font: inherit;
    font-weight: 600;
    text-decoration: none;
    background: 0 0;
    cursor: pointer;
  }
}

.eventCardWrapper {
  margin-bottom: 16px;
}

.copyInput {
  width: 100%;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
