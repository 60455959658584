@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../../scss/mixins' as mixins;

.root {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
}

.content {
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media.sm {
    width: 100%;
    padding: 50px 15px 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.headerContainer {
  margin-bottom: 60px;

  &.withSubheader {
    margin-bottom: 32px;
  }
}

.eventItem,
.headerContainer {
  width: 500px;

  @include media.sm {
    width: 100%;
  }
}

.header {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #ebecf0;
  margin-bottom: 4px;
}

.subheader {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #a5adba;

  .subheaderLink {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: #ebecf0;

    &:hover {
      opacity: 0.8;
    }
  }
}

.guestFlowDemoHeader {
  @extend .subheader;

  margin-bottom: 32px;
}

.guestFlowDemo {
  width: 700px;

  @include media.sm {
    width: 100%;
  }
}

.steps {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #a5adba;
  margin-bottom: 24px;
}

.channelsHeader {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ebecf0;
  margin-bottom: 24px;
}

.channelTiles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.channelTile {
  @include mixins.button-reset;

  background: #253858;
  border-radius: tokens.$border-radius-200;
  height: 108px;
  width: 116px;
  display: flex;
  padding: 24px 16px 8px;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #ebecf0;

  transition: opacity 200ms;

  @include media.sm {
    padding: 16px 8px;
    height: auto;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.channelTileText {
  @include media.sm {
    visibility: hidden;
    display: none;
  }
}

.channels {
  width: 100%;
  max-width: 500px;
}

.buttons {
  text-align: center;
}

.addChannelsButton {
  padding: 12px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.ignoreChannelsButton {
  @include mixins.button-reset;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #c1c7d0;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: default;
    opacity: 0.8;
  }
}

.joinStreamButton {
  padding: 12px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
