.root {
  display: flex;
  width: 100%;
  gap: 12px;

  margin-bottom: 16px;
}

.button {
  width: 100%;
}
