.root {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 4px;

  background: #172b4d;
  padding: 4px;

  border-radius: 8px;

  height: fit-content;
}

.root[data-orientation='horizontal'] {
  flex-direction: column;
}

.root[data-orientation='vertical'] {
  flex-direction: row;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  padding: 16px;
}

.root[data-orientation='vertical']::after {
  content: '';
  position: absolute;
  width: calc(100% - 16px);
  height: 1px;
  background: #2b3d5c;

  bottom: 0;
  left: 8px;
}

.trigger {
  cursor: pointer;
  padding: 10px 0 8px;
  margin: 0;

  background: none;
  border: none;

  display: flex;
  gap: 6px;
  flex-direction: column;
  align-items: center;

  border-radius: 8px;

  width: 56px;
  height: 56px;

  transition: background-color 0.2s ease-in-out;

  svg {
    path {
      transition: all 0.2s ease-in-out;
      stroke: var(--Gray-300, #bac1cc);
    }
  }

  .label {
    color: var(--Gray-300, #bac1cc);
    text-align: center;
    font-family: Graphik, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    transition: color 0.2s ease-in-out;
  }
}

.trigger[data-state='active'] {
  background: #2b3d5c;
}

.trigger:hover,
.trigger[data-state='active'] {
  svg {
    path {
      stroke: var(--Gray-25, var(--Gray-25, #fafbfc));
    }
  }

  .label {
    color: var(--Gray-25, var(--Gray-25, #fafbfc));
  }
}

.trigger[data-orientation='vertical'] {
  width: 100%;
  height: unset;

  flex-direction: row;
  justify-content: center;
  padding: 12px 0;

  .label {
    font-size: var(--font-size-9, 14px);
    font-style: normal;
    font-weight: var(--font-weight-medium, 500);
    line-height: var(--line-height-9, 20px); /* 142.857% */
  }
}
