@use '@restream/styles/scss/tokens';

.buttonIcon {
  padding: 0;

  &:hover {
    background: none;
  }
}

.iconCopy,
.iconCheck {
  fill: none;
}

.iconDisabled {
  cursor: inherit;
}

.iconCopy {
  color: tokens.$color-blue-500;
}
