.root {
  background: linear-gradient(
    112.83deg,
    rgba(255, 255, 255, 0.93) 0%,
    rgba(255, 255, 255, 0.75) 110.84%
  );
  box-shadow: inset -1px -1px 0px rgb(255 255 255 / 20%),
    inset 1px 1px 0px rgb(255 255 255 / 50%);
  border-radius: 8px;
  user-select: none;
  color: #000;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr;
}

.wrapper {
  margin: calc(14px * var(--scale, 1));
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
}

.productImageBackground {
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(400px * var(--scale, 1));
  min-width: calc(200px * var(--scale, 1));
  padding: calc(16px * var(--scale, 1)) calc(26px * var(--scale, 1))
    calc(24px * var(--scale, 1));

  .withProductImage & {
    padding-left: calc(6px * var(--scale, 1));
  }

  .withQrCodeImage & {
    padding-right: calc(6px * var(--scale, 1));
  }
}

.title {
  color: #000;
  font-weight: 400;
  font-size: calc(20px * var(--scale, 1));
  line-height: calc(24px * var(--scale, 1));

  margin-bottom: 3px;
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;

  &.titlePlaceholder {
    min-height: calc(24px * var(--scale, 1));
    background: rgba(0, 0, 0, 0.2);
    max-width: 70%;
    border-radius: 6px;
  }
}

.description {
  color: #000;
  font-weight: 400;
  font-size: calc(13px * var(--scale, 1));
  line-height: calc(16px * var(--scale, 1));
  margin-left: calc(1px * var(--scale, 1));

  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.prices {
  word-break: break-all;
  line-height: 1;
}

.price {
  display: inline;
  color: #000;
  font-weight: 600;
  font-size: calc(24px * var(--scale, 1));
  line-height: calc(24px * var(--scale, 1));
  letter-spacing: 0.01em;

  .prices.decreased & {
    font-size: calc(20px * var(--scale, 1));
    line-height: calc(20px * var(--scale, 1));
  }

  &.pricePlaceholder {
    min-height: calc(24px * var(--scale, 1));
    max-width: 20%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }
}

.oldPrice {
  display: inline;
  vertical-align: top;
  color: #000;
  font-weight: 400;
  font-size: calc(15px * var(--scale, 1));
  line-height: calc(15px * var(--scale, 1));
  letter-spacing: -0.02em;
  text-decoration: line-through;
  opacity: 0.5;
  margin-left: calc(6px * var(--scale, 1));

  .prices.decreased & {
    font-size: calc(13px * var(--scale, 1));
    line-height: calc(13px * var(--scale, 1));
  }
}

.productImage {
  border-radius: 12px;
  display: block;

  object-fit: contain;
  position: relative;
  width: calc(112px * var(--scale, 1));
  height: calc(112px * var(--scale, 1));
  backdrop-filter: blur(7px);

  &.noBorder {
    border-radius: initial;
  }
}

.qrCodeImage {
  place-self: center;
  width: calc(112px * var(--scale, 1));
  height: calc(112px * var(--scale, 1));
  padding: calc(12px * var(--scale, 1));
}
