@import '@restream/styles/scss/media';

$paddingX: 32px;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 $paddingX;
  margin-bottom: 16px;

  @include xs {
    padding: 0 16px;
  }
}

.accountSelectWrapper {
  @include xs {
    display: none;
  }
}

.titleRoot {
  margin: 0;
}

.tabs {
  padding: 0 $paddingX;
  margin: 0;

  @include xs {
    padding: 0 16px;
  }
}

.notification {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 4px; // adjust later to 0 (with padding decrease)
    right: 4px;
    width: 8px;
    height: 8px;
    background-color: #f04438;
    border-radius: 50%;

    @media (min-width: 1080px) {
      right: 12px;
    }
  }
}
