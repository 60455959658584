@use '@restream/styles/scss/tokens';

.label {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid tokens.$color-gray-200;
  background-color: #ffffff;

  transition: all 0.2s ease-out;

  &.checked {
    border-color: tokens.$color-blue-600;
    background-color: tokens.$color-blue-50;
  }
}

.input {
  margin: 4px 8px auto 0 !important;
}

.labelContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.titleWrapper {
  display: flex;
  align-items: center;

  column-gap: 4px;
}

.price,
.title {
  font-size: 14px;
  color: tokens.$color-gray-700;
  margin: 0;
  font-weight: 500;
}
