@use '@restream/styles/scss/tokens';
@use '../colors';

.trigger {
  //border: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: white;
  color: var(--Gray-900, #091e42);

  padding: 7px 12px;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 20px; /* 150% */

  //box-shadow: inset 0 0 0 1px tokens.$color-gray-300;
  border: 1px solid tokens.$color-gray-300;
  outline: none;
  width: 100%;
}

.trigger:hover {
  //background-color: var(--mauve-3);
}

.trigger:focus {
  box-shadow: inset 0 0 0 1px #286fff;
}

.trigger[data-placeholder] {
  color: rgba(9, 30, 66, 0.25);
}

:global(.dark-theme) {
  .trigger {
    color: colors.$dark-color-input-fg;
    background: colors.$dark-color-input-bg;
    border-color: colors.$dark-color-border;
  }

  .trigger[data-placeholder] {
    color: rgba(255, 255, 255, 0.3);
  }
}

:global(.transparent-theme) {
  .trigger {
    color: colors.$transparent-theme-color-input-fg;
    background: colors.$transparent-theme-color-input-bg;
    border-color: colors.$transparent-theme-color-border;
  }

  .trigger[data-placeholder] {
    color: rgba(255, 255, 255, 0.3);
  }
}

.triggerIcon {
  display: flex;
  align-items: center;
}

.content {
  width: var(--radix-popover-trigger-width);

  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  box-shadow:
    0 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);

  z-index: 10000;
}

.content[data-state='open'] {
  //animation: fadeIn 200ms ease-out;
}

.content[data-state='closed'] {
  //animation: fadeOut 100ms ease-in;
}

.item {
  //border-radius: 3px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  user-select: none;

  color: var(--Gray-900, #091e42);

  padding: 4px 12px;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 20px; /* 150% */
  cursor: pointer;
  outline: none;
}

.item[data-disabled='true'] {
  pointer-events: none;
}

.item[data-highlighted] {
  outline: none;
  background: tokens.$color-gray-50;
}

.item[data-selected='true'] {
  outline: none;
  background: tokens.$color-gray-50;
}

.itemText {
  display: flex;
  flex: 1;
}

.viewport {
  padding: 8px 0;
}

.scrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  background-color: white;
  color: var(--Gray-900, #091e42);
  cursor: default;
}

.itemIndicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
