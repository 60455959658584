@use '@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

.root {
  color: tokens.$color-gray-200;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-10, 12px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: flex;
  align-items: center;

  & > svg {
    margin-right: 4px;

    @include media.xs {
      display: none;
    }
  }
}

.root.darker {
  background: tokens.$color-gray-800;
  padding: 2px 6px;
  border-radius: 4px;
}
