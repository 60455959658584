@use '@restream/styles/scss/tokens';
@use '../../mixins';

.editorToolbar {
  display: flex;
  width: 100%;
  height: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 14px;
  // --@todo: refer to tokens scss for token the appropriate values
  // background-color: #d1d5db;
}

.toolbarButtonGroup {
  display: flex;
  flex-basis: 33.33%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
  padding: 0;

  &:nth-of-type(1) {
    justify-content: flex-start;
  }

  &:nth-of-type(2) {
    justify-content: center;
  }

  &:nth-of-type(3) {
    justify-content: flex-end;
  }
}

.srOnly {
  display: none;
}

.zoomSlider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  width: 100%;
  max-width: 100px;
  min-width: 50px;
  appearance: none;
  height: 4px;
  background: tokens.$color-gray-300;
  outline: none;
  border-radius: 4px;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: tokens.$color-blue-600;
  border-radius: 50%;
  cursor: pointer;
}

.slider.disabled::-webkit-slider-thumb {
  background-color: tokens.$color-gray-300;
  cursor: not-allowed;
}

.slider::-moz-range-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: tokens.$color-blue-600;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  background-color: tokens.$color-gray-300;
  cursor: not-allowed;
}

.zoomButton {
  &:disabled {
    opacity: 0.5;
  }

  &:not(&:disabled):hover {
    background-color: rgb(247, 247, 248);
  }

  @media (max-width: 480px) {
    display: none; // hide buttons on mobile
  }
}

.slicesDuration {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;

  white-space: nowrap;

  & > span {
    display: inline-block;
    margin: 0 12px;
  }

  & span {
    font-size: 14px;
    font-weight: 500;
  }
}

.slicesDuration.error {
  color: tokens.$color-red-500;
}

.saveButton {
  border: none;
  color: tokens.$color-white;
  background-color: tokens.$color-blue-500;
}

.undoRedoButton {
  @include mixins.mobile {
    display: none;
  }
}
