@use '../../../../next/scss/mixins' as mixins;
@use '../events-list/events-list.module' as eventsList;
@use '@restream/styles/scss/tokens';

.wrapper {
  padding: 0 0 32px 30px;
  position: relative;

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 40px;

    & .title {
      &::after {
        mask-image: linear-gradient(rgb(0, 0, 0), transparent);
      }
    }
  }

  .last {
    padding-bottom: 0;

    &::after {
      mask-image: linear-gradient(rgb(0, 0, 0), transparent);
    }
  }
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f5f6f8;
  margin-bottom: 12px;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border: 2px solid #6b778c;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    top: 7px;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    left: 5.5px;
    border-left: 1px dashed #42526e;
    height: calc(100% - 25px);
    bottom: 0;
    top: 25px;
    right: 0;
  }
}

.subTitle {
  color: #6b778c;
}

.collapsedEventsDraftsWrapper {
  margin-top: eventsList.$itemsGap;
}

.showMoreButton {
  @include mixins.button-reset;
  color: tokens.$color-blue-400;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-top: 16px;

  &:hover:not(&:disabled) {
    background: transparent;
    color: tokens.$color-blue-500;

    & .showMoreButtonIcon path {
      stroke: tokens.$color-blue-500;
    }
  }
}

.showMoreButton.lobbyDrafts {
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
