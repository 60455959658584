$color-outline: #015ecc;
$outline: $color-outline auto 1px;

@mixin focus-visible-outline {
  outline: none;

  &:focus-visible {
    outline: $outline;
  }
}
