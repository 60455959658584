@use '@restream/styles/scss/tokens';

.root {
  padding: 2px 8px;
  font-size: 12px;
  border-radius: tokens.$border-radius-100;
  text-transform: uppercase;
  background: #12b76a;
  line-height: 12px;
  letter-spacing: 1.2px;
  text-align: center;
  color: tokens.$color-gray-900;
  font-weight: 500;
}

.warning {
  background-color: tokens.$color-yellow-300;
}
