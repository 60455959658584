@use '@restream/styles/scss/tokens';
@use '../colors';

.root {
  &:hover {
    background: tokens.$color-blue-50;

    svg {
      g {
        path {
          stroke: tokens.$color-blue-600;
        }
      }
    }
  }
}

:global(.dark-theme) {
  svg {
    g {
      path {
        stroke: colors.$dark-color-input-fg;
      }
    }
  }

  .root {
    &:hover {
      background: tokens.$color-gray-600;

      svg {
        g {
          path {
            stroke: tokens.$color-white;
          }
        }
      }
    }
  }
}

:global(.transparent-theme) {
  svg {
    g {
      path {
        stroke: colors.$transparent-theme-color-input-fg;
      }
    }
  }

  .root {
    &:hover {
      background: rgba(255, 255, 255, 0.15);

      svg {
        g {
          path {
            stroke: tokens.$color-white;
          }
        }
      }
    }
  }
}

.wrapper {
  display: flex;
}

.wrapperSuccess {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
