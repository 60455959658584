.eventTitle {
  font-weight: 600;
}

.viewButton {
  color: inherit;
  text-decoration: underline;

  &:hover:not(&:disabled) {
    text-decoration: none;
    color: inherit;
  }
}
