.description {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #8993a4;
  text-align: left;

  padding: 0;
  margin: 0 0 20px;

  a {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;

    text-decoration: underline;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}
