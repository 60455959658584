@use 'sass:math';
@use '@restream/styles/scss/tokens' as tokens;

$itemsPerRow: 7;

$size: 32px;

.root {
  max-width: 768px;
  width: 100%;

  .title {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: tokens.$color-gray-800;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    margin-top: 12px;

    &.twoCards {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .chartWrapper {
    margin-top: 12px;
  }

  .card {
    background: #ecf0f121;
    padding: 16px 18px;
    border: 1px solid tokens.$color-gray-200;
    border-radius: tokens.$border-radius-200;
    height: 100%;

    .images {
      margin-top: 6px;
      display: flex;
      .platformImage {
        width: 26px;
      }

      .imageWrapper {
        border: solid 1px #fdfdfd;
      }

      @for $i from 1 through $itemsPerRow {
        .channelIcon:nth-child(#{$i}) {
          z-index: $itemsPerRow - $i + 1;

          @if $i >= 2 {
            margin-left: math.div(-$size, 3);
          }
        }
      }
    }

    .totalWrapper {
      display: flex;
      align-items: center;
      gap: 6px;

      .icon {
        width: 32px;
      }
    }

    .total {
      margin-top: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 28px;

      letter-spacing: -0.02em;

      color: tokens.$color-gray-900;
      display: flex;
      align-items: flex-start;
      gap: 6px;

      .indicator {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 6px;
        gap: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: tokens.$color-green-700;

        background: tokens.$color-green-50;
        border-radius: 999px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    .definition {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      color: tokens.$color-gray-500;
    }

    .innerCard {
      .definition {
      }
    }
  }

  .skeleton {
    border-radius: tokens.$border-radius-100;
  }
}
