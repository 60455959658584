@use '@restream/styles/scss/tokens';

.root {
  margin-bottom: 16px;
}

.skeletonWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &:nth-of-type(3n) {
    .textSkeleton {
      width: 60%;
    }
  }

  &:nth-of-type(3n + 1) {
    .textSkeleton {
      width: 75%;
    }
  }

  &:nth-of-type(3n + 2) {
    .textSkeleton {
      width: 80%;
    }
  }
}

.textSkeleton,
.priceSkeleton {
  height: 12px;
  background: tokens.$color-gray-400;
  border-radius: tokens.$border-radius-100;

  animation: opacity-glow 1s linear infinite alternate;
}

.priceSkeleton {
  width: 10%;
}

@keyframes opacity-glow {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 0.3;
  }
}
