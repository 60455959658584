@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.footer {
  &.withSkipButton {
    @include media.xs {
      display: flex;
      flex-direction: column;
    }
  }
}

.skipButton {
  color: tokens.$color-gray-600;
  background: transparent;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  padding: 8px 16px;
  border-radius: 8px;

  margin-right: 12px;

  &:disabled {
    color: tokens.$color-gray-300;
  }

  &:hover:not(:disabled) {
    background: tokens.$color-gray-50;
    color: tokens.$color-gray-700;
  }

  @include media.xs {
    margin: 0 0 12px;
  }
}

.tooltipWrapper {
  @include media.xs {
    width: 100%;
  }
}

.submitButton {
  &:disabled {
    opacity: 0.4;
  }

  &:hover:not(:disabled) {
    background: #4280ff;
  }

  &.fullWidthButton {
    @include media.xs {
      width: 100%;
      height: 36px;
      background: #2970ff;
      border-radius: tokens.$border-radius-200;
    }
  }
}
