@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.modalBody {
  padding-top: 16px;
}

.modalBodyContentWrapper {
  overflow-y: scroll;
}

.videoCardWrapper {
  display: grid;
  width: 100%;
  position: relative;
}

.videoCard {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 0 1px rgba(9, 30, 66, 0.08) !important;

  .videoCardThumbnail {
    background-size: cover;
  }
}

.videoCardBackgroundThumbnail {
  height: 100%;
  border-radius: 8px;

  position: absolute;
  top: -6px;
  width: calc(100% - 16px);
  left: 8px;
  right: 8px;

  background-position: 50% -50px;
  background-repeat: no-repeat;
  background-size: cover;
  // todo decrease on mobile
}

.videoCardSeparationLine {
  width: 100%;
  height: 1.5px;
  background-color: #ffffff;
  position: relative;
}

.badgeIcon {
  margin-right: 4px;
}

.badge {
  display: flex;
  align-items: center;

  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 2;

  font-size: tokens.$font-size-body-sm;

  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  color: #fafbfc;
  font-weight: 500;
  padding: 3px 6px;
}

.importButtonOverlay {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in;

  color: tokens.$color-white;
  font-size: tokens.$font-size-body-sm;
  font-weight: 500;
  line-height: 20px;
  white-space: initial;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
