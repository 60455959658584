@mixin overlay {
  display: flex;
  flex-direction: column;

  position: absolute;
  width: 100%;
  //height: 100%;
  top: 0;
  left: 0;
  background: white;
}

@mixin border-shadow($color) {
  box-shadow: inset 0 0 0 1px $color;
}

@mixin truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
