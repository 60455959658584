.root {
  display: flex;

  color: #bac1cc;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  margin: 0;
  padding: 0;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
