@use '~@restream/styles/scss/media';
@use '@restream/styles/scss/variables';
@use '~@restream/styles/scss/colors';
@use '~@restream/styles/scss/outline';
@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: row;
}

.placeholder {
  padding: 4px 16px;
  font-family: inherit;
  margin-right: 0;
}

.destinations {
  margin-left: variables.$grid-base-value;
}

.button {
  height: 36px;
  padding: 4px 16px 4px 16px;
  transition: all variables.$animation-hover-time ease;
  background: #253858;

  &:hover:not(:disabled) {
    background: tokens.$color-blue-500;
  }

  &:focus {
    outline: none;
  }

  @include outline.focus-visible-outline;

  &:active {
    background: tokens.$color-blue-500 !important;
  }
}

.buttonText {
  color: #ebecf0;
  //font-size: 14px;
  //font-weight: 500;
  //line-height: 1;
}

.popover {
  z-index: 10;
}
