.root {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 7px 12px;
  margin: 0;

  cursor: pointer;

  color: var(--Base-White, #fff);
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-9, 20px); /* 142.857% */

  border-radius: 8px;
  border: none;

  background: #42526e;

  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

  transition: background 0.2s ease;

  &:hover:not(&:disabled) {
    background: #6b778c;
  }
}
