@use '@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  font-size: 14px;
  gap: 8px;

  @include media.sm {
    font-size: 12px;
  }
}

.protocol {
  text-transform: uppercase;
}

.healthIconContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.healthIcon {
  color: #12b76a;
  height: 18px;
  width: 18px;
}

.warning {
  color: tokens.$color-yellow-300;
}
