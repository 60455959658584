.title {
  margin-bottom: 8px;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.text {
  font-size: 14px;
  color: #aeb4bf;
  letter-spacing: 0.01em;
  line-height: 18px;
  margin-bottom: 20px;
}

.modalRootClassName {
  width: 520px;
}

.button {
  height: 40px;
}
