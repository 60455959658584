@use '@restream/styles/scss/tokens';
@import '../../../../components/scss/modals';
@import '../../../../components/scss/typography';

.root {
  @include modal-width;
}

.body {
  @media screen and (max-width: 576px) {
    padding: 12px;
  }
}

.buttons {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
