.root {
  max-width: 500px;
  padding: 0 24px 24px;
}

.header {
  padding-top: 16px;
  padding-bottom: 16px;

  position: relative;

  margin-bottom: 12px;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: -12px;
}

.title {
  color: #091e42;
  text-align: center;
  font-family: Graphik, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.inputGroup {
  margin-bottom: 12px;
}

.input {
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;

  border-radius: unset;
}

.inputWrapper {
  padding: 8px 12px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.button {
}
