@use '@restream/styles/scss/tokens';
@use '../../../../../components/scss/modals' as modals;

.nonStudioEventPromo {
  background: linear-gradient(
    90deg,
    rgba(40, 190, 255, 0.2) 0%,
    rgba(0, 209, 177, 0.2) 48.96%,
    rgba(178, 155, 255, 0.2) 100%
  );
  padding: 16px 24px;
}

.formGroup {
  margin-bottom: 0;
  padding-bottom: 0;
}

.inputLabel {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #6b778c;
  margin-bottom: 4px;
}

.linkInputContainer,
.linkInputWrapper {
  overflow: hidden;
}

.linkInputWrapper {
  border: 1px solid #cccccc;
  border-radius: tokens.$border-radius-200;
  box-shadow: none;

  &.linkInputWrapperDisabled {
    background: rgba(165, 173, 186, 0.2);
    backdrop-filter: blur(3px);
    filter: blur(1.5px);
    border-color: transparent;
    opacity: 1;
  }
}

.linkInput {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #172b4d;
  box-shadow: none;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;

  &.linkInputDisabled {
    color: #8993a4;
    -webkit-text-fill-color: #8993a4;
    opacity: 1;
    text-overflow: initial;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
}

.columnDirectionFooter {
  flex-direction: column;
}

.copyButton {
  width: 100%;
  height: 36px;
  background: #2970ff;
  border-radius: tokens.$border-radius-200;
}

.pairsTip {
  margin: 8px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #505f79;
}

.pairsTipLink {
  color: #505f79;
  font-weight: 500;
  border-bottom: 1px solid;
}
