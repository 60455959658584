.container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.text {
  margin-bottom: 64px;
  margin-top: 16px;
}
