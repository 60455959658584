@import '~@restream/styles/scss/media';

.root {
  line-height: 24px;
  font-size: 16px;

  color: #0a1e42;

  h5,
  p {
    color: #0a1e42;
  }
}

.rootModal {
  width: fit-content;

  @include xs {
    width: unset;
  }
}
