@import '@restream/styles/scss/colors';

.item {
  display: flex;
  align-items: center;

  cursor: pointer;
  color: $bg-color--darken-1;
}

.item-dropdown {
  padding: 8px;
  transition: all 200ms ease;
}

.item-dropdown.highlighted {
  background: #bde4ff;
}

.icon {
  height: 18px;
  margin-right: 8px;
}

.icon-game {
  margin-left: 4px;
}

.game-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
}
