@use 'sass:math';
@use '@restream/styles/scss/tokens';
@import '../../../styles/mixins';

$itemsPerRow: 3;

.root {
  @include button-reset;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  margin-right: 8px;
  opacity: 1;
  border-radius: tokens.$border-radius-200;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.8;
  }

  &.outline {
    border: 2px solid #344563;
    padding: 4px 8px;

    &.withIcons {
      padding: 4px 4px 4px 10px;
    }

    .icons {
      .placeholderIcon {
        box-shadow: 0 0 0 4px #1b2b4b;
      }
    }
  }

  &.default {
    background: #286fff;
    padding: 6px 10px;

    &.withIcons {
      padding: 6px 6px 6px 10px;
    }

    .icons {
      .placeholderIcon {
        box-shadow: 0 0 0 4px #286fff;
      }
    }
  }

  &.withIcons {
    .text {
      margin-right: 8px;
    }
  }

  .left {
    display: inline-flex; // in order to make svg take area only of its dimensions
    margin-right: 4px;
  }

  .text {
    color: #ebecf0;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    height: 24px;
    display: inline-flex;
    align-items: center;
  }

  .icons {
    display: flex;
    overflow: hidden;

    .placeholderIcon {
      margin-right: 12px;

      width: 24px;
      height: 24px;

      &:last-child {
        margin-right: 0;
      }
    }

    .placeholderIconWrapper {
      width: 12px;
      height: 12px;
    }

    @for $i from 1 through $itemsPerRow {
      .placeholderIcon:nth-child(#{$i}) {
        z-index: $itemsPerRow - $i + 1;

        @if $i >= 2 {
          margin-left: math.div(24px, -2);
        }
      }
    }
  }
}
