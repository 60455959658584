.spinner-snake {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.spinner-snake > div {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-snake .bounce1 {
  animation-delay: -0.32s;
}

.spinner-snake .bounce2 {
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
