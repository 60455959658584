@use '@restream/styles/scss/tokens';

.skeleton {
  background: tokens.$color-gray-400;
  border-radius: tokens.$border-radius-200;
}

.skeletonInput {
  width: 100%;
  height: 36px;
  margin-bottom: 12px;
}
