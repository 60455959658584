.button {
  margin: 0;
  //padding: 0 8px;
  background: transparent;
  border: none;

  color: #286fff;
  cursor: pointer;
  outline: none;
}

.button:focus:not(:disabled) {
  text-decoration: underline;
}

.button:hover:not(:disabled) {
  text-decoration: underline;
}

.button:disabled {
  color: gray;
  cursor: not-allowed;
}
