.root {
  display: grid;
  grid-gap: 20px;

  font-size: 14px;
  color: #aeb4bf;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin-bottom: 30px;

  a {
    color: #4c9aff;
    border-bottom: 1px solid transparent;
  }

  a:hover {
    border-bottom-color: #4c9aff;
  }
}
