@use '@restream/styles/scss/tokens';

.boxWithAnimatedBorder {
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 0;

    border-radius: tokens.$border-radius-200;
    // TODO(color): use token (optionally)
    background: linear-gradient(90deg, transparent, #fef3f2, transparent)
      no-repeat;
    background-size: 25% 100%;
    background-position: 100% 200%;
    animation: rainbow-border 4s linear infinite;
    // increase width and height on two pixels than the element
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }

  &.withGradient {
    width: 100%;
    background: linear-gradient(
      78.2deg,
      #2e90fa 0%,
      #004eeb 50.1%,
      #6927da 100%
    );
    border-radius: 44px;
    border: 2px solid transparent;

    border-left: 0;
    border-right: 0;

    transition: all 0.2s ease;

    &:hover {
      background: linear-gradient(90deg, #004eeb 0%, #6927da 100%);
    }

    &::before {
      height: calc(100% + 4px);
      top: -2px;
      border-radius: 44px;
    }
  }
}

@keyframes rainbow-border {
  0% {
    background-position: -15% 50%;
    opacity: 1;
  }
  12.5% {
    background-position: 115% 200%;
    opacity: 1;
  }
  12.51%,
  100% {
    opacity: 0;
  }
}
