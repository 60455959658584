@use '@restream/styles/scss/media';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  padding: 18px 18px 40px;
}

.text {
  font-size: 14px;

  text-align: center;
  margin-top: 16px;
  color: #091e42;
}

.actionWrapper {
  margin-top: 24px;
}
