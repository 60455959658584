@use '../title/ClipsRecordingProjectsTitle.module' as titleStyles;
@use '@restream/styles/scss/media';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  margin-bottom: titleStyles.$titleMarginBottom;
}

.tabsList {
  @include media.xs {
    column-gap: 0;
  }
}

.tab {
  @include media.xs {
    letter-spacing: initial;
    font-size: 12px;
  }
}

.generateButton {
  @include media.xs {
    font-size: 12px;
  }
}

.hiddenOnMobile {
  @include media.xs {
    display: none;
  }
}
