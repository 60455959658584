.root {
  //padding: 8px;

  background: #2b3d5c;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  display: flex;
  transition: background-color 0.2s ease;
}

.root:has(.link:hover) {
  background: #42526e;
}

.link {
  display: flex;
  flex: 1;
  min-width: 0;
  cursor: pointer;
  margin: 8px;
}

.imageWrapper {
  display: flex;
  width: 64px;
  margin-right: 12px;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background: #172b4d;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  min-width: 0;
  min-height: 38px;
}

.titleWrapper {
  display: flex;
}

.title {
  color: #edf0f3;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.date {
  color: #bac1cc;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  margin: 0;
  padding: 0;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.editButton {
  margin: 0;
  padding: 0 12px;
  background: none;
  border: none;

  display: flex;
  align-items: center;

  color: #fff;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  opacity: 0;
  cursor: pointer;

  height: 20px;

  transition: opacity 0.2s ease;
}

.buttonTitle {
  margin-right: 4px;
}

.link:hover .editButton {
  opacity: 1;
}
