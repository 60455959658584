@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  background-color: tokens.$color-blue-500;
  font-weight: 600;

  &:disabled {
    opacity: 0.24;
  }

  &:not(&:disabled) {
    cursor: pointer;

    &:hover {
      background: tokens.$color-blue-700;
    }
  }
}

.uploadIcon {
  @include media.xs {
    width: 20px;
  }
}
