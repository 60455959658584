@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/modals' as modals;

.root {
  @include modals.modal-width;

  text-align: center;
}

.body {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #172b4d;
  padding: 12px 0;
}

.subtitle {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0 0;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  position: relative;
}

.channelIcon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.platformIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
}

.channelName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8993a4;
}

.bottomBackButton {
  margin-top: 32px;
  width: 100%;
  color: #286fff;
  background-color: #fff;
  border: 2px solid #286fff;
  box-sizing: border-box;
  border-radius: tokens.$border-radius-200;
  transition: background-color 200ms ease;

  &:hover {
    color: #fff !important;
    background-color: #286fff !important;
  }
}
