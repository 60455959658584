.root {
  padding: 8px;

  margin: 0;
  border: none;

  width: 100%;
  min-height: 48px;

  background: #2b3d5c;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

  display: flex;
  justify-content: flex-end;
  align-items: center;

  transition: background-color 0.2s ease;

  cursor: pointer;
}

.root:hover {
  background: #42526e;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.content {
  display: flex;
  flex: 1;
  min-width: 0;
}

.chevronWrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group {
  width: inherit;

  & > div:first-child > .root {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    margin-bottom: 1px;
  }

  & > div:last-of-type > .root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.labelWrapper {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
