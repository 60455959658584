.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 84px;
}

.text {
  color: var(--Gray-900, #091e42);
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */

  margin-top: 24px;
}

.buttonWrapper {
  width: 100%;
  max-width: 300px;
}
