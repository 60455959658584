@use '@restream/styles/scss/tokens';

.root {
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  // TODO(color): use token
  color: #aeb4bf;
  letter-spacing: 0.01em;

  display: grid;
  grid-gap: 20px;
  padding: 20px;
  background: tokens.$color-gray-700;
  border-radius: 8px;
}

.extraInformation {
  font-weight: 600;
}

.blockquote {
  padding: 0 12px;
  margin: 4px 0 8px;
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;
  border-left: 4px solid #464545;
}

.button {
  margin-right: auto;
}
