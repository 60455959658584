@use '../../scss/colors' as colors;
@use '../../scss/grid' as grid;

.label {
  display: flex;
  align-items: center;

  margin-bottom: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: colors.$fg-color;
}

.text {
  margin-right: grid.$grid-base-value;
}
