.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  padding-bottom: 50px;
  box-sizing: border-box;
}

.icon {
  margin-bottom: 12px;
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #42526e;
}
