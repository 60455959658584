.container {
  transition: all 150ms ease-in-out;
}

.dropdown-enter {
  opacity: 0.01;
  transform: scaleY(0);
  transform-origin: 50% 0;
}

.dropdown-enter-active {
  opacity: 1;
  transform: scaleY(1) translateY(0);
  transform-origin: 50% 0;
  transition: all 150ms ease-in-out;
}

.dropdown-exit {
  opacity: 1;
  transform-origin: 50% 0;
  transform: scaleY(1) translateY(0);
}

.dropdown-exit-active {
  opacity: 0.01;
  transform: scaleY(0.1);
  transform-origin: 50% 0;
  transition: all 150ms ease-in-out;
}
