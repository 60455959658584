@use '@restream/styles/scss/tokens';

$dragHandleSize: 16px;

.root {
  box-sizing: border-box;
  height: 86px;
  position: relative;
  overflow: hidden;

  // we do this to move drag handle outside of the root
  // and to overcome overflow: hidden
  padding-left: $dragHandleSize;
  transform: translateX(-$dragHandleSize);
  width: calc(100% + #{$dragHandleSize});

  .overlay {
    width: 100%;
    display: flex;
    background: #253858;
    transition: border 100ms ease-in-out;
    border-radius: tokens.$border-radius-200;
    border: solid 2px rgba(40, 111, 255, 0);
    align-items: stretch;
    height: 86px;
    position: relative;
    box-sizing: border-box;

    &.active {
      transition: border 150ms ease-in-out;
      border: solid 2px #286fff;
    }

    &:hover {
      border: solid 2px rgba(40, 111, 255, 0.8);

      &::after {
        opacity: 1;
      }
    }

    &.activeDeleteConfirmation {
      border: solid 2px #286fff;
    }

    .scenesMode & {
      background: #2b3d5c;

      filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
        drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
    }

    .productContent {
      width: 100%;
      padding: 10px 10px;
      display: flex;
      cursor: pointer;
      flex: 1;
      align-items: center;

      .productImg {
        position: relative;
        min-width: 60px;
        height: 60px;
        border-radius: tokens.$border-radius-200;

        &.withQr {
          min-width: 57px;
          height: 57px;
          margin-right: 3px;
        }

        .qrCodeWrapper {
          border-radius: tokens.$border-radius-50;
          position: absolute;
          bottom: -3px;
          right: -3px;
          width: 30px;
          height: 30px;
          padding: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: tokens.$color-gray-100;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .productData {
        width: 100%;
      }
      .productTitle {
        font-weight: 600;
        color: #edf0f3;
        width: calc(100% - 75px);
        font-size: 14px;
        margin-left: 10px;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
        max-height: 2.8em;
        line-height: 1.4em;
      }
      .productPrice {
        margin-left: 10px;
        font-weight: 300;
        color: #aeb4bf;
        font-size: 14px;
      }
    }

    &::after {
      position: absolute;
      width: 100%;
      height: calc(100% + 2px);
      user-select: none;
      top: -1px;
      left: 0;
      display: flex;
      background: rgba(40, 111, 255, 0.8);
      border-radius: tokens.$border-radius-150;
      opacity: 0;
      content: ' ';
      z-index: 3;
      cursor: pointer;
      transition: opacity 100ms ease-in-out, background-color 100ms ease-in-out;
    }

    &.activeDeleteConfirmation {
      &::after {
        opacity: 1 !important;
        background: rgba(40, 111, 255, 0.95);
      }
    }
  }

  .dragWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $dragHandleSize;
    height: 100%;
    color: rgba(255, 255, 255, 0.08);
    outline: 0;
    cursor: grab;

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 250ms ease;

    .dragIcon {
      opacity: 1;
      transition: opacity 250ms ease;

      .dragWrapper:hover & {
        opacity: 0.8;
      }
    }
  }

  &:hover .dragWrapper {
    opacity: 1;
  }
}

.content {
  display: flex;
  align-items: center;
  opacity: 0;
  color: #edf0f3;
  cursor: pointer;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  transition: opacity 100ms ease-in-out;
  font-size: 14px;

  .icon {
    margin-right: 7px;
  }

  .overlay:hover & {
    opacity: 1;

    &.hide {
      opacity: 0;
    }
  }

  .activeDeleteConfirmation {
    opacity: 1;
  }
}

.commonActions {
  z-index: 5;
}

.actions {
  opacity: 0;

  .overlay:hover & {
    opacity: 1;
  }
}

.popover {
  display: flex;
  gap: 6px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 6px;
  z-index: 4;
  opacity: 1;
  transform: translate(0, -50%);
  transition: opacity 100ms ease-in-out;
  align-items: center;

  @media screen and (min-width: 1080px) {
    opacity: 0;
    .overlay:hover & {
      opacity: 1;
    }
  }

  &.open {
    z-index: 5;
  }

  @media screen and (max-width: 1080px) {
    transform: none;
    top: 4px;
    right: 4px;
  }

  &.alwaysOnScreen {
    opacity: 1;
  }
}

.tooltip {
  color: #edf0f3;
  opacity: 1;
  transition: opacity 100ms ease-in-out;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin-right: 6px;

  &.hide {
    opacity: 0;
  }

  &.alwaysOnScreen {
    opacity: 1;
  }
}

.popoverButton {
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0;
  border-radius: tokens.$border-radius-200;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: unset;
  border-color: unset;

  &:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.4);
    &.edit {
      background-color: #bedaff;
      color: #286fff;
    }

    &.delete {
      background-color: #ffbdad;
      color: #bf2600;
    }
  }

  &:not(:disabled).popoverOpen {
    background-color: #0a1f42;
  }

  @media screen and (max-width: 1080px) {
    width: 36px;
    height: 36px;
  }

  &.hide {
    opacity: 0;
  }

  &.alwaysOnScreen {
    opacity: 1 !important;
  }
}
.dropdown {
  width: 112px;
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  border-radius: tokens.$border-radius-200;
  background: #fff;
  z-index: 5;
  animation: fadeIn 300ms ease;

  .dropdownItem {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border: none;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    color: #091e42;

    &:hover {
      background: #deebff;
      color: #286fff;
    }

    &.remove:hover {
      color: #fb5630;
      background-color: #ffebe6;
    }

    & span {
      margin-left: 10px;
    }
  }
}
