//Light theme colors
$color-light-bg: #e3e5ea;
$color-light-bg-1: #c1c7d0;

//Dark theme colors
$color-dark-bg-2: #172b4d;
$color-dark-bg-3: #253858;
$color-dark-fg: #e6e6e6;

$color-primary: #ff5630;

// Colors from titles folder
$bg-color--lighten-1: #253858;
$bg-color--darken-1: #091e42; // TODO should be migrated to tokens.$color-gray-900
$bg-color--darken-3: rgba(0, 0, 0, 0.9);
$bg-color--darken-4: #222;

$fg-color: rgba(255, 255, 255, 0.9);
$fg-color--darken-1: rgba(255, 255, 255, 0.7);
$fg-color--darken-2: rgba(255, 255, 255, 0.5);
$fg-color--darken-3: rgba(255, 255, 255, 0.3);

$primary-color: #ff5630;
$error-color: #fc8471;

$ui-focus-color: #4c9aff;
$ui-placeholder-color: hsl(220.6, 30.7%, 45.3%);
