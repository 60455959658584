@use '~@restream/styles/scss/media' as media;
@use '../../scss/mixins' as mixins;

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.content {
  width: 100%;
  max-width: 360px;

  @include media.sm {
    width: 100%;
    padding: 50px 15px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.headerContainer {
  margin-bottom: 32px;
}

.header {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #ebecf0;
  margin-bottom: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 300px;
  margin: 0 auto;
}

.oauthSignInRoot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .oauthButton {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .orText {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    max-width: 300px;
    width: 100%;
    text-transform: uppercase;

    &:before {
      position: absolute;
      left: 0;
      content: '';
      width: 40%;
      height: 1px;
      background: #ebecf0;
    }

    &:after {
      position: absolute;
      right: 0;
      content: '';
      width: 40%;
      height: 1px;
      background: #ebecf0;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomFormContent {
  display: flex;
  justify-content: center;

  .buttonLinkLabel {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #c1c7d0;
    margin-right: 4px;
  }

  .buttonLink {
    @include mixins.button-reset;

    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #ebecf0;

    &:hover {
      opacity: 0.8;
    }
  }
}
