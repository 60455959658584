@use '@restream/styles/scss/tokens';

@import '~@restream/styles/scss/media';
@import '@restream/styles/scss/colors';

.root {
  //width: 1140px;
  //height: 600px;
}

.header {
  padding-bottom: 24px;

  &.mobileModalHeader {
    @include xs {
      box-shadow: 0 20px 24px -4px rgb(16 24 40 / 8%);
    }
  }
}

.body {
  max-height: 572px;
  height: 70vh;
  position: relative;
  color: hsla(0, 0%, 100%, 0.9);
  width: 1012px;
  overflow-y: scroll;
  padding: 0;

  @include xl {
    width: 816px;
    max-width: 816px;
  }

  @include md {
    width: 620px;
    max-width: 620px;
  }

  @include sm {
    width: 540px;

    max-height: 90%;
    height: 90%;
  }

  @include xs {
    width: 100%;

    max-height: 90%;
    height: 90%;
  }

  &.mobileModalBody {
    @include xs {
      height: calc(100vh - 84px);
      min-height: 270px;
    }
  }

  &.extendedOnboardingInterface {
    width: 540px;
  }
}

.platformsPaid {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  overflow: auto;
  border-bottom-left-radius: tokens.$border-radius-100;
  border-bottom-right-radius: tokens.$border-radius-100;

  background: tokens.$color-blue-500;

  .platformsPaidContainer {
    width: 100%;
    padding: 12px 24px 24px 30px;
  }

  @include lg {
    display: none;
  }

  &.extendedOnboardingInterface {
    display: none;
  }
}

.platformsPaidSticky {
  width: 100%;
  h5 {
    color: tokens.$color-white !important;
    font-weight: 400 !important;
    a {
      color: $fg-color--darken-2 !important;
      text-decoration: underline !important;
      &:hover {
        opacity: 0.7 !important;
      }
    }
  }
}
