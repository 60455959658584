.root {
  width: calc(var(--productOverlayScale) * 468px);
  user-select: none;
  background: transparent;
  border-radius: calc(var(--productOverlayScale) * 24px);
  overflow: hidden;
  color: #000000;

  .wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
  }

  .imageWrapper {
    position: relative;
    overflow: hidden;
    border-top-right-radius: calc(var(--productOverlayScale) * 24px);
    border-top-left-radius: calc(var(--productOverlayScale) * 24px);

    .imageBackground {
      border-top-right-radius: calc(var(--productOverlayScale) * 24px);
      border-top-left-radius: calc(var(--productOverlayScale) * 24px);
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 0 auto;
      padding-bottom: 100%;
      content: '';
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;

      &.noOpacity {
        opacity: 0;
      }
    }
    .image {
      border-top-right-radius: calc(var(--productOverlayScale) * 24px);
      border-top-left-radius: calc(var(--productOverlayScale) * 24px);
      width: 100%;
      margin: 0 auto;
      padding-bottom: 100%;
      backdrop-filter: blur(7px);
      position: relative;

      &.noBorder {
        border-radius: initial;
      }
    }
  }

  .contentWrapper {
    margin-top: -1px;
    background: linear-gradient(
      112.83deg,
      rgba(255, 255, 255, 0.93) 0%,
      rgba(255, 255, 255, 0.75) 110.84%
    );
    padding: calc(var(--productOverlayScale) * 24px);

    min-height: calc(var(--productOverlayScale) * 69px);

    position: relative;

    &.fullHeight {
      height: 100%;
      min-height: calc(var(--productOverlayScale) * 184px);
    }

    .content {
      max-width: calc(100% - var(--productOverlayScale) * 162px);

      .title {
        font-weight: 400;
        font-size: calc(var(--productOverlayScale) * 32px);
        line-height: calc(var(--productOverlayScale) * 40px);
        color: #000000;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }

      .desc {
        margin-top: calc(var(--productOverlayScale) * 10px);
        font-weight: 400;
        font-size: calc(var(--productOverlayScale) * 20px);
        line-height: calc(var(--productOverlayScale) * 24px);
        color: #000000;
        opacity: 0.8;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }

    .qrCodeWrapper {
      position: absolute;
      bottom: calc(var(--productOverlayScale) * 12px);
      right: calc(var(--productOverlayScale) * 12px);

      background-color: #fff;
      width: calc(var(--productOverlayScale) * 160px);
      height: calc(var(--productOverlayScale) * 160px);

      padding: calc(var(--productOverlayScale) * 12px);

      border-radius: calc(var(--productOverlayScale) * 12px);

      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &.fullHeight {
        top: calc(var(--productOverlayScale) * 12px);
      }

      .qrCodeImg {
        width: 100%;
      }
    }
  }

  &.autoHeight {
    height: auto;
  }
}
