@import '~@restream/styles/scss/media';
@import '../../../scss/typography';

.light {
  a {
    color: #505f79 !important;
    text-decoration: underline !important;
    transition: color 250ms ease;

    &:hover {
      color: #091e42 !important;
    }
  }
}

.dark {
  a {
    color: #ff5630 !important;
    text-decoration: none !important;
    transition: opacity 250ms ease;

    &:hover {
      opacity: 0.7 !important;
    }
  }
}

.description {
  @include font-14;

  color: #505f79;

  p {
    font-size: 14px !important;
    line-height: 24px !important;

    margin-top: 16px !important;
    margin-bottom: 16px !important;
    color: inherit;
  }

  //a {
  //  color: #0a1e42 !important;
  //  text-decoration: underline !important;
  //
  //  &:hover {
  //    opacity: .7 !important;
  //  }
  //}
}

.videoWrapper {
  display: flex;
  align-content: center;
  justify-content: center;
}

.video {
  width: 560px;
  height: 315px;

  @include xl {
    width: 480px;
    height: 270px;
  }

  @include xs {
    width: 100%;
    height: auto;
  }
}
