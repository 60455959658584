@use 'sass:math';
@use '~@restream/styles/scss/media' as media;

// It's width dashboard-page_main block #flex: 2 1;
$mainWidth: math.div(100%, 3);
$intercomButtonHeight: 60px;
$intercomButtonBadgeHeight: 22px;
$bottomOffset: 12px;

.intercom-lightweight-app {
  // stylelint-disable-next-line declaration-no-important
  z-index: 100 !important;
  animation: fadeIn 1.2s ease-in;
}

.hide-intercom .intercom-app,
.hide-intercom .intercom-lightweight-app {
  display: none !important;
}

.intercom-messenger-frame + div[class^='intercom-'],
.intercom-lightweight-app-launcher,
// Button when received message from support
[name='intercom-notifications-frame'] + div[class^='intercom-'] {
  // intercom decreased the desired size
  transform: scale(1) !important;
  transform-origin: bottom right;
  right: 10px;

  @media (min-width: 1080px) {
    transform: scale(1.2) !important;
    right: 20px;
  }

  // stylelint-disable-next-line declaration-no-important
  transition: right 300ms ease, bottom 300ms ease;
}

// TODO ist flag for dashboard-next page
// stylelint-disable-next-line
.body[page='dashboard-next'] {
  .intercom-messenger-frame + div[class^='intercom-'],
  .intercom-lightweight-app-launcher,
  .intercom-launcher-frame,
  .intercom-messenger-frame,
  .intercom-messenger,
  .intercom-borderless-frame,
  [name='intercom-notifications-frame'] {
    bottom: 0;

    @media (min-width: 1080px) {
      bottom: 12px;
      right: calc(36% + 12px);
    }
  }
}

.body[page='dashboard-next'] {
  .intercom-borderless-frame,
  .intercom-messenger,
  .intercom-messenger-frame,
  [name='intercom-notifications-frame'] {
    right: -20px;
    bottom: 0 !important;

    @media (min-width: 1080px) {
      bottom: $bottomOffset + $intercomButtonHeight + $bottomOffset !important;
      right: calc(36% + 12px);
    }
  }

  .intercom-launcher-badge-frame {
    bottom: $bottomOffset + $intercomButtonHeight - $intercomButtonBadgeHeight !important;
  }
}

.body[page='video-storage'] {
  .intercom-messenger-frame + div[class^='intercom-'],
  .intercom-lightweight-app-launcher,
  .intercom-launcher-frame,
  .intercom-messenger-frame,
  .intercom-messenger,
  .intercom-borderless-frame,
  [name='intercom-notifications-frame'] {
    //
  }
}

// TODO ist flag for lobby page
// stylelint-disable-next-line
.body[page='lobby'] {
  .intercom-messenger-frame + div[class^='intercom-'],
  .intercom-lightweight-app-launcher,
  .intercom-launcher-frame,
  .intercom-messenger-frame,
  .intercom-messenger,
  .intercom-borderless-frame,
  [name='intercom-notifications-frame'] {
    //
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
