@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/outline';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-height: 40px;
  min-width: 220px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0;

  text-align: left;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  color: #172b4d;
  background-color: transparent;
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  user-select: none;
  border: none;
  transition: background-color 300ms ease;

  &:disabled {
    opacity: 0.5;
  }

  @include outline.focus-visible-outline;

  &.isActive {
    background-color: #deebff;
    font-weight: 500;
    cursor: default;
  }
}

.content {
  flex: 1;
}

.chevronIcon {
  width: 16px;
  height: 16px;

  color: #a5adba;

  margin-left: 8px;

  &.isActive,
  .root:not(:disabled):hover & {
    color: #172b4d;
  }
}
