@use '@restream/styles/scss/tokens';

.button {
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-blue-700;

  background: tokens.$color-blue-50;
  border: 1px solid tokens.$color-blue-200;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

  &:hover:not(:disabled) {
    color: tokens.$color-blue-800;
    background: tokens.$color-blue-100;
    border: 1px solid tokens.$color-blue-200;
  }
}

.icon {
  height: 20px;
  color: #004eeb;
}

.transparentButton {
  width: 100%;
  text-align: center;
  color: tokens.$color-blue-700;

  padding: 10px 16px;
  background-color: transparent;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  border-radius: 6px;
  transition: all 0.3s;

  &:hover:not(:disabled) {
    color: tokens.$color-blue-700;
    background-color: tokens.$color-blue-50;
  }
}
