.container {
  height: 80vh;
}

.modalBody {
  height: 100vh;
  padding: 16px 0 0;
}

.iframe {
  min-height: 100%;
  width: 100%;
}
