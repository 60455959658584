@use '../../../../../scss/mixins' as mixins;

.bottomFormContent {
  display: flex;
  justify-content: center;

  &.contentRight {
    justify-content: flex-end;
  }

  .buttonLinkLabel {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #c1c7d0;
    margin-right: 8px;
  }

  .buttonLink {
    @include mixins.button-reset;

    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #ebecf0;

    &:hover {
      opacity: 0.8;
    }
  }
}

.oauthSignInRoot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .oauthButton {
    &:not(:first-child) {
      background: white;
      margin-top: 16px;
    }
  }

  .orText {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    max-width: 300px;
    width: 100%;
    text-transform: uppercase;

    &:before {
      position: absolute;
      left: 0;
      content: '';
      width: 40%;
      height: 1px;
      background: #ebecf0;
    }

    &:after {
      position: absolute;
      right: 0;
      content: '';
      width: 40%;
      height: 1px;
      background: #ebecf0;
    }
  }
}
