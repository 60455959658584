@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../scss/modals' as modals;

.root {
  @include modals.modal-width;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 70px;
  background: linear-gradient(180deg, #eae6ff 0%, #fafbfc 100%);
  border-radius: tokens.$border-radius-200;
  box-sizing: border-box;

  &.mobileOnboardingRoot {
    @include media.xs {
      height: inherit;
      border-radius: 0;
      background: tokens.$color-gray-800;
      padding: 25vh 20px 0;
    }
  }

  &.scheduleStudioRecording {
    background: linear-gradient(0deg, #ffffff 0%, #fffbf2 100%);
  }
}

.image {
  height: 135px;
}

.title {
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 0.01em;
  color: tokens.$color-gray-900;
  margin: 45px 0 10px;

  &.mobileOnboardingTitle {
    @include media.xs {
      color: white;
    }
  }
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: tokens.$color-gray-800;
  max-width: 385px;
  text-align: center;
  margin: 0 0 20px;

  &.mobileOnboardingText {
    @include media.xs {
      color: white;
      margin-bottom: 40px;
    }
  }

  &.scheduleStudioRecording {
    max-width: 320px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 16px;
  background: tokens.$color-blue-500;
  border-radius: tokens.$border-radius-200;
  border: none;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 14px;
  color: tokens.$color-white;
  cursor: pointer;

  &:hover {
    background: tokens.$color-blue-700;
  }

  &.mobileOnboardingButton {
    @include media.xs {
      font-weight: 500;
      width: 100%;
      max-width: 340px;
    }
  }
}
