@use '@restream/styles/scss/tokens';

.root {
  padding: 10px 16px 8px;
  width: 100%;
}

.header {
  display: grid;
  grid-template-columns: 24px auto 24px;
  grid-template-areas: 'back text close';
  width: auto;
  margin-bottom: 10px;
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: tokens.$color-white;
  grid-area: text;
}

.backButtonWrapper {
  display: flex;
  justify-content: center;
  grid-area: back;
}

.backButton {
  padding: 6px;
  opacity: 1;

  &:hover {
    background-color: transparent;
  }

  & svg {
    fill: tokens.$color-white;
    opacity: 1;
  }
}

.closeButtonWrapper {
  display: flex;
  justify-content: center;
  grid-area: close;
}

.closeButton {
  padding: 6px;

  &:hover {
    background-color: transparent;
  }
}

.closeButtonIcon {
  color: tokens.$color-white;
  opacity: 1;
}

.stepsWrapper {
  display: flex;
  justify-content: center;
}

.step {
  width: 100%;
  height: 4px;

  max-width: 33%;
  background-color: tokens.$color-gray-600;
  border-radius: tokens.$border-radius-200;

  margin-right: 8px;

  transition: background-color 0.2s;

  &:last-of-type {
    margin-right: 0;
  }

  &.filled {
    background-color: tokens.$color-blue-500;
  }
}
