.root {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.07);
}

.content {
  display: flex;
}

.description {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings {
  display: flex;
  margin-left: 8px;
}

.channelName {
  margin: 0;

  font-size: 14px;
  line-height: 24px;
  color: #344563;

  &:hover,
  &:focus,
  &:active {
    color: #344563;
  }
}

.titlePlaceholder {
  margin: 0;

  color: #a5adba !important;
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editButton {
  margin: 0;
  padding: 0 8px;
  font-size: 14px;
  background: transparent;
  border: none;
  color: #286fff !important;
  cursor: pointer;
  outline: none;

  &:disabled {
    color: grey !important;
    cursor: not-allowed;
  }

  &.noRightPadding {
    padding-right: 0;
  }
}

.editButtonWrapper {
  margin-right: 12px;

  &.noRightMargin {
    margin-right: 0;
  }
}

.statusTitle,
.statusTitle a,
.statusTitle button {
  font-size: 14px;
  line-height: 20px;
}

.error {
  margin-top: 8px;
}

.skipWaitPlatformsWrapper {
  margin-top: 12px;
}
