@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.chevronIcon {
  width: 20px;
  stroke-width: 1.5;
  color: inherit;

  transition: color 0.2s ease-out;
}
