@use '@restream/styles/scss/tokens';

.root {
  color: tokens.$color-gray-700;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;

  a {
    text-decoration-line: underline;
    color: inherit;
  }
}
