@use '@restream/styles/scss/tokens';

.service {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 12px 24px;
  border-bottom: solid 1px #ededed;

  .actions {
    display: flex;
    align-items: center;
    .trashIconWrapper {
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background: transparent;
      transition: background 0.3s ease-in-out;
      border-radius: tokens.$border-radius-200;
      cursor: pointer;

      &:hover {
        background: #e2e2e9;
      }

      .trashIcon {
        height: 14px;
        transition: fill 0.2s ease-in-out;
      }
    }
  }
  .leftSide {
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
  }

  &:first-child {
    margin-top: 0;
  }

  .serviceImgWrapper {
    display: flex;
    align-items: center;
    border-radius: tokens.$border-radius-200;
    position: relative;
    width: 32px;

    .serviceImg {
      max-width: 32px;
      width: auto;
      max-height: 32px;
      height: auto;
    }

    & .imageLoaderWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fafbfc;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .imageLoader {
      & circle {
        stroke: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .serviceContent {
    box-sizing: border-box;
    padding-left: 20px;
    width: 100%;
    overflow: hidden;
    max-width: 100%;

    .serviceName {
      color: #212121;
      font-size: 16px;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .button {
    font-size: 14px;
    -webkit-appearance: none;
    color: #004eeb;
    display: flex;
    background: #eff4ff;
    border-radius: tokens.$border-radius-200;
    position: relative;
    border: solid 1px #b2ccff;
    padding: 8px 42px;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    transition: background-color 0.2s ease-in-out;

    &.disabled {
      color: #e4e4e4;
      pointer-events: none;
      cursor: default;
    }

    &:not(:disabled):hover {
      background: #dee5ff;
    }

    &:hover {
      background: rgba(10, 102, 194, 0.1);
    }
  }
}

.reconnectError {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
  justify-content: flex-start;
  align-self: flex-start;
  margin: 0 24px 10px;

  padding: 12px 14px;
  border-radius: 0 0 tokens.$border-radius-200 tokens.$border-radius-200;
  width: calc(100% - 48px);
  box-sizing: border-box;

  position: relative;
  overflow: hidden;

  background-color: rgba(222, 53, 11, 0.16);

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #de350b;
    top: 0;
    left: 0;
    right: 0;
  }

  & .descriptionWrapper {
    display: flex;
    align-items: center;
  }

  & .description {
    color: #172b4d;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
  }
}
