@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/modals' as modals;

.root {
  max-width: 600px;
  width: 100%;
}

.body {
  margin-bottom: 8px;
}

.bodyTitle {
  margin: 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #172b4d;
}

.bodyWrap .bodyDescription {
  color: #6b778c;
}

.bodyDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin-top: 24px;
  margin-left: 12px;
}

.points {
  color: #6b778c;
  list-style: disc;
  margin-left: 36px;
  margin-top: 12px;

  font-size: 14px;
}

.link {
  display: inline;
  cursor: pointer;
  border: none;
  background: none;
  margin: 0;
  padding: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #286fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.noteBlock {
  display: grid;
  grid-gap: 8px;
  background-color: #ebecf0;
  border-radius: tokens.$border-radius-200;
  padding: 16px;
  margin-bottom: 24px;
}

.noteTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #091e42;
  text-transform: uppercase;

  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 8px;
  margin: 0;
}

.noteText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #172b4d;
  margin: 0;
}

.applyButton {
  display: block;
  margin-top: 48px;
  padding: 8px 12px;
  color: #fff;
  background: #286fff;
  border-radius: tokens.$border-radius-200;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus-visible {
    outline: #015ecc auto 1px;
  }
}
