@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '~@restream/styles/scss/colors' as colors;
@use '@restream/styles/scss/variables' as variables;

// Button Breakpoints
$breakpoint-five-btn: 1280px;
$breakpoint-four-btn: 1130px;
$breakpoint-three-btn: 1075px;

.inputLabel {
  display: flex;
  flex-direction: row;
}

.inputContainer {
  width: 100%;
  min-height: 48px;
  min-width: 280px;

  border-radius: tokens.$border-radius-200;
  background-color: #ffffff;
  color: #172b4d;

  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap !important;

  @include media.xs {
    min-width: 0;
  }

  input {
    font-size: 16px;
    color: #172b4d;
    padding-left: 10px;
    overflow: visible;
    margin: 0;
    width: 100%;
    border-radius: tokens.$border-radius-200;

    &:focus {
      outline: none;
    }
  }
}

.linkInput {
  width: 100%;
  border: none;
  border-radius: tokens.$border-radius-200;
}

.buttonIcon {
  align-items: center;
  svg {
    fill: none;
    color: #0a7cff;
  }
  &:disabled {
    background-color: #e3e3e3;
    svg {
      color: #6e6e6e;
    }
  }
}

.sectionTitle {
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 5px;
}

.sectionSubTitle {
  font-size: 16px;
  padding-bottom: 20px;
}

.container {
  background-color: #253858;
  border-radius: tokens.$border-radius-200;
  width: 100%;
  flex: 2;
  padding: 30px;
  max-height: 200px;

  @include media.xs {
    max-height: none;
  }
}

.linkShareSection {
  margin-top: 15px;
  div {
    display: flex;
    flex-direction: row;

    @include media.xs {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

.shareButtons {
  justify-content: space-between;

  Button {
    margin-left: 10px;
    height: 48px;
    width: 48px;
    padding: 8px;
    display: flex;
    align-items: center;
    color: colors.$color-dark-fg;

    &:hover {
      color: colors.$color-dark-fg;
    }

    &:before {
      background-size: 24px 24px;
      width: 30px;
      height: 30px;
      content: '';
      display: inline-flex;
      justify-content: center;
    }

    @include media.xs {
      margin-left: 0;
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  @include media.xs {
    flex-direction: column;
    justify-content: center;
  }
}

.facebookBtn {
  background: #1877f2;
  border: 1px solid #1877f2;

  &:hover {
    background: #3c83c3 !important;
    border: 1px solid #3c83c3 !important;
  }

  &:before {
    background: url(../img/facebook.svg) no-repeat 50% 50%;
  }
}

.twitterBtn {
  background: #1da1f2;
  border: 1px solid #1da1f2;

  &:hover {
    background: #1d79c3 !important;
    border: 1px solid #1d79c3 !important;
  }

  &:before {
    background: url(../img/twitter.svg) no-repeat 50% 50%;
  }
}

.linkedinBtn {
  background: #2867b2;
  border: 1px solid #2867b2;

  &:hover {
    background: #2c87de !important;
    border: 1px solid #2c87de !important;
  }

  &:before {
    background: url(../img/linkedin.svg) no-repeat 50% 50%;
  }

  @media (max-width: #{$breakpoint-three-btn}) {
    display: none !important;
  }

  @include media.md {
    display: flex !important;
  }

  @include media.sm {
    display: none !important;
  }
  @include media.xs {
    display: flex !important;
  }
}

.redditBtn {
  background: #ff4500;
  border: 1px solid #ff4500;

  &:hover {
    background: #e63800 !important;
    border: 1px solid #e63800 !important;
  }

  &:before {
    background: url(../img/reddit.svg) no-repeat 50% 50%;
  }

  @media (max-width: #{$breakpoint-four-btn}) {
    display: none !important;
  }
}

.whatsappBtn {
  background: #128c7e;
  border: 1px solid #128c7e;

  &:hover {
    background: #12ad9f !important;
    border: 1px solid #12ad9f !important;
  }

  &:before {
    background: url(../img/whats_app.svg) no-repeat 50% 50%;
  }

  @media (max-width: #{$breakpoint-five-btn}) {
    display: none !important;
  }
  @include media.md {
    display: flex !important;
  }
  @include media.sm {
    display: none !important;
  }
  @include media.xs {
    display: flex !important;
  }
}

.moreBtn {
  background: rgba(93, 87, 93, 0.83);
  border: 1px solid rgba(93, 87, 93, 0.83);

  &:hover {
    background: rgba(165, 166, 168, 0.83) !important;
    border: 1px solid rgba(165, 166, 168, 0.83) !important;
  }

  &:before {
    background: url(../img/more.svg) no-repeat 50% 50%;
  }
}

.inputContainer:focus-within {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}

.error {
  color: #de350b;
  padding-left: 10px;
}

.success {
  color: #00aa77;
}
