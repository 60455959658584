.services {
  display: grid;
  grid-gap: 24px;
  width: 100%;
  padding-bottom: 16px;
  grid-template-columns: repeat(2, 1fr);

  .platform {
    cursor: pointer;
    width: 100%;
    height: 148px;
    padding: 24px 16px;
    border: 2px solid rgba(245, 246, 248, 0.24);
    border-radius: 12px;
    transition: 0.3s;
    overflow: hidden;
    position: relative;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: 0.3s;
      z-index: 21;
      position: relative;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      z-index: 19;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.076) -31.08%,
        rgba(255, 255, 255, 0.198) 68.04%
      );
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      z-index: 19;
      left: 0;
      transition: opacity 0.3s ease-in-out;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: radial-gradient(
        60.01% 210.81% at 50% -76.35%,
        rgba(255, 255, 255, 0.4) 18.55%,
        rgba(255, 255, 255, 0.324) 64.19%,
        rgba(255, 255, 255, 0.212) 100%
      );
    }

    .imgWrapper {
      height: 58px;
      max-width: 120px;
      position: relative;

      &.smallImage {
        & .img {
          width: 90%;
          height: 90%;
        }
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      & .imageLoaderWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.2s ease-in-out;

        &.hidden {
          opacity: 0;
        }
      }
      & .imageLoader {
        & circle {
          stroke: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      margin-top: 22px;
      transition: color 0.3s ease-in-out;
    }
    &:hover {
      border: 2px solid rgba(212, 235, 255, 0.45);

      &::after {
        opacity: 1;
      }

      &::before {
        opacity: 0;
      }
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }

  @media (max-width: 510px) {
    .platform {
      align-items: center;
      justify-content: center;
      padding: 18px 12px;
      height: 128px;
      .title {
        margin-top: 16px;
        font-size: 14px;
      }
    }
  }
}
