@use '@restream/styles/scss/tokens';
@import '~@restream/styles/scss/colors';
@import '../../../components/scss/typography';
@import '../../../components/scss/animation';
@import '../../../components/scss/mixins';

.button {
  display: contents;
  font-family: Graphik, sans-serif;
  text-align: left;
}

.root.legacy {
  font-size: 16px;
  margin-bottom: 24px;
  background: $bg-color--lighten-1;

  &:hover {
    background: lighten($bg-color--lighten-1, 1%);

    .arrow {
      opacity: 1;
    }
  }

  .coverWrapper {
    position: relative;
    display: flex;
  }

  .cover {
    width: 120px;
  }

  .label {
    @include font-14;
    text-transform: uppercase;
    color: $fg-color--darken-2;
  }

  .title {
    @include font-16;
    color: $fg-color--darken-1;
  }

  .arrow {
    transition: opacity $animation-default-time;
    opacity: 0.5;
  }
}

.root {
  border-radius: tokens.$border-radius-200;
  cursor: pointer;
  display: flex;
  padding: 12px;
  text-decoration: none;
  transition: background-color $animation-default-time;
}

.titleWrapper {
  display: flex;
  align-items: baseline;
  flex: 1;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
  min-width: 0;
}

.label,
.title {
  margin: 0;
}

.title {
  @include truncate-text;
  width: 100%;
}

.arrowWrapper {
  display: flex;
  align-items: center;
}

.arrow {
  transition: opacity $animation-default-time;
}

.root:not(.legacy) {
  .coverWrapper {
    box-sizing: border-box;
    border-radius: tokens.$border-radius-200;
    display: flex;
    position: relative;
    height: 44px;
    width: 80px;
  }

  .cover {
    border-radius: tokens.$border-radius-200;
    object-fit: cover;
    width: 100%;
  }

  .label,
  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: none;
  }

  .title {
    margin-top: 4px;
  }

  .arrow {
    opacity: 0.75;
  }
}

.root.light {
  background: #edf0f3;

  &:hover {
    background: darken(#edf0f3, 2%);

    .arrow {
      opacity: 1;
    }
  }

  .coverWrapper {
    border: 1px solid #d7dce3;
  }

  .label {
    color: #091e42;
  }

  .title {
    color: #6b778c;
  }
}

.root.dark {
  background: #172b4d;

  &:hover {
    background: lighten(#172b4d, 5%);

    .arrow {
      opacity: 1;
    }
  }

  .label {
    color: #ffffff;
  }

  .title {
    color: #a5adba;
  }
}
