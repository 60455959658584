@use '@restream/styles/scss/tokens';
@use '../../../../scss/mixins' as mixins;
@import '../constants';
@import '../../constants';

@mixin calendarIcon {
  @include mixins.display-flex-center;
  width: 20px;
  height: 20px;
}

.uploadButton {
  height: auto;
  background-color: tokens.$color-blue-500;
  border-radius: tokens.$border-radius-200;
  padding: 12px 44px;

  @media (max-width: $smallMobileMaxWidth) {
    padding: 10px 18px;
  }
}

.calendarIcon {
  @include calendarIcon;
}
