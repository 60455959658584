@use '@restream/styles/scss/tokens';
@import '~@restream/styles/scss/media';

.root {
  width: 550px;

  @include xs {
    width: 100%;
  }
}

.modalHeader {
  color: tokens.$color-gray-800;
}

.modalTitle {
  &.withBackupStreams {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: tokens.$color-gray-900;
  }
}

.modalBody {
  &.withBackupStreams {
    padding-top: 30px;
  }
}

.calloutTitle {
  margin-left: 6px;
}

.header {
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4;

  color: tokens.$color-gray-800;
  border-bottom: none;
}

.description {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: tokens.$color-gray-700;
  margin-bottom: 20px;
}

.inputWrapper {
  &:last-of-type {
    padding-bottom: 0;
  }
}

.inputWithMarginTop {
  margin-top: 12px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
