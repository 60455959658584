@use '../../../scss/modals';

.dialogOverlay {
  background-color: rgba(0, 0, 0, 0.12);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 102;
  backdrop-filter: blur(5px);
}

.dialogContent {
  //@include modals.modal-width;

  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 10vh;
  //bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
  //transform: translate(-50%, -50%);
  //max-height: 85vh;
  animation: contentShow 550ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 102;

  width: fit-content;

  //margin: 10vh auto 30px;

  //height: fit-content;

  //bottom: 0;
  //left: 0;
  //outline: 0;
  //overflow: auto;
  //position: fixed;
  //right: 0;
  //top: 0;
  //z-index: 1050;
}

.dialogContent:focus {
  outline: none;
}

.dialogWrapper {
  //background: red;
  //width: fit-content;
  //margin: 10vh auto 30px;
}

@keyframes overlayShow {
  from {
    opacity: 0;
    backdrop-filter: blur(0);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(5px);
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    //transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    //transform: translate(-50%, -50%) scale(1);
  }
}
