@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 230px;
  background-color: #ebecf0;
  border-radius: tokens.$border-radius-200;
  padding: 30px 15px;
}

.img {
  width: 39px;
  height: 25px;

  margin-bottom: 12px;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #8993a4;
}
