@use '@restream/styles/scss/tokens';

.container {
  position: relative;
  height: 100%;
}

.buttonWrapper {
  position: absolute;

  display: flex;
  justify-content: center;

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  margin-top: 20px;
}

.button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: #97a0af;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.errorToastWrapper {
  width: 450px;
  padding: 12px 10px 16px !important;
  background: #ffffff !important;
  border-radius: tokens.$border-radius-200 !important;
  border: 1px solid #edf0f3;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  @media (max-width: 480px) {
    width: auto;
    max-width: 95%;
    padding: 16px !important;
    bottom: 1.5em !important;
  }
}
