@use '@restream/styles/scss/tokens';
@use '../../../../../scss/typography' as typography;
@use '../../../../../scss/colors' as colors;

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  gap: 4px;
  color: tokens.$color-gray-300;

  @include typography.font-12;
}

.icon {
  width: 16px;
  height: 16px;

  & path {
    stroke-width: 1px;
  }
}
