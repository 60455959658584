@use '@restream/styles/scss/tokens';

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.addMoreBtn {
  appearance: none;
  -webkit-appearance: none;
  background: #fff;
  transition: background-color 0.2s ease-in-out;
  border: solid 1px rgba(186, 193, 204, 1);
  color: tokens.$color-gray-700;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: tokens.$border-radius-200;
  .addMoreBtnPlus {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: tokens.$color-gray-50;
  }
}

.leftPart,
.rightPart {
  padding: 32px 24px 24px;
  height: 680px;

  @include mobileMedia() {
    padding: 16px;
    height: auto;
  }
}

.leftPart {
  display: grid;
  grid-gap: 12px;
  max-width: 100%;
  position: relative;
  padding-top: 0;
  height: 100%;
  grid-template-rows: auto 1fr auto;
  padding-bottom: 0;
  max-height: 680px;

  @include mobileMedia() {
    padding: 0;
    grid-gap: 0;
    max-height: initial;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobileMedia() {
      padding: 16px;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      color: #091e42;
      line-height: 32px;
    }
  }

  .featuredOverlaysWrapper {
    position: relative;

    .detach {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .overlays {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    width: 100%;
    overflow-y: auto;

    &.caption {
      grid-auto-rows: min-content;
      grid-template-columns: 1fr;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 8px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: tokens.$border-radius-200;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.4);
    }
    padding-bottom: 16px;

    @include mobileMedia() {
      padding: 0 16px 16px;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 815px) {
      padding: 0 16px 16px;
      grid-gap: 16px;

      &:not(.caption) {
        grid-template-columns: repeat(auto-fill, 35vw);
        grid-auto-flow: column;
        grid-auto-columns: 35vw;
        overflow-x: auto;
        overflow-y: hidden;
      }

      @media (max-height: 700px) {
        &:not(.caption) {
          grid-template-columns: repeat(auto-fill, 45vw);
          grid-auto-columns: 45vw;
        }
      }
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(auto-fill, 70vw);
      grid-auto-columns: 70vw;

      @media (max-height: 700px) {
        grid-template-columns: repeat(auto-fill, 47vw);
        grid-auto-columns: 47vw;
      }
    }

    @media (max-width: 370px) {
      grid-template-columns: repeat(auto-fill, 60vw);
      grid-auto-columns: 60vw;
    }

    .overlayWrapper {
      border: solid 1px rgba(186, 193, 204, 1);
      border-radius: tokens.$border-radius-400;
      position: relative;
      overflow: hidden;
      height: 0;
      padding-bottom: calc(860 / 470 * 100%);
      transition: border 0.4s ease-in;

      &.caption {
        height: 0;
        padding-bottom: calc(172 / 660 * 100%);
      }

      &:hover {
        border: solid 1px #155eef;
        transition: border 0.4s ease-out;
      }

      @keyframes highlight {
        0% {
          border: solid 1px rgba(186, 193, 204, 1);
        }
        10% {
          border: solid 1px #155eef;
        }
        50% {
          border: solid 1px #155eef;
        }
        90% {
          border: solid 1px #155eef;
        }
        100% {
          border: solid 1px rgba(186, 193, 204, 1);
        }
      }

      &.highlight {
        animation: highlight 3s;
      }

      .overlay {
        border-radius: tokens.$border-radius-200;
        overflow: hidden;
      }

      &:hover .overlayControls {
        background-color: rgba(39, 108, 248, 0.7);

        & .editBtn {
          opacity: 1;
        }
      }

      &:hover .removeBtn {
        opacity: 1;
      }

      .overlayControls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        border-radius: tokens.$border-radius-200;
        background-color: rgba(39, 108, 248, 0);
        transition: background-color 200ms ease-in-out;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        .editBtn {
          font-weight: 500;
          font-size: 18px;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 8px 12px;
          color: #fff;
          opacity: 0;
          transition: opacity 200ms ease-in;

          .editBtnImg {
            width: 25px;
            height: auto;
            margin-right: 10px;
          }
        }
      }

      .removeBtn {
        position: absolute;
        top: 4px;
        right: 4px;
        z-index: 100;
        background: #ffffff;
        border: 1px solid #bac1cc;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: tokens.$border-radius-200;
        cursor: pointer;
        opacity: 0;
        transition: all 200ms ease-in;

        .removeBtnContent {
          box-sizing: border-box;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          transition: background 200ms ease-in;
        }

        .removeBtnImg {
          width: 16px;
        }

        &:hover {
          border: solid 1px rgba(205, 32, 31, 0.8);

          .removeBtnContent {
            background: rgba(205, 32, 31, 0.1);
          }

          & path {
            stroke: #cd201f;
          }
        }
      }
    }
  }

  .bottomGradient {
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    width: 100%;
    height: 22px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.0816599) 20%,
      rgba(255, 255, 255, 0.231775) 33.33%,
      rgba(255, 255, 255, 0.96449) 86.67%,
      #ffffff 100%
    );
  }
}

.rightPart {
  background: tokens.$color-gray-50;
  max-width: 264px;
  height: 100%;
  display: grid;
  padding-top: 42px;
  grid-template-rows: 1fr auto;

  @include mobileMedia() {
    min-width: 100%;
    max-width: 100%;
    padding: 16px 16px 16px;
  }

  .settings {
    overflow-y: auto;

    @include mobileMedia() {
      width: 100%;
      justify-content: flex-start;
    }

    &.babushka {
      display: flex;
      align-items: center;
      justify-content: center;

      .babushkaText {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: #0a1e42;
        font-size: 18px;
        font-weight: 500;

        div {
          margin-top: 12px;
        }
      }

      .babushkaImg {
        width: 120px;
        height: auto;
      }
    }

    .header {
      color: tokens.$color-gray-700;
      font-weight: 500;
      font-size: 14px;
    }

    .layoutSettingsOverlay {
      margin-top: 16px;

      @include mobileMedia() {
        display: flex;
        margin-top: 8px;
      }

      .layoutSettingsItem {
        margin-top: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &.soon {
          opacity: 0.25;
          cursor: default;
        }

        @include mobileMedia() {
          margin-top: 0;
          position: relative;
          margin-right: 12px;
          flex-direction: column;
        }

        .layoutSettingsItemImg {
          width: 80px;
          height: auto;
          border: solid 2px transparent;
          transition: border 0.2s ease-in-out;
          border-radius: tokens.$border-radius-200;

          @include mobileMedia() {
            width: 70px;
            height: auto;
          }
        }

        .layoutSettingsItemName {
          margin-left: 8px;
          font-size: 14px;
          font-weight: 500;
          color: tokens.$color-gray-700;

          @include mobileMedia() {
            margin-left: 0;
            font-size: 12px;
          }
        }

        &.active {
          .layoutSettingsItemImg {
            border: solid 2px #2970ff;
            & rect:nth-child(1) {
              fill: #bac1cc;
            }
          }
          .layoutSettingsItemName {
            color: #155eef;
          }
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .buttons {
    padding-top: 12px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-flow: column;

    @include mobileMedia() {
      align-items: flex-start;
      flex-flow: row;
      justify-content: space-between;
    }

    .demoBtn {
      padding: 8px 14px;
      -webkit-appearance: none;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #004eeb;
      background: #eff4ff;
      border: 1px solid #b2ccff;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: tokens.$border-radius-200;
      position: relative;
      transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
      cursor: pointer;

      .hidden {
        opacity: 0;
      }

      .loader {
        position: absolute;
      }

      &:hover:not(:disabled) {
        background: #dee5ff;
      }

      &:disabled {
        opacity: 0.9;
      }

      @include mobileMedia() {
        display: none;
      }
    }

    .backBtn {
      appearance: none;
      -webkit-appearance: none;
      background: #ffffff;
      border: 1px solid #bac1cc;
      border-radius: tokens.$border-radius-200;
      padding: 11px 0;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: tokens.$color-gray-700;
      max-width: 25%;
      min-width: 75px;
      margin-right: 20px;
      display: none;

      &:hover {
        border: 1px solid #6b778c;
        opacity: 0.9;
      }

      @include mobileMedia() {
        display: block;
      }
    }

    .importBtn {
      appearance: none;
      -webkit-appearance: none;
      border: none;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      background: #155eef;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: tokens.$border-radius-200;
      padding: 12px 28px;
      transition: 0.2s opacity ease-in-out;
      cursor: pointer;
      flex: 1;

      &:hover {
        opacity: 0.9;
      }

      &:disabled {
        opacity: 0.4;
      }
    }
  }
}
