@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/colors' as colors;
@use '@restream/styles/scss/media' as media;

@mixin verticalLayout {
  @include media.lg {
    @content;
  }
}

.root {
  margin-top: 10vh;
  position: relative;

  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 1fr;

  //padding-left: 24px;
  //padding-right: 24px;

  min-width: 0;

  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;

  @include verticalLayout {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  @include media.sm {
    margin-top: 8px;
  }
}

.leftArea {
  //flex: 1;
  //display: flex;

  justify-self: center;

  width: 100%;

  //padding: 8px;
  padding-right: 16px;
  padding-left: 16px;

  margin-bottom: 40px;

  position: relative;
}

.leftContentWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: sticky;
  top: calc(10vh + 53px);
  z-index: 1;

  @include verticalLayout {
    position: unset;
  }
}

.leftContentWrapper:after {
  content: '';
  display: block;
  width: 1px;
  height: 90vh;
  background: linear-gradient(180deg, #253858 80%, rgba(37, 56, 88, 0));
  position: absolute;
  right: -16px;
  top: -10vh;

  @include verticalLayout {
    display: none;
  }
}

.rightArea {
  justify-self: center;

  max-width: 780px;
  width: 100%;

  padding: 0 40px 40px;

  @media (max-width: 719px) {
    // Hack to fix the layout on mobile (do not allow huge mobile cards)
    max-width: 400px;
    padding: 0 8px 40px;
  }
}

.tabsSticky {
  position: sticky;
  top: 0;
  background: #182a4e;
  z-index: 1;
}
