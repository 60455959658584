@use '@restream/styles/scss/tokens';

.root {
  width: 100%;

  // TODO remove hack to put this block a bit higher
  margin-bottom: 10vh;

  @media (max-width: 1170px) {
    margin-bottom: 0;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: 16px;
}

.headerText {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #edf0f3;
}

.headerButton {
  font-size: 14px;
  padding: 10px 16px;
}

.textWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 200px;

  padding: 0 15px;
  background: #0e2347;
  border-radius: tokens.$border-radius-400;
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  text-align: center;
  color: #42526e;
}
