@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';
@import '../common';

.productMenuAvatar {
  width: $icon-size;
  height: $icon-size;
  border-radius: 50%;
  transition: background-color $animation-hover-time;
  display: flex;
  align-items: center;
  justify-content: center;
  background: tokens.$color-gray-800;
}
