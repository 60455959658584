@use '@restream/styles/scss/tokens';
@use '../../scss/colors' as colors;

.input {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  width: 100%;
  transition: all 0.3s;
  padding: 10px 12px;
  border-radius: tokens.$border-radius-200;
  outline: none;

  color: colors.$fg-color;
  background: colors.$bg-color--darken-1;
  border: 1px solid colors.$color-border-default;
}

.input:disabled {
  color: lighten(colors.$fg-color, 20%);
}

.input:focus {
  border-color: colors.$color-border-focus;
}

.input.error {
  border-color: colors.$color-border-error;
}

.input::placeholder {
  font-size: 14px;
  color: colors.$ui-placeholder-color;
}
