$bg-color--lighten-1: #253858;
$bg-color: #172c4d;
$bg-color--darken-1: #091e42; // TODO should be migrated to tokens.$color-gray-900

$fg-color: rgba(255, 255, 255, 0.9);
$fg-color--darken-1: rgba(255, 255, 255, 0.7);
$fg-color--darken-2: rgba(255, 255, 255, 0.5);
$fg-color--darken-3: rgba(255, 255, 255, 0.3);

$primary-color: #ff5630;
$error-color: #fc8471;
$text-color: #ebecf0;

$ui-focus-color: #4c9aff;
$ui-placeholder-color: $fg-color--darken-3;

$color-border-focus: #286fff; // TODO should be migrated to tokens.$color-blue-500
$color-border-error: #ff004a;
$color-border-default: #a5adba;

$color-border-error-dark: #fc8471;
