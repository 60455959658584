@use '@restream/styles/scss/media';

.modalRoot {
  width: fit-content;

  @include media.xs {
    margin: auto;
    padding: 10px;
  }

  &.iosDevices {
    display: flex;
    align-items: center;

    height: 100%;

    margin: auto;
  }
}

.root {
  position: relative;
  overflow: hidden;

  background: none;

  &:hover {
    .topBar {
      opacity: 1;
    }
  }
}

.closeButtonWrapper {
  margin: 6px 6px 0 0;
}

.closeButton {
  border-radius: 50%;
  padding: 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.closeButtonIcon {
  fill: #fff !important;
}

.topBar {
  background: linear-gradient(180deg, #000000, rgba(19, 19, 19, 0));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 4;
  display: flex;
  justify-content: flex-end;

  // initial invisible state, is set to 1 on root hover
  opacity: 0;
  transition: opacity 300ms;

  &.iosDevices {
    display: none;
  }
}

.overlay {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: black;
}

.overlayContent {
  position: absolute;
  top: 45%;
}

.playerWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: black;
}

.player {
  max-width: 100%;
}
