.root {
  display: flex;
  width: fit-content;

  background: linear-gradient(
      90deg,
      rgba(103, 227, 249, 0.1) -99.46%,
      rgba(46, 144, 250, 0.1) -49.56%,
      rgba(0, 78, 235, 0.1) 0.47%,
      rgba(105, 39, 218, 0.1) 76.1%,
      rgba(212, 68, 241, 0.1) 100%
    ),
    #ffffff;
  border-radius: 8px;
  padding: 12px 10px;
  margin: 0 auto 24px;
}

.text {
  background: linear-gradient(90deg, #004eeb 0%, #6927da 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-left: 4px;
}
