@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/modals' as modals;

.root {
  @include modals.modal-width;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newBadge {
  display: flex;

  &::after {
    content: 'New';
    background-color: #9145ff;
    color: #fff;
    margin-left: 6px;
    padding: 2px 4px;
    font-size: 12px;
    line-height: 1;
    border-radius: tokens.$border-radius-200;
  }
}
