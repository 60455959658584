@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.iconWrap {
  margin-left: 10px;
  top: -3px;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;

  @include media.sm {
    min-height: auto;
  }
}

.content {
  padding: 0 32px 12px;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 3px 0 8px;
}

.accountSelectWrapper {
  @include media.xs {
    display: none;
  }
}

.titleRoot {
  &.marginTop {
    margin-top: 20px;
  }

  @include media.sm {
    margin: 0 0 16px;
  }
}

.gridContainer {
  position: relative;
  display: grid;
  width: 100%;
  grid-gap: 16px;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1680px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media.lg {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media.md {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media.sm {
    grid-template-columns: repeat(1, 1fr);
  }
}

.loaderWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  & .text {
    color: tokens.$color-gray-400;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  & .retryButton {
    padding: 0;
    color: inherit;
    background: transparent;

    text-decoration: underline;

    &:hover:not(&.disabled) {
      color: inherit;
      background: transparent;

      text-decoration: initial;
    }
  }
}

.noSubscriptionWrap {
  max-width: 505px;
  margin: auto;
}

.uploadsTabActionsContainer {
  display: flex;
  align-items: center;

  @include media.xs {
    flex-direction: column;
    align-items: flex-start;
  }
}

.storageVolumeTrigger {
  margin-right: 16px;

  @include media.xs {
    margin: 0 0 20px;
  }
}

.tabs {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;

  @include media.sm {
    margin-bottom: 24px;
  }
}

.tab {
  letter-spacing: 0.08em;
}

.tabUploadsNumber {
  font-weight: 400;
  margin-left: 4px;

  &.exceededMaxSlots {
    color: tokens.$color-red-400;
  }
}

.dragOverlayEnter {
  opacity: 0;
}

.dragOverlayEnterActive {
  opacity: 1;
  transition: opacity 200ms;
}

.dragOverlayExit {
  opacity: 1;
}

.dragOverlayExitActive {
  opacity: 0;
  //transition: opacity 200ms; // was commented out because of new DnD icon animation (it takes 200 more ms which impacts the DnD animation and makes it look like it renders twice)
}

.errorToastWrapper {
  width: 450px;
  padding: 12px 10px 16px !important;
  background: #ffffff !important;
  border-radius: tokens.$border-radius-200 !important;
  border: 1px solid #edf0f3;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  @media (max-width: 480px) {
    width: auto;
    max-width: 95%;
    padding: 16px !important;
    bottom: 1.5em !important;
  }
}

.badge {
  color: #fff;
  text-align: center;

  padding: 1px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.96px;
  text-transform: uppercase;

  border-radius: 10px;
  margin-left: 10px;
  background: linear-gradient(
    90deg,
    #67e3f9 -99.46%,
    #2e90fa -49.56%,
    #004eeb 0.47%,
    #6927da 50.24%,
    #d444f1 100%
  );
}
