@use '~@restream/styles/scss/media' as media;
@use '@restream/styles/scss/tokens';

$amazonColor: #ff9400;

.buttonsWrapper {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: auto min-content;
  border-radius: tokens.$border-radius-200;
  overflow: hidden;

  //@include media.xs {
  //  padding: 8px 12px 8px 16px; // it includes the space for svg icon
  //}

  &:hover {
    opacity: 1 !important;
  }
}

.button {
  position: relative;
  height: 40px;
  margin: 0 !important;
  border-radius: 0;
  background-color: $amazonColor;
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease;

  &:not(:disabled):hover {
    background-color: $amazonColor;
    opacity: 0.9;
  }

  &.dropdownButton {
    height: 40px;
    width: 56px;
    position: relative;
  }

  &.dropdownButton::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 100%;
    // TODO(color): use token
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.buttonImage {
  position: absolute;
  left: 16px;
}

.buttonText {
  position: relative;
  left: 12px;
}

.dropdown {
  background-color: tokens.$color-white;
  //box-shadow: 0 6px 26px rgb(103 110 144 / 20%);
  // TODO(color): use token
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: tokens.$color-white solid tokens.$color-gray-100;
  border-radius: tokens.$border-radius-200;
  margin: 0;
  padding: 0;
}

.dropdownButtonContent {
  //@include media.xs {
  //justify-content: space-between;
  //}
}
