@import '../layout/header/PublicClipsHeader.module';

.loadingOverlay {
  position: absolute;

  height: 100%;
  width: 100%;
  background: white;

  top: $headerHeight;

  .title {
    margin-left: -4px;
    letter-spacing: 1px;
  }
}
