@use '~@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

.root {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  align-items: center;
  text-transform: uppercase;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  font-weight: 500;
  border: none;
  cursor: pointer;
  padding: 0;
  // TODO(color): use token
  color: #ebecf0;
  background-color: transparent;
  transition: color 300ms ease;

  &:not(:disabled):hover {
    color: tokens.$color-white;
    background-color: transparent;
  }
}

.content {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
}

.text {
  @include media.md {
    display: none;
    flex-direction: column;
  }
}

.icon {
  width: 17px;
  height: auto;
}
