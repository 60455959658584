@use '@restream/styles/scss/tokens';

.list {
  list-style: initial;
  margin-left: 32px;
  margin-bottom: 12px;

  & .item {
    color: tokens.$color-gray-700;
    font-size: 12px;
    line-height: 20px;

    &::marker {
      content: var(--marker-content);
    }
  }

  &.noMarginBottom {
    margin-bottom: 0;
  }

  &.noMarginLeft {
    margin-left: 0;
  }
}
