@import '@restream/styles/scss/tokens';

.radioButton {
    position: relative;
}

.radioButton.topRight{
    position: absolute;
    top: $spacing-xs;
    right: $spacing-xs;
}

.content{
    display: flex;
    flex-direction: column;
}

.subtitle {
    color: $color-gray-500;
}