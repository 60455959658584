@use '@restream/styles/scss/modals';
@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.selectDropDown {
  & .selectedItem {
    background-color: tokens.$color-gray-50;
  }
}

.selectFormGroup {
  & .selectErrorContainer {
    bottom: -8px;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.itemName {
  margin-left: 8px;
}

.selectButton {
  &.channels {
    padding: 0 12px 0 0;
  }

  &:focus-visible,
  &.active {
    outline: none;
    box-shadow: inset 0 0 0 1px tokens.$color-blue-500;
  }
}

.submitButton {
  margin: auto 0 0 auto;
}

.checkboxGroupWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
}
