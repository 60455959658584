$color-secondary: #758092;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.imageWrapper {
  margin-bottom: 16px;
}

.titleWrapper {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10%;
}

.title {
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
  text-align: center;
  color: #fff;
  margin-bottom: 16px;
}

.message {
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 32px;
}

.squid {
  height: 90px;
}

.anchorButton {
  font-size: inherit;
  line-height: inherit;
  text-decoration: underline;
}
