@use '../../../../../scss/typography' as typography;
@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 100px;
  line-height: initial;
  gap: 4px;
}

.warning {
  .value {
    color: tokens.$color-yellow-300;
  }
}

.title {
  font-style: normal;
  font-weight: normal;
  color: tokens.$color-white;
  mix-blend-mode: normal;
  opacity: 0.5;
  font-size: 12px;
}

.value {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  color: tokens.$color-gray-25;
  font-size: 14px;
  font-weight: 500;
  min-height: 20px;
}

.icon {
  margin-right: 4px;
}
