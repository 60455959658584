@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  list-style-type: none;
  transition: background-color 150ms;
  border-radius: tokens.$border-radius-200;

  background-color: #0f2447;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.rows {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  gap: 4px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statuses {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  justify-content: flex-start;
}

.content {
  display: flex;
}

.titles {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
}

.placeholder {
  font-style: italic;
  color: tokens.$color-gray-500;
}

.channelName {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  color: rgba(255, 255, 255, 0.94);
}

.channelStatusContainer {
  min-height: 0;
}

.channelStatusTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #7a869a;
}

.error:not(:empty) {
  margin-top: 8px;
}

.error:empty {
  display: none;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
}

.editButton {
  margin: 0;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #7a869a;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.switch {
  transform: translateY(-1px);
}

.viewers {
  line-height: 20px;
}
