@use '@restream/styles/scss/tokens';
@use '../../../../components/scss/modals' as modals;
@use '~@restream/styles/scss/media' as media;
@use '../../../connect-platform-item/connect-platform-tile-item/constants.module'
  as tilePlatformsMedia;

.root {
  @include modals.modal-width(510px);

  &.fullHeight {
    display: grid;
    grid-template-rows: min-content auto min-content;
    height: 100%;
    grid-template-columns: 100%;
  }

  &.mobileOnboardingRoot {
    @include media.xs {
      grid-template-rows: auto min-content;
    }
  }
}

.modalHeaderAndBodyWrapper {
  @include media.xs {
    position: initial;
    overflow: auto;
    height: inherit;
    display: grid;
    grid-template-rows: max-content auto;
    width: 100%;
    grid-template-columns: 100%;
  }
}

.modalHeader {
  margin-bottom: 20px;

  &.mobileOnboardingModalHeader {
    @include media.xs {
      position: initial;
      padding-bottom: 0;
      box-shadow: none;
    }
  }

  &.mobileModalHeader {
    @include media.xs {
      position: sticky;
      top: 0;
      z-index: 5;

      background: white;
      padding-bottom: 24px;
      border-radius: tokens.$border-radius-300 tokens.$border-radius-300 0 0;
      margin: 0;

      box-shadow: 0 20px 24px -4px rgb(16 24 40 / 8%);
    }
  }

  &.tilePlatformsHeader {
    @media (max-width: 480px) {
      box-shadow: none;
    }
  }
}

.modalBody {
  padding: 0;

  &.mobileModalBody {
    @include media.xs {
      max-height: calc(100vh - 360px);
      min-height: 260px;
      overflow: scroll;
      padding-top: 20px;
    }

    .fullHeight & {
      max-height: initial;
      min-height: initial;
      height: 100%;
    }
  }

  &.mobileOnboardingModalBody {
    @include media.xs {
      overflow: initial;
      max-height: initial;
      min-height: initial;
    }
  }

  &.extendedOnboardingInterface {
    padding: 40px 12px 0; // Side paddings might be changed in the future
  }
}

.productsGroup {
  padding: 0 24px;
}

.tabsContainer {
  padding: 0 24px 20px;
}

.miniEventCardContainer {
  padding: 0 24px;
  margin-bottom: 20px;
}

.eventSourceRoot {
  padding: 0 24px;
  margin-bottom: 24px;
}

.modalCustomTab {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: tokens.$color-gray-800;
}

.submitButton {
  width: 135px;
  height: 36px;
  font-weight: 600;
  font-size: 14px;

  // added because fullWidth class overrides
  &.startStreaming {
    font-weight: 500;
    border: 1px solid #f33905;
    background: linear-gradient(0deg, #ff4405 0%, #f63 50%, #ff4405 100%), #f63 !important;
    background-size: 100% 200% !important;
    background-position-y: 100% !important;
    padding: 0 14px;
    color: tokens.$color-white;
    transition:
      background 200ms ease-out,
      border 200ms ease-out;

    &:hover:not(:disabled) {
      border: 1px solid rgba(243, 57, 5, 0.8);
      background-position-y: 0 !important;
      box-shadow: 0 1px 4px 0 rgba(242, 60, 0, 0.25);
    }
  }
}

.eventModalDatetime {
  padding: 0 24px;
  margin-bottom: 24px;

  &.withLooping {
    margin-bottom: 12px;
  }

  &.mobileOnboarding {
    padding: 0;
  }
}

.videoLoopingFormGroup {
  padding: 0 24px;
  margin-bottom: 24px;
}

.modalFooter {
  &.columnDirectionFooter {
    flex-direction: column;
  }

  &.mobileModalFooter {
    @include media.xs {
      position: sticky;
      bottom: -1px;
      z-index: 3;

      background: white;
      padding-top: 24px;
      border-radius: 0 0 tokens.$border-radius-300 tokens.$border-radius-300;
      box-shadow: 0 -20px 24px -4px rgb(16 24 40 / 8%);
    }
  }

  &.mobileOnboardingModalFooter {
    @include media.xs {
      border-top: 1px solid tokens.$color-gray-100;
      box-shadow: none;
    }
  }

  &.tilePlatformsFooter {
    @media (max-width: 480px) {
      box-shadow: none;
    }
  }
}

.toggleText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
}

.disabledToggleText {
  color: #505f79;
}

.badgeNew {
  background: #8777d9;
  padding: 1px 6px;
  margin: 0 0 0 4px;
  border-radius: tokens.$border-radius-200;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: tokens.$color-white;
  text-transform: uppercase;

  @media (max-width: 480px) {
    position: absolute;
    top: -12px;
    right: -18px;
  }
}

.fullWidthButton {
  width: 100%;
  height: 36px;
  background: tokens.$color-blue-500;
}

.fullWidthTooltip {
  width: 100%;
}

.pairsTip {
  margin: 12px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #505f79;
}

.pairsTipLink {
  color: #505f79;
  font-weight: 500;
  border-bottom: 1px solid;
}

.destinationsContentAndPlaceholderWrapper {
  height: 325px;
  display: grid;
  place-items: center stretch;
  grid-template-columns: 100%;
  margin: 0 auto 0;

  @include media.xs {
    height: auto;
  }
}

.bottomDivider {
  height: 16px;
  width: 100%;
}

.bottomDivider.shadow {
  box-shadow: 0px -6px 8px 0px rgba(0, 0, 0, 0.05);
}

.addDestinationsButtonContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  &.tileItemsButtonContainer {
    @media (max-width: tilePlatformsMedia.$tilePlatformsMaxWidth) {
      margin-bottom: 0;
    }
  }

  &.mobileOnboarding {
    padding: 0;
    margin-bottom: 12px;
  }
}

.footerButtonsWrapper {
  @include media.xs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &.extendedOnboardingInterface {
    display: flex;
  }
}

.footerBackButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: tokens.$color-gray-700;
  background: transparent;

  margin-right: 16px;
  border-radius: tokens.$border-radius-200;

  &:hover:not(:disabled) {
    background: tokens.$color-gray-50;
    color: tokens.$color-gray-700;
  }
}

.toggleRoot {
  width: 100%;
}

.contactSalesButton {
  border: none;
  background: none;
  cursor: pointer;
  color: tokens.$color-red-500;
  text-align: left;
  font-size: inherit;
  text-decoration: underline;
  padding: 0;

  &:focus-visible {
    outline: none;
  }

  span {
    font-weight: 500;
  }
}

.concurrentError {
  text-wrap: pretty;
}
