.text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #091e42;
  margin-bottom: 12px;
  text-wrap: pretty;

  &:last-child {
    margin-bottom: 0;
  }
}
