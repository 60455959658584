@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/modals' as modals;

.root {
  @include modals.modal-width;
}

.noPaddingBottom {
  padding-bottom: 0;
}
