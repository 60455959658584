.item {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 12px;
  align-items: center;
  color: #ced9e7;
  cursor: pointer;
  width: fit-content;

  box-sizing: border-box;
  margin: 1rem 0;
  user-select: none;

  .disabled & {
    cursor: default;
  }
}
