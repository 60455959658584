@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;

  margin-top: 12px;
  margin-bottom: 20px;
}

.title {
  color: tokens.$color-white;
  font-size: tokens.$font-size-heading-xs;
  letter-spacing: 0.01em;
  line-height: 24px;
  font-weight: 500;
}

.buttonBackWrapper {
  margin-right: 16px;
}
