@use '@restream/styles/scss/tokens';

.duplicateButton {
  color: tokens.$color-gray-300;
  box-shadow: inset 0 0 0 1px tokens.$color-gray-500;
  transition: all 0.25s ease;

  &:not(:disabled):hover {
    background-color: rgba(215, 220, 227, 0.1);
    color: tokens.$color-gray-200;
  }
}

.duplicateButtonIcon {
  display: flex;
  justify-content: center;
}
