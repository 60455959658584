.root {
  display: grid;
  padding: 24px;
  align-items: center;
  grid-gap: 16px;
  grid-template-rows: auto 1fr auto;
  border-radius: 18px;
  background: #ecf0f1;
  margin-top: 4px;

  &:nth-of-type(2n - 1) {
    margin-top: -72px;
    margin-bottom: 72px;
  }

  &:nth-of-type(4n - 1) {
    background: #c7ecee;
  }

  &:nth-of-type(4n - 2) {
    background: #c7ecee;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: auto 1fr auto;
    justify-items: start;
    grid-template-rows: unset;
  }

  .imageWrapper {
    display: flex;
    justify-content: center;

    .image {
      width: 75%;
      height: auto;

      @media screen and (max-width: 768px) {
        width: 100px;
      }
    }
  }

  .content {
    margin-top: 12px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
    width: 100%;

    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
      height: 56px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-style: normal;
    }

    .price {
      margin-top: 12px;
      font-size: 24px;
      font-weight: 700;
    }
  }

  .actions {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .primary {
      padding: 10px 32px;
      border-radius: 12px;
      color: #fff;
      font-size: 14px;
      background: #155eef;
      cursor: pointer;
      font-weight: 500;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
