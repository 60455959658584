@property --gradient-start {
  syntax: '<color>';
  initial-value: rgba(110, 113, 255, 0.15);
  inherits: false;
}

@property --gradient-end {
  syntax: '<color>';
  initial-value: rgba(255, 42, 246, 0.15);
  inherits: false;
}

.root {
  width: 100%;
  display: flex;
  height: 54px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border: none;
  border-bottom: 1px solid #6e71ff;

  --gradient-start: rgba(110, 113, 255, 0.15);
  --gradient-end: rgba(255, 42, 246, 0.15);

  background: linear-gradient(
    90deg,
    var(--gradient-start) 0%,
    var(--gradient-end) 100%
  );

  cursor: pointer;
  transition:
    --gradient-start 0.3s ease-out,
    --gradient-end 0.3s ease-out;

  &:hover {
    --gradient-start: rgba(110, 113, 255, 0.5);
    --gradient-end: rgba(255, 42, 246, 0.5);
  }
}

.text {
  color: white;
  font-size: 14px;
  line-height: 20px;
}

.button {
  border-radius: 8px;
  border: 0 solid #0a56ed;
  background: black;
  padding: 8px;

  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  cursor: pointer;
  transition: all 0.25s ease-out;

  &:hover {
    background: white;
    color: black;
  }
}
