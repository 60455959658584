@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/animation';

.uploadAction {
  display: flex;

  &.hidden {
    display: none;
  }
}

.uploadButton {
  height: 40px;
  padding: 8px 12px 8px 14px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.centeredImportButton {
  padding: 10px 10px 8px 16px;
  height: 40px;
  width: 40px;
  border-radius: 0;
  margin-left: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightEdgeImportButton {
  padding: 10px 10px 8px 16px;
  height: 40px;
  width: 40px;
  margin-left: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.popoverTrigger {
  height: 40px;
  width: 40px;
  border-bottom-right-radius: tokens.$border-radius-100;
  border-top-right-radius: tokens.$border-radius-100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: tokens.$color-blue-500;
  margin-left: 1px;
  cursor: pointer;
}

.popoverTriggerOpen {
  background-color: tokens.$color-blue-800;
}

.popoverTrigger,
.popoverTriggerOpen {
  transition: background-color animation.$animation-default-time ease-in-out;
}

.icon {
  display: block;
}
