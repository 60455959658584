.switch {
  display: flex;
  margin-right: 8px;
}

.switchLabel {
  margin: 0;
}

.formGroup {
  gap: 8px;
}
