@use '~@restream/styles/scss/colors' as colors;

.paidFeatureDescription {
  font-size: 14px;
  color: #091e42;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;

  cursor: pointer;
  color: colors.$bg-color--darken-1;
}

.item-dropdown {
  padding: 8px;
  transition: all 200ms ease;
}

.item-dropdown.highlighted {
  background: #bde4ff;
}

.icon {
  height: 18px;
  margin-right: 8px;
}

.icon.default {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  font-size: 10px;
  color: white;
  background-color: rgb(77, 117, 163);
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  background: none;
  cursor: pointer;

  flex-grow: 0;
  margin: 0;
  border: none;
  color: #286fff;

  font-size: inherit;
  line-height: inherit;
  font-weight: 500;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #286fff;
  }

  transition: opacity 200ms;
}
