@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';
@use '~@restream/styles/scss/media';

.root {
  display: flex;
  column-gap: 12px;

  border-radius: 12px;
  background: tokens.$color-gray-700;

  padding: 8px;

  transition: 0.2s ease-out;

  &:is(a) {
    cursor: pointer;

    &:hover {
      background: tokens.$color-gray-600;

      & .separator {
        background: tokens.$color-gray-500;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;

  overflow: hidden;
}

.title {
  @include mixins.truncate-text();

  color: tokens.$color-white;
  font-style: normal;
  font-weight: 500;
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;

  margin-bottom: 2px;
}

.description {
  @include mixins.truncate-text();

  color: var(--Gray-200, #d7dce3);
  font-variant-numeric: lining-nums tabular-nums;
  font-size: var(--font-size-10, 12px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 16px; /* 133.333% */

  &.error {
    display: block;
    color: #ff8f73;

    margin-top: auto;
  }
}

.clipsCountBadge {
  background: tokens.$color-gray-800;
  color: tokens.$color-gray-200;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.detailsGroup {
  display: flex;
  align-items: center;

  margin-bottom: 4px;
}

.inputType {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;

  color: #d7dce3;
  font-size: 12px;
  font-style: normal;
  font-variant-numeric: lining-nums tabular-nums;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.separator {
  background: tokens.$color-gray-600;
  height: 12px;
  margin-left: 8px;
  margin-right: 8px;
  width: 1px;

  transition: 0.2s ease-out;
}
