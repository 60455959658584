@use '@restream/styles/scss/tokens';

.root {
  background-color: #253858;
  border-radius: tokens.$border-radius-200;

  // transition: all 300ms;
  position: relative;

  &.previewMode {
    padding: 16px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  position: relative;
}

.imageWrapper {
  display: flex;
  gap: 16px;
  width: 100%;
}

.image {
  height: 100%;
}

.description {
  display: flex;
}

.titleWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  display: flex;
  padding: 14px 10px 16px 20px;

  &.previewMode {
    padding: 0;
  }
}

.actionButtonsWrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.thumbnailWrapper {
  display: flex;
}

.chatBlockWrapper {
  display: flex;
  width: 33.7%;

  padding-top: 16px;
}

.separator {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #35445d;
    left: 0;
    top: 12px;
  }
}

.monitorBlockWrapper {
  overflow: hidden;
}
