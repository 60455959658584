@use '@restream/styles/scss/tokens';
@import '../../../../components/scss/modals';

.root {
  color: #0a1e42;

  max-height: 70vh;
  position: relative;
  overflow: auto;

  @include modal-width;
  min-width: 0;
}

.calloutWrapper {
  margin-bottom: 24px;
}

.link {
  color: tokens.$color-gray-500;
  text-decoration: underline;
}
