$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1400px;

$breakpoint-height-sm: 447px;

@mixin xs {
  @media (max-width: #{$breakpoint-xs}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$breakpoint-xl}) {
    @content;
  }
}

@mixin height-sm {
  @media (max-height: #{$breakpoint-height-sm}) {
    @content;
  }
}
