@use '@restream/styles/scss/tokens';
@import '~@restream/styles/scss/colors';

.root {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 2px 10px;

  font-style: normal;
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  text-transform: capitalize;
  user-select: none;

  background-color: tokens.$color-gray-700;
  color: tokens.$color-gray-25;
  border-radius: tokens.$border-radius-200;
  z-index: 10;
}

.root.live {
  background: tokens.$color-orange-dark-400;
  color: tokens.$color-orange-dark-25;
  animation: blinkingLive 1.2s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

.root.small {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 10px;
  line-height: 12px;

  top: 5px;
  left: 5px;
  margin-left: 0;
}

@keyframes blinkingLive {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}
