@use '@restream/styles/scss/tokens';
@import '../../../constants';

.root {
  color: tokens.$color-white;
  font-style: normal;
  font-weight: 500;
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;

  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: $mobileMaxWidth) {
    max-width: 160px;
  }
}
