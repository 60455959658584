@use '@restream/styles/scss/tokens';
@use '../../../../scss/typography' as typography;
@use '../../../../scss/colors' as colors;

.root {
  display: flex;
  flex-direction: column;

  background: tokens.$color-gray-900;
  border-radius: tokens.$border-radius-200;
  padding: 16px;
}

.tooltip {
  text-align: center;
  padding: 2px 6px;
}

.clickableStat {
  padding: 0;
  background: transparent;
  border: none;
  text-align: initial;
  cursor: pointer;
}

.container {
  display: flex;
  gap: 16px;
}

.left {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 16px;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right {
  margin-left: auto;
  margin-right: -16px;
}
