@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(90deg, #6e71ff 0%, #ff2af6 100%);
  color: tokens.$color-white;
  padding: 1px;
}

.content {
  border-radius: 7px;
  background: #283468;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.closeButton {
  flex-shrink: 0;
  color: tokens.$color-gray-300;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  width: 24px;
  height: 24px;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.2s ease-in-out;
  margin: 8px 8px 8px 0px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  & svg {
    width: 16px;
    height: 16px;
  }
}

.prompt {
  padding: 10px 12px 10px 12px;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 14px;

  &:focus-visible {
    outline: none;
  }

  span {
    font-weight: 500;
  }

  &:is(:hover, :focus-visible) span {
    text-decoration: underline tokens.$color-white;
    -webkit-text-decoration: underline tokens.$color-white; // for safari
  }
}

.animateShimmerText {
  --muted-foreground: #{tokens.$color-gray-300};
  --foreground: #{tokens.$color-white};

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: var(--muted-foreground);
  background-image: -webkit-gradient(
    linear,
    100% 0,
    0 0,
    from(var(--muted-foreground)),
    color-stop(0.5, var(--foreground)),
    to(var(--muted-foreground))
  );
  animation: shimmer-text 3s infinite;
  background-repeat: no-repeat;
  background-size: 50% 200%;

  &:is(:hover, :focus-visible) {
    --muted-foreground: #{tokens.$color-gray-25};
    --foreground: #{tokens.$color-gray-25};
  }
}

@keyframes shimmer-text {
  0% {
    background-position: -100% top;
  }
  100% {
    background-position: 250% top;
  }
}
