//@use '~@restream/styles/scss/colors' as colors;

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video {
  object-fit: fill;
}

.debugHistory {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 50%;
  font-size: 11px;
  padding-left: 12px;

  z-index: 10;
}
