@use '@restream/styles/scss/modals';

.root {
  @include modals.modal-width;
}

.body {
  height: 400px;
  position: relative;
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
