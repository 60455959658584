.stepIcon {
  @media (max-width: 768px) {
    width: 17px;
  }

  @media (max-width: 576px) {
    display: none;
  }
}

.desktopStepperWrapper {
  margin: 12px 0;
  flex: 1;

  @media (max-width: 576px) {
    margin-right: 0;
  }
}
