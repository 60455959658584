@use '@restream/styles/scss/media';

.root {
  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  @include media.xs {
    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
}
