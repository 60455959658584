@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.primaryText,
.text,
.button {
  font-size: 14px;
  color: tokens.$color-gray-700;
  line-height: 20px;

  & .bold {
    font-weight: 500;
  }
}

.primaryText {
  margin-top: 12px;
}

.button {
  font-weight: 400;
  text-decoration: underline;

  border: none;
  background: none;
  padding: 0;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover:not(&.disabled) {
    text-decoration: none;
    color: tokens.$color-gray-700;
    background: transparent;
  }
}
