.root {
  height: 100%;
  display: flex;
  align-items: center;
}

.field {
  color: #ebecf0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 6px;
}

.toggle {
  order: 2;
}
