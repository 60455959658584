// Mostly copied from studio-frontend
@use '@restream/styles/scss/tokens';

.root {
  padding: 16px;

  &.destinationIssuePopup {
    display: grid;
    grid-template-rows: min-content auto;
    max-width: 336px;

    padding: 0;
    border-radius: 12px;
    overflow: hidden;
  }

  &.minHeight {
    min-height: 180px;
  }
}

.popover {
  &[data-popper-placement^='bottom'] {
    .arrow {
      &.destinationIssuePopup {
        &::before {
          background-color: tokens.$color-red-100;
        }
      }
    }
  }
}

.warningIcon {
  margin-right: 4px;
}

.title {
  margin-bottom: 8px;
  margin-top: 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: tokens.$color-gray-800;

  &.destinationIssuePopup {
    font-weight: 600;
    color: tokens.$color-red-700;

    background-color: tokens.$color-red-100;
    padding: 12px 16px;
    margin: 0;
  }
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-800;
  margin: 0;
}

.button {
  display: inline-block;

  padding: 6px 14px;

  margin-top: 12px;

  border-radius: tokens.$border-radius-200;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;

  color: tokens.$color-white;

  background-color: tokens.$color-blue-500;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #04c;
    color: tokens.$color-white !important;
  }
}

button.button {
  border: none;
}

.closeButtonWrapper {
  position: absolute;
  right: 8px;
  top: 8px;
}

.closeButton {
  display: flex;
  align-items: center;
  margin: 3px 3px 0 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  &.destinationIssuePopup {
    margin: 6px 6px 0 0;
  }
}

.closeButtonIcon {
  &.destinationIssuePopup {
    & path {
      stroke: tokens.$color-gray-600;
    }
  }
}
