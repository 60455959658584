@import '../../../scss/typography';

.button {
  @include font-16;
  padding: 6px;

  color: white;
}

.icon {
  display: flex;
  position: absolute;
  left: 0;

  margin-left: 8px;
}

.button.defaultLight {
  background: #286fff;
  color: rgba(255, 255, 255, 0.9);

  &:hover:not(:disabled) {
    background: rgba(40, 111, 255, 0.8);
    color: rgba(255, 255, 255, 0.9);
  }
}

.button.default {
  background: #fff;
  color: #293042;

  &:hover {
    background: #788090;
    color: #293042;
  }
}

.button.facebook {
  background: #1677f0;

  &:hover:not(:disabled) {
    background: rgba(22, 119, 240, 0.8);
  }
}

.button.twitch {
  background: #9146ff;

  &:hover:not(:disabled) {
    background: rgba(145, 70, 255, 0.8);
  }
}

.button.youtube {
  background: #cd201f;

  &:hover:not(:disabled) {
    background: rgba(161, 25, 24, 0.8);
  }
}

.button.goodgame {
  background: #3a6097;

  &:hover:not(:disabled) {
    background: rgba(77, 115, 172, 0.8);
  }
}

.button.dailymotion {
  background: #0af;

  &:hover:not(:disabled) {
    background: rgba(0, 170, 255, 0.8);
  }
}

.button.periscope {
  background: #40a4c4;

  &:hover:not(:disabled) {
    background: rgba(64, 164, 196, 0.8);
  }
}

.button.linkedin {
  background-color: #0a66c2;

  &:hover:not(:disabled) {
    background-color: #004182;
  }
}

.button.dlive {
  background: #ffd300;
  color: #000;

  &:hover:not(:disabled) {
    background: rgba(255, 211, 0, 0.8);
    color: #000;
  }
}

.button.trovo {
  background: #32c27c;

  &:hover:not(:disabled) {
    background: rgba(26, 171, 119, 0.8);
  }
}

.button.slack {
  background: #4a154b;

  &:hover:not(:disabled) {
    background: rgba(74, 21, 75, 0.8);
  }
}

.button.tiktok {
  background: #000;

  &:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.8);
  }
}

.button.picarto {
  background: #27ae60;

  &:hover:not(:disabled) {
    background: rgba(46, 205, 113, 0.8);
  }
}

.button.twitter {
  background: #000000;

  &:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.8);
  }
}

.button.rumble {
  background: #000000;

  &:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.8);
  }
}

.button.kick {
  background: #000;

  &:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.8);
  }
}
