@use '@restream/styles/scss/tokens';
@import '../scss/media-mf';

.menuPlaceholder {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: none;

  @include tablet {
    display: block;
  }
}

.productMenu {
  box-sizing: border-box;
  background-color: tokens.$color-gray-900;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  position: fixed;
  height: 100%;
  width: 60px;
  padding: 14px;
  top: 0;
}

.productMenuItems {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productMenuItem {
  // TODO(color): use token
  border-color: rgba(206, 217, 224, 0.2) !important;
  box-shadow: none !important;
  background: rgba(206, 217, 224, 0.2);
  background-clip: padding-box !important;
  cursor: default;
  color: transparent !important;
  animation: skeleton-glow 1s linear infinite alternate;
  pointer-events: none;
  user-select: none;
}

.productMenuItemLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  margin-bottom: 11px;
  border-radius: 50%;
}

.productMenuItemLink {
  border-radius: tokens.$border-radius-200;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transition: background-color 0.5s ease-out;

  border: 0;
  padding: 0;

  width: 36px;
  height: 36px;
  margin: 6px 2px;
}

.userMenu {
  position: relative;
}

.userMenuButton {
  width: 32px;
  height: 32px;
  text-transform: uppercase;

  font-size: 14px;
  border: none;

  border-radius: 50%;
}

@keyframes skeleton-glow {
  0% {
    border-color: rgba(206, 217, 224, 0.2);
    background: rgba(206, 217, 224, 0.2);
  }

  to {
    border-color: rgba(92, 112, 128, 0.2);
    background: rgba(92, 112, 128, 0.2);
  }
}

.srOnly {
  display: none;
}
