@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@import '../constants';
@import '../schedule-or-upload-button/ScheduleOrUploadButton.module';

.scheduleButton {
  background-color: tokens.$color-blue-500;
  border-radius: tokens.$border-radius-200;
  padding: 12px 40px;

  @include media.xs {
    padding: 10px 16px;
  }
}

.calendarIcon {
  @include calendarIcon;
}
