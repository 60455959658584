@use '@restream/styles/scss/tokens';

$color-button-bg: rgba(9, 30, 66, 0.08);
$color-button-fg: #8993a4;

.cropped {
  display: flex;
  align-items: center;
}

.croppedImage {
  width: 112px;
  height: 64px;

  border-radius: tokens.$border-radius-200;
  border: 1px solid #8993a4;

  margin-right: 16px;
}

.button {
  color: $color-button-fg;
  background: $color-button-bg;
  font-weight: normal;
  border-radius: tokens.$border-radius-200;
  padding-top: 4px;
  padding-bottom: 4px;

  &:hover:not(:disabled) {
    color: $color-button-fg;
    background: lighten($color-button-bg, 50%);
  }

  &.replaceButton {
    // margin-right: 8px;
  }

  &.removeButton {
    @media (max-width: 576px) {
      font-size: 0;
    }
  }
}

.buttonIcon {
  display: flex;
}

.removeButtonIcon {
  @media (max-width: 576px) {
    margin: 0 -8px 0 0;
  }
}

.uploadImg {
  margin-right: 3px;
}

.error {
  padding: 8px 0;

  font-size: 14px;
  line-height: 16px;
  font-weight: normal;

  color: red;
  margin-left: 8px;
}

.buttonWrapper {
  display: flex;
}

.input {
  display: none;
}
