@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
}

.text {
  color: tokens.$color-gray-700;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  margin: 12px 0;

  & .bold {
    font-weight: 500;
  }
}

.switchAccountButton {
  color: tokens.$color-gray-400;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  text-align: center;
  padding: 0;
  text-decoration: underline;
  background: transparent;

  &:hover:not(&.disabled) {
    background: transparent;
  }
}

.submitButtonsWrapper {
  width: 100%;

  display: flex;
  align-items: center;
  column-gap: 8px;
}

.separator {
  height: 1px;
  width: 100%;
  margin: 24px 0;
  background: #d7dce3;
}

.scopesWrapper {
  width: 100%;
}

.listHeader {
  color: tokens.$color-gray-700;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 8px;
}

.list {
  list-style: initial;
  padding: 0 0 0 16px;
  margin: 0;
}

.item {
  color: tokens.$color-gray-700;
  font-size: 12px;
  line-height: 18px;
}
