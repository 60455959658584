@use '@restream/styles/scss/tokens';

$smallMobileMaxWidth: 576px;

.modalOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: transparentize(tokens.$color-gray-900, 0.2);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $smallMobileMaxWidth) {
    background-color: tokens.$color-gray-800;
    height: 100%;
    overflow: hidden;
  }
}

.container {
  border-radius: 20px;
  width: 500px;
  margin: 0 auto;

  @media (max-width: $smallMobileMaxWidth) {
    border-radius: 0;
    width: 100%;
    transform: translateY(0px); // for positioning footer to this container
  }
}

.modalFooter {
  padding: 0 40px 40px 40px;

  @media (max-width: $smallMobileMaxWidth) {
    background-color: tokens.$color-white;
    border-top: 1px solid tokens.$color-gray-100;
    padding: 12px 16px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

.title,
.subtitle {
  text-align: left;
  margin: 0;

  @media (max-width: $smallMobileMaxWidth) {
    text-align: center;
  }
}

.title {
  color: tokens.$color-gray-900;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.subtitle {
  color: tokens.$color-gray-400;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;

  @media (max-width: $smallMobileMaxWidth) {
    color: tokens.$color-gray-500;
    font-size: 14px;
    line-height: 20px;
    max-width: 316px;
    margin: 4px auto;
  }
}

.nextButton {
  @media (max-width: $smallMobileMaxWidth) {
    width: 100%;
  }
}
