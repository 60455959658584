.container {
  display: flex;
  padding: 0 24px 24px 24px;

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }
}
