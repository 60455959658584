@import '~@restream/styles/scss/media';

.root {
  line-height: 24px;
  font-size: 16px;
  color: #0a1e42;

  h5,
  p {
    color: #0a1e42;
  }
}

.rootModal {
  width: fit-content;
  min-width: 500px;
  margin: 5vh auto 30px;

  @include xs {
    width: unset;
    min-width: unset;
    margin: 10px;
  }
}

.body {
  padding: 20px 24px 16px;
  max-height: calc(88vh - 54px);
  overflow: auto;

  @include xs {
    max-height: calc(95vh - 100px);
  }
}
