@use '@restream/styles/scss/tokens';

.importButton {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  height: 44px;
  color: tokens.$color-gray-50;

  margin-top: 24px;

  background: linear-gradient(78.2deg, #ff0000 0%, #ff0000 50.1%, #ff0000 100%);
  border-radius: 44px;

  transition: all 5.2s ease;

  &:hover:not(&:disabled) {
    background: linear-gradient(
      90deg,
      lighten(#ff0000, 10%) 0%,
      lighten(#ff0000, 5%) 100%
    );
  }

  @media (max-width: 375px) {
    font-size: 15px;
  }
}
