@use '@restream/styles/scss/tokens';
@use '../../../../scss/mixins' as mixins;
@import '../constants';

.root {
  @include mixins.display-flex-center;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #0e2347;
  border-radius: tokens.$border-radius-400;
  // padding: 20px 6px 10px;
  gap: 0;

  width: 100%;
  max-height: calc(
    100vh - #{$headerHeight} - #{$destinationsBlock} - #{$sumOfSectionsMargins}
  ); // 40px it sum of 2 margins
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  padding-top: 5px;
  padding-left: 24px;
  padding-right: 5px;
  margin-right: 24px;
  border-radius: tokens.$border-radius-400;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #42526e;
    border-radius: tokens.$border-radius-200;
  }
}

.videosTabContent {
  height: 100%;
}

.sidebarStorageError {
  padding: 0;
  height: 100%;
}
