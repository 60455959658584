.root {
  padding: 16px 8px;
  margin-bottom: 24px;
}

.channels {
  margin-bottom: 34px;
}

.blackFridayWrapper {
  background-image: url('./assets/bg.png');
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
}
