.platform-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.channel-name {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
