.subtitleWithTooltipWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  top: 30px;
  left: 0;
  margin-bottom: 20px;
}

.subtitleWrapper {
  display: flex;
  align-items: center;
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  color: #8993a4;

  margin: 0;
  padding: 0;

  cursor: default;

  strong {
    font-weight: 500;
  }
}
