.dateGroup {
  display: flex;
}

.date {
  flex: 1;
  margin-right: 16px;
}

.time {
  align-items: flex-end;
}

.error {
  border: 1px solid red;
}

.formGroupContainer {
  //margin-bottom: 16px;
}

.errorContainer {
  line-height: 1;
  bottom: -12px;
}
