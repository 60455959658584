@use '@restream/styles/scss/tokens';

.root {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  grid-gap: 8px;
  min-height: 58px;
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  min-width: fit-content;
}

.button {
  padding: 4px 8px;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: #ebecf0;
  transition: background-color 200ms ease, color 200ms ease;

  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:disabled):hover {
    background: #23385c;
    color: tokens.$color-white;
    opacity: 1;
  }

  @media (min-width: 576px) {
    height: 36px;
    width: unset;
  }
}
