$color-close-btn: #286fff;

.modalRoot {
  width: fit-content;
}

.root {
  width: 306px;
}

.container {
  padding: 16px;
  text-align: center;
}

.footer {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.img {
  width: 120px;
  height: auto;

  margin-bottom: 12px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #091e42;

  align-self: center;
  margin-bottom: 8px;
}

.desc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;

  color: #091e42;

  align-self: center;
  margin: 0 4px;
}

.closeButton {
  color: $color-close-btn;
  text-align: left;
  margin-left: 10px;

  span {
    transition: all 0.2s;
  }

  &:hover {
    color: $color-close-btn;
    opacity: 0.7;
  }
}
