@use '@restream/styles/scss/tokens';

.wrapper {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.root {
  height: 20px;
  min-width: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px;
  border-radius: 4px;

  background: tokens.$color-gray-800;
  cursor: pointer;

  &.error {
    width: 22px;
    border-radius: 4px;
    background: rgba(240, 68, 56, 0.2);
  }
}

.icon {
  flex-shrink: 1;
  height: 14px;
  color: tokens.$color-red-100;
}

.text {
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  color: white;
}
