.root {
  display: flex;
  justify-content: space-between;
}

.counterWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.counter {
  color: var(--Gray-300, #bac1cc);

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-10, 12px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: 16px; /* 150% */

  strong {
    font-weight: 500;
  }
}

.upgradeButton {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;

  background: linear-gradient(90deg, #ede4fb 0%, #fbecfe 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.toggleButton {
  font-size: 12px;
  line-height: 16px;
}
