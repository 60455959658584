@use '@restream/styles/scss/tokens';
@use '../../../../../scss/modals' as modals;

.modalContainer {
  @include modals.modal-width;
}

.content {
  width: 90%;
  margin: 4px auto 8px;
  color: #505f79;
  font-size: 15px;
  line-height: 22px;
  text-align: center;

  min-height: 220px;
}

.count {
  border-radius: tokens.$border-radius-200;
  bottom: 0;
  color: #ebecf0;
  font-size: 14px;
  font-weight: normal;
  height: fit-content;
  letter-spacing: 0.01em;
  line-height: 20px;
  margin: auto;
  outline: none;
  padding: 5px 8px;
  position: absolute;
  right: 6px;
  top: 0;
  transition: background-color 0.1s ease, color 0.1s ease;
  z-index: 1;
}

.loader {
  width: 100%;
  height: 100%;
  border-radius: tokens.$border-radius-200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  justify-content: center;
}
