@use '@restream/styles/scss/tokens';

.contentEnter {
  opacity: 0;
}
.contentEnterActive {
  opacity: 1;
  transition: opacity 0.2s, transform 1300ms;
}
.contentExit {
  opacity: 1;
}
.contentExitActive {
  opacity: 0;
  transition: opacity 0.2s, transform 1300ms;
}

.confirmationOverlay {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(5px);
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 101;
}

.confirmationContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  max-width: 450px;
  max-height: 85vh;
  padding: 0;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  z-index: 101;
}
.confirmationContent:focus {
  outline: none;
}
