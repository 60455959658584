@use '@restream/styles/scss/tokens';

$width: 50px;
$height: 24px;

.TimelineCursor {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: none;
}

.TimelineCursor {
  position: absolute;
  top: 24px;
  z-index: 4;
  width: 2px;
  height: 64px;
  background-color: #000;
  pointer-events: none;
}
