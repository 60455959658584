@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  justify-content: center;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: tokens.$color-gray-500;
}

.iconWrapper {
  margin-left: 4px;
}

.tooltip {
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  padding: 0;
  color: #fff;
}

.blackFriday {
  margin-top: 0 !important;
  font-weight: 600;
}
