@use '@restream/styles/scss/tokens';

$siblingMargin: 12px;

.root {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
}

.bottomRow {
  & > *:not(:last-child) {
    margin-right: $siblingMargin;
  }
}

.headerActionsWrapper {
  display: flex;
}

.withMarginsBetweenSiblings {
  & > * {
    margin-right: $siblingMargin;
  }
}

.inviteButton {
  padding: 10px;
  width: 36px;
}

.viewStreamButtonMobile {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.modeSwitcherActionButton,
.dropdownTriggerButton {
  @media (max-width: 400px) {
    padding: 8px;
  }
}

.primary {
  background-color: tokens.$color-blue-500;

  &:hover:not(:disabled) {
    background-color: #4280ff;
    color: tokens.$color-white;
  }
}

.secondary {
  background-color: tokens.$color-gray-800;

  &:hover:not(:disabled) {
    background-color: transparentize(tokens.$color-gray-800, 0.3);
  }
}

.previewMode {
  background-color: tokens.$color-gray-600;

  &:hover:not(:disabled) {
    background-color: transparentize(tokens.$color-gray-600, 0.3);
  }
}
