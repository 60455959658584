@import '@restream/styles/scss/variables';
@import '../scss/colors';

.#{$ns}-typography {
  color: $color-dark-fg;
  font-weight: normal;
  margin: 0;
}

.#{$ns}-typography.#{$ns}-disabled {
  color: grey;
}

h1.#{$ns}-typography {
  font-size: 3rem;
  margin-bottom: 1rem;
}

h2.#{$ns}-typography {
  font-size: 2.25rem;
  margin-bottom: 1rem;
}

h3.#{$ns}-typography {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

h4.#{$ns}-typography {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

h5.#{$ns}-typography {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

h6.#{$ns}-typography {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

label.#{$ns}-typography {
  font-size: 1rem;
}
