.root {
  color: var(--UI-N900, #091e42);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;

  a {
    color: var(--Blue-600, #155eef);

    &:hover {
      text-decoration: underline;
    }
  }
}
