@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';
@import '../../scss/media';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 24px 24px 0 24px;
}

.headerContent {
  width: 100%;
}

.title {
  font-weight: 600;
  font-size: tokens.$font-size-body-xl;
  text-align: center;
  color: tokens.$color-gray-800;
  padding: 0 24px;
}

.subtitle {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-500;
  text-wrap: balance;

  &:not(:empty) {
    margin-top: 8px;
  }
}

.buttons {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  padding: 20px 20px 8px 20px;
  box-sizing: border-box;
}
