$color-fg: grey;

.body {
  flex: 1;
}

.loader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

//.body {
//  position: relative;
//  min-width: 500px;
//}

.description-label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.description {
  box-sizing: border-box;
}

.counter {
  font-size: 12px;
  opacity: 0.4;
}

.buttons {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.select {
  position: relative;
}

.autocomplete {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.autocomplete-list {
  max-height: 200px;
  overflow: auto;
}

.autocomplete-item {
  padding: 8px 16px;
  color: $color-fg;
  background: white;
}

.autocomplete-item.selected {
  font-weight: 700;
}

.autocomplete-item.focus {
  color: black;
}

//.loader {
//  position: absolute;
//  z-index: 1;
//
//  width: 100%;
//  height: 100%;
//
//  left: 0;
//  top: 0;
//
//  display: flex;
//  justify-content: center;
//  align-items: center;
//
//  background: white;
//}
