$titleMarginBottom: 28px;

.title {
  font-weight: normal;
}

.titleWrapper {
  margin: 0 0 $titleMarginBottom;

  &.noMarginBottom {
    margin: 0;
  }
}
