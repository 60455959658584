@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/tokens';

.root {
  @include mixins.modal-width;

  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  max-height: 280px;

  object-fit: cover;
}

.closeButtonWrapper {
  position: absolute;
  right: 12px;
  top: 12px;

  & .button {
    &:hover:not(&:disabled) {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.title {
  color: tokens.$color-gray-900;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 12px;
}

.text {
  color: tokens.$color-gray-700;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.footer {
  column-gap: 8px;
}
