@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../../scss/mixins' as mixins;

.root {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.button {
  display: flex;
  align-items: center;

  @include mixins.button-reset;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-blue-300;
  transition: all 0.3s ease;

  &:hover {
    color: #4280ff;

    .icon {
      path {
        stroke: #4280ff;
      }
    }
  }
}

.icon {
  margin-left: 8px;

  path {
    stroke: tokens.$color-blue-300;
    transition: all 0.3s ease;
  }
}
