@use '@restream/styles/scss/tokens';

.root {
  @media (min-width: 1100px) {
    width: 1005px;
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  max-width: 1005px;
}

.infoWrap {
  margin-bottom: 40px;
}

.billingBlock {
  margin-bottom: 10px;
}

.title {
  margin-bottom: 20px;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.contactSalesLink {
  background: none;
  border: none;
  padding: 0;
  color: tokens.$color-blue-400;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.promoBanner {
  margin-bottom: 16px;
}

.titleWrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .title {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.invoicePromoIcon {
  width: 14px;
  height: 14px;
}

.invoicePromoWrapper {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.alert {
  margin-bottom: 21px;
  border: 1px solid #48c5d1;
  position: relative;
  background: #48c5d1;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  border-radius: 0;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;

  &.info {
    background-color: #fff;
    border-color: #fff;
    color: #293042;
  }
}

.topLinkButton {
  color: #ff5630;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 150ms ease;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
  margin: 0 3px;

  &:hover {
    border-bottom-color: #ff5630;
  }
}

.retryCharge {
  vertical-align: middle;
}

.inlineLoader {
  display: inline;
  vertical-align: middle;
}

.brightBackCancel {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
  transition: border-bottom-color 150ms ease;

  &.button {
    height: 20px;
    padding: 2px 0;
    appearance: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    cursor: pointer;
  }

  &:hover {
    border-bottom-color: transparent;
  }
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #253858;
  box-sizing: border-box;
  border-radius: tokens.$border-radius-200;

  &.column {
    display: flex;
    flex-direction: column;
    column-gap: 8px;
    align-items: flex-start;
    margin-top: 8px;

    & .blockItem {
      margin-right: 0;
    }
  }
}

.blockBundle {
  align-items: flex-start;
}

.blockBundleInfo {
  border-left: 5px solid #286fff;
  padding: 20px 20px 20px 15px;
}

.blockItem {
  margin-right: 20px;
}

.blockItemBottom {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.54);
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
}

.blockBtn {
  font-size: 13px;
  color: #fff;
  line-height: 20px;
}

.blockTitle {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  margin-bottom: 5px;

  .blockBundle & {
    margin-bottom: 10px !important;
  }
}

.blockTitleBadge {
  margin-left: 8px;
  vertical-align: middle;
}

.blockText {
  font-weight: 400;
  font-size: 13px;
  color: #fff;
  line-height: 20px;

  .blockBundle & {
    margin-bottom: 20px !important;
  }
}

.featuresList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.featureItem {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.54);
  line-height: 1.833;
  padding-left: 6px;

  & br {
    display: none;
  }

  & div {
    &:first-of-type {
      margin-left: 0;
    }

    display: inline;
    margin-left: 4px;
  }

  &::before {
    content: '';
    background: url(./img/checkmark-white.svg) no-repeat 50% 50%;
    opacity: 0.7;
    position: relative;
    top: 2px;
    right: 6px;
    width: 14px;
    height: 12px;
    display: inline-block;
  }
}

.extraFeatureItem {
  border-radius: tokens.$border-radius-200;
  color: #fff;
  display: inline-block;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 3px;
  padding: 2px 10px;
}

.uppercase {
  text-transform: uppercase;
}

.blockPeriod {
  font-size: 13px;
  color: #fff;
  line-height: 20px;
  margin-right: 10px;
  align-self: center;
}

.pastDueMessageContent {
  margin-right: 4px;
}

.pastDueMessageContent::before {
  background: url('./img/alert-icon.svg') no-repeat;
  background-size: cover;
  content: '';
  display: inline-block;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
  width: 30px;
}

.paidFeature {
  background: none;
  border: 1px solid #253858;
  border-radius: tokens.$border-radius-200;
  padding: 17px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.paidFeatureGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paidFeatureUpgrade {
  position: relative;
  top: 0;
  right: 0;
  font-size: 13px;
  color: #84aaf7;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    color: #ff5630;
  }
}

.paidFeatureItem {
  margin-right: 10px;
  color: rgba(235, 237, 242, 0.75);
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.paidFeatureTitle {
  margin: 0 10px 0 16px;
  font-size: 13px;
  letter-spacing: 0;
  color: #ebecf0;
  font-weight: 500;
  text-align: center;
}

.paidFeatureValue {
  font-size: 13px;
  letter-spacing: 0;
  color: #6c798f;
}

.buttonLink {
  border-radius: tokens.$border-radius-200;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  outline: 0;
  outline: none;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all 0.2s;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cardButton {
  background: #59688e;
  color: #fff;

  &:hover {
    background: #45516f !important;
    color: #fff;
  }
}

.button {
  height: 40px;
}
