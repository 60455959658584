@use '@restream/styles/scss/tokens';

.root {
  border: 1px solid tokens.$color-gray-100;
  border-radius: tokens.$border-radius-200;
  margin-bottom: 24px;
}

.callout {
  display: flex;
  background: tokens.$color-gray-50;
  border-radius: tokens.$border-radius-200 tokens.$border-radius-200 0 0;
  padding: 16px;
}

.calloutIcon {
  min-width: 32px;
  margin-right: 12px;
}

.calloutText {
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-900;
}

.calloutButton {
  font-weight: 500;
  font-size: inherit;
  color: tokens.$color-blue-700;

  background-color: transparent;
  padding: 0;
  text-decoration-line: underline;

  &:hover:not(:disabled) {
    background-color: transparent;
    color: tokens.$color-blue-700;
  }
}

.skipWaitPlatformsWrapper {
  padding: 16px;
}
