@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../../components/scss/modals' as modals;
@use '../../../../styles/mixins' as mixins;

.root {
  @include modals.modal-width;
}

.steps {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #a5adba;
  margin-bottom: 32px;
}

.channelTiles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.channelTile {
  @include mixins.button-reset;

  height: 108px;
  width: 116px;
  display: flex;
  padding: 24px 16px 8px;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: #fff;
  border: 1px solid #ebecf0;
  box-sizing: border-box;
  border-radius: tokens.$border-radius-200;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #172b4d;

  transition: opacity 200ms;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  @include media.sm {
    padding: 16px 8px;
    height: auto;
  }

  &:hover {
    opacity: 0.8;
  }
}

.addChannelsButton {
  padding: 12px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
