@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  & .title {
    color: tokens.$color-gray-600;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 0 12px;
  }
}
