.productPlaceholder {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 12px;
  color: #2b3d5c;
  padding: 12px 24px;
  align-items: center;
  border-bottom: solid 1px #d7dce3;

  .placeholderPhoto {
    width: 44px;
    height: 44px;
    background-color: #d7dce3;

    display: flex;
    justify-content: center;
    align-items: center;

    &.storeLogo {
      background: none;
    }

    img {
      width: 80%;
      height: 80%;
    }
  }

  .placeholderData {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
  }

  .placeholderLink {
    font-weight: 500;
    font-size: 14px;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 2.8em;
    line-height: 1.4em;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &.storeLink {
      color: #2b3d5c;
    }
  }

  .placeholderError {
    font-size: 12px;
    color: #ff004a;
  }

  .errorLink {
    margin-left: 5px;
    color: #2b3d5c;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .placeholderLoading {
    font-size: 12px;
  }

  .placeholderStore {
    font-size: 12px;
    color: #2b3d5c;
  }

  .placeholderLoader {
    opacity: 0.6;
  }
}
