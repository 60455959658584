@use '@restream/styles/scss/tokens';
@use '../scss/outline';
@import '@restream/styles/scss/mixins';
@import '@restream/styles/scss/colors';
@import '@restream/styles/scss/animation';

// TODO refactor styles. extract common / use input styles

// TODO(color): use token
$item-focus-color: tokens.$color-gray-50;

.container {
  position: relative;
  width: 100%;
}

.button {
  appearance: none;
  margin: 0;
  border: none;

  padding: 10px 12px;
  border-radius: tokens.$border-radius-200;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
}

.dropdownWrapper {
  z-index: 8;
}

.dropdown {
  transform-origin: 50% 0;

  border-radius: tokens.$border-radius-200;
  padding: 0;
  margin: 4px 0 0;

  outline: none;
  list-style-type: none;

  width: 100%;
  z-index: 2;
  background-color: tokens.$color-white;
  max-height: 200px;
  overflow: auto;

  // TODO(color): use token
  box-shadow: 0 5px 6px 0 #0000004d;
}

.dropdown-item {
  display: flex;
  align-items: center;

  padding: 10px 12px;
  color: tokens.$color-gray-900;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  cursor: pointer;
  outline: none;
}

.select-button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  cursor: pointer;
  color: tokens.$color-gray-900;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;

  // TODO(color): use token
  border: 1px solid $color-border-default;
  box-shadow: none;
  outline: none;

  width: 100%;
  transition: all 0.3s;

  @include outline.focus-visible-outline;
}

.select-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.select-button--non-filterable {
  display: flex;
  flex: 1;
  padding: 10px 12px;

  transition: all $animation-default-time;

  background-color: tokens.$color-white;
  color: tokens.$color-gray-900;
  border-radius: tokens.$border-radius-200;
  border: 0;

  // TODO(color): use token
  @include border-shadow($color-border-default);

  &.error {
    @include border-shadow($color-border-error);
  }
}

:global(.dark-theme) {
  .dropdown {
    // TODO(color): use token
    background: $bg-color--lighten-1;
    color: $fg-color;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
  }

  .dropdown-item {
    // TODO(color): use token
    color: $fg-color;
  }

  .select-button {
    // TODO(color): use token
    border: none;
    color: $fg-color;
  }

  .select-button--non-filterable {
    // TODO(color): use token
    background: $bg-color--lighten-1;
    box-shadow: none;
  }

  .select-button--non-filterable.error {
    // TODO(color): use token
    @include border-shadow($color-border-error-dark);
  }
}

.dropdown-item.selected {
  // TODO(color): use token
  background: $item-focus-color;
}

.dropDownItemIcon {
  color: inherit;
  height: 18px;
  flex-shrink: 0;
  margin-left: auto;
}

.dropdown-enter {
  outline: none;
  opacity: 0.01;
  //transform: scaleY(0);
  //transform-origin: 50% 0;
}

.dropdown-enter-active {
  opacity: 1;
  //transform: scaleY(1) translateY(0);
  //transform-origin: 50% 0;
  transition: opacity 150ms ease-in-out;
}

.dropdown-exit {
  opacity: 1;
  //transform-origin: 50% 0;
  //transform: scaleY(1) translateY(0);
}

.dropdown-exit-active {
  opacity: 0.01;
  //transform: scaleY(0.1);
  //transform-origin: 50% 0;
  transition: opacity 150ms ease-in-out;
}

.selected-item {
  display: inline-flex;
  margin-bottom: 4px;
  margin-right: 4px;
  padding: 0 4px;
  border-radius: tokens.$border-radius-200;

  font-size: tokens.$font-size-body-xs;
  line-break: tokens.$font-line-height-body-xs;

  background: grey;
  color: tokens.$color-white;

  .close-button {
    margin-left: 8px;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}

.caret-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  color: tokens.$color-gray-500;
  margin-left: 8px;
  stroke-width: 2px;
  width: 10px;
}

.caret {
  transition: $animation-default-time;
}

.caret.open {
  transform: rotate(180deg);
}

.caret.close {
  transform: rotate(0);
}

.noneOutline {
  outline: none;
}
