@use '../../scss/colors' as colors;

.container {
  opacity: 1;
}

.container-hoverable {
  opacity: 0.5;
}

.container-hoverable:hover {
  opacity: 0.8;
}

.icon {
  color: colors.$fg-color;
}
