@use '@restream/styles/scss/tokens';

.table {
  width: 100%;
  box-shadow: inset 0 0 0 1px tokens.$color-gray-700;
  border-radius: tokens.$border-radius-200;
  margin-bottom: 64px;
}

.tableBody {
}

.tablePlaceholder {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
