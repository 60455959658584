@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
}

.textContainer {
  display: flex;
  align-items: center;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-700;
}
