.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
}

.iconWrapper {
  margin-bottom: 24px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #edf0f3;
  margin: 24px 0 8px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #bac1cc;
}

.createEventButton {
  margin-top: 24px;
}
