@use '@restream/styles/scss/tokens';

.root {
  position: relative;
  width: 48px;
  height: 48px;
  margin-right: 12px;
  transition: all 300ms ease;

  &.compactMode {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }
}

.disabled {
  opacity: 0.5;
  filter: grayscale(1);
}

.paired {
  opacity: 0.5;
}

.channelIconWrapper {
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: tokens.$border-radius-200;
  border-color: transparent;

  overflow: hidden;

  background-color: tokens.$color-gray-100;

  &.compactMode {
    width: 28px;
    height: 28px;
  }

  &.dark {
    background-color: tokens.$color-gray-600;
  }
}

.channelIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.channelInitials {
  color: #14171f;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &.dark {
    color: tokens.$color-gray-100;
  }
}

.platformIconWrapper {
  position: absolute;

  width: 20px;
  height: 20px;
  bottom: -2px;
  right: -2px;
  display: block;

  overflow: hidden;

  &.compactMode {
    width: 14px;
    height: 14px;
  }
}

.platformIcon {
  width: 100%;
  height: 100%;

  display: block;
}

.pairedChannelIcon {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
