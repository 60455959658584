@use '@restream/styles/scss/media';

.processing {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  z-index: 1;
  width: 100%;
  max-width: 200px;
  text-align: center;

  @include media.xs {
    padding: 0 16px;
  }
}

$progressLineHeight: 4px;

.progressBar {
  background: #b3bac5;
  height: $progressLineHeight;
  width: 100%;
  margin-top: 8px;
  border-radius: 100px;

  .progressLine {
    height: $progressLineHeight;
    background: #2684ff;
    border-radius: 100px;
  }
}

.indeterminateProgressBar {
  width: 100%;
  height: $progressLineHeight;
  margin-top: 8px;
  overflow-x: hidden;
  position: relative;

  .mainLine {
    height: $progressLineHeight;
    background: #b3bac5;
    width: 100%;
    border-radius: 100px;
  }

  $indeterminateLineWidth: 39px;

  .indeterminateLine {
    position: absolute;
    background: #2684ff;
    height: $progressLineHeight;
    animation: indeterminateLine 3s infinite;
    border-radius: 100px;
  }

  @keyframes indeterminateLine {
    from {
      left: -$indeterminateLineWidth;
      width: $indeterminateLineWidth;
    }

    to {
      left: 100%;
      width: $indeterminateLineWidth;
    }
  }
}
