.container {
  display: flex;
  flex-direction: column;
}

.text {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #97a0af;
  text-wrap: nowrap;

  &.marginTop {
    margin-top: 4px;
  }

  &.marginBottom {
    margin-bottom: 4px;
  }
}

.upgradeButton {
  color: #538cff;
  line-height: inherit;
  text-transform: inherit;
  font-size: inherit;
  letter-spacing: inherit;

  &:hover {
    color: #4280ff;
  }
}

.progressBar {
  position: relative;
  width: 100%;
  height: 4px;
  background: #344563;
  border-radius: 2px;

  .progress {
    top: 0;
    left: 0;
    position: absolute;
    height: 4px;
    border-radius: 2px;
    transition: all 0.2s linear;

    &.ok {
      background: #ebecf0;
    }

    &.warning {
      background: #ff8b00;
    }

    &.full {
      background: #ff5630;
    }
  }
}
