@use '@restream/styles/scss/tokens';

.body {
  padding: 12px 24px;
}

.description {
  margin: 12px 0;
  font-size: 14px;
  color: #172b4d;
}

.cancelButton {
  margin-right: 8px;
  box-shadow: none;
  min-width: auto;
  color: #505f79;
  transition: background-color 200ms ease;

  &:hover {
    background-color: rgba(9, 30, 66, 0.08) !important;
    color: #505f79 !important;
  }
}

.link {
  border-radius: tokens.$border-radius-200;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  outline: 0;
  outline: none;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  min-width: 120px;
  color: #fff;
  transition: background-color 200ms ease;

  background-color: #de350b;

  &:hover {
    background-color: #bf2600;
  }
}
