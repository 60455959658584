@use '@restream/styles/scss/tokens';
//@use '~@restream/styles/scss/colors' as colors;
@use '../../../scss/modals' as modals;

$content-padding: 14px;
@mixin mainText {
  font-size: 14px;
  color: #091e42;
  line-height: 20px;
}

.container {
  @include modals.modal-width;
}

.content {
  background: #ebecf0;
  border-radius: tokens.$border-radius-200;
  display: flex;
  align-items: center;
  padding: $content-padding;
}

.thumbnailWrapper {
  position: relative;
  height: 36px;
  width: 60px;
  margin-right: 22px;
  // Is used to keep the width that's broken by text with text-overflow: ellipsis;
  min-width: 60px;
}

.description {
  min-width: 0;
}

.filename {
  color: #091e42;
}

.dates {
  color: #7a869a;
}

.filename,
.dates {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cancelButton {
  color: #8993a4;
}

.trimsWarning {
  margin-top: 16px;

  & .mainText {
    @include mainText;
  }

  & .list {
    margin-top: 8px;
    list-style: initial;
    padding-left: $content-padding;
  }

  & .item {
    @include mainText;

    &:not(&:first-of-type) {
      margin-top: 8px;
    }
  }
}
