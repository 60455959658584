@use '@restream/styles/scss/media';
@use '@restream/styles/scss/mixins';

.root {
  @include mixins.pageContentPaddings;

  display: flex;
  align-items: center;

  &.noAccountSelect {
    padding-top: 20px;

    @include media.md {
      padding-top: 0;
    }
  }
}

.left {
  display: flex;
  flex: 1;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  flex: 3;

  &.summer2022 {
    flex: none;
  }

  @include media.sm {
    display: none;
  }
}

.centerMobile {
  display: none;
  justify-content: center;

  @include media.sm {
    display: flex;
    height: fit-content;
  }
}

.rightComponent {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  //padding-right: 16px;

  &:not(&.showOnMobile) {
    @include media.xs {
      display: none;
    }
  }
}

.accountSelectWrapper {
  margin-top: 3px;

  @include media.xs {
    display: none;
  }
}
