@use '@restream/styles/scss/tokens' as tokens;
@use '../colors';

.input {
  padding: 7px 14px;
  //border: solid 1px tokens.$color-gray-300;
  border: none;

  overflow: hidden;
  color: var(--Gray-900, #091e42);
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 20px;

  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.searchIcon {
  transform: translateY(7px) translateX(12px);
  padding-right: 4px;
}
