@use '@restream/styles/scss/tokens';

@mixin active-color($color) {
  color: $color;
  box-shadow: inset 0px -2px 0px 0px $color;
}

.modalTabsContainer {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.modalTab {
  cursor: pointer;
  font-family: inherit;
  z-index: 1;
  background: transparent;
  border: none;
  color: inherit;
  transition: 0.2s;
  text-transform: uppercase;

  // TODO(font): use token
  font-size: 13px;
  line-height: 20px;
  padding-bottom: 4px;

  &:focus {
    outline: none;
  }
}

.modalTabs {
  position: relative;

  > .modalTab:not(:first-child) {
    margin-left: 40px;
  }

  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.modalTabs.dark {
  .modalTab.tabActive {
    @include active-color(#ffffff);
  }
}

.modalTabs.light {
  .modalTab.tabActive {
    @include active-color(#000000);
  }
}

.actions {
  position: absolute;
  right: 0;
  bottom: 14px;
}
