@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.text {
  font-size: 14px;
  color: tokens.$color-gray-900;
}
