@use '@restream/styles/scss/tokens';

.root {
  display: table-row;
  background: none;

  &:hover {
    background: none;
  }
}

.cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: none;
  box-shadow: none;
  border-bottom: none;
  padding: 64px 32px;
}

.text {
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 16px;
  color: tokens.$color-gray-400;
}
