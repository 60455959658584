@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/outline';

.media {
  width: 100%;
  height: 100%;
  border-radius: tokens.$border-radius-200;

  @media (min-width: 576px) {
    width: 440px;
  }
}

.information {
  display: grid;
  grid-gap: 8px;
  margin: 16px 0 20px;
}

.title {
  font-weight: 600;
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;
  color: tokens.$color-gray-900;
  letter-spacing: 0.01em;
}

.description {
  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  letter-spacing: 0.01em;
  color: tokens.$color-gray-900;
}

.callout {
  margin-top: 4px;
}

.link {
  // TODO(color): use token
  color: #286fff;

  &:hover {
    text-decoration: underline;
  }
}

.upgrade {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  justify-content: flex-start;
  align-items: center;
}

.upgradeButton {
  font-weight: 600;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  text-align: center;
  cursor: pointer;
  width: fit-content;
  padding: 8px 12px;
  color: tokens.$color-white;
  // TODO(color): use token
  background: #286fff;
  border-radius: tokens.$border-radius-200;
  border: none;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.9;
  }

  @include outline.focus-visible-outline;
}

.fallback {
  margin-top: 24px;
}
