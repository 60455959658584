.root {
  display: inline-flex;

  background: none;
  border: none;
  margin: 0;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;

  transition: background-color 0.2s;

  &:hover {
    background-color: #42526e;
  }
}
