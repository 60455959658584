@import '@restream/styles/scss/media';
@import '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';
@import '@restream/styles/scss/mixins';
@import '@restream/styles/scss/typography';

.container {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: $color-white;
  color: $color-gray-900;
  @include lowlight-overlay;
  @include border-radius-large;
  @include font-16;
  align-items: center;
}

.container.column {
  flex-direction: column;

  .leading {
    padding-right: 0;
    padding-bottom: $spacing-xs;

    @include xs {
      padding-top: $spacing-xs;
      padding-bottom: 0;
    }
  }

  .trailing {
    padding-left: 0;
    padding-top: $spacing-md;
  }
}

.container.fullWidth {
  width: 100%;
}

.container.focused {
  background-color: $color-blue-50;
  color: $color-blue-600;
  font-weight: bold;
}

.container.bordered {
  @include border-default;
}

.container.button {
  cursor: pointer;
}

.container.bordered.focused {
  @include border-focus;
}

.container.bordered.error {
  @include border-error;
}

.leading {
  display: flex;
  padding-right: $spacing-md;
  justify-content: center;

  .icon {
    height: 1.3rem;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content.column {
  flex-direction: column;
}

.trailing {
  display: flex;
  padding-left: $spacing-md;
}
