@mixin button-reset {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
}
