@use '@restream/styles/scss/tokens';

.root {
  //overflow: hidden;
}

.errorBoundaryRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25%;

  & .image {
    width: 128px;
    margin: 16px 0 0;
  }

  & .message {
    text-align: center;
    color: #172b4d;
  }
}

.loaderOverlay {
  border-radius: 6px;
  overflow: hidden;
}

.loaderOverlayWrapper {
  background: white;
  opacity: 0.1;
}

.buttonCloseWrapper {
  position: absolute;
  right: -45px;
  top: 0;
}
