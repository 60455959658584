@use '@restream/styles/scss/media';
@use '@restream/styles/scss/variables';

.layout {
  @include media.md {
    flex-direction: column;
  }
}

.productMenuMobile {
  @include media.md {
    display: flex;
    padding-left: 36px;
    padding-right: 36px;
  }

  @include media.xs {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.eventsWrapper {
  overflow: hidden;
  height: 100vh;
}

.contentWrapper {
  padding-left: variables.$renaissance-lobby-product-menu-width;

  @include media.md {
    padding-left: 0;
  }
}

.accountSelectWrapper {
  width: 100%;
  max-width: none;
}

.accountSelectButton {
  padding: 4px 8px;
}

.accountSelectPopover {
  min-width: 280px;
}

.accountSelectTriggerButton {
  width: 100%;
}
