@use '@restream/styles/scss/tokens';

.root {
  display: grid;
  gap: 24px;
}

.singleColumn {
  grid-template-columns: 1fr;
}

.inputsWrapper {
  display: grid;
  gap: 12px;
}

.keyWrapper {
  display: flex;
}

.inputWithCopy {
  display: flex;
  flex: 1;
}
