.root {
  margin: 0;
  border: none;
  background: none;

  display: inline-flex;

  cursor: pointer;

  padding: 2px;
  border-radius: 4px;

  transition: background-color 0.2s;

  &:hover {
    background-color: #42526e;
  }
}

.tooltipTrigger {
  width: 24px;
  height: 24px;
}
