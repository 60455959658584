@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

$breakpoint: 992px;

.upgradeLinkContainer {
  background: #081f42;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: tokens.$border-radius-400;
  position: relative;
  background-clip: padding-box; /* !importanté */
  margin-top: 12px;
  margin-left: 12px;

  @media (min-width: $breakpoint) {
    margin: 0;
  }

  &.withGradientBorder {
    border: 1px solid transparent;
  }

  &.withGradientBorder::after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px;
    left: -1px;
    z-index: -1;
    border-radius: tokens.$border-radius-400;
    background: linear-gradient(
      30.49deg,
      #0044cc -4.93%,
      #9700cc 27.92%,
      #ba7bd1 47.18%,
      #9700cc 70.41%,
      #0044cc 98.81%
    );
  }
}

.upgradeLinkRoot {
  border-radius: tokens.$border-radius-400;
  background: #081f42;
  padding: 4px 12px;
  min-height: 40px;

  @media (min-width: $breakpoint) {
    padding: 4px 16px;
    min-height: 36px;
  }

  & [class*='upgrade-link_text'] {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.7);
    margin-right: 4px;
  }

  & [class*='upgrade-link_link'] {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    background: linear-gradient(
      95.97deg,
      #d79bfd 10%,
      #a313fe 57.37%,
      #6c87f1 81.77%,
      #3c24e2 96.61%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    border-bottom: 0;
  }
}

.root {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint) {
    flex-direction: row;
    height: 100%;
  }
}

.leftPart {
  width: 100%;
  height: 100%;
  flex: 1 1;
  background-color: #122649;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  order: 1;

  max-width: 600px;
  margin: auto;

  @media (min-width: $breakpoint) {
    max-width: initial;
    margin: initial;
  }

  @media (min-width: $breakpoint) {
    padding: 0 80px;
    order: 0;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
  }
}

.rightPart {
  height: 100%;
  width: 100%;
}

.blackFridayMobile {
  display: none;

  @media screen and (max-width: 576px) {
    display: flex;
    margin: 12px auto;
  }
}

.rightPartContent {
  width: 100%;
  max-width: 700px;
  margin: auto;
  padding: 0 20px 8px;

  @media (min-width: $breakpoint) {
    max-width: 800px;
  }

  @include media.xs {
    padding: 0 16px 8px;
  }
}

.section {
  width: 100%;
  margin: 20px auto 0;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  position: relative;

  &.topSection {
    margin-top: 32px;

    @media (min-width: $breakpoint) {
      margin-top: 60px;
    }
  }

  &.noTopMargin {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $breakpoint) {
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 1fr;
    }
  }

  &.bottomSection {
    min-height: 0; // necessary for scroll
  }

  &.eventSection {
    margin-top: 32px;

    @media (min-width: $breakpoint) {
      margin-top: 80px;
    }
  }

  &.onlyDesktop {
    @media (max-width: 576px) {
      display: none;
    }
  }

  .sectionHeader {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 12px;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    margin-bottom: 16px;
  }

  .sectionTitleWrapper {
    //display: flex;
    //align-items: center;
  }

  .sectionTitle {
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.02em;
    color: #ffffff;

    @media (min-width: $breakpoint) {
      font-size: 20px;
    }
  }
}

.wrapper {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 14px;

    // fixes inconsistent safari linear-gradient.
    background: linear-gradient(to top, rgb(#122649, 0), #122649);
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 22px;

    // fixes inconsistent safari linear-gradient.
    background: linear-gradient(to bottom, rgb(#122649, 0), #122649);
    z-index: 1;
    pointer-events: none;
  }
}

.sectionList {
  padding: 16px 0;
  height: 100%;
  overflow: auto;
}

.sectionLoader {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyStateDescription {
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #d1e0ff;
}

.gradientContainer {
  border-radius: tokens.$border-radius-400;
  padding: 4px;
  background: linear-gradient(
    30.49deg,
    #0044cc -4.93%,
    #9700cc 27.92%,
    #ba7bd1 47.18%,
    #9700cc 70.41%,
    #0044cc 98.81%
  );

  .emptyStateContainer & {
    margin: 36px auto 0;
  }

  @media (min-width: $breakpoint) {
    margin: 36px auto 0;
  }

  &.sidebar {
    max-width: 100%;
    width: inherit;
    padding: 2px;
  }
}

.gradientContent {
  box-sizing: border-box;
  padding: 60px 0;
  border-radius: 14px;
  background: linear-gradient(0deg, rgba(8, 31, 66), rgba(8, 31, 66));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.withText {
    padding: 42px 32px;
  }

  &.noBackground {
    background: #182a4e;
    padding: 48px 48px 60px;
  }

  & .emptyStateDescription {
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 32px;
  }
}

.scheduleEventButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  padding: 10px 16px;
  border: none;
  border-radius: tokens.$border-radius-200;
  background-color: #2970ff;
  box-sizing: border-box;
  transition: background-color 250ms ease, opacity 250ms ease;

  &:not(:disabled):hover {
    background-color: #155eef;
  }

  &:not(:disabled):active {
    background-color: #2970ff;
  }

  &:disabled {
    opacity: 0.2;
  }
}

.scheduleEventTitle {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.sectionEmptyState {
  width: 100%;
  border: 1px solid #253858;
  border-radius: tokens.$border-radius-200;
  background: linear-gradient(0deg, rgba(8, 31, 66, 0.5), rgba(8, 31, 66, 0.5));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  padding: 20px 24px;

  @media (min-width: $breakpoint) {
    //height: 158px;
    padding: 69px 80px;
  }

  &.withTopMargin {
    margin-top: 16px;
  }
}

.emptyTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #b2ccff;

  opacity: 0.6;

  &.altStyle {
    opacity: 1;
  }
}

.emptyDescription {
  margin-top: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #b2ccff;
}

.sectionRedirect {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.redirectText {
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.sectionTooltipTrigger {
  position: relative;
  top: 1px;
  display: none;
  margin-left: 8px;

  @media (min-width: 1080px) {
    display: inline-block;
  }
}

.sectionTooltip {
  display: flex;
}

.sectionTooltipText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ebecf0;
}

.introEcommerceModal {
  @media (min-width: 576px) {
    width: 576px;
  }
}

.header {
  width: 100%;

  &.ecommerce {
    & [class*='rightComponent'] {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }
}

.wallpaper {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    background: radial-gradient(
      50% 6968.56% at 49.88% 50%,
      rgba(171, 36, 219, 0.5625) 0%,
      rgba(73, 17, 209, 0.375) 31.56%,
      rgba(23, 82, 193, 0.1875) 64.18%,
      rgba(14, 134, 210, 0) 100%
    );
    filter: blur(120px);
    border-radius: 999px;
    width: 100%;
    height: 100%;
  }
}

.babeImage {
  width: 320px;
  height: 100%;
  object-fit: contain;

  @media (min-width: 576px) {
    width: 100%;
    height: 342px;
  }
}

.upgradeButtonGradient {
  display: block !important;
  width: max-content;

  @include media.sm {
    margin-top: 4px;
  }

  @include media.xs {
    margin-top: 20px;
  }
}

.cardsSection {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 24px;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  @media (min-width: $breakpoint) {
    margin-top: 120px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.card {
  background-color: tokens.$color-gray-700;
  padding: 20px 24px;
  border-radius: tokens.$border-radius-200;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: tokens.$color-gray-50;
}

.cardDescription {
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bac1cc;
}

.cardAction {
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 0 14px;
  border-radius: tokens.$border-radius-200;
  border: 1px solid #bac1cc;
  color: #bac1cc;
  background-color: transparent;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color, background-color, border-color;

  &:not(:disabled):hover {
    background-color: rgb(#d7dce3, 10%);
    color: #d7dce3;
    border-color: #d7dce3;
  }

  &:not(:disabled):active {
    background-color: transparent;
    color: #bac1cc;
    border-color: #bac1cc;
  }

  &:disabled {
    color: #42526e;
    border-color: #42526e;
  }
}

.cardActionContent {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
}

.row {
  display: flex;
  align-items: center;
}

.group {
  margin: 0 8px 0 0;
}
