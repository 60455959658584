@import '@restream/styles/scss/tokens';
@import '@restream/styles/scss/typography';

.container {
    display: flex;
    & > *:not(:last-child) {
        margin-bottom: $spacing-md;
    }
}

.row {
    flex-direction: row;
    flex-wrap: wrap;
}

.column {
    flex-direction: column;
}

.items {
    flex: 1;
    display: flex;
    gap: 1rem;
}

.legend {
    @include font-18;
    color: $color-gray-700;
}
