@use '@restream/styles/scss/tokens';
@use '../../../../scss/colors' as colors;
@use '@restream/styles/scss/media' as media;

.button {
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 0fr;
  transition: grid-template-columns 0.2s ease-out;
  justify-content: flex-start;

  & > span {
    filter: blur(10px);
    opacity: 0;
    transition: all 0.2s ease-out;
  }

  &:focus-visible,
  &:hover {
    grid-template-columns: auto 1fr;

    & > span {
      filter: blur(0px);
      opacity: 1;
    }
  }

  @include media.xs {
    display: none;
  }
}

.labelContainer {
  overflow: hidden;
}

.label {
  padding-left: 10px;
  white-space: nowrap;
  color: tokens.$color-white;
  font-size: 14px;
}

.icon {
  width: 16px;
  height: 16px;
}
