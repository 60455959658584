@use '@restream/styles/scss/tokens';

.body {
  padding: 20px 24px 12px;
}

.form {
  display: grid;
  grid-gap: 12px;
}

.title {
  font-weight: 500;
  font-size: 13px;
  color: #6c798f;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
  box-shadow: none;
}

.input {
  background-color: #fff;
  color: #091e42;
  font-size: 15px;
  border-radius: tokens.$border-radius-200;
  border: 1px solid rgb(193, 199, 208);
  height: 40px;
  padding: 10px 15px;
  width: 100%;

  &.invalid {
    box-shadow: 0 0 0 1px #fc8471 !important;
    border-color: transparent;
  }
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
}

.button {
  height: 40px;
  border-radius: tokens.$border-radius-200;
  min-width: auto;
}

.cancelButton {
  color: #505f79;
  background-color: transparent;

  &:hover {
    color: #505f79 !important;
    background-color: rgba(9, 30, 66, 0.08) !important;
  }
}
