@use '@restream/styles/scss/tokens';
@use '../../../../../../scss/modals' as modals;
@use './common' as common;

.root {
  @include modals.modal-width;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: common.$background;
  border-radius: tokens.$border-radius-200;
  box-sizing: border-box;
  padding: 0 24px 60px;
}

.image {
  height: 135px;
  margin-top: 30px;
}

.title {
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 0.01em;
  color: #091e42;
  margin: 45px 0 10px;

  &.subTitle {
    font-size: 16px;
  }
}

.description,
.planText {
  font-family: 'Graphik', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
}

.description {
  display: none;
  max-width: 280px;
  color: #172b4d;
  margin-bottom: 20px;

  @media (max-width: 540px) {
    display: block;
  }
}

.planText {
  color: #8993a4;
  margin: 20px 24px 0;
}

.upgrade {
  color: #286fff;

  border: none;
  box-sizing: border-box;
  font-size: inherit;
  cursor: pointer;
  background: unset;
  padding: 0;
  margin: 0 0 0 8px;
}

.uploadButtonWrapper {
  @media (max-width: 576px) {
    width: 100%;
    max-width: 340px;
  }

  @media (max-width: 375px) {
    max-width: 90%;
  }
}

.uploadButton {
  @media (max-width: 576px) {
    width: 100%;
  }
}

.dragOverlayEnter {
  opacity: 0;
}

.dragOverlayEnterActive {
  opacity: 1;
  transition: opacity 200ms;
}

.dragOverlayExit {
  opacity: 1;
}

.dragOverlayExitActive {
  opacity: 0;
  transition: opacity 200ms;
}

.modalHeaderWrapper {
  width: 100%;
}
