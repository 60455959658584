.wrapper {
  display: flex;
  justify-content: center;
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 72px;
  box-sizing: border-box;
  padding: 8px 24px;
  // TODO(border): use token
  border-radius: 12px;
  // TODO(color): use token
  background: #253858;

  @media (max-width: 768px) {
    height: 54px;
    padding: 8px 16px;
  }

  @media (max-width: 650px) {
    height: auto;
    background: transparent;
    padding: 8px;
  }

  @media (max-width: 576px) {
    padding: 8px 0;
  }
}
