@use '@restream/styles/scss/tokens';

.root {
  animation: skeleton-glow 1s linear infinite alternate;
}

@keyframes skeleton-glow {
  from {
    opacity: 0.1;
    border-color: tokens.$color-white;
    background-color: tokens.$color-white;
  }

  to {
    opacity: 0.2;
  }
}
