@use '@restream/styles/scss/tokens';

$disabledActionButtonColor: tokens.$color-gray-400;

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1px;
  padding: 6px 12px;
  background-color: transparent;
  border: none;
  border-radius: tokens.$border-radius-200;
  font-size: tokens.$font-size-body-sm;
  font-weight: semibold;
  color: tokens.$color-gray-600;
  cursor: pointer;
  transition: color 0.2s linear, background-color 0.2s linear;

  & ~ .actionButton {
    margin-left: 8px;
  }

  & svg ~ span {
    margin-left: 12px;
  }
}

.actionButton:hover:not(:disabled) {
  background-color: tokens.$color-gray-100;
  color: tokens.$color-gray-900;
}

.actionButton:active:not(:disabled) {
  background-color: tokens.$color-gray-200;
}

.actionButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: $disabledActionButtonColor;
}
