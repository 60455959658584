@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/tokens';

.root {
  @include mixins.modal-width;
}

.modalBody {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.checkmarkWrapper {
  margin-top: 28px;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  user-select: none;
  transition: opacity 0.35s ease-out 0.4s;

  &.show {
    opacity: 1;
    visibility: initial;
    user-select: initial;
  }
}

.errorDescriptionWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 12px;

  & .text {
    color: tokens.$color-gray-800;
    font-size: 16px;

    & .bold {
      color: tokens.$color-gray-900;
      font-weight: 600;
    }
  }
}
