@use '@restream/styles/scss/tokens';
@use '../../scss/colors' as colors;
@use '../../scss/grid' as grid;

.textarea {
  font-size: 14px;
  font-family: inherit;

  width: 100%;
  transition: all 0.3s;
  padding: grid.$grid-base-value grid.$grid-base-value * 2;
  line-height: 1.42857143;
  border-radius: tokens.$border-radius-200;
  outline: none;

  color: colors.$fg-color;
  background: colors.$bg-color--darken-1;
  border: 1px solid colors.$color-border-default;

  resize: none;
}

.textarea:disabled {
  color: lighten(colors.$fg-color, 20%);
  opacity: 0.5;
}

.textarea:focus {
  border-color: colors.$color-border-focus;
}

.textarea.error {
  border-color: colors.$color-border-error;
}

.textarea::placeholder {
  color: colors.$ui-placeholder-color;
}
