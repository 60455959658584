@use '@restream/styles/scss/tokens';
@use '../../scss/grid' as grid;
@use '../../scss/colors' as colors;

.container {
  background: #42516e;
  color: colors.$fg-color;
  border-radius: tokens.$border-radius-200;
  padding: grid.$grid-base-value grid.$grid-base-value * 2;
  margin-bottom: grid.$grid-base-value * 2;
}
