@use '@restream/styles/scss/tokens';
@import '../../../components/scss/mixins';

.event {
  display: flex;
  box-sizing: border-box;

  background: #ebecf0;
  border-radius: tokens.$border-radius-200;
  padding: 8px;

  margin-bottom: 22px;
  &.inList {
    margin-bottom: 6px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 45px;
  width: 78px;

  img {
    height: 45px;
    max-width: 78px;
    border-radius: tokens.$border-radius-200;
  }
}

.info {
  display: flex;
  flex-direction: column;

  margin-left: 12px;
  min-width: 0;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #253858;

  @include truncate-text;
}

.scheduledTime {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.01em;

  color: #97a0af;
}
