@use '@restream/styles/scss/tokens';

@property --recordingClipButtonTopColor {
  syntax: '<color>';
  initial-value: #67e3f9;
  inherits: false;
}

@property --recordingClipButtonMiddleColor {
  syntax: '<color>';
  initial-value: #2e90fa;
  inherits: false;
}

@property --recordingClipButtonBottomColor {
  syntax: '<color>';
  initial-value: #004eeb;
  inherits: false;
}

.button {
  position: relative;
  z-index: 1;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    padding: 1px;
    border-radius: inherit;

    background-image: linear-gradient(
      132deg,
      var(--recordingClipButtonTopColor) 0%,
      var(--recordingClipButtonMiddleColor) 50%,
      var(--recordingClipButtonBottomColor) 100%
    );

    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;

    transition:
      background 0.3s ease-out,
      --recordingClipButtonTopColor 0.2s ease-out,
      --recordingClipButtonMiddleColor 0.2s ease-out,
      --recordingClipButtonBottomColor 0.2s ease-out;
  }

  &:hover:not(&:disabled) {
    background: tokens.$color-blue-500;

    &::before {
      --recordingClipButtonTopColor: #2970ff;
      --recordingClipButtonMiddleColor: #2970ff;
      --recordingClipButtonBottomColor: #2970ff;
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;

    &::before {
      display: none;
    }
  }
}
