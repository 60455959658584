@use '@restream/styles/scss/tokens';

.root {
  border: none;
  background: none;
  padding: 0;
  margin: 0;

  overflow: hidden;
  color: tokens.$color-gray-400;
  text-overflow: ellipsis;
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-9, 20px); /* 142.857% */

  transition: color 0.2s ease-in-out;
  cursor: pointer;
}

.root:hover {
  color: tokens.$color-red-700;
}
