@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../../../../styles/mixins' as mixins;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  border-radius: tokens.$border-radius-200;
  background: linear-gradient(
    90deg,
    rgba(103, 227, 249, 0.1) -99.46%,
    rgba(46, 144, 250, 0.1) -49.56%,
    rgba(0, 78, 235, 0.1) 0.47%,
    rgba(105, 39, 218, 0.1) 50.24%,
    rgba(212, 68, 241, 0.1) 100%
  );
  border: 1px solid rgba(105, 39, 218, 0.14);
}

.icon {
  min-width: 20px;
  margin-right: 10px;

  @include media.xs {
    margin-right: 6px;
  }
}

.textWrapper {
  display: flex;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-blue-800;
}

.contactUsLink {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #286fff;
  margin-left: 10px;

  &:hover {
    opacity: 0.8;
  }
}

.upgradeButton {
  padding: 8px 14px;
  background: linear-gradient(
    90deg,
    #67e3f9 -99.46%,
    #2e90fa -49.56%,
    #004eeb 0.47%,
    #6927da 50.24%,
    #d444f1 100%
  );
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-left: 10px;

  transition: 0.3s all ease;

  &:hover:not(&:disabled) {
    background: tokens.$color-blue-700;
  }
}
