@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';
@use '@restream/styles/scss/outline';
@use '../../../../scss/mixins';

$min-width-desktop: 820px;

@mixin modal-width {
  width: $min-width-desktop;

  @include media.lg {
    width: 640px;
  }

  @include media.md {
    width: 100%;
    margin: 0;
  }

  @include media.xs {
    width: 100%;
    margin: 0;
  }
}

.root {
  display: flex;
  gap: 12px;
  align-items: center;

  & > * {
    flex: 1;
    max-width: 50%;
  }
}

.root.full {
  & > * {
    flex: 1;
    max-width: 100%;
  }
}

.modalRootClassName {
  @include modal-width;
}

.chatOverlayTitle {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  margin-right: 8px;
}

.button {
  @include mixins.button-reset;

  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  font-weight: 400;
  color: #ebecf0;
  opacity: 0.8;
  background: rgba(0, 0, 0, 0);
  border-radius: tokens.$border-radius-200;
  text-align: left;
  transition: background-color 100ms ease;

  &:hover {
    background: rgb(37 56 88 / 25%);
    opacity: 1;
  }

  @include outline.focus-visible-outline;
}

.buttonMarginLeft {
  margin-left: 4px;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
