@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../../scss/mixins' as mixins;

@import '../constants';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.errorBlack {
  max-height: 200px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  @media (max-width: $tabletMaxWidth) {
    padding: 0;
  }
}

.titleWrap {
  @include mixins.display-flex-center;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: tokens.$color-gray-100;
}

.eventListItem {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.eventListItemWithTitle {
    margin-top: 40px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.titleButton {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
}

.emptyBottomBlock {
  display: flex;
  padding: 2px 0;

  @media (max-width: $tabletMaxWidth) {
    display: none;
  }
}
