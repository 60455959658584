@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.text {
  font-size: 14px;
  color: tokens.$color-gray-900;

  & .bold {
    font-weight: 600;
  }
}

.button {
  &:first-of-type {
    margin-right: 8px;
  }
}
