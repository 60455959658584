@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';
@import '../../../../components/scss/modals';
@import '../../../connect-platform-item/connect-platform-tile-item/constants.module';

.root {
  @include modal-width;
  min-width: 0;
}

.modalHeader {
  padding-bottom: 16px;
}

.modalHeaderSubtitleText {
  max-width: 360px;
  color: inherit;
  font-size: 14px;
  line-height: 20px;

  & .link {
    color: inherit;
    text-decoration: underline;
  }
}

.modalTabsContainer {
  padding: 0;
  margin-bottom: 16px;
}

.destinations {
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.07);
  max-height: 400px;
  overflow: auto;
  padding: 0;

  .pairedDestinationsRoot {
    flex-direction: row;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: tokens.$border-radius-200;
    border: 2px solid tokens.$color-white;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.placeholder {
  margin-top: 20px;
  padding: 10px 0 !important;

  li {
    padding: 0 16px !important;
  }

  &.tilePlatformsPlaceholder {
    margin: 0;
    box-shadow: none;
  }
}

.upgradeButton {
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.collapsed {
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.calloutWrapper {
  h6 {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
  }
}

.calloutDescription {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  margin: 0;

  a,
  button {
    margin-top: 8px;
    background: transparent;
    border: none;
    color: tokens.$color-blue-500;
    cursor: pointer;
    outline: none;
  }
}

.subHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin: 14px 0;

  &.oldPricing {
    justify-content: flex-end;
  }
}

.destinationCounter {
  @include mixins.truncate-text;

  font-size: 14px;
  color: tokens.$color-gray-700;

  .link {
    text-align: center;
    letter-spacing: 0.01em;
    cursor: pointer;
    font-weight: normal;
    opacity: 1;
    color: tokens.$color-blue-500;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  .dot {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;

    &.hidden {
      opacity: 0;
      user-select: none;
      cursor: unset;
    }
  }
}

.onOffToggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  margin-left: 4px;

  .toggleAllButton {
    color: tokens.$color-gray-700;

    &:hover {
      $buttonColor: rgba(138, 147, 164, 0.7);
      color: $buttonColor;
      box-shadow: inset 0 -1px 0 0 $buttonColor;
    }
  }
}

.platformsWrapper {
  &.tilePlatformsWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);

    grid-column-gap: 16px;
    grid-row-gap: 16px;

    padding: 0 24px;

    @media (max-width: $tilePlatformsMaxWidth) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.startStreamingButton {
  width: 135px;
  height: 36px;
  font-weight: 500;
  font-size: 14px;

  padding: 0 14px;
  border: 1px solid #f33905;
  background: linear-gradient(0deg, #ff4405 0%, #f63 50%, #ff4405 100%), #f63 !important;
  background-size: 100% 200% !important;
  background-position-y: 100% !important;
  color: tokens.$color-white;
  transition: background 200ms ease-out, border 200ms ease-out;

  &:hover:not(:disabled) {
    border: 1px solid rgba(243, 57, 5, 0.8);
    background-position-y: 0 !important;
    box-shadow: 0 1px 4px 0 rgba(242, 60, 0, 0.25);
  }
}
