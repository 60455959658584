.thumbnail {
  position: absolute;
  height: 100%;
  width: 100%;

  &.letterboxed {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #000000;
  }

  &.withIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      max-width: 80px;
      max-height: 80px;
    }
  }
}
