@use '@restream/styles/scss/tokens';
@import '../../constants';

.layoutMobile {
  background-color: tokens.$color-gray-700;
}

.darkLayout {
  background-color: #091e42;
}

.hosted {
  background: linear-gradient(
      270deg,
      rgba(64, 50, 148, 0.5) 0%,
      rgba(49, 54, 138, 0.5) 20%,
      rgba(39, 56, 127, 0.5) 40%,
      rgba(33, 57, 115, 0.5) 60%,
      rgba(33, 57, 102, 0.5) 80%,
      rgba(37, 56, 88, 0.5) 100%
    ),
    #253858;
}

.isError {
  border-radius: 0;
}

.image {
  filter: blur(4px);
  border-radius: tokens.$border-radius-200;
}

.streamingMonitor {
  margin-top: 10px;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 5px;
}

.streamingMonitorStatItem {
  min-width: 100px;
  white-space: nowrap;
  margin-bottom: 10px;

  &:nth-of-type(4) {
    margin-right: 25px;
  }
}

.destinationsSummaryWrapper {
  display: flex;
  margin-top: 44px;

  &.hasTime {
    margin-top: 24px;
  }

  @media (max-width: $mobileMaxWidth) {
    margin-top: 0;

    &.hasTime {
      margin-top: 0;
    }
  }
}

.calloutWrapper {
  margin-bottom: 16px;
}

.titleWrap {
  @media (max-width: $mobileMaxWidth) {
    flex-basis: 60%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
}

.title {
  margin: 0;
  padding: 0;
}

.streamingPreviewVideo {
  border-radius: tokens.$border-radius-200;

  @media (max-width: $mobileMaxWidth) {
    border-radius: tokens.$border-radius-200 tokens.$border-radius-200 0 0;
  }
}

.streamingPreview {
  border-radius: tokens.$border-radius-200 tokens.$border-radius-200 0 0;
}

.isModal {
  border-radius: tokens.$border-radius-200;
}

.chatWrapper {
  min-height: 380px;
}
