@use '@restream/styles/scss/tokens';
@use '../common' as common;

.root {
  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;

  background: common.$background;
}

.border {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  margin: 20px;
  border: 1px dashed #172a4d;
  border-radius: tokens.$border-radius-200;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #172b4d;
  max-width: 385px;
  text-align: center;
}
