.root {
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.overlay.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
