@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/colors' as color;
@use '@restream/styles/scss/variables' as variables;

.item {
  display: flex;
  align-items: center;

  cursor: pointer;
  color: color.$bg-color--darken-1;
}

.item-dropdown {
  padding: 8px;
  transition: all 200ms ease;
  font-size: 14px;
}

.item-dropdown.highlighted {
  background: #bde4ff;
}

.icon {
  height: 18px;
  margin-right: 8px;
  border-radius: tokens.$border-radius-200;
}

.icon.default {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  font-size: 10px;
  color: white;
  background-color: rgb(77, 117, 163);
}
