@use '@restream/styles/scss/tokens';

.uploadButton {
  height: 36px;
  color: tokens.$color-gray-700;
  background: tokens.$color-white;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  font-weight: 500;

  padding: 7px 14px 7px 16px;
  border: 1px solid #bac1cc;
  border-radius: tokens.$border-radius-200;

  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

  transition: all 0.2s;

  &:not(&:disabled) {
    cursor: pointer;
  }

  &:disabled {
    color: tokens.$color-gray-300;
    background: tokens.$color-white;
    opacity: 1;
    cursor: not-allowed;

    .uploadIcon {
      path {
        stroke: tokens.$color-gray-300;
      }
    }
  }

  &:not(&:disabled) {
    &:hover {
      color: tokens.$color-gray-800;
      background: tokens.$color-gray-50;

      .uploadIcon {
        & path {
          stroke: tokens.$color-gray-800;
        }
      }
    }
  }
}

.uploadIconWrapper {
  display: flex;
}

.noPaddingBottom {
  padding-bottom: 0;
}
