@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';
@import '../../public-clips/layout/header/PublicClipsHeader.module';

.loadingOverlay {
  position: absolute;

  height: calc(100% - $headerHeight);
  width: 100%;
  background: white;

  top: $headerHeight;
}

.dark {
  background: transparent;

  & .title {
    color: white;
  }

  & .link,
  & .text {
    color: tokens.$color-gray-300;
  }

  & .progress {
    &::after {
      background-color: tokens.$color-blue-500;
    }
  }
}

.inLayout {
  & .progress {
    max-width: 64px;
    height: 4px;
  }

  & .title {
    font-size: 16px;
    color: tokens.$color-gray-100;
    font-weight: 400;
    margin: 16px 0 8px;
    line-height: normal;
  }

  & .link,
  & .text {
    color: tokens.$color-gray-500;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  & .link {
    margin-top: 12px;
  }
}

.progress {
  max-width: 224px;
  height: 8px;

  &::after {
    background-color: black;
  }
}

.loadingOverlayContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30vh 24px 0;
  height: 100%;
  width: 100%;

  @include media.xs {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.title {
  color: black;

  font-size: 50px;
  font-weight: 600;
  line-height: 56px;

  margin: 16px 0 8px;
  text-align: center;

  @include media.xs {
    font-size: 36px;
    line-height: 42px;
  }
}

.link,
.text {
  color: black;
  text-align: center;

  font-size: 18px;
  font-weight: 400;
  line-height: 26px;

  @include media.xs {
    font-size: 16px;
    line-height: 20px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;

  margin-top: 16px;
  text-decoration: underline;

  .icon {
    height: auto;
    width: 18px;

    transform: rotate(180deg);
  }

  &:hover {
    text-decoration: none;
  }
}
