@use '@restream/styles/scss/media';
@use '@restream/styles/scss/scrollbar';

$scrollbarWidth: 5;

.modalBody {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 16px;

  &.mobileModalBody {
    @include media.xs {
      max-height: calc(100vh - 330px);
      min-height: 230px;
      overflow: scroll;
    }
  }

  &.mobileOnboardingModalBody {
    @include media.xs {
      overflow: initial;
      max-height: initial;
      min-height: initial;
    }
  }

  &.extendedOnboardingInterface {
    padding: 40px 0 0;
  }
}

.contentWrapper {
  display: flex;
  height: 450px;
  position: relative;

  @include media.xs {
    max-height: none;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 16px;
    right: 16px;
    height: 12px;
    pointer-events: none;

    @include media.xs {
      display: none;
    }
  }

  &::before {
    top: -1px;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), #fff);
    z-index: 4;
  }

  &::after {
    bottom: -1px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    z-index: 3;
  }

  &.mobileContentWrapper {
    @include media.xs {
      height: auto;
      min-height: 200px;
    }
  }

  &.extendedOnboardingInterface {
    &::after,
    &::before {
      display: none;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px (24px - $scrollbarWidth) 10px 24px;

  overflow-y: scroll;

  @include scrollbar.scrollbar(
    $size: $scrollbarWidth,
    $color: #c1c7d0,
    $hover-color: #c1c7d0,
    $active-color: #c1c7d0
  );

  @media (max-width: 576px) {
    overflow: initial;
  }

  &.extendedOnboardingInterface {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.withInnerWarning {
    flex: none;
    width: 100%;
    display: block;
  }
}
