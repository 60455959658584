@import '@restream/styles/scss/variables';
@import '@restream/styles/scss/tokens';
@import '../content/NestedAccountSelectContent.module.scss';

.root {
  border-radius: 8px;
  animation: FadeIn 0.5s ease-in 1 both alternate;
}

.content {
  height: $accountSelectTriggerHeight;
  width: $accountSelectTriggerWidth;
  border-radius: $border-radius-200;
  animation: SkeletonGlow 1s linear infinite alternate;
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes SkeletonGlow {
  from {
    background-color: $color-background-dark3;
  }

  to {
    background-color: #3c4d69;
  }
}
