@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';
@import '@restream/styles/scss/typography';
@import '@restream/styles/scss/animation';

// TODO(color): use token
$danger-color: #ff7452;
$transition: opacity $animation-default-time ease-in-out;

.root {
  position: relative;
  display: inline-block;
}

.content {
  z-index: 1;
}

.contentEnter {
  opacity: 0.01;
}

.contentEnterActive {
  opacity: 0.3;
  transition: $transition;
}

.contentExit {
  opacity: 1;
}

.contentExitActive {
  opacity: 0.01;
  transition: $transition;
}

.button {
  display: flex;
  justify-content: center;
  align-self: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  border-radius: tokens.$border-radius-200;

  &.disabled {
    cursor: not-allowed;
  }
}

//.item {
//  margin: 4px 0;
//}
//
//.item:focus {
//  outline: none;
//
//  .menuItemRoot {
//    background: #172b4d;
//  }
//
//  .menuItemRoot.danger {
//    background-color: tokens.$color-orange-dark-400;
//    color: #fbfbfb;
//
//    .menuItemIcon {
//      svg {
//        fill: #fbfbfb;
//      }
//    }
//  }
//}

.item {
  min-height: 28px;
  cursor: pointer;
  white-space: pre;
  //margin: 4px 0;
  padding: 8px 12px;

  display: flex;
  align-items: center;

  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  //border-radius: tokens.$border-radius-150;
  // TODO(color): use token
  color: #aeb4bf;
  user-select: none;
  transition: all 400ms ease;

  &:hover {
    background-color: tokens.$color-gray-800;
  }

  &:active {
    outline: none;
  }

  &.focus {
    outline: none;
    background-color: tokens.$color-gray-800;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;

  .icon {
    transition: 200ms all;
  }
}

.item.danger {
  // TODO(color): use token
  color: $danger-color;

  .icon {
    fill: $danger-color;
  }

  &:hover,
  &.focus {
    // TODO(color): use token
    color: #fbfbfb;
    background-color: tokens.$color-orange-dark-400;

    .icon {
      fill: #fbfbfb;
    }
  }
}

.badge {
  margin-left: 8px;
  padding: 0 8px;
}

.list {
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;

  // TODO(color): use token
  background: #253858;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.6);
  border-radius: tokens.$border-radius-200;

  list-style: none;

  margin: 8px;
  padding: 8px 0;

  z-index: 10;

  li {
    outline: none;
  }

  li:first-child {
    .item {
      margin-top: 0;
    }
  }

  li:last-child {
    .item {
      margin-bottom: 0;
    }
  }
}
