@use '@restream/styles/scss/tokens';

.root {
  position: relative;

  &::after {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    border: solid 3px rgba(238, 240, 243, 0);
  }

  &.active {
    &::after {
      animation: sonnar-border ease-in-out 1.6s infinite;
      transition: border 0.2s ease-in-out;
      border-radius: tokens.$border-radius-200;
    }
  }
}

.popoverRoot {
  position: relative;
  padding: 16px;
  display: flex;
  max-width: 280px;
  border-radius: tokens.$border-radius-200;
}

.closeButtonWrapper {
  position: absolute;
  top: 8px;
  right: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  margin-bottom: 0;
  margin-top: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;

  color: #091e42;
}

.text {
  font-style: normal;
  white-space: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: #6c798f;
  margin: 0;

  span {
    margin-top: 6px;
    display: block;
    letter-spacing: 0.01em;
    font-weight: 700;
    cursor: pointer;

    color: #286fff;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}

@keyframes sonnar-border {
  0% {
    border: solid 3px rgba(238, 240, 243, 0.6);
  }

  50% {
    border: solid 3px rgba(238, 240, 243, 0);
  }

  100% {
    border: solid 3px rgba(238, 240, 243, 0.6);
  }
}
