@use '@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  padding: 0;
  margin: 0 8px 0 0;
  color: tokens.$color-gray-500;
  font-size: tokens.$font-size-body-sm;
  font-weight: 400;
  white-space: nowrap;
}

.buttons {
  display: flex;
  align-items: center;
}

.button {
  //font-size: 1em; /* 14px */
  line-height: 1.5em; /* 24px */
  padding: 0;
  margin: 0 4px 0 0;

  font-weight: 500;
  letter-spacing: 0.04em;
  color: #505f79;
  text-transform: uppercase;
  background: none;
  border: none;

  cursor: pointer;

  &:hover {
    color: #fbfbfb;
    box-shadow: inset 0 -1px 0 0 #fff;
    transition: all 300ms;
  }

  &:focus {
    outline: none;
  }

  &:last-of-type {
    margin: 0 0 0 4px;
  }
}

.delimiter {
  width: 1px;
  height: 16px;
  background: #c1c7d0;
  opacity: 0.7;
}
