@use '@restream/styles/scss/media';
@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/variables';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 3px;

  &.noAccountSelect {
    padding-top: 20px;
  }
}

.content {
  @include mixins.pageContentPaddings;

  overflow-y: auto;

  padding-top: 20px;
  padding-bottom: 32px;

  @include media.xs {
    padding-top: 16px;
  }
}

.noPermissionsRoot {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.noPermissionsWrapper {
  min-height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
