.root {
}

.title {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.description {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  padding: 0;
  margin: 0;

  a {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    color: #ff7452;
    cursor: pointer;
    text-decoration: underline;
  }
}
.tiktokDescription {
  color: #172b4d;
}

ul.description {
  list-style-type: none;
}

.button {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  color: #ff7452;
  cursor: pointer;
  text-decoration: underline;
}

.image {
  width: 100%;
  margin-bottom: 24px;
}
