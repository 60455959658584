@use '../layout';

.root {
  display: flex;
  align-items: center;

  gap: 8px;

  @include layout.horizontal-md {
    gap: 16px;
  }

  padding: 12px 16px;

  border-bottom: 1px solid var(--Gray-700, #2b3d5c);
}

.blackFridayBanner {
  display: none;

  @include layout.vertical-xs {
    display: block;
  }
}

.mobileBlackFridayBanner {
  display: block;

  @include layout.vertical-xs {
    display: none;
  }
}

.startingIn {
  display: none;

  @include layout.horizontal-md {
    display: block;
  }
}

.eventButtonsWrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
}

.upgradeButton {
  display: none !important;

  @include layout.vertical-xs {
    display: flex !important;
  }
}

.recordingsTrigger {
  & label {
    display: flex;
    flex-direction: row-reverse;
  }
}
