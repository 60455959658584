@use '@restream/styles/scss/colors';
@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.form {
  display: grid;
  gap: 16px;
}

.item {
  display: flex;
  align-items: center;
  //justify-content: space-between;

  cursor: pointer;
  color: colors.$bg-color--darken-1;
  overflow: hidden;

  width: 100%;
}

.item-dropdown {
  padding: 8px;
  transition: all 200ms ease;
  font-size: 14px;
}

.item-dropdown.highlighted {
  background: #f5f6f8;
}

.itemTextTruncate {
  @include mixins.truncate-text;
}

.formGroup {
  min-width: 0;
}

.selectItem {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleWrapper {
  display: flex;
  align-items: center;
  //flex: 1;
  min-width: 0;
}

.badge {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  padding: 0 4px;
  margin-left: 6px;

  border-radius: tokens.$border-radius-200;
  background-color: rgba(9, 30, 66, 0.08);
  color: #6b778c;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;

  .dark & {
    background: rgba(165, 173, 186, 0.16);
    color: #a5adba;
  }
}
