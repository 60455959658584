@use '../../../../../scss/colors' as colors;

$background: #172b4d;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: $background;
  padding: 12px 24px;

  appearance: none;
  width: 100%;
  border: none;

  font-size: 16px;
  font-family: inherit;

  cursor: pointer;

  color: colors.$fg-color--darken-1;

  &:hover {
    background: #253858;
    transition: background-color 0.2s;
  }
}

.root.open {
  border-bottom: 1px solid #253858;
}

.header {
  font-size: 1em;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: colors.$fg-color--darken-1;
}
