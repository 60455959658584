@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';
@import '../../../scss/modals';

.root {
  @include modal-width;
  box-sizing: border-box;
}

.modalBody {
  min-height: 300px;
}

.mainText {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: tokens.$color-gray-700;
}

.tileContent {
  display: flex;
}

.textContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.name,
.followers {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.name {
  color: tokens.$color-gray-700;
}

.followers {
  color: tokens.$color-gray-500;
}

.skipButton {
  @include media.xs {
    margin: 0 0 12px;
  }
}

.modalFooter {
  @include media.xs {
    display: flex;
    flex-direction: column;
  }
}

.connectButton {
  &:disabled {
    opacity: 0.4;
  }

  @include media.xs {
    width: 100%;
    height: 36px;
    background: #2970ff;
    border-radius: tokens.$border-radius-200;
  }
}

.skipButton {
  margin-right: 12px;

  @include media.xs {
    margin: 0 0 12px;
  }
}
