@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@use '../../../../../../scss/modals' as modals;

.root {
  @include modals.modal-width;
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 40px;

  width: 100%;
  max-width: 800px;
  align-items: center;

  padding: 40px;
  box-sizing: border-box;

  @include media.md {
    padding: 32px;
  }

  @include media.sm {
    padding: 24px;
    grid-column-gap: 30px;
  }

  @include media.xs {
    display: flex;
    flex-direction: column-reverse;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stepsCount {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #97a0af;
}

.stepsList {
  margin: 8px 0 0;
  position: relative;

  &::after {
    content: '';
    height: 100%;
    width: 1px;
    background: #edf0f3;
    position: absolute;
    left: 15.5px;
    top: 0;
  }
}

.step {
  display: flex;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.centeredContent {
    align-items: center;
  }
}

.stepIconWrapper {
  height: 32px;
  min-width: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 8px;
  border-radius: 50%;

  background: #ffffff;
  border: 1px solid #edf0f3;

  z-index: 1;

  &.isActive {
    background: #eff4ff;
    border: 1px solid #d1e0ff;
  }
}

.stepHeader {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #091e42;
}

.stepText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #172b4d;
}

.planText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6b778c;

  @include media.sm {
    max-width: 200px;
  }
}

.dragArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 320px;
  min-width: 340px;

  padding: 20px;
  background: tokens.$color-blue-25;

  border: none;

  border-radius: tokens.$border-radius-400;
  box-sizing: border-box;

  @include media.md {
    min-width: 300px;
  }

  @include media.sm {
    min-width: 250px;
    height: 310px;
  }

  @media (max-width: 650px) {
    min-width: 220px;
    height: 300px;
  }

  @include media.xs {
    width: 100%;
    margin-bottom: 15px;
  }
}

.dragAreaHeader {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: tokens.$color-gray-700;

  margin-bottom: 16px;
}

.upgrade {
  font-weight: 500;
  color: #004eeb;

  border: none;
  box-sizing: border-box;
  font-size: inherit;
  cursor: pointer;
  background: unset;
  padding: 0;
  margin: 0 0 0 4px;

  &.disabled {
    cursor: not-allowed;
  }
}
