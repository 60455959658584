@use '@restream/styles/scss/tokens';

// selector duplication to override scss styles of organizationSelectWrapper in OrganizationSelect
.organizationMembersSelectWrapper.organizationMembersSelectWrapper {
  .organizationMultiSelect {
    &__option {
      &--is-disabled {
        opacity: 0.6;
      }
    }
  }
}
