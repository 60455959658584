@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;

  border-radius: tokens.$border-radius-200;
  padding: 10px 12px;
  margin-bottom: 16px;

  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  font-weight: normal;

  background: tokens.$color-white;
  color: tokens.$color-gray-800;

  h6 {
    margin: 0;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
    font-weight: 700;
  }
}

.root.zero-margin {
  margin: 0;
}

.warning {
  background-color: #fef7c3;
  border: 1px solid #eaaa08;
}

.error {
  // TODO(color): use token
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}

.info {
  // TODO(color): use token
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.success {
  // TODO(color): use token
  background: red;
}

.rootDefault {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 12px;

  // TODO(color): use token
  background: #5d495f;

  border-radius: tokens.$border-radius-200;

  flex: none;
  order: 0;
  flex-grow: 0;

  .textWrapper {
    display: flex;
    flex-direction: column;
  }

  #iconLight {
    display: none;
  }
}

.rootDefault.warning {
  // TODO(color): use token
  background: rgb(253 223 136 / 17%);
  border: 2px solid tokens.$color-yellow-200;
}

.rootDefault.error {
  // TODO(color): use token
  background: #5d495f;
  border: 2px solid tokens.$color-red-400;

  &.minimalistic {
    background: rgba(217, 45, 32, 0.2);
    border: 1px solid tokens.$color-red-400;
  }
}

.rootDefault {
  .title {
    font-weight: 500;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
    padding: 0;
    margin: 0;
    color: tokens.$color-white;

    &.withDescription {
      margin-bottom: 4px;
    }
  }

  .description {
    font-weight: 400;
    font-size: tokens.$font-size-body-sm;
    line-height: tokens.$font-line-height-body-sm;
    color: tokens.$color-white;

    margin: 0;
    padding: 0;

    a,
    button {
      text-decoration: underline;
      margin: 0;
      padding: 0;
      background: unset;
      border: none;
      color: inherit;

      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;

      cursor: pointer;
    }
  }
}

:global(.light-theme) {
  .rootDefault {
    .title {
      color: tokens.$color-gray-900;

      &.neutral {
        color: tokens.$color-gray-700;
        margin-bottom: 2px;
      }
    }

    .description {
      color: tokens.$color-gray-900;

      a,
      button {
        font-weight: 500;
      }

      &.neutral {
        color: tokens.$color-gray-600;
      }
    }
  }

  .rootDefault.error {
    background: tokens.$color-red-50;
    border: 1px solid tokens.$color-red-200;
  }

  .rootDefault.info {
    border: none;
    background-color: tokens.$color-blue-50;
  }

  .rootDefault.neutral {
    align-items: flex-start;

    background-color: tokens.$color-gray-25;
    border: 1px solid tokens.$color-gray-300;
    border-radius: tokens.$border-radius-200;
  }

  #iconDark {
    display: none;
  }

  #iconLight {
    display: unset;
  }
}

.iconWrapper {
  display: flex;
}

.iconErrorMinimalistic {
  color: tokens.$color-red-100;
}

.actionWrapper {
  margin-left: auto;
}

.sm {
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 12px;
}
