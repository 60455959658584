.modalRootClassName {
  width: 520px;
}

.errorToast > * {
  color: #fff;
}

.alert {
  margin-bottom: 20px;
  padding: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #091e42;
  background-color: #fffcf5;
  border: 1px solid #fffaeb;
  border-radius: 12px;

  width: fit-content;
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;

  & .icon {
    width: 32px;
    height: 32px;
  }

  &.danger {
    background-color: #fecdca;
  }

  & > .left {
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
  }

  & .emphasized {
    font-weight: 500;
  }
}

.label {
  font-weight: 400;
  text-transform: none;
  font-size: 13px;
  color: #6c798f;
  margin-bottom: 10px;
  box-shadow: none;
}

.emailBlock {
  min-height: 24px;
  font-size: 14px;
  color: #aeb4bf;
  line-height: 22px;
  margin-bottom: 10px;
  display: grid;
  justify-content: flex-start;
  grid-auto-flow: column;
  grid-gap: 4px;
}

.emailButtons {
  display: grid;
  justify-content: flex-start;
  grid-auto-flow: column;
  grid-gap: 8px;
  margin-top: 8px;
}

.loader {
  display: inline;
}

.warn {
  width: 24px;
  height: 24px;
  display: block;
  text-align: center;
}

.button {
  height: 40px;
}

.alertButton {
  height: 36px;
  transition: background-color 200ms ease, color 200ms ease,
    box-shadow 200ms ease;

  &.danger {
    background-color: #fef3f2;
    color: #b42318;

    &:hover {
      background-color: #fee4e2 !important;
      color: #b42318 !important;
    }

    // probably not needed here
    //&:active {
    //  background-color: #fef3f2 !important;
    //  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #fee4e2;
    //}
  }
}
