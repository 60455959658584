@use '../../../../scss/colors';

.root {
  max-width: 460px;
}

.inputTitle {
  font-weight: 400;
  text-transform: none;
  font-size: 13px;
  color: #6c798f;
  margin-bottom: 10px;
  box-shadow: none;
}

.select {
  height: 40px;
  color: #fff;
  background-color: #253858;
}

.item {
  padding: 8px 12px;
  min-height: 20px;
  background-color: #253858;
  color: #fff;
  cursor: pointer;
  border-radius: 0 !important;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 200ms ease;

  &:not(.selected):hover {
    background-color: #286fff;
  }

  &.selected {
    padding: 0;
  }
}

.button {
  margin-top: 20px;
  height: 40px;
}
