@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;

  background: #172b4d;

  border-radius: 8px;
  animation: fadeIn 500ms ease;
}

.root[data-state='active'] {
  flex-direction: column;
}

.root[data-state='inactive'] {
  display: none;
}

.root[data-orientation='horizontal'] {
  margin-right: 16px;
}

.root[data-orientation='vertical'] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.header {
  color: var(--Gray-100, #edf0f3);
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-9, 20px); /* 142.857% */

  padding: 16px;
  border-bottom: 1px solid tokens.$color-gray-700;
}

.root[data-orientation='vertical'] .header {
  display: none;
}

.body {
  overflow: auto;

  display: flex;
  flex-direction: column;
  flex: 1;
}
