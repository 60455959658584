@use '@restream/styles/scss/tokens';
@import '../../../components/scss/typography';

.switch {
  display: flex;
  flex: 1;

  padding-bottom: 16px;
}

.body {
  position: relative;

  display: flex;

  flex-direction: column;

  font-size: 16px;
}

.auto-checkbox {
  display: flex;
}

.label {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
  margin-left: 8px;
}

.label.disabled {
  opacity: 0.5;
}

.row {
  display: flex;

  margin-top: 16px;
}

.row > *:nth-child(1) {
  margin-right: 8px;
}

.row > *:nth-child(2) {
  margin-left: 8px;
}

.input-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.input-label {
  font-weight: normal;
  margin-bottom: 8px;
}

.input-label.disabled {
  opacity: 0.5;
}

.input {
  width: 100%;
  padding: 8px 16px;
  border-radius: tokens.$border-radius-200;
  box-shadow: inset 0 0 0 1px #f5f5f5;
  color: grey;

  background: #efefef;
}

.input[disabled] {
  opacity: 0.7;
}

select.input {
  border: none;
}

.input:focus {
  box-shadow: inset 0 0 0 1px #a1bae4;
}

.input.error {
  box-shadow: inset 0 0 0 1px #ff004a;
}

.intro {
  @include font-14;
  color: #091e42;
  padding: 0;
  margin: 0;
}
