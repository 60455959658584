@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/colors';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  padding-bottom: 16px;
  position: relative;
}

.container.row {
  flex-direction: row;
}

.error {
  position: absolute;
  bottom: -4px;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  color: #ff004a;
}

.error.relative {
  position: relative;
  bottom: 0;
}

// Dark theme
:global(.dark-theme) {
  .error {
    color: colors.$color-border-error-dark;
  }
}
