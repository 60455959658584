@use '@restream/styles/scss/variables';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  // padding: 8px;
  position: relative;

  background: #5243aa;
  opacity: 0.9;
  transition: all 0.3s;

  &.secondary {
    background: variables.$color-background-dark3;
  }

  &.pastDue {
    opacity: 1;
    background: #ebecf0;
  }

  &::after {
    content: '';
    background: transparent;
    transition: all 0.3s;
    width: 100%;
    position: absolute;
    height: 100%;
  }

  &:hover {
    opacity: 1;
    background-color: #403294;

    &.pastDue {
      background: #ebecf0;
    }

    &.secondary {
      background: variables.$color-background-dark3;
    }
  }

  &.blackFriday {
    background: transparent;

    &:hover {
      background: transparent;
    }

    &::after {
      content: none;
    }
  }
}

.blackFridayFlash {
  margin-top: 12px;
}
