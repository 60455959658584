@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/outline';

.root {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 28px;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), #fff);
    z-index: 2;
    pointer-events: none;
    border-radius: tokens.$border-radius-200 tokens.$border-radius-200 0 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    z-index: 2;
    pointer-events: none;
    border-radius: 0 0 tokens.$border-radius-200 tokens.$border-radius-200;
  }
}

.scrollableContent {
  display: grid;
  grid-gap: 12px;
  padding: 16px 0 12px;
  max-height: 300px;
  overflow: auto;
}

.regionWrapper {
  position: relative;
  cursor: default;
  user-select: none;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1px;
    background-color: #d7dce3;
    z-index: -1;
  }
}

.region {
  display: block;
  width: fit-content;
  margin: auto;
  padding: 0 12px;
  background: #fff;
  color: #091e42;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.countries {
  display: grid;
  margin-top: 8px;
}

.oauthButton {
  margin: 0 !important;
  background-color: transparent !important;
  color: #091e42 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  align-items: center;

  padding: 10px 14px;
  border: 0;
  border-radius: 0;

  //font-weight: 400;
  //font-size: 16px;
  //line-height: 24px;

  text-align: left;
  cursor: pointer;
  user-select: none;
  transition: background-color 400ms ease, color 400ms ease;

  &:hover {
    background-color: #edf0f3 !important;
    z-index: 3;
  }

  @include outline.focus-visible-outline;
}

.oauthButtonContent {
  display: flex;
  justify-content: flex-start;
}

.countryWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
}

.country {
}
