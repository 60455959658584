@use '@restream/styles/scss/tokens';

.button {
  position: relative;
  line-height: 48px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  transition: background 0.2s ease;
  font-family: Graphik-Bold-Web, Helvetica, Arial, sans-serif;
  font-weight: normal;

  display: flex;
  justify-content: flex-start;
  padding: 0 13px;
  align-items: center;
  height: 40px;
  color: #3c4043;
  background: #fff;
  border: 1px solid #dadce0;
  letter-spacing: 0.25px;
  border-radius: tokens.$border-radius-200;
  width: 300px;
  max-width: 400px;
  min-width: min-content;

  &:hover {
    background: rgba(66, 133, 244, 0.04);
    box-shadow: none;
    border-color: #d2e3fc;
    outline: none;
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  .text {
    flex: 1 1;
    display: inline-block;
    letter-spacing: 0.25px;
    font-family: 'Google Sans', arial, sans-serif !important;
    font-weight: 500;
  }
}
