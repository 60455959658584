@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: opacity 300ms ease;

  &.disabled {
    opacity: 0.25;
  }
}

.channelName {
  color: tokens.$color-white;
  text-align: center;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;

  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;

  &.compactMode {
    font-weight: 500;
    margin-left: 14px;
  }

  &:hover,
  &:focus,
  &:active {
    // make sure that the end project styles won't affect it
    color: tokens.$color-white;
  }

  &:not(:disabled):hover.clickable {
    text-decoration: underline;
  }
}

.privacyBadge {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  padding: 0 6px;
  margin-left: 6px;
  position: relative;
  color: tokens.$color-gray-500;
  background: tokens.$color-gray-100;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  border-radius: 20px;
  line-height: 20px;

  .dark & {
    color: tokens.$color-gray-300;
    background: tokens.$color-gray-700;
  }
}

.capitalize {
  text-transform: capitalize;
}
.lowercase {
  text-transform: lowercase;
}

.eventLink {
  width: 140px;

  font-weight: normal;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  color: #a5adba;
  white-space: pre;

  &:not(:disabled):hover {
    text-decoration: underline;
  }
}

.divider {
  width: 1px;
  height: 12px;
  background: #a5adba;
  margin-left: 12px;
  margin-right: 12px;

  color: #a5adba;
  opacity: 0.6;
}
