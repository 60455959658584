@import '~@restream/styles/scss/media';

.rootModal {
  width: fit-content;
  //min-width: 500px;
  margin: 5vh auto 30px;

  @include xs {
    width: unset;
    min-width: unset;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.rootAnalyticsModal {
  width: 100%;
  max-width: 768px;
  margin: 5vh auto 30px;

  @include xs {
    width: unset;
    min-width: unset;
    margin: 0;
    padding: 0;
    height: 100%;
  }
}
