@use '@restream/styles/scss/tokens';
@import '~@restream/styles/scss/media';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: tokens.$border-radius-200;
  transition: 0.2s;
  padding: 1px;

  &.highlighted {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: tokens.$border-radius-200;
      background: linear-gradient(
        90deg,
        #67e3f9 0%,
        #2e90fa 49.86%,
        #004eeb 99.84%,
        #6927da 149.57%,
        #d444f1 199.29%
      );
    }
  }

  & > .button {
    width: 100%;
    height: 112px;
    padding: 8px;
    z-index: 1;

    @include xs {
      width: 100%;
    }

    .icon {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    .altHoverIcon {
      display: none;
      opacity: 0;
    }

    .title {
      position: absolute;
      bottom: 8px;
      font-size: 12px;
      font-weight: normal;
      transition: 0.2s;
      text-align: center;
      margin: 0;
      padding: 0;
      border: 0;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.root.altHoverIcon:hover {
  & > .button {
    .icon {
      display: none;
    }

    .altHoverIcon {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      animation: fadeIn 0.2s ease-in-out forwards;
      animation-delay: 100ms;
    }

    .badgeContainer {
      display: none;
    }
  }
}

.badgeContainer {
  display: flex;
  position: absolute;
  top: 8px;
  left: 8px;

  &div {
    text-decoration: none;
  }
}

.darkTheme {
  color: #aeb4bf;
  background: #253858;

  &.kick {
    box-shadow: 0 0 0 2px #4eef18;

    .badge {
      background: #fff !important;
      color: #172b4d !important;
    }
  }

  .badge {
    color: #233759;
    background: #fff;
  }

  &:hover {
    cursor: pointer;

    & > .button {
      .title {
        color: #ffffff;
      }

      .badge {
        color: #ff5630;

        &.new {
          color: #fff;
        }
      }
    }
  }
}

.lightTheme {
  color: #172b4d;
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);

  .badge {
    color: #ff5630;
    background: #fff;
  }

  .title {
    color: tokens.$color-gray-500;
  }

  &:hover {
    box-shadow:
      0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }

  & > .button {
    background: tokens.$color-white;
    border-radius: calc(tokens.$border-radius-200 - 1px);
    outline: 2px solid transparent;
  }

  & > .button:is(:hover, :focus-visible) {
    outline-color: tokens.$color-blue-600;

    .title {
      color: tokens.$color-gray-700;
    }

    .badge {
      color: #ff5630;

      &.new {
        color: #fff;
      }
    }
  }
}

.small {
  box-shadow: 0 2px 4px rgba(9, 30, 66, 0.2);

  & > .button {
    width: 136px !important;
    height: 84px !important;

    .icon {
      margin-bottom: 0 !important;
    }

    .title {
      display: none;
    }
  }
}

.tiktokLogo {
  width: 128px;
}
