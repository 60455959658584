@use '@restream/styles/scss/tokens';

$size: 36px;
$fillColor: #6b778c;
$border: 2px solid $fillColor;

.root {
  background-color: $fillColor;
  border: none;
  border-radius: tokens.$border-radius-200;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;

  &.isClickable {
    cursor: pointer;
    transition: opacity 200ms ease;
  }

  &.isClickable:hover {
    opacity: 0.8;
  }
}

.list {
  display: flex;
}

.imageContainer {
  height: $size;
  width: $size;
  margin-left: -8px;
  border-radius: tokens.$border-radius-200;
  border: $border;
  background-color: #fff;
  z-index: var(--order, 0);
  overflow: hidden;

  &:first-child {
    margin-left: 0;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;

  .offsetImage & {
    padding: 4px;
  }
}

.moreImagesBlock {
  width: $size;
  height: $size;
  display: flex;
  margin-left: -5px;
  border-radius: tokens.$border-radius-200;
  align-items: center;
  justify-content: center;
  //background: #edf0f3;
  //color: #42526e;
  background-color: $fillColor;
  color: #ebecf0;
  font-size: 12px;
  font-weight: 500;
  border: $border;
}
