.liveEventsContainer {
  margin-bottom: 25px;

  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
}

.liveEventWrapper {
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.toggleCollapsingButton {
  display: block;
  font-weight: 500;
  font-size: 14px;

  color: #84adff;
  background: none;
  border: none;

  margin: 10px 0 0 auto;
  cursor: pointer;
}
