$text-color: #ebecf0;

.hideOnMobile {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.root {
  text-align: center;
  width: 100%;
  cursor: pointer;
  min-height: 36px;
  font-size: 14px;
  z-index: 1;

  &.pastDue {
    background: $text-color;
    color: #212121;

    &:hover {
      background: #fff;
      .pastDueLink {
        color: #000;
      }
    }

    .pastDueText,
    .pastDueLink {
      color: #212121;
    }

    .pastDueText {
      b {
        margin-right: 0;
      }
    }

    .pastDueLink {
      border-bottom: 1px solid #212121;
      margin-left: 2px;

      &:hover {
        color: #000;
      }
    }
  }

  .icon {
    display: flex;
    margin-left: 8px;
    transition: all 0.3s;
    opacity: 1;
  }

  &.flex {
    @media screen and (max-width: 500px) {
      flex-flow: column;
    }
  }

  &:hover {
    .link {
      color: #fff;

      &.black {
        color: #000;
        opacity: 0.9;
      }
    }
  }

  & > * {
    display: inline-block;
  }
}

.container {
  width: fit-content;
  z-index: 1;

  &.pastDue {
    width: 100%;
  }
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  //line-height: 20px;
  line-height: 24px;

  color: #fff;
  margin-right: 4px;

  &.flex {
    display: flex;
    align-items: center;
  }

  b {
    font-weight: 600;
    margin-right: 4px;
  }

  &.black {
    color: #000;
    position: relative;
    z-index: 2;
    text-transform: uppercase;
    font-style: normal;

    @media screen and (max-width: 550px) {
      font-size: 12px;
    }
  }
}

.link {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  color: #fff;

  //color: #ebecf0;

  //background-image: linear-gradient(45deg, #6A4FC9, #268CB9, #0044CC);
  //background-size: 100%;
  //background-repeat: repeat;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
  //box-shadow: inset 0 -1px 0 0 #9bdfea;

  margin-top: 1px;
  border-bottom: 1px solid #ebecf0;
  line-height: 16px;

  transition: all 300ms ease;
  text-decoration: none;

  &:hover {
    color: #fff;
    border-bottom-color: transparent;
    //opacity: .9;
  }

  &.black {
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    color: #000;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 550px) {
      font-size: 12px;
    }

    &:hover {
      opacity: 0.9;
      color: #000;
    }
  }
}

.rootButton {
  background: none;
  border: none;
  font-family: inherit;

  &.flex {
    display: flex;
    align-items: center;
  }
}

.emoji {
  margin-right: 4px;
}

.star {
  width: 16px;
  margin-right: 6px;
}
