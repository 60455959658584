@use '@restream/styles/scss/tokens';

.toast {
  &.default {
    opacity: 1 !important;
    background: tokens.$color-gray-600;
  }
}

.title {
  color: tokens.$color-white !important;
  font-size: tokens.$font-size-body-sm;
  line-height: 20px;

  &.noWrap {
    text-wrap: nowrap;
    display: flex;
    gap: 4px;
    max-width: min(600px, 70vw);

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.message {
  &.noWrap {
    text-wrap: nowrap;
  }
}

.icon {
  width: 20px;
  flex-shrink: 0;

  & path {
    stroke: white;
  }

  &.success {
    stroke-width: 0;
  }
}

.closeButton {
  height: fit-content;
  margin: auto;

  background: transparent;
  border-radius: tokens.$border-radius-50;
  color: tokens.$color-white;
  padding: 2px;

  &:hover:not(&:disabled) {
    background: transparent;
  }
}

.closeIcon {
  flex-shrink: 0;
  color: tokens.$color-white;
  width: 16px;
  stroke-width: 2;
}
