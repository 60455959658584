@use '@restream/styles/scss/tokens';

.root {
  .emptyState {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 48px auto;

    .emptyStateImg {
    }

    .emptyStateText {
      margin-top: 12px;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: tokens.$font-size-body-sm;
      line-height: tokens.$font-line-height-body-sm;
      color: tokens.$color-gray-50;
    }

    .emptyStateBtn {
      margin-top: 16px;
      padding: 12px 24px;
    }

    .emptyStateBtnImgWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .emptyStateBtnImg {
      width: 20px;
      height: 20px;
      path {
        stroke: #ffffff;
      }
    }
  }

  .settingItems {
    display: grid;
    grid-gap: 4px;
    margin-top: 20px;
  }

  .overlays {
    display: flex;
    margin-top: 2px;

    .overlay {
      background: #172b4d;
      border: 2px solid rgba(255, 255, 255, 0.04);
      border-radius: tokens.$border-radius-200;
      width: 76px;
      height: 43px;
      cursor: pointer;
      position: relative;
      transition: border 0.2s ease-in-out;

      .overlayName {
        color: #ffffff;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        position: absolute;
        top: 50%;
        line-height: 12px;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .classic {
        width: 23px;
        height: 35px;
        border-radius: 2px;
        top: 2px;
        position: absolute;
        background: #42526e;
        right: 2px;
        transition: background 0.2s ease-in-out;

        &.active {
          background: #2970ff;
        }
      }

      .compact {
        position: absolute;
        background: #42526e;
        transition: background 0.2s ease-in-out;
        width: 32px;
        border-radius: 2px;
        height: 12px;
        left: 4px;
        top: 25px;

        &.active {
          background: #2970ff;
        }
      }
      &.active {
        border: 2px solid #2970ff;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }

  .btn {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
    border-radius: 6px;
    border: 0;
    color: #ebecf0;
    background-color: #344563;
    transition: background-color 200ms ease, color 200ms ease;

    &:hover {
      background-color: #286fff;
      color: #fff;
    }
  }

  .btnImg {
    margin-right: 2px;
    color: #ebecf0;
    transition: color 200ms ease;

    .btn:hover & {
      color: #fff;
    }
  }

  .header {
    display: block;
    font-weight: 500;
    font-size: 20px;
    color: tokens.$color-gray-50;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    &.increasedMargin {
      margin-top: 22px;
    }

    .innerHeader {
      display: flex;
      font-size: 16px;
      letter-spacing: 0.01em;
      align-items: center;
      font-weight: 600;

      &.bigFont {
        font-size: 20px;
      }
    }

    &.noMarginTop {
      margin-top: 0;
    }

    .iconWrapper {
      margin-top: 2px;
      margin-left: 8px;
    }
  }
  .settingsHeader {
    color: #ebecf0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 12px;
    &.product {
      margin-top: 20px;
    }
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.infoIcon {
  width: 16px;
  height: auto;
  path {
    fill: rgba(255, 255, 255, 0.5);
  }
}
.tooltip {
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
}
