@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/colors';
@use '@restream/styles/scss/variables';
@use '@restream/styles/scss/mixins';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  color: colors.$bg-color--darken-1;
  overflow: hidden;
}

.item-dropdown {
  padding: 8px;
  transition: all 200ms ease;
  font-size: 14px;
}

.item-dropdown.highlighted {
  background: #f5f6f8;
}

.itemTextTruncate {
  @include mixins.truncate-text;
}

.itemDate {
  white-space: nowrap;
  margin-left: 4px;
  color: #6b778c;
}

.icon {
  height: 18px;
  margin-right: 8px;
  border-radius: 4px;
}

.icon.default {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  font-size: 10px;
  color: white;
  background-color: rgb(77, 117, 163);
}

.titleWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}
