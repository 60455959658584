@use '@restream/styles/scss/tokens';
@use '../colors';

.list {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: tokens.$color-gray-50;
  padding: 4px;
  color: tokens.$color-gray-500;
}

:global(.dark-theme) {
  .list {
    background-color: transparent;
    color: tokens.$color-gray-300;
    border: 1px solid colors.$dark-color-border;
  }
}

:global(.transparent-theme) {
  .list {
    background-color: transparent;
    color: tokens.$color-gray-300;
    border: 1px solid colors.$transparent-theme-color-border;
  }
}

.trigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: inherit;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  cursor: pointer;

  transition: all 0.2s ease-in-out; /* transition-all */
}

.trigger:focus-visible {
  outline: none; /* focus-visible:outline-none */
}

.trigger:disabled {
  pointer-events: none; /* disabled:pointer-events-none */
  opacity: 0.5; /* disabled:opacity-50 */
}

.trigger[data-state='active'] {
  background-color: tokens.$color-white;
  color: tokens.$color-gray-700;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1),
    0 1px 2px 0 rgba(16, 24, 40, 0.06);
}

:global(.dark-theme) {
  .trigger[data-state='active'] {
    background-color: colors.$dark-color-input-bg;
    color: tokens.$color-gray-25;
  }
}

:global(.transparent-theme) {
  .trigger[data-state='active'] {
    background-color: colors.$transparent-theme-color-input-bg;
    color: colors.$transparent-theme-color-input-fg;
  }
}

.content {
  margin-top: 8px;
}

.content:focus-visible {
  outline: none;
}
