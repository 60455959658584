@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/colors' as colors;
@use '@restream/styles/scss/media' as media;

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 0;

  font-size: 16px;
  line-height: 24px;
}

.hiddenOnMobile {
  @include media.xs {
    display: none;
  }
}

.blackFriday {
  @media screen and (max-width: 768px) {
    flex-flow: column-reverse;
    display: flex;
    gap: 16px;
  }

  @include media.xs {
    display: none;
  }
}

.header {
  display: flex;
  margin-bottom: 0;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.01em;

    color: #a5adba;
    margin: 8px 0;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    text-align: left;
    letter-spacing: 0.01em;

    color: #fafbfc;
    margin: 8px 0;
  }

  &:not(.headerVersionD) {
    @include media.xl {
      .main {
        display: none;
      }
    }
  }

  &.headerVersionD {
    h3 {
      margin-bottom: 0;
    }

    @include media.lg {
      .main {
        display: none;
      }
    }
  }

  .main {
    &::before {
      margin-top: 20px;
      content: '';
    }
  }

  .sidebar {
    &::before {
      margin-top: 80px;
      content: '';

      @include media.sm {
        margin-top: 20px;
      }
    }

    @include media.xs {
      &::before {
        margin-top: 60px;
        content: '';
      }

      &.zeroMargin::before {
        margin-top: 0;
        content: '';
      }

      .sidebarTitle {
        display: none;
      }

      .sidebarDesc {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }
    }

    .sidebarDesc {
      text-align: center;
    }
  }
}

.headerVersionD {
  display: flex !important;
}

.main {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 2;

  padding: 0 40px;

  min-height: 100%;
  min-width: 0;

  @include media.xl {
    padding: 0 28px;
  }

  &.concurrentEventsMain {
    margin-top: -42px;

    @include media.xl {
      margin-top: 0;
    }

    @include media.xs {
      padding: 0 16px;
    }
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  min-width: 0;

  min-height: 100%;

  padding: 0 40px;

  border-right: 1px solid colors.$bg-color--lighten-1;

  @include media.xl {
    padding: 0 28px;
  }

  &:not(.sidebarVersionD) {
    @include media.xl {
      margin-bottom: 32px;
    }

    @include media.xs {
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }

  &.sidebarVersionD {
    @include media.md {
      margin-bottom: 32px;
    }

    @include media.xs {
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }

  @include media.sm {
    margin: 0;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  position: absolute;
  top: 0;
  min-height: 36px;
  max-height: 36px;
  z-index: 99;
}

.flashSale {
  width: 100%;
}

.blackFridayMobile {
  display: none;

  @media screen and (max-width: 576px) {
    display: flex;
    margin: 12px auto;
  }
}

.content {
  display: flex;
  flex: 1;

  min-width: 0;

  @include media.xl {
    flex-direction: column;
    flex: initial;
  }
}

.upgradeLinkRoot {
  min-height: unset;
  background: #5243aa;
  border-radius: tokens.$border-radius-200;
  padding: 4px 16px;
  width: unset;

  @include media.sm {
    width: 100%;
  }
}

.upgradeLinkContainer {
  width: unset;

  @include media.sm {
    width: 100%;
  }
}

.sidebarVersionD {
  min-width: 450px;
  border: none !important;
}

@include media.lg {
  .sidebarVersionD {
    min-width: 0 !important;
  }
}

.dividerLine {
  width: 1px;
  background: linear-gradient(to top, #253858 60%, rgba(37, 56, 88, 0));
}

@include media.lg {
  .dividerLine {
    display: none;
  }
}
