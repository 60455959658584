.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.refreshIcon {
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.updatingTitleWrapper {
  position: relative;
}

.updatingTitle {
  opacity: 0;
}

.updatingTitleLoader {
  position: absolute;
  left: 0;
  top: 0;
}

.successIcon {
  display: flex;
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  40%,
  60% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
