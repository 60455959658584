.root {
  display: flex;
  height: var(--font-size-3, 36px);
  padding: var(--Layout-x2, 8px) 12px;
  align-items: center;
  gap: var(--Layout-x2, 8px);
  border-radius: 8px;
  //border: 1px solid #6E71FF;
  background: linear-gradient(90deg, #6e71ff 0%, #ff2af6 100%);
  border: none;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  color: white;

  position: relative;

  cursor: pointer;
}

$border-width: 2px;

.content {
  position: absolute;
  background: #283468;

  inset: 1px;
  border-radius: 7px;

  transition: background-color 0.2s ease-in-out;
}

.root:hover {
  .content {
    background: rgba(24, 43, 77, 0.65);
  }
}

.text {
  z-index: 2;
}
