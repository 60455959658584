@use '@restream/styles/scss/tokens';

.body {
  font-size: 14px;
  color: #aeb4bf;
  letter-spacing: 0.01em;
  line-height: 22px;

  a {
    color: #4c9aff;
    border-bottom: 1px solid transparent;
  }

  a:hover {
    border-bottom-color: #4c9aff;
  }
}

.text {
  font-size: 14px;
  color: #aeb4bf;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin-bottom: 20px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.qrCodeWrapper {
  width: 100%;
  text-align: center;
}

.qrCode {
  height: 160px;
  width: 160px;
}

.secretCodeContainer {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.secretCode {
  margin-left: 20px;
  font-size: 20px;
  font-weight: 600;
  overflow: auto;
}

.button {
  height: 40px;

  &.submit {
    width: 100%;
    margin-top: 12px;
  }
}

.labelText {
  align-items: center;
  display: flex;
  margin-bottom: 6px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a5adba;
}

.inputWrapper {
  height: 40px;
  cursor: text;

  &.invalid {
    box-shadow: 0 0 0 1px #fc8471 !important;
    border-color: transparent;
  }
}

.error {
  min-height: 38px;
  border: 1px solid #fcecf2;
  position: relative;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.01em;
  line-height: 20px;
  border-radius: tokens.$border-radius-200;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: #fcecf2;
  color: #d62c0c;
  animation: fadeIn 500ms ease-out;
}

.form {
  display: grid;
  grid-gap: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
