@use '@restream/styles/scss/media';
@use '@restream/styles/scss/mixins';
@use '@restream/styles/scss/tokens';

$color-title-light: #ebecf0;
$color-title-dark: tokens.$color-gray-700;

.root {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  border: none;
  margin: 0;
  background: transparent;
  cursor: pointer;

  width: 100%;

  transition: all 0.2s ease-out;

  &:hover:not(&.disabled) {
    background: tokens.$color-gray-50;
  }

  &.disabled,
  &.selected {
    cursor: initial;
  }

  &.isInProductMenu {
    border-radius: 8px;
    padding: 8px;
    margin: 8px 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.root.isTile {
  border-radius: 8px;
  border: 1px solid tokens.$color-gray-300;
  margin-bottom: 10px;
  padding: 12px 16px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: tokens.$color-gray-300;

  &:last-child {
    margin-bottom: 0;
  }

  &.disabled {
    & .contentWrapper {
      opacity: 0.6;
    }
  }

  &:hover:not(&.disabled) {
    border-color: tokens.$color-blue-500;
    background-color: white;
    box-shadow:
      0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);

    & .rightComponentWrapper {
      color: tokens.$color-blue-500;
    }
  }

  &.selected {
    cursor: pointer;
  }

  & .titleWrapper {
    margin-left: 16px;
  }
}

.mainContentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contentWrapper {
  display: flex;
  align-items: center;

  min-width: 0;
}

.avatarWrapper {
  display: flex;
}

.titleWrapper {
  margin-left: 8px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  min-width: 0;

  &.hidden {
    display: none;
  }
}

.titleWrapper.hiddenOnMobile {
  @include media.sm {
    display: none;
  }
}

.title,
.subtitle {
  @include mixins.truncate-text;

  text-align: left;
}

.title {
  width: 100%;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: $color-title-dark;

  &.bold {
    font-weight: 500;
  }

  &.withLabel {
    width: initial;
    margin-right: 4px;
  }
}

.detailsWrapper {
  margin-top: 10px;

  & .text {
    text-align: left;
    color: tokens.$color-gray-500;
    font-size: tokens.$font-size-body-sm;
    line-height: 20px;
  }
}

.subtitleWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
}

.subtitle {
  width: 100%;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  color: tokens.$color-gray-500;
}

:global(.dark-theme) {
  .root {
    &:not(&:disabled) {
      &:hover {
        background: tokens.$color-gray-800;
      }
    }

    &.selected {
      background: tokens.$color-gray-800;
    }
  }

  .root.isInProductMenu {
    &.selected {
      background-color: #253858;
    }

    &:not(&:disabled) {
      &:hover {
        background-color: #253858;
      }
    }

    & .title {
      color: #bac1cc;
    }
  }

  .title {
    color: $color-title-light;

    &.selected {
      color: #ffffff;
    }
  }
}
