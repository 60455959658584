@use '@restream/styles/scss/tokens';

.root {
  font-weight: bolder;
  font-size: 10px;
  line-height: 1;
  // TODO(color): use token
  background: #286fff;
  padding: 3px 6px;
  color: tokens.$color-white;
  border-radius: tokens.$border-radius-150;
  user-select: none;
}

.secondary {
  // TODO(color): use token
  background: #8993a4;
}

.warning {
  color: #263858;
  background: #ebecf0;
}
