@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 20px;
  height: 20px;
  padding: 2px;
  background-color: tokens.$color-gray-700;

  font-size: 12px;
  margin-left: 4px;
  border-radius: 4px;

  @include media.xs {
    margin-left: 0;
  }
}
