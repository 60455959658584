@use '@restream/styles/scss/tokens';

.label {
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  padding: 8px 16px;
  transition: color 200ms;
}

.percentsExp {
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  font-weight: 500;
  // TODO(color): use token
  color: tokens.$color-blue-700;
  margin-left: 8px;
}

.percents {
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  font-weight: 500;
  // TODO(color): use token
  color: tokens.$color-blue-700;
  margin-left: 4px;
}

.labelContent {
  // fixes percents jump on another line in Safari (Studio)
  display: flex;
  align-items: center;
}
