.errorContainer {
  align-items: flex-start;
}

.errorWrapper {
  width: fit-content;
  max-width: 345px;
  margin: auto 5px auto 16px !important;

  @media (max-width: 480px) {
    margin-left: 0 !important;
  }
}

.checkMessage {
  @media (max-width: 480px) {
    display: block;
  }
}

.referenceText {
  color: inherit;
  font-weight: 500;
  text-decoration: underline;
}

.errorIcon {
  @media (max-width: 480px) {
    display: none;
  }
}

.closeButton {
  min-width: fit-content;
  min-height: fit-content;
  margin: 5px 5px 0 0;

  @media (max-width: 480px) {
    margin: 0;
  }
}
