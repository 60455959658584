.list {
  list-style: initial;
  padding-left: 12px;
}

.text {
  &:not(&:last-child) {
    margin-bottom: 16px;
  }
}

.bold {
  font-weight: 500;
}
