@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: column;
}

.videoPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;

  color: tokens.$color-gray-500;
  background-color: rgb(226 232 240);
}

.videoPlaceholder,
.video {
  width: 100%;

  aspect-ratio: 1080 / 1920;
  border-radius: 16px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 12px 0;
  color: black;
}

.invalidVideoIcon {
  width: 30%;

  path {
    fill: tokens.$color-gray-400;
  }
}

.title {
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;

  font-weight: 500;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.description {
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadButton {
  height: 40px;

  padding: 8px 16px;
  border-radius: 12px;

  color: #ffffff;
  background-color: black;

  &:disabled {
    user-select: none;
    opacity: 0.6;
  }

  &:hover:not(&:disabled) {
    opacity: 0.75;
    background: black;
    border: initial;
  }
}
