@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';

// values
$destinationWidth: 100%;
$buttonWidth: 154px;
$titleHeight: 36px;
$listHeight: 147px;

.root {
  display: flex;
  flex-direction: column;
  animation: FadeIn 0.5s ease-in 1 both alternate;
  width: 100%;
}

.list {
  width: $destinationWidth;
  height: $listHeight;
  border-radius: tokens.$border-radius-200;
  animation: SkeletonGlow 1s linear infinite alternate;
}

.title {
  height: $titleHeight;
  margin-bottom: 20px;
  width: $buttonWidth;
  border-radius: tokens.$border-radius-200;
  animation: SkeletonGlow 1s linear infinite alternate;
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes SkeletonGlow {
  from {
    background-color: $color-background-dark3;
  }

  to {
    background-color: #3c4d69;
  }
}
