@use '@restream/styles/scss/tokens';

.body {
  padding: 20px 24px;
}

.text {
  color: tokens.$color-gray-800;
  letter-spacing: 0.01em;
  // TODO(font): use token
  font-size: 15px;
  line-height: 24px;
}

.button {
  height: 40px;
  min-width: auto;
}

.cancelButton {
  margin-right: 8px;
  color: #505f79;
  background-color: transparent;

  &:hover {
    color: #505f79 !important;
    background-color: rgba(9, 30, 66, 0.08) !important;
  }
}

.form {
  display: grid;
  grid-gap: 12px;
}

.title {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: #a5adba;
}

.input {
  height: 40px;
  cursor: text;
}
