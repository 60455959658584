@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';

@mixin vertical-xs {
  @media only screen and (min-width: media.$breakpoint-xs) {
    @content;
  }
}

@mixin horizontal-md {
  @media only screen and (min-width: media.$breakpoint-md) {
    @content;
  }
}

@mixin horizontal-lg {
  @media only screen and (min-width: media.$breakpoint-lg) {
    @content;
  }
}
