$defaultColor: #7a869a;

.root {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2px;
  justify-content: flex-start;
  text-decoration: none;
}

.text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  color: $defaultColor;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  transition: color 200ms ease, border-bottom-color 200ms ease;

  &:hover {
    border-bottom-color: $defaultColor;
  }

  .dark:hover & {
    border-bottom-color: #fff;
    color: #fff;
  }
}

.icon {
  fill: $defaultColor;
  transition: fill 200ms ease;

  .dark:hover & {
    fill: #fff;
  }
}

.capitalize {
  text-transform: capitalize;
}
