@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

$min-height: 100px;

.root {
  > .eventCard {
    padding: 8px;
    border-radius: tokens.$border-radius-200;
    background: #ebecf0ff;

    @include media.sm {
      display: none;
    }

    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-areas: 'preview info';
    grid-template-columns: auto 1fr;
    grid-template-rows: 65px;
    grid-gap: 16px;
  }
}

.preview {
  grid-area: preview;

  .thumbnail {
    border-radius: tokens.$border-radius-200;
    width: auto;
    height: 65px;
    display: block;
  }
}

.info {
  grid-area: info;

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344563;
  }

  .timeRow {
    font-size: 14px;
    line-height: 20px;
    color: #97a0af;
    display: flex;
  }
}
