@use '@restream/styles/scss/tokens';

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.step {
  display: grid;
  grid-template-columns: 36px auto;
  grid-column-gap: 12px;
  margin-right: 40px;

  position: relative;

  &:not(.vertical)::after {
    @media (max-width: 576px) {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -20px;

      height: 1px;
      width: 17px;
      background: #505f79;
      margin: auto;
      border-radius: 1px;
    }
  }

  &:last-of-type {
    margin-right: 0;

    &::after {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    margin-right: 30px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 28px auto;
    grid-column-gap: 8px;
    margin-right: 25px;
  }

  @media (max-width: 650px) {
    margin-right: 15px;
  }

  &:not(.vertical) {
    @media (max-width: 576px) {
      display: inline-block;
      margin-right: 24px;
    }
  }

  &.hiddenOnMobile {
    @include mobileMedia() {
      display: none;
    }
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: #505f79;

  &.active {
    background: #286fff;
  }

  &.done {
    background: #ebecf0;
  }

  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
  }

  &:not(.vertical) {
    @media (max-width: 576px) {
      width: 24px;
      height: 24px;

      background: transparent;
      border: 1px solid #505f79;

      &.done,
      &.active {
        border-color: transparent;
      }
    }
  }
}

.tickIcon {
  @media (max-width: 576px) {
    width: 14px;
  }
}

.stepNum {
  display: none;

  @media (max-width: 576px) {
    display: inline-block;
    font-size: tokens.$font-size-body-sm;
    color: #505f79;

    &.active {
      color: #ebecf0;
    }
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-gray-400;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: normal;
  }

  &:not(.vertical) {
    @media (max-width: 576px) {
      display: none;
    }
  }
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #b3bac5;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 11px;
  }

  @media (max-width: 768px) {
    display: none;
  }
}
