@use '../../scss/colors' as colors;

.typography {
  color: colors.$fg-color;
}

.typography.center {
  text-align: center;
}

h2.typography {
  font-size: 24px;
  letter-spacing: 0.01em;
  line-height: 24px;
  font-weight: 500;
}

h3.typography {
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 20px;
  font-weight: 500;
}
