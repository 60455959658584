@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;

@mixin mobileMedia() {
  @media (max-width: 1020px) {
    @content;
  }

  @media (max-height: 700px) {
    @content;
  }
}

.root {
  @include mobileMedia() {
    grid-template-rows: 1fr;
    position: relative;

    .leftPartWrapper {
      overflow-y: scroll;
      padding-bottom: 48px !important;
    }
    .rightPart {
      padding-top: 16px;
    }
  }
}

.rightPartWrapper {
  @include mobileMedia() {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.leftPart,
.rightPart {
  padding: 0 24px 24px;
  height: 680px;

  &.withoutBack {
    padding-top: 24px;
  }

  @include mobileMedia() {
    padding: 16px;
    height: auto;
  }
}

.leftPartWrapper {
  padding-top: 46px !important;

  &.zeroPadding {
    padding-top: 0 !important;
  }

  @include mobileMedia() {
    padding: 0 !important;
  }
}

.leftPart {
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    display: none;
    font-weight: 600;
    font-size: 24px;
    color: #091e42;
    order: 1;

    @media (min-width: 576px) {
      display: initial;
    }

    @include mobileMedia() {
      padding: 0 16px;
    }
  }

  .errorContainer {
    order: 2;
  }

  @include mobileMedia() {
    overflow-y: auto;
    padding: 16px 0 0;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }

  @include media.xs {
    padding: 0;
  }

  .inputGroup {
    width: 100%;
    margin-top: 16px;
    order: 4;

    &.orderFirst {
      order: 3;
    }

    @include mobileMedia() {
      padding: 0 16px;
    }

    .preview {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #bac1cc;
      opacity: 1;
      transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out,
        stroke 0.2s ease-in-out;

      &.active {
        color: #004eeb;

        .previewImg {
          transition: stroke 0.2s ease-in-out;
          path {
            stroke: #004eeb;
          }
        }
      }

      .previewImg {
        transition: stroke 0.2s ease-in-out, transform 0.2s ease-in-out;
        width: 14px;
        margin-right: 6px;
        path {
          transition: stroke 0.2s ease-in-out;
        }
        transform: rotate(0deg);
      }

      &.rotate {
        .previewImg {
          transform: rotate(180deg);
        }
      }

      &.hide {
        opacity: 0;
      }

      &.disabled {
        cursor: default;
      }

      &:not(.disabled):hover {
        opacity: 0.8;
      }
    }

    .resetBtnWrapper {
      display: flex;
      .resetBtn {
        cursor: pointer;
        color: #4c9aff;
        font-size: 12px;
        margin-top: 4px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.flex,
    .flex {
      display: flex;
      span {
        display: flex;
        width: 100%;
      }

      &.alignEnd {
        align-items: flex-end;
        span {
          align-items: flex-end;
        }
      }
      &.spaceBetween {
        justify-content: space-between;

        span {
          justify-content: space-between;
        }
      }
    }

    .flexItem {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    .images {
      flex-wrap: wrap;
      max-height: 150px;
      padding-top: 6px;
      overflow: hidden;
      overflow-y: auto;

      .image {
        position: relative;
        width: 40px;
        cursor: pointer;
        height: 40px;
        border-radius: tokens.$border-radius-200;
        margin-right: 6px;
        margin-top: 1px;
        border: 1px solid #d7dce3;
        transition: border 0.2s ease-in-out, opacity 0.2s ease-in-out;

        &:hover {
          .imageDelete {
            opacity: 1;
          }
        }

        &:not(.active):hover {
          opacity: 0.8;
        }

        &.active {
          border: 2px solid #0065ff;
        }

        &.disabled {
          opacity: 0.4;
        }

        &:first-child {
          margin-left: 0;
        }
      }

      .imageDelete {
        top: -8px;
        right: -6px;
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: tokens.$border-radius-200;
        border: 1px solid #d7dce3;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: opacity 0.3s ease-in;

        // We show delete button on desktop
        // only on hover
        @media (min-width: 1080px) {
          opacity: 0;
        }

        svg {
          & > * {
            stroke: #000;
          }
        }

        &:hover {
          border: 1px solid rgba(205, 32, 31, 0.8);

          svg {
            & > * {
              stroke: rgba(205, 32, 31, 0.8);
            }
          }
        }
      }

      .initialImageAddButton {
        background: #ffffff;
        border: 1px solid #a5adba;
        border-radius: tokens.$border-radius-200;
        cursor: pointer;

        & .initialImageAddButtonLabel {
          width: 100%;
          height: 100%;
          padding: 8px 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }

        & p {
          margin-left: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 1;
          color: tokens.$color-gray-700;
        }

        &:hover {
          border: 1px solid #0065ff;

          & p {
            color: #0065ff;
          }

          & path {
            stroke: #0065ff;
          }
        }
      }

      .imageAddButton {
        width: 40px;
        cursor: pointer;
        height: 40px;
        border-radius: tokens.$border-radius-200;
        margin-right: 6px;
        margin-top: 1px;
        border: 1px solid #a5adba;
        transition: border 0.2s ease-in-out, opacity 0.2s ease-in-out;

        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
          width: 24px;
          height: 24px;
        }

        &:hover {
          border: 1px solid #0065ff;

          & path {
            stroke: #0065ff;
          }
        }
      }

      .imageAddButtonLabel {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin: 0;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    .inputInlineGroup {
      display: flex;
      width: 100%;
    }

    .inputWrapper {
      position: relative;
      width: 100%;

      .wrapperOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
      }
    }

    .input {
      height: 42px;
      border-right: none;
      border-radius: tokens.$border-radius-200 0 0 tokens.$border-radius-200;

      @include media.xs {
        height: auto;
      }

      &.error {
        box-shadow: inset 0 0 0 1px #ff004a !important;
        border: none;
      }
    }
    .inputBtn {
      display: flex;
      height: 42px;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: tokens.$color-gray-700;
      cursor: pointer;
      padding: 0 12px;
      margin: 0;
      border: 1.5px solid #a5adba;
      // box-shadow: inset 0 0 0 1px #a5adba;
      border-left: none;
      border-radius: 0 tokens.$border-radius-200 tokens.$border-radius-200 0;
      transition: background-color 0.2s ease-in-out;
      background-color: #fff;

      @include media.xs {
        height: auto;
      }

      &:hover {
        background: tokens.$color-gray-50;
      }

      &.disabled {
        .inputBtnImg {
          opacity: 0.7;
        }
      }
    }

    .inputLabel {
      color: tokens.$color-gray-700;
      font-weight: 500;
      font-size: 14px;

      .inputLabelCombined {
        display: flex;
        color: tokens.$color-gray-700;
        font-weight: 500;
        font-size: 14px;
        white-space: nowrap;
      }

      span.note {
        margin-left: 3px;
        font-weight: 400;
        font-size: 12px;
        opacity: 0.8;
      }

      span.inputErrorText {
        margin-left: 5px;
        font-weight: 400;
        font-size: 12px;
        color: #ff004a;

        &.bigMargin {
          margin-left: 10px;
        }
      }

      &.imageInputLabel {
        margin-bottom: 2px;
      }
    }
  }

  .mobileOnly {
    order: 5;
    margin-top: 16px;
    display: none;
    background: tokens.$color-gray-50;
    box-sizing: border-box;
    padding: 16px 16px 36px;

    @include mobileMedia() {
      display: block;
    }

    .mobileOnlyContent {
      width: 226px;
      margin: 0 auto;
    }
  }
}

.rightPart {
  background: tokens.$color-gray-50;
  max-width: 264px;
  padding-top: 46px;
  height: 100%;
  min-width: 264px;
  display: grid;
  grid-template-rows: 1fr auto;

  @include mobileMedia() {
    min-width: 100%;
    max-width: 100%;
  }

  .desktopOnly {
    max-width: 216px;

    @include mobileMedia() {
      display: none;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;

    @include mobileMedia() {
      width: 100%;
      justify-content: start;
      padding-top: 0;
    }

    .cancelBtn {
      appearance: none;
      -webkit-appearance: none;
      background: #ffffff;
      border: 1px solid #bac1cc;
      border-radius: tokens.$border-radius-200;
      padding: 11px 0;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: tokens.$color-gray-700;
      max-width: 25%;
      min-width: 75px;
      margin-right: 20px;
      display: none;

      &:hover {
        border: 1px solid #6b778c;
        opacity: 0.9;
      }

      @include mobileMedia() {
        display: block;
      }
    }

    .importBtn {
      appearance: none;
      -webkit-appearance: none;
      border: none;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      background: #155eef;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: tokens.$border-radius-200;
      padding: 12px 44px;
      transition: 0.2s opacity ease-in-out;
      cursor: pointer;
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.85;
      }

      &:disabled {
        opacity: 0.4;
        cursor: auto;
      }
    }
  }
}

.item {
  display: flex;
  align-items: center;

  cursor: pointer;
  color: #091e42;
}

.item-dropdown {
  padding: 8px;
  transition: all 200ms ease;
  font-size: 14px;
}

.item-dropdown.highlighted {
  background: #bde4ff;
}

.captionOverlay {
  margin-top: 16px;
}

.errorWrapper {
  width: 100%;
  margin-top: 15px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  box-sizing: border-box;
  padding: 15px;

  & p {
    width: 100%;
    font-size: 16px;
    color: #253858;

    &.textAlign {
      text-align: center;
    }

    & span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

// Tooltip styles

.tooltipTrigger {
  height: 42px;
  display: flex;

  @include media.xs {
    height: auto;
  }
}

.tooltipDisabled {
  display: none;
}
