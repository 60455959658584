.actionButton {
  display: flex;
  height: 36px;

  font-size: 14px;
  font-weight: 500;

  padding: 8px 14px;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.camIcon {
  margin-right: 8px;
}
