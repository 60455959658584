@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/media' as media;
@import '../../home/constants';

.root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #172b4d;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 60px; // product menu width should be reduced when centering all components

  @include media.xs() {
    padding-left: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $smallMobileMaxWidth) {
    width: 100%;
    padding-top: 20px;
  }

  &.contentMobileFullHeight {
    @media (max-width: $smallMobileMaxWidth) {
      height: 100%;
      min-height: 0;
      padding-top: 20px;
    }
  }
}

.eventFlowModalRoot {
  &.zeroBorderRadius {
    @media (max-width: $smallMobileMaxWidth) {
      border-radius: 0;
    }
  }
}

.eventFlowWrapper {
  width: fit-content;
  margin-top: calc(15vh - 84px);
  margin-bottom: 24px;

  @media (max-width: $smallMobileMaxWidth) {
    width: 100%;
    height: 100%;
    min-height: 0;
    margin: 0 0 20px;
  }

  &.eventFlowWrapperMobileFullHeight {
    @media (max-width: $smallMobileMaxWidth) {
      height: 100%;
      min-height: 0;
      margin: 0;
    }
  }
}

.dragOverlayEnter {
  opacity: 0;
}

.dragOverlayEnterActive {
  opacity: 1;
  transition: opacity 200ms;
}

.dragOverlayExit {
  opacity: 1;
}

.dragOverlayExitActive {
  opacity: 0;
  //transition: opacity 200ms; // was commented out because of new DnD icon animation (it takes 200 more ms which impacts the DnD animation and makes it look like it renders twice)
}

.stepperWrapper {
  display: flex;
  align-items: center;

  width: 100%;
}

.errorToastWrapper {
  width: 450px;
  padding: 12px 10px 16px !important;
  background: #ffffff !important;
  border-radius: tokens.$border-radius-200 !important;
  border: 1px solid #edf0f3;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  @media (max-width: 480px) {
    width: auto;
    max-width: 95%;
    padding: 16px !important;
    bottom: 1.5em !important;
  }
}
