@use '~@restream/styles/scss/colors';
@use '~@restream/styles/scss/outline';
@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 81px;

  font-weight: 400;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-800;
  border-radius: tokens.$border-radius-200;
  border: none;
  margin: 0;
  padding: 8px 16px;
  user-select: none;
  cursor: pointer;
  transition: background-color 200ms ease, opacity 200ms ease;

  &:hover {
    opacity: 0.8;
    // TODO(color): use token
    background-color: #fafbfc;
  }

  &.isActive {
    opacity: 1;
    background-color: tokens.$color-white;
    cursor: default;
  }

  @include outline.focus-visible-outline;
}
