@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/colors' as colors;
@use '~@restream/styles/scss/media' as media;

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 0;

  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;
}

.header {
  display: flex;
  margin-bottom: 0;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: tokens.$font-size-body-md;
    line-height: tokens.$font-line-height-body-md;
    text-align: left;
    letter-spacing: 0.01em;

    color: #a5adba;
    margin: 8px 0;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    // TODO(font): use token
    font-size: 22px;
    line-height: 36px;
    text-align: left;
    letter-spacing: 0.01em;

    color: #fafbfc;
    margin: 8px 0;
  }

  @include media.xl {
    .main {
      display: none;
    }
  }

  .main {
    &::before {
      margin-top: 20px;
      content: '';
    }
  }

  .sidebar {
    &::before {
      margin-top: 80px;
      content: '';
    }

    @include media.xs {
      &::before {
        margin-top: 60px;
        content: '';
      }

      &.zeroMargin::before {
        margin-top: 0;
        content: '';
      }
    }
  }
}

.headerVersionD {
  display: block !important;
}

.main {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 2;

  padding: 0 40px;

  min-height: 100%;
  min-width: 0;

  @include media.xl {
    padding: 0 28px;
  }

  @include media.xs {
    padding: 0 16px;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  min-width: 0;

  min-height: 100%;

  padding: 0 40px;

  border-right: tokens.$border-width solid colors.$bg-color--lighten-1;

  @include media.xl {
    padding: 0 28px;
  }

  @include media.xs {
    padding: 0 16px;
  }

  &:not(.sidebarVersionD) {
    @include media.xl {
      margin-bottom: 32px;
    }
  }
  &.sidebarVersionD {
    @include media.md {
      margin-bottom: 32px;
    }
  }

  &.noMarginMobile {
    @include media.xs {
      margin-bottom: 0;
    }
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  position: absolute;
  top: 0;
  min-height: 36px;
  max-height: 36px;
  z-index: 99;
}

.flashSale {
  width: 100%;
}

.content {
  display: flex;
  flex: 1;

  min-width: 0;

  @include media.lg {
    flex-direction: column;
    flex: initial;
  }
}

.upgradeLinkRoot {
  min-height: unset;
  background: #5243aa;
  border-radius: tokens.$border-radius-200;
  padding: 4px 16px;
  width: unset;

  @include media.sm {
    width: 100%;
  }
}

.upgradeLinkContainer {
  width: unset;
  @include media.sm {
    width: 100%;
  }
}

.sidebarVersionD {
  min-width: 450px;
  border: none;
}

@include media.xl {
  .main {
    margin-top: -32px;
  }
}

@include media.lg {
  .sidebarVersionD {
    min-width: 0 !important;
  }
}

@include media.md {
  .main {
    margin-top: 0 !important;
  }
}

.dividerLine {
  width: 1px;
  background: linear-gradient(to bottom, #253858 80%, rgba(37, 56, 88, 0));
}

@include media.lg {
  .dividerLine {
    display: none;
  }
}
