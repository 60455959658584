@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/outline';

.image {
  border-radius: tokens.$border-radius-200;
  width: 100%;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #091e42;
  margin: 24px 0;
}

.link {
  color: #286fff;

  &:hover {
    text-decoration: underline;
  }
}

.upgradeButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  width: fit-content;
  padding: 8px 12px;
  color: #fff;
  background: #286fff;
  border-radius: tokens.$border-radius-200;
  border: none;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.9;
  }

  @include outline.focus-visible-outline;
}

.fallback {
  margin-top: 24px;
}
