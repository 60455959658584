@use 'sass:math';
@use '../../../../components/scss/modals' as modals;
@use '@restream/styles/scss/variables' as variables;

$padding: variables.$grid-base-value;
$color-success: #00aa77;
$color-error: #de350b;
$stroke-width: 3px;
$size: 60px;
$checkmark-color: white;

.root {
  max-width: 100vw;
  min-height: 150px;

  @media (min-width: 576px) {
    width: 500px;
  }
}

.container {
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px auto 16px;
}

.checkmark_circle_success {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: $stroke-width;
  stroke-miterlimit: 10;
  stroke: $color-success;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark_circle_error {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: $stroke-width;
  stroke-miterlimit: 10;
  stroke: $color-error;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: $size;
  height: $size;
  border-radius: 50%;
  display: block;
  stroke-width: $stroke-width;
  stroke: $checkmark-color;
  stroke-miterlimit: 10;
  margin: 0 auto;

  &.success {
    box-shadow: inset 0 0 0 $color-success;
    animation: fillsuccess 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  &.error {
    box-shadow: inset 0 0 0 $color-error;
    animation: fillerror 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }
}

.checkmark_check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards;
}

.progress {
  width: $size;
  height: $size;
  border-radius: 50%;
  display: block;
  stroke-width: $stroke-width;
  stroke: $checkmark-color;
  stroke-miterlimit: 10;
  margin: 0 auto;
  transform: rotate(-90deg);

  path {
    display: none;
  }
}

.progress circle {
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
  animation: dash 1.5s infinite;
}

@keyframes dash {
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -130;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fillsuccess {
  100% {
    box-shadow: inset 0px 0px 0px math.div($size, 2) $color-success;
  }
}

@keyframes fillerror {
  100% {
    box-shadow: inset 0px 0px 0px math.div($size, 2) $color-error;
  }
}
