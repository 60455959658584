@import '../../../../scss/typography';
@import '@restream/styles/scss/variables';
@import '~@restream/styles/scss/media';

$padding: $grid-base-value;

.root {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  width: 100%;

  //padding: 24px;

  @include xs {
    min-width: 300px;
  }
}

.platform {
  display: flex;
  flex-direction: column;

  &.twitter {
    margin-bottom: 0;
  }
}

.about {
  margin-top: $padding * 3;
  display: flex;
  flex-direction: column;
}

.blockHeader {
  margin-bottom: 24px;
  margin-top: 24px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #091e42;
}

.modalTitle {
  font-size: 16px;
}

.followBlock {
  user-select: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 6px;
  color: #95a5a6;

  a {
    color: #95a5a6;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }

  input[type='checkbox'] {
    accent-color: #ecf0f1;
    margin-right: 6px;
    transition: opacity 0.2s ease-in-out;
    border: solid 1px #ecf0f1;

    &:checked {
      &:hover {
        accent-color: #ecf0f1 !important;
        background: #ecf0f1 !important;
        opacity: 0.9;
      }
    }
  }
}
