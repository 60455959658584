@import '../../../../components/scss/modals';

.root {
  @include modal-width;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
}

.skipButton {
  font-size: 14px;
  padding: 0 8px;
  background: transparent;
  border: none;
  color: #8993a4;
  cursor: pointer;
  outline: none;
  margin: 0 8px 0 0;

  &:hover {
    text-decoration: underline;
  }
}
