.noPermissionsRoot {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.noPermissionsWrapper {
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.modal {
    justify-content: flex-start;
    margin-top: 25vh;
  }
}
