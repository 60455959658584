@use '../../scss/grid' as grid;
.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .preview {
    margin-top: grid.$grid-base-value;
    margin-left: grid.$grid-base-value * 4;
  }
  .form {
    margin-top: grid.$grid-base-value * 2;
  }
}
