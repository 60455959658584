@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.text {
  font-size: 14px;
  color: tokens.$color-gray-900;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
