@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';
@use '@restream/styles/scss/mixins';

@mixin initial-mobile-scroll {
  @include media.xs {
    height: auto;
    max-height: none;
    overflow: initial;
  }
}

.toggleAllContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px 14px;
  color: #091e42;

  .activeIndicator {
    @include mixins.truncate-text;

    display: flex;

    font-size: 14px;
    color: tokens.$color-gray-700;

    .link {
      text-align: center;
      cursor: pointer;
      color: tokens.$color-blue-700;
      transition: all 0.2s ease-in-out;

      border: none;
      margin: 0;
      padding: 0;
      font-weight: 500;
      background: none;
      display: flex;
      align-items: center;
      gap: 4px;

      svg {
        path {
          stroke: tokens.$color-blue-700;
        }
      }

      &:hover {
        color: tokens.$color-blue-500;

        svg {
          path {
            stroke: tokens.$color-blue-500;
          }
        }
      }
    }

    .dot {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;

      &.hidden {
        opacity: 0;
        user-select: none;
        cursor: unset;
      }
    }
  }

  .toggleAll {
    justify-content: flex-end;
    margin-left: 4px;

    @include media.xs {
      flex-direction: row;

      & label {
        margin-right: 8px;
      }
    }
  }

  .toggleAllButton {
    color: tokens.$color-gray-700;

    &:hover {
      $buttonColor: rgba(138, 147, 164, 0.7);
      color: $buttonColor;
      box-shadow: inset 0 -1px 0 0 $buttonColor;
    }
  }
}

.toggleAllContainer.shadow {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.05);
}

.eventSourceRoot {
  padding: 0 24px;
  margin-bottom: 24px;
}

.addChannelButtonCustom {
  height: 36px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  border-radius: tokens.$border-radius-200;
  color: #0044cc;
  background: #deebff;
  box-shadow: none;
  transition: all 0.2s;

  &:hover {
    background-color: #b3d4ff;
    color: #0044cc;
  }
}

.destinationsWrapper {
  height: 290px;
  overflow: scroll;

  @include initial-mobile-scroll;
}

.destinationsList {
  &.withDestinationsPlaceholder {
    margin-bottom: 0;
  }

  @include initial-mobile-scroll;
}

.channelsCountButton {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  text-decoration: underline;

  cursor: pointer;
}

.borderItemNoBorder > li:first-child {
  border-top: none;
}

.borderItemNoBorder > li:last-child {
  border-bottom: none;
}
