@use '@restream/styles/scss/tokens';
@use '../../../../next/scss/grid' as grid;
@use '../../../../next/scss/colors' as colors;

.button {
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  font-size: tokens.$font-size-body-md;
  line-height: tokens.$font-line-height-body-md;
  border-radius: tokens.$border-radius-200;
  margin: 0;
  outline: 0;
  padding: grid.$grid-base-value grid.$grid-base-value * 2;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all 200ms ease;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
}

.button:focus {
  box-shadow: 0 0 0 1px colors.$ui-focus-color;
}

.button:disabled {
  cursor: default;
  opacity: 0.8;
}

.button--outline {
  background: none;
  box-shadow: inset 0 0 0 1px tokens.$color-white;
  color: tokens.$color-white;

  &:hover:not(:disabled) {
    background: none;
    color: tokens.$color-white;
  }
}

.button--primary {
  background-color: tokens.$color-orange-dark-400;
  color: tokens.$color-white;

  &:hover:not(:disabled) {
    background-color: tokens.$color-orange-dark-400;
    color: tokens.$color-white;
  }
}

.button--default {
  background: tokens.$color-gray-700;
  color: tokens.$color-white;

  &:hover:not(:disabled) {
    background: tokens.$color-gray-600;
    color: tokens.$color-white;
  }
}

.button--info {
  background: tokens.$color-blue-500;
  color: tokens.$color-white;

  &:disabled {
    opacity: 0.4;
  }

  &:hover:not(:disabled) {
    background: tokens.$color-blue-600;
    color: tokens.$color-white;
  }
}

.button-size--small {
  height: 30px;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  padding: 6px 12px;
}

.button--fill {
  width: 100%;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  border-radius: tokens.$border-radius-200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.anchor-button {
  background: none;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: tokens.$font-size-body-sm;
  color: tokens.$color-white;
  transition: all 0.2s;
  outline: 0;
  padding: 0;

  &:not(:disabled):hover {
    color: tokens.$color-gray-200;
  }
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .left {
    position: absolute;
    display: flex;
    margin-right: grid.$grid-base-value auto;
    left: 0;
  }

  .right {
    position: absolute;
    display: flex;
    margin-right: grid.$grid-base-value auto;
    right: 0;
  }
}
