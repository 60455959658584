.text {
  color: var(--Gray-700, #2b3d5c);

  font-family: Graphik, sans-serif;
  font-size: var(--font-size-9, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-9, 20px); /* 142.857% */

  margin-bottom: 12px;

  strong {
    font-weight: var(--font-weight-bold, 500);
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.list {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 4px;

  .text {
    margin-bottom: 0;
  }
}
