@import '../../components/scss/typography';
@import '@restream/styles/scss/colors';

.channelsError {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.image {
  max-width: 100px;
  margin-bottom: 30px;
}

.errorText {
  align-items: center;
  text-align: center;
  color: $fg-color;
  margin: 0;
  padding: 0;

  @include font-18;
}
