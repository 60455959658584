@use '@restream/styles/scss/tokens';
@import '../../scss/media';
@import '../../scss/colors';

.modalView {
  height: fit-content;
  width: auto !important;
  margin: 20vh 20px 30px !important;

  @media (min-width: $breakpoint-xs) {
    width: 320px !important;
    margin: 30vh auto 30px !important;
  }
}

.body {
  text-align: center;

  .text {
    margin: 0;
    padding: 0;
    font-size: tokens.$font-size-body-md;
    line-height: tokens.$font-line-height-body-md;
    color: tokens.$color-gray-800;
  }
}

.buttons {
  display: flex;
  flex: 1;
  justify-content: space-between;

  // todo remove this hack later and revert the commit
  // todo investigate since what moment has styles ordering (priority) changed
  .confirmButton {
    color: tokens.$color-orange-dark-400;
    box-shadow: inset 0 0 0 1px tokens.$color-orange-dark-400;
    margin-right: 8px;

    &:hover:not(:disabled) {
      color: tokens.$color-orange-dark-300;
      box-shadow: inset 0 0 0 1px tokens.$color-orange-dark-300;
    }
  }
}

// TODO(color): use token
$cancel-color: grey;

.button {
  width: 100%;
}
