@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  height: 100px;
  aspect-ratio: 9 / 16;

  border-radius: 4px;

  background: #172b4d;
  color: white;

  overflow: hidden;
}

.hidden {
  display: none;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  user-select: none;
  z-index: 1;
}

.progressBar {
  height: 4px;
  margin: 0 8px;

  &::after {
    background-color: tokens.$color-blue-500;
  }
}

.invalidThumbnailWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  & .icon {
    width: 100%;
  }
}
