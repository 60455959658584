@use '@restream/styles/scss/tokens';
@use '../../../../scss/mixins' as mixins;

$buttonHeight: 36px;

.root {
  display: flex;
}

.actionButton {
  height: $buttonHeight;

  font-weight: 500;
  font-size: 14px;
  color: white;

  &.withMultiSelect {
    border-right: 1px solid #0e2347;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.dropdown {
  min-width: 250px;

  background: tokens.$color-gray-25;
  padding: 8px 0;

  box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.25);
  border-radius: tokens.$border-radius-200;
  box-sizing: border-box;
}

.dropdownItem {
  height: 36px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  padding: 8px 16px;
  color: tokens.$color-gray-700;
  margin: 0;

  &:hover {
    background: tokens.$color-gray-100;
  }
}

.dropDownItemIcon {
  width: 20px;
  margin-right: 10px;
}

.dropdownButton {
  @include mixins.button-reset;

  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
}

.dropdownItemIcon {
  & path,
  & g {
    fill: tokens.$color-gray-700;
  }
}

.dropdownTriggerButton {
  background: tokens.$color-gray-600;
  height: $buttonHeight;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:not(:disabled):hover {
    background: tokens.$color-gray-500;
  }
}
