@use '@restream/styles/scss/tokens';

.root {
  display: grid;
  grid-template-columns: 55% 140px auto auto;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 tokens.$color-gray-700;
  padding: 12px 16px;
  column-gap: 16px;
  background: none;
  color: tokens.$color-gray-300;

  &.isInAccordion {
    padding-right: 0;
    padding-left: 30px;
  }
}

.cell {
  display: flex;
  font-size: tokens.$font-size-body-sm;
  line-height: 16px;

  &:last-of-type {
    justify-content: flex-end;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.avatarWrapper {
  margin-right: 8px;
}

.title {
  color: #fff;
}

.text,
.description {
  font-size: 12px;
  line-height: 16px;
}

.description {
  &.warning {
    color: tokens.$color-yellow-400;
  }

  &.danger {
    color: tokens.$color-red-400;
  }
}

.text {
  &.withMinWidth {
    display: inline-block;
    min-width: 48px;
  }

  &.capitalize {
    text-transform: capitalize;
  }
}
