@use '@restream/styles/scss/outline';
@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/colors';

$input-height: 28px;

.inputWrapper {
  border-color: #a5adba;
  padding: 6px 12px;
  border-radius: 8px;
  min-height: 80px;

  &.focused {
    border-color: #286fff;
  }

  &.error {
    border-color: colors.$color-border-error;
  }

  & > span[data-placeholder] {
    left: initial;
    top: 10px;
    padding: 2px;
  }
}

.input {
  font-size: 14px;
  line-height: 20px;
  color: #091e42;
}

.errorIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: $input-height;
  width: $input-height;
  background: rgba(225, 31, 90, 0.1);
  margin-right: 8px;
}

.errorIcon {
  height: 18px;
  color: rgb(225, 31, 90);
}

.emailWrapper {
  height: $input-height;
  padding: 2px 8px !important;
  overflow: hidden;
  cursor: pointer;
  margin: 2px 4px 2px 0 !important;

  &.error {
    padding-left: 0 !important;
    border: 1px dashed rgba(224, 30, 90, 0.3);
    background-color: white;

    &:hover {
      background: rgba(224, 30, 90, 0.1);
    }
  }
}

.emailText {
  font-size: 14px;
  font-weight: 500;
  color: tokens.$color-gray-900;
  line-height: 20px;
}

.emailCloseButton {
  @include outline.focus-visible-outline;

  color: inherit;
  padding: 0 2px;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-left: 8px !important;
}
