@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/mixins';
@import '@restream/styles/scss/animation';
@import '@restream/styles/scss/colors';

$transition-time: 0.25s;
$opacity-disabled: 0.5;

// Light theme - default

.root {
  position: relative;
  display: flex;
  width: 100%;
}

.wrapper {
  display: flex;
  flex: 1;
  background-color: tokens.$color-white;
  color: tokens.$color-gray-900;
  border-radius: tokens.$border-radius-200;
  padding: 10px 12px;
  transition: all $animation-default-time;

  // TODO(color): use token
  @include border-shadow($color-border-default);

  &.error {
    @include border-shadow($color-border-error);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.leftComponent {
  display: flex;
  margin-right: 4px;
}

.rightComponent {
  display: flex;
  margin-left: 4px;
}

.inputWrapper {
  display: flex;
  flex: 1;
}

.input {
  font-family: inherit;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;

  width: 100%;
  transition: all 0.3s;
  border-radius: tokens.$border-radius-200;
  outline: none;
  color: inherit;
  cursor: inherit;

  background-color: transparent;
  border: none;
}

.input:focus {
  // TODO(color): use token
  border-color: $color-border-focus;
}

.input.error {
  // TODO(color): use token
  border-color: $color-border-error;
}

.input::placeholder {
  // TODO(color): use token
  color: rgba(9, 30, 66, 0.25);
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
}

// Dark theme
:global(.dark-theme) {
  .input {
    // TODO(color): use token
    background: $bg-color--lighten-1;
    color: tokens.$color-gray-900;
  }

  .input::placeholder {
    // TODO(color): use token
    color: $fg-color--darken-3;
  }

  .input.error {
    // TODO(color): use token
    border-color: $color-border-error-dark;
  }

  .wrapper {
    // TODO(color): use token
    background: $bg-color--lighten-1;
    box-shadow: none;
  }

  .wrapper.error {
    // TODO(color): use token
    @include border-shadow($color-border-error-dark);
  }
}

.wrapper:focus-within:not(.error) {
  // TODO(color): use token
  @include border-shadow($color-border-focus);
}

.counter {
  position: absolute;
  right: 8px;
  bottom: -8px;
  padding: 2px 4px;

  font-size: tokens.$font-size-body-xs;
  line-height: 1;
  font-family: inherit;

  // TODO(color): use token
  background: $color-border-default;
  color: tokens.$color-white;
  border-radius: tokens.$border-radius-200;
  transition: background-color $transition-time;
}

.input:focus + .counter {
  // TODO(color): use token
  background: $color-border-focus;
}

.wrapper.error + .counter {
  // TODO(color): use token
  background: $color-border-error;
}

.input:disabled + .counter {
  opacity: $opacity-disabled;
}
