.root {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #97a0af;
  display: flex;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}
