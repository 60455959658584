@use '@restream/styles/scss/tokens';

.root {
  @media (min-width: 1100px) {
    width: 1005px;
  }
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.mb10 {
  margin-bottom: 10px;
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #253858;
  box-sizing: border-box;
  border-radius: tokens.$border-radius-200;
}

.blockItem {
  margin-right: 20px;
}

.blockText {
  font-weight: 400;
  font-size: 13px;
  color: #fff;
  line-height: 20px;
}

.removeCardButton {
  background-color: transparent;
  color: #fff;

  &:hover {
    background-color: transparent !important;
  }
}

.button {
  height: 40px;
}

.uppercase {
  text-transform: uppercase;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  max-width: 1005px;
}
