@use '@restream/styles/scss/tokens';

.streamingRtmpSetting {
  max-width: 600px;
  margin-bottom: 60px;
}

.speedTest {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 12px;
  align-items: center;
}

.speedTestImageBlock {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #253858;
}

.speedTestImage {
  width: 24px;
  height: 24px;

  .speedTestImageBlock & {
    position: relative;
    bottom: 2px;
  }
}

.speedTestTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
}

.speedTestDescription {
  font-size: 14px;
  line-height: 20px;
  color: #aeb4bf;
}

.titleWrapper {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  color: var(--Gray-25, var(--Gray-25, #fafbfc));

  /* Body xl - 20/Normal */
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-6, 20px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-7, 28px); /* 140% */
  margin-right: 20px;
}

.description {
  color: var(--Gray-300, #bac1cc);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;

  margin-bottom: 16px;

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}

.eventSelectWrapper {
  margin-bottom: 16px;
}

.separator {
  margin-bottom: 24px;
  margin-top: 24px;
  opacity: 0.1;
}

.promoBanner {
  margin-bottom: 16px;
}

.contactSalesLink {
  background: none;
  border: none;
  padding: 0;
  color: tokens.$color-blue-400;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.backupStreamIcon {
  width: 14px;
  height: 14px;
}

.backupStreamWrapper {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}
