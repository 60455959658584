@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);

  position: sticky;
  z-index: 11;
  top: 0;
  width: 100%;
  margin-bottom: 14px;
  backdrop-filter: blur(16px);
}

.tabsRoot {
  margin: 0 !important;
  border: none;
}

.tabs {
  width: 100%;
  border: none;

  .tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #d7dce3;

    padding: 10px 0;
    border: none;
    text-transform: capitalize;
    border-bottom: 2px solid transparent;

    transition: 0.2s all ease-in-out;

    &:first-child {
      margin-right: 32px;
    }

    &:hover {
      color: #f5f6f8;
      border-color: #f5f6f8;
    }
  }

  .activeTab {
    color: #84adff;
    border-color: #84adff;
    cursor: default;

    &:hover {
      color: #84adff;
      border-color: #84adff;
    }
  }
}

.createEventButton {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: tokens.$color-white;
  height: fit-content;

  margin-right: 8px;
  text-align: right;
  letter-spacing: 0.01em;
  padding: 0;

  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover:not(&:disabled) {
    text-decoration: underline;
    background: none;
    border: none;
    outline: none;
    color: tokens.$color-white;
  }
}
