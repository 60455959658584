@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 16px;

  background: #fffaeb;

  border: 1px solid #fedf89;
  border-radius: tokens.$border-radius-200;

  margin-bottom: 24px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: #091e42;

  padding: 0 0 0 16px;
}
