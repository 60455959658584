.root {
  position: relative;
  //line-height: 48px;
  text-align: center;
  text-decoration: none;
  appearance: none;
  cursor: pointer;

  transition: background 0.2s ease;
  font-family: Graphik-Bold-Web, Helvetica, Arial, sans-serif;
  font-weight: 400;

  display: flex;
  padding: 0 12px;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  color: #3c4043;
  background: #fff;
  border: 1px solid #dadce0;
  letter-spacing: 0.25px;
  border-radius: 4px;
  width: 300px;
  max-width: 400px;
  min-width: min-content;

  &:hover {
    background: rgba(66, 133, 244, 0.04) !important;
    box-shadow: none;
    border-color: #d2e3fc;
    outline: none;
  }

  svg {
    position: absolute;
    scale: 0.8;
    left: 12px;
  }
}
