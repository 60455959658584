@use '@restream/styles/scss/tokens';

.errorText {
  font-size: 12px;
  color: #ff8f73;
}

.text {
  font-size: 12px;
}

.moreButton {
  margin-right: -8px;
}

.descriptIconWrapper {
  width: 20px;
  padding-left: 2px;
  display: flex;
  justify-content: center;
}

.saveIcon {
  &.withFill {
    & path {
      fill: tokens.$color-gray-700;
    }
  }
}

.deleteItem,
.cancelItem {
  color: tokens.$color-red-600;
}
