@use '@restream/styles/scss/tokens';
@import '../../../components/scss/modals';

.body {
  box-sizing: border-box;
  color: white;
}

.text {
  color: tokens.$color-gray-900;
  margin-bottom: 24px;
  span {
    font-weight: 500;
  }
  &.more {
    text-align: center;
    margin-top: 18px;
    font-size: 14px;
    margin-bottom: 12px;
    color: tokens.$color-gray-400;
  }
  &.attention {
    margin-bottom: 12px;
  }
}

.buttons {
  button:first-child {
    margin-right: 8px;
  }
}
