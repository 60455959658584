.body {
  padding: 12px 24px;
}

.form {
  display: grid;
  grid-gap: 12px;
}

.description {
  margin: 12px 0;
  font-size: 14px;
  color: #172b4d;

  &.confirmation {
    text-align: center;
    max-width: 360px;
    margin: 0 auto;
  }

  & .bold {
    font-weight: 600;
  }
}

.contactUsButton {
  all: unset;
  text-decoration: underline;
  cursor: pointer;
}

.field {
  display: grid;
  grid-gap: 4px;
}

.inputTitle {
  font-weight: 500;
  font-size: 13px;
  color: #6c798f;
  letter-spacing: 0.01em;
  box-shadow: none;
}

.inputWrapper {
  height: 40px;
  cursor: text;

  &.invalid {
    box-shadow: 0 0 0 1px #fc8471 !important;
    border-color: transparent;
  }
}

.cancelButton {
  margin-right: 8px;
  box-shadow: none;
  min-width: auto;
  color: #505f79;
  transition: background-color 200ms ease;

  &:hover {
    background-color: rgba(9, 30, 66, 0.08) !important;
    color: #505f79 !important;
  }
}

.confirmButton {
  min-width: auto;
  background-color: #de350b;

  &:hover {
    background-color: #bf2600 !important;
  }
}
