@use '../../../../components/scss/modals' as modals;
@use '../../../../styles/mixins' as mixins;

.root {
  @include modals.modal-width;
}

.text {
  padding: 0;
  margin: 0;
  text-align: center;

  font-size: 16px;
  font-weight: normal;
  line-height: 1;
}
