@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/mixins';

.root {
  padding: 6px 8px;
  display: flex;
  align-items: center;

  border: none;
  margin: 0;
  background: none;
  cursor: pointer;

  width: 100%;

  font-weight: normal;
  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
  color: tokens.$color-gray-700;

  &:hover {
    background: tokens.$color-gray-50;
  }
}

.content {
  @include mixins.truncate-text;

  &.withIcon {
    margin-left: 8px;
  }
}

.labelWrapper {
  margin-left: 8px;
}
