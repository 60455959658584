@import '@restream/styles/scss/animation';

.root {
  position: relative;
  overflow: auto;

  transition: all $animation-default-time;
  width: 100%;
}

.content {
  position: absolute;
  width: 100%;

  transition: all $animation-default-time;
}
