@use '@restream/styles/scss/tokens';

.upgradeText {
  color: tokens.$color-gray-600;
  font-size: 14px;
  line-height: 20px;

  & .button {
    color: tokens.$color-blue-700;

    &:focus-visible,
    &:hover:not(&.disabled) {
      text-decoration: none;
      color: tokens.$color-blue-800;
    }
  }
}

.upgradeLink {
  color: tokens.$color-blue-700;

  &:hover {
    text-decoration: underline;
  }
}
