@use '@restream/styles/scss/tokens';

.root {
  display: flex;
  width: 100%;
}

.content {
  padding: 16px;
}

.content.light {
  background: tokens.$color-white;
  border-radius: tokens.$border-radius-200;
}

.title {
  padding: 0;
  margin: 0 0 16px;

  color: tokens.$color-white;
}

.light {
  .title {
    // TODO(color): use token
    color: #0a1e42;
  }
}

.subtitle {
  padding: 0;
  margin: 24px 0 16px;

  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  color: tokens.$color-white;
  opacity: 0.7;
}

.light {
  .subtitle {
    // TODO(color): use token
    color: #0a1e42;
  }
}
