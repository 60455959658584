@use '@restream/styles/scss/tokens';

.dropdownMenuContent {
  min-width: 160px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: rgba(255, 255, 255, 0.9);
  overflow: clip;
  z-index: 100;

  // apply blur with a 100ms delay to force browser repaint
  // this is necessary because Chromium browsers do not apply blur immediately if the parent has opacity 0, causing no blur effect until the first repaint (e.g., on hover).
  animation: showBlurBackground 0s 100ms forwards;
}

@keyframes showBlurBackground {
  from {
    backdrop-filter: blur(0);
  }

  to {
    backdrop-filter: blur(16px);
  }
}

.focusOutline {
  border-radius: tokens.$border-radius-200;
  outline: 2px solid #2970ff;
}

.dropdownMenuItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  color: tokens.$color-gray-900;
  cursor: pointer;
  gap: 8px;
  border-radius: 4px;

  &,
  &:hover {
    transition: background-color 0.2s ease-out;
  }

  &:hover {
    background-color: tokens.$color-white;
    outline: none;
  }

  & svg {
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  & > svg {
    flex-shrink: 0;
  }

  .btnContainer[data-state='open'] & {
    background-color: #374866;
  }

  &.danger {
    color: tokens.$color-red-600;
  }

  &.danger:hover {
    background-color: tokens.$color-red-50;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.disabled::selection {
    background-color: transparent;
  }
}

.dropdownMenuSeparator {
  display: block;
  height: 1px;
  background-color: #eee;
}

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  height: 22px;
  border-radius: 8px;
  line-height: 1.4;
  background-color: #2970ff;
}

.deleteItem {
  color: tokens.$color-red-600;
}

.dropdownButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #fff;
  border: 0;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &.light {
    & .dropdownButtonIcon {
      color: tokens.$color-gray-300;
    }
  }

  .btnContainer[data-state='open'] &,  // button container is for a div that wraps the button becaus. Some uikit buttons do not support forwardRefs
  &:active,
  &:focus,
  &:hover,
  &:focus-within {
    outline: none;
    background-color: #374866;

    &.light {
      background-color: tokens.$color-gray-100;

      & .dropdownButtonIcon {
        color: tokens.$color-gray-500;
      }
    }
  }

  & .srOnly {
    display: none;
  }
}

.dropdownButtonIcon {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  transition: all 0.2s ease-out;
}

.btnContainer {
  display: inline-block;
}
