@use '@restream/styles/scss/mixins';

.root {
  @include mixins.modal-width;
}

.cardFormGroup {
  width: 100%;
}

.buttonLoader {
  margin-right: 8px;
}
