.root {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 20px;
  color: #0a1e42;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.01em;
  text-align: center;

  transition: all 200ms;
}

.root.zeroMargin {
  margin-bottom: 0;
}
