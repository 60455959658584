@use '@restream/styles/scss/tokens';

.videoControlsContainer {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 65px;
  flex-wrap: wrap;
  margin-top: -65px;
  margin-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
  background: linear-gradient(
    to bottom,
    rgba(45, 45, 47, 0) 0%,
    rgba(45, 45, 47, 0.24) 100%
  );

  &:hover .VideoBuffered {
    transition: opacity 0.2s linear;
    opacity: 0.4;
  }
}

.videoControlsContentWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.timestamp {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  & > span {
    position: relative;
    display: inline-block;
    min-width: 80px;
    text-align: center;
  }

  & > span:first-of-type:after {
    position: absolute;
    right: -3px;
    content: '/';
  }
}

.buttonMute {
  color: #fff !important;
  opacity: 0.5;
  background-color: transparent !important;
  transition: opacity 0.2s linear;

  &:hover,
  &:focus-within {
    opacity: 1;
  }
}

.volumeSlider {
  appearance: none;
  width: 100px;
  height: 4px;
  background-color: rgba(tokens.$color-gray-300, 0.2);
  outline: none;
  border-radius: 4px;
}

.volumeSlider::-webkit-slider-thumb {
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: tokens.$color-blue-600;
  border-radius: 50%;
  cursor: pointer;
}

.VolumeSlider::-moz-range-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: tokens.$color-blue-600;
  border-radius: 50%;
  cursor: pointer;
}

.popoverContent {
  width: 120px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 0;
  &:focus {
    outline: none;
  }
}

.popoverContent[data-side='right'] {
  animation-name: slideUp;
}

.popoverContent[data-side='center'] {
  animation-name: slideDown;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateZ(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateZ(10px);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.videoBuffered {
  width: 100%;
  height: 4px;
  padding: 0;
  margin: 16px 10px 0;
  opacity: 0;
  border-radius: tokens.$border-radius-200;
  background-color: rgba(tokens.$color-gray-100, 0.15);
  transition: opacity 0.2s linear;
}
