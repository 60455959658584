@use '@restream/styles/scss/tokens';

.event {
  display: flex;

  background: tokens.$color-gray-100;
  border: 1px solid tokens.$color-gray-200;
  border-radius: tokens.$border-radius-200;
  padding: 8px;

  margin-bottom: 22px;

  &:last-child {
    margin-bottom: 0;
  }
}

.thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  height: 45px;
  width: 78px;

  overflow: hidden;

  border-radius: 4px;

  & figure {
    border-radius: 4px;
  }

  img {
    height: 45px;
    max-width: 78px;
  }
}

.eventThumbnail {
  flex: 1;
  border-radius: tokens.$border-radius-200;
}

.info {
  display: flex;
  flex-direction: column;

  gap: 4px;

  margin-left: 12px;
}

.title {
  font-style: normal;
  font-weight: 500;
  color: tokens.$color-gray-900;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.scheduledTime {
  height: 18px;

  display: flex;
  align-items: center;

  color: tokens.$color-gray-900;

  /* Body xs - 12/Normal */
  font-family: Graphik, sans-serif;
  font-size: var(--font-size-10, 12px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 18px; /* 150% */
}

p.eventType {
  color: tokens.$color-gray-900;
}
