$transition: 300ms ease;
.root {
  width: 500px;
  background: #fff;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 20px;

  opacity: 0;
  will-change: opacity;
  transition: opacity $transition;
}

.shouldShow {
  cursor: default;
  pointer-events: auto;
  opacity: 1;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 8px;
  min-height: 197px;
}

.image {
  width: 280px;
}

.container {
  padding: 24px 24px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #172b4d;
  padding: 0;
  margin: 0 0 16px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #172b4d;
  padding: 0;
  margin: 0;

  strong {
    font-weight: 500;
  }
}

.footer {
  display: flex;
  padding: 0 24px 24px 24px;
}
