@use '../../../scss/colors' as colors;
@use '../../../scss/grid' as grid;

.container {
  color: colors.$error-color;
  display: flex;
  align-items: center;
}

.link {
  color: colors.$fg-color--darken-1;
  cursor: pointer;
  margin-left: grid.$grid-base-value;

  &:hover {
    text-decoration: underline;
  }
}
