@use '@restream/styles/scss/tokens';
@import '@restream/styles/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  padding-bottom: 16px;
  position: relative;

  &.noMarginBottom {
    margin-bottom: 0;
  }

  &.noPaddingBottom {
    padding-bottom: 0;
  }
}

.error {
  position: absolute;
  bottom: -4px;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;

  // TODO(color): use token
  color: $color-border-error;
}
