.listLoaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
}

.customLoader {
  & circle {
    stroke: white;
  }
}
