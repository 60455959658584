.root {
  min-width: 500px;

  @media (max-width: 765px) {
    min-width: 100%;
  }
}
.body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 680px;

  @media (max-width: 765px) {
    min-height: 100%;
  }

  .clockWrapper {
    margin: 24px auto;
    width: 148px;
  }

  .waitAMinute {
    width: 100%;
    text-align: center;
  }
}

.customLoader {
  & circle {
    stroke: rgba(0, 0, 0, 0.5);
  }
}

.modalMainTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #091e42;
}
