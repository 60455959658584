@use '@restream/styles/scss/tokens';
@use '~@restream/styles/scss/colors' as colors;
@use '~@restream/styles/scss/colors' as scssColors;
@use '~@restream/styles/scss/media';
@use '~@restream/styles/scss/outline';

$font-color: #ebecf0;

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;

  width: 147px;
  height: 147px;

  padding: 24px 14px;
  border-radius: tokens.$border-radius-200;

  background: colors.$bg-color--lighten-1;
  color: $font-color;
  transition: all 0.3s ease-in;
  cursor: pointer;
  border: 0;

  &:hover {
    background: rgba(40, 111, 255, 0.8) !important;
    color: #fff;
  }

  &:hover ~ .prime {
    background: colors.$bg-color--lighten-1 !important;
  }

  @include outline.focus-visible-outline;

  @include media.xs {
    padding: 12px;
  }
}

.prime {
  background: #286fff; // #04c;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  //grid-gap: 12px;
  //margin-top: 16px; // synthetic offset from top
}

.image {
  margin-right: 14px;
  width: 32px;
  height: 32px;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  letter-spacing: normal;
  color: $font-color;
  transition: 0.2s color ease-in-out;

  @include media.xs {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}

.secondary {
  background: colors.$bg-color--lighten-1 !important;
}

@mixin biggerButtons {
  .content {
    flex-direction: column;
  }

  .text {
    text-align: center;
    margin-top: 12px;
  }

  .image {
    margin-right: 0;
    width: 48px;
    height: 48px;
  }
}

@media (min-width: media.$breakpoint-xs) {
  @include media.lg {
    @include biggerButtons;
  }
}

.bigger {
  @include biggerButtons;
}
