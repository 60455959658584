@use '@restream/styles/scss/tokens';

.getProTrigger {
  margin-top: 1px;
}

.getProIcon {
  margin-bottom: 1px;
  will-change: transform;
  transition: transform 200ms ease;

  .getProTrigger:hover & {
    animation: pulse 1.1s ease;
  }
}

@keyframes pulse {
  60% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

.mobileHeader {
  display: block;

  border-radius: 8px;
  background: linear-gradient(
    45deg,
    #7c6ff4 0%,
    #7764f2 25.45%,
    #7756ed 49.54%,
    #9151ed 74.34%,
    #c553f3 100%
  );
  height: 40px;

  .getProContentTrigger {
    background: transparent;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 2px;

    & > span {
      color: tokens.$color-white;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-left: 0;
    }
  }

  .getProIcon {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
