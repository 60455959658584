@use '../../../../components/scss/modals' as modals;
@use '../../../../styles/mixins' as mixins;

.root {
  @include modals.modal-width;
}

.steps {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #a5adba;
  margin-bottom: 32px;
}

.bottomFormContent {
  display: flex;
  justify-content: center;

  &.contentRight {
    justify-content: flex-end;
  }

  .buttonLinkLabel {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-right: 4px;
    letter-spacing: 0.01em;
    color: #172b4d;
  }

  .buttonLink {
    @include mixins.button-reset;
    @extend .buttonLinkLabel;

    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}

.oauthSignInRoot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .oauthButton {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .orText {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    max-width: 300px;
    width: 100%;
    text-transform: uppercase;

    &:before {
      position: absolute;
      left: 0;
      content: '';
      width: 40%;
      height: 1px;
      background: #ebecf0;
    }

    &:after {
      position: absolute;
      right: 0;
      content: '';
      width: 40%;
      height: 1px;
      background: #ebecf0;
    }
  }
}
