@use '@restream/styles/scss/tokens';

.root {
  border-radius: tokens.$border-radius-200;
  display: flex;
  flex-direction: column;

  transition: all 300ms;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;

  position: relative;
}

.imageWrapper {
  display: flex;
  width: 100%;
}

.image {
  height: 100%;
}

.description {
  border-radius: 0 0 tokens.$border-radius-200 tokens.$border-radius-200;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.destinationsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.renderInlineActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.infoBlock {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.actionButtonsWrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.thumbnailWrapper {
  display: flex;
  width: 100%;
}

.isModal {
  padding: 16px 16px 0;
}

.chatBlockWrapper {
  display: flex;
  width: 100%;
}

.separator {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #35445d;
    left: 0;
    top: 0;
  }
}
