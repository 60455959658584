@use '@restream/styles/scss/tokens';

.content {
  border-radius: 12px;
  padding: 12px 32px 12px 12px;
  width: 260px;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  display: flex;
  gap: 14px;

  z-index: 2;
}

.content[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}

.content[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}

.content[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}

.content[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.arrow {
  fill: white;
}

.title {
  text-align: left;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 4px;

  color: tokens.$color-gray-900;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.description {
  color: tokens.$color-gray-700;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.descriptionHighlight {
  font-weight: 500;
}

.closeButton {
  margin: 0;
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;

  right: 8px;
  top: 8px;
  position: absolute;

  display: flex;
  border-radius: 6px;

  &:hover {
    background: #e2e2e9;
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
