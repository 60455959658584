.overlay {
  padding-top: 24px;
}

.destinations {
  position: relative;
  max-height: 400px;
  overflow: auto;
  padding: 0;
  box-shadow: inset 0 0 4px #d6d6d6;

  margin: 0 0 24px;
}

.destination {
  display: flex;

  padding: 12px 28px;
}

.destination:not(:last-child) {
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.07);
}

.description {
  display: flex;
  flex: 1;
  flex-direction: column;

  min-width: 0;
}

.channelName {
  margin: 0;

  font-size: 14px;
  line-height: 24px;
  color: #344563;

  &:hover,
  &:focus,
  &:active {
    color: #344563;
  }
}

.titlePlaceholder {
  margin: 0;

  color: #a5adba !important;
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.platformIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.errorCallout {
  padding: 20px;
}

.editButtonContainer {
  padding: 0 24px;
  margin-bottom: 24px;
}
